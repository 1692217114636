import { useState,useEffect,useRef } from "react";
import { GrFilter } from "react-icons/gr";
import useFormTemplateTabHook from "hooks/FormTemplateTab.hook";
import { _sortItems } from "constant/Methods";
import TemplateListTable from "./TemplateListTable";
import CreateTemplate from "./CreateTemplate";
import Spinner from "components/spinner";
import { Button, Divider, Grid,TextField , IconButton ,Autocomplete,Chip, Stack } from "@mui/material";
import {  Close } from "@mui/icons-material";

import DialogComponent from '../Shared/DialogComponent';
import ChecklistFormTemplate from './ChecklistFormTemplate';
import PublishTemplateModal from './PublishTemplateModal';

const TEMPLATE_TYPES = {
  pdfForm: 'PDF',
  checklistForm: 'Checklist',
};

const FormTemplateTab = (props) => {
  const {createPopEnable, tableFormList, _handleOpenCreate, credential,
         closeAndRefresh, showSpinner, setSpinner , forTabHook } = useFormTemplateTabHook(props);
  const [formTemplateId, openedFormTemplate] = useState(null);
  const [viewListData, setViewListData] = useState([]);
  //const [filteredList, setFilterList] = useState([]);
  var filteredList = useRef([]);
  //const [filterObject, setFilterObject] = useState([]); // [{fieldName : 'filterField1' , values : ['value 1' , 'value 2']} , {fieldName : 'filterField2' , values : ['value 1']}]
  var filterObject = useRef([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterPanel, enableFilterPanel] = useState(false);

  const [openTemplateType, setOpenTemplateType] = useState(false);
  const [selectedTemplateType, setSelectedTemplateType] = useState('');
  
  const [formTemplateId_pub, setFormTemplateId_pub] = useState(null);
  const [openPublishTemplate, setOpenPublishTemplate] = useState(false);

  const handleOpenCreateTemplate = () => {
    setOpenTemplateType(true);
  };

  const handleCloseCreateTemplate = () => {
    setOpenTemplateType(false);
  };

  const handleTemplateTypeSelection = type => {
    setSelectedTemplateType(type);
    handleCloseCreateTemplate();
    _handleOpenCreate(true);
  };
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    let columns_ = [
      { accessor: "title", Header: "Title", flex: 1,
        sortType: (prev, curr, columnId) => _sortItems(prev, curr, columnId)
      },
      { accessor: "formType", Header: "Template Type", flex: 1},
      { accessor: "type", Header: "Type", flex: 1 },
      { accessor: "createdBy", Header: "Created By", width:'150' ,flex: 1 },
      { accessor: "lastUpdatedBy", Header: "Last Updated By", width:'150' ,flex: 1 },
      { accessor: "lastUpdatedDate", Header: "Last Updated Date", flex: 1 , width:'200'}
    ];
    if(credential?.ProfileName == 'System Administrator'){
      columns_.push(
        { accessor: "publishCount", Header: "Projects", flex: 1 , width:'150'} // ,disableSortBy : true
      );
      columns_.push(
        { accessor: "itemCount", Header: "Items", flex: 1 , width:'150'}
      );
      columns_.push(
        { accessor: "formCount", Header: "Instances", flex: 1 , width:'150'}
      );
    }
    columns_.push(
      { accessor: "id", Header: "", flex: 1 , width:'200',
        Cell: (params_) => {
          let params = { id: params_.row.original.id, row: params_.row.original };
          return (
            <Button variant="outlined" onClick={(e) =>{ e.stopPropagation();setFormTemplateId_pub(params.id); setOpenPublishTemplate(true);} }>
              Publish
            </Button>
          );
        }
      }
    );
    setColumns(columns_);
  }, []);
  useEffect(() => {
    //console.log('projectId :: '+projectId);
    filterView();
  }, [tableFormList]);

  function _handleFilterSelection(event ,newValue, fieldName) {
    const valuee = newValue;
    //console.log('valuee',JSON.stringify(valuee));
    
    let toTemp = [];
    let staticValues = [];
    if(valuee && valuee.length > 0 ){
      for(let obj of valuee){
        staticValues.push(obj.value);
      }
    }
    if(filterObject && filterObject.current && filterObject.current.length > 0 ){
      toTemp = JSON.parse(JSON.stringify(filterObject.current));
    }
    const fInd = toTemp?.findIndex( (data) => data.fieldName == fieldName );
    if (fInd > -1) {
      toTemp[fInd].ele_values = valuee ;
      toTemp[fInd].values = staticValues ;
    }else{
      toTemp.push({fieldName : fieldName , values : staticValues  , ele_values : valuee });
    }
    const fInd2 = toTemp?.findIndex( (data) => data.fieldName == fieldName );
    if (fInd2 > -1) {
      if( !(toTemp[fInd2].values && toTemp[fInd2].values.length > 0) ){
        toTemp.splice(fInd2, 1);
      }
    }
    //setFilterObject(toTemp);
    filterObject.current = toTemp;
    filterView();
  }

  function filterView(_filterObject = filterObject.current) {
    let filterData = [];
    setSearchTerm(null);
    if(tableFormList?.length > 0 ){
      if(_filterObject && _filterObject.length > 0 ){
        for(let item of tableFormList){
          let push = false ;
          let filCount = 0;
          let matchCount = 0;
          for(let fil of _filterObject){
            if(fil.values && fil.values.length > 0){
              filCount++;
              for(let check of fil.values){
                if(item[fil.fieldName]?.toLowerCase().indexOf(check.toLowerCase()) > -1){
                  matchCount++;
                }
              }
            }
          }
          if(filCount == matchCount){
            filterData.push(item);
          }
        }
      }
    }
    //console.log('filterData :: '+filterData?.length);
    //setFilterList(filterData);
    filteredList.current = filterData ;
    filterDataFuntion('');
  }
  function filterDataFuntion(_searchTerm , _filteredList = filteredList.current) {
    let orgData = filterObject.current && filterObject.current.length > 0 ? JSON.parse(JSON.stringify(_filteredList)) : JSON.parse(JSON.stringify(tableFormList));
    //console.log('_filteredList :: '+_filteredList?.length);
    //console.log('tableFormList :: '+tableFormList?.length);
    let filterData = orgData;
    if (orgData && _searchTerm && _searchTerm != null && _searchTerm !== "") {
      filterData = [];
      for (let el of orgData) {
        if ( (el.name && el.name.toLowerCase().includes(_searchTerm)) || (el.title && el.title.toLowerCase().includes(_searchTerm)) ) {
          filterData.push(el);
        }
      }
    }
    if(!filterData){
      filterData=[];
    }
    setViewListData(filterData);
  }
  function getFilterObject(fieldName) {
    const fInd = filterObject.current?.findIndex( (data) => data.fieldName == fieldName );
    if (fInd > -1) {
      if(filterObject.current[fInd].values && filterObject.current[fInd].values.length > 0){
        return filterObject.current[fInd];
      }
    }
    return;
  }
  const handleCloseMenu = () => {
    let hdds = true;
    console.log("handleCloseMenu handleCloseMenu", hdds);
    setOpenPublishTemplate(false);
    setFormTemplateId_pub(null);
    closeAndRefresh(hdds);
    //setSelectedMenuRow(null);
  };
  function getFilterValues(fieldName , asOption) {
    let values = [];
    let obj = getFilterObject(fieldName);
    if(obj){
      values = obj.values;
      if(asOption){
        values = obj.ele_values;
      }
    }
    return values;
  }

  return (
      <>
        <div className="container">
          {!createPopEnable &&
            <>
              <div className="table_head">
                <div className="left_side">
                  <div>
                    <IconButton onClick={() => enableFilterPanel(!filterPanel)}>
                      <GrFilter />
                    </IconButton>
                  </div>
                  <div>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      label="Search"
                      onChange={(e) => {
                        //convert input text to lower case
                        var lowerCase = e.target.value.toLowerCase();
                        if (!lowerCase || lowerCase == null || lowerCase === "") {
                          //console.log(lowerCase);
                          filterDataFuntion(lowerCase , filteredList.current);
                        }
                        setSearchTerm(lowerCase);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          // Do code here
                          e.preventDefault();
                          filterDataFuntion(searchTerm , filteredList.current);
                        }
                      }}
                    />
                  </div>
                  {filterObject.current?.length > 0 &&
                    <div>
                      <Chip
                        color="success"
                        label="Filtering is on"
                        onDelete={() => { /*setFilterObject([]);*/filterObject.current = []; filterView([]); enableFilterPanel(false)}}/>
                    </div>
                  }
                </div>
                <div className="right_side">
                  <Button variant="contained" component="span" onClick={() => { openedFormTemplate(null); handleOpenCreateTemplate(); }}>
                    Create Template
                  </Button>
                </div>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
              {filterPanel && (
                <div style={{ width: "20%" }}>
                  <div className="versions-sidebar-header">
                    <span className="versions-sidebar-header-title">
                      Filter
                    </span>
                    <IconButton
                      size="small"
                      className="versions-sidebar-header-close"
                      onClick={() => enableFilterPanel(!filterPanel)}
                    >
                      <Close />
                    </IconButton>
                  </div>
                  <Divider variant="middle" />
                  <div
                    className="versions-sidebar-body"
                    style={{ height: "calc(91.7vh - 200px)" }}
                  >
                    <Grid
                      container
                      rowSpacing={2}
                      style={{ width: "100%", paddingTop: "15px" }}
                      className="sf-detail-container"
                    >
                      <Grid item xs={12}>
                        {forTabHook?.templateTypeOptions &&
                          forTabHook?.templateTypeOptions.length > 0 && (
                            <div style={{ paddingTop: "10px" }}>
                              <Autocomplete
                                multiple
                                id="tags-standard"
                                className="inlineAutocomplete"
                                options={forTabHook?.templateTypeOptions}
                                getOptionLabel={(option) => option?.label}
                                defaultValue={getFilterValues("type", true)}
                                onChange={(e, newValue) => {
                                  _handleFilterSelection(e, newValue, "type");
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Type"
                                  />
                                )}
                              />
                            </div>
                          )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
             
                <div style={{ width: filterPanel ? "80%" : "100%" }}>
                  {showSpinner && <Spinner />}
                  <TemplateListTable
                    templateTablePorps={{
                      data: viewListData,
                      columns,
                    }}
                    openFormDetail={(row) => {
                      openedFormTemplate(row.id);
                      setSelectedTemplateType(row.formType);
                      _handleOpenCreate(true);
                    }}
                  />
                </div>
             
             
            </div>
            </>
          }
          {createPopEnable && selectedTemplateType === TEMPLATE_TYPES.pdfForm &&
            <CreateTemplate formTemplateId={formTemplateId} credential={credential}  formType={TEMPLATE_TYPES.pdfForm}
              handleClose={(refresh)=>{openedFormTemplate(null);closeAndRefresh(refresh);} }
              templateTypeOptions={forTabHook?.templateTypeOptions}
              defaultType={forTabHook?.defaultTemplateType}
            ></CreateTemplate>
          }
          {createPopEnable && selectedTemplateType === TEMPLATE_TYPES.checklistForm &&
            <ChecklistFormTemplate formTemplateId={formTemplateId} credential={credential}  formType={TEMPLATE_TYPES.checklistForm}
              handleClose={(refresh)=>{openedFormTemplate(null);closeAndRefresh(refresh);} }
              templateTypeOptions={forTabHook?.templateTypeOptions} defaultType={forTabHook?.defaultTemplateType}
              itemResponseTypeOptions={forTabHook?.sectionItemResponseOptions} defaultItemResponse={forTabHook?.defaultItemResponse} >
            </ChecklistFormTemplate>
          }
        </div>
        {openPublishTemplate &&
          <PublishTemplateModal formTemplateId={formTemplateId_pub} credential={credential}
            handleClose={handleCloseMenu}
          />
        }
        {openTemplateType &&
          <DialogComponent
            dialogProp={{
              opened: openTemplateType,
              handleClose: handleCloseCreateTemplate,
              titleContent: 'Select Type',
              classFor: 'template_type_selection',
              fullScreen: false,
            }}>
            <Stack className="template_type_selection_wrapper" spacing={2} direction="column">
              <Button variant="outlined" onClick={() => handleTemplateTypeSelection(TEMPLATE_TYPES.pdfForm)}>PDF Record/Template</Button>
              <Button variant="outlined" onClick={() => handleTemplateTypeSelection(TEMPLATE_TYPES.checklistForm)}>Checklist Record/Template</Button>
            </Stack>
          </DialogComponent>
        }
      </>
  );
};
export default FormTemplateTab;
