import React from "react";
import { FormControl, RadioGroup, FormControlLabel, FormHelperText, Radio } from "@mui/material";

const SingleSelectRender = ({ handleChange , req , ...props}) => {
  const { responseOptions,defaultValue,type, checklistFormobj } = props;

  return (
      <FormControl error={!(defaultValue && defaultValue !== '') && req}>
       { checklistFormobj.Status__c !== "Completed" &&(
        <RadioGroup
          aria-labelledby="preconfigured-answer-responses"
          name="Response__c"
          value={defaultValue}
          onChange={handleChange}
          required={req}
          row={type==='Preconfigured'}
        >
          {responseOptions.map((r) => (
            <FormControlLabel
              key={r.key}
              value={r.value}
              control={<Radio />}
              label={r.label}
            />
          ))}
        </RadioGroup>)
        }
         { checklistFormobj.Status__c === "Completed" &&(
        <RadioGroup
          aria-labelledby="preconfigured-answer-responses"
          name="Response__c"
          value={defaultValue}
          required={req}
          row={type==='Preconfigured'}
        >
          {responseOptions.map((r) => (
            <FormControlLabel
              key={r.key}
              value={r.value}
              control={<Radio />}
              label={r.label}
            />
          ))}
        </RadioGroup>)
        }
        <FormHelperText>{( !req || (defaultValue && defaultValue !== '') ) ? undefined : "Select an option."}</FormHelperText>
      </FormControl>
  );
};

export default SingleSelectRender;
