import React, {useRef, useState, useEffect } from "react";
import { useNavigate,useParams } from 'react-router-dom';
import axios from "axios";
import { fetchUrl , inAppLoginUrl } from "Urls";
import { GetToken } from "api";
import Redirect from "Pages/Redirect";
import CreateProjectForm from "components/ProjectFormTabComponent/CreateProjectForm";

import {
  Dialog,
  DialogContent,
} from "@mui/material";
const FormRecordView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [formId , setFormId] = useState(params?.formId);
  const [credential, setCredentials] = useState(null);
  const [formDetails, setFormDetails] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(true);
  useEffect(() => {
    getCreditials();
  },[]);
  async function getCreditials() {
    const data = await GetToken();
    setCredentials(data?.data);
    const token = data?.data?.token ;
    const instanceUrl = data?.data?.instanceUrl ;
    if(token && instanceUrl){
      await axios.get( `${fetchUrl}/formRecord/${formId}?token=${token}&instanceUrl=${instanceUrl}`).then(async (res) => {
        if (res?.data?.status === 200) {
          const reData = res.data;
          setFormDetails(reData);
          return;
        }else{
          setFormDetails({msg : 'Form record not found'});
        }
      }).catch((err) => {
        setFormDetails({msg : 'Form record not found'});
        console.error(err);
      });
    }else{
      // login into salesforce
      window.location = `${inAppLoginUrl}formId=${formId}&viewForm=yes`;
    }
  }
  return (
    <div>
      {formDetails?.projectId ?
        <>
        <Redirect 
 projectId={formDetails?.projectId}
 tabNumber={2}
></Redirect>
<Dialog  open={dialogOpen} 
 style={{height: "1000px", marginTop: "115px"}}
 fullScreen
>
          
          
          <CreateProjectForm
            projectFormId={formId}
            setFormId={(nFormId) => {
              console.log('setFormId called :: ' + nFormId);
            }}
            credential={credential}
            templateList={formDetails?.projectFormTemplates}
            handleClose={(refresh) => {
             // window.open('', '_self', '');
            //  window.close();
            setDialogOpen(false);
              //window.location = `${inAppLoginUrl}projectId=${formDetails?.projectId}`;
            }}
            statusOptions={formDetails?.formStatusOptions}
            defaultStatus={formDetails?.defaultFormStatus}
            userList={formDetails?.userList}
            selectedFormType={formDetails?.formRec?.Form_Template__r?.Form_Type__c}
          ></CreateProjectForm>
          
         
         </Dialog>
        </>
        :
        <>
          {formDetails?.msg ?<>{formDetails?.msg}</> : 
            <>
              Fetching Form Data
            </>
          }
        </>
      }
    </div>
  );
};

export default FormRecordView;
