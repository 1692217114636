import React, { useState, useEffect } from "react";
import  _, {  cloneDeep, find} from "lodash";
import ArrowDownIcon from "../../assets/images/arrow_drop_down.svg";
import styled from "styled-components";
import { Checkbox, FormControlLabel, Button } from "@mui/material";
import SearchIcon from "../../assets/images/search.svg";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const VersionSetFilterStyles = styled.div`
  .filter-btn {
    margin-bottom: 10px;
  }

  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .filter-search {
    display: flex;
  }

  .filter-option {
    cursor: pointer;
    padding: 6px 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .filter-option.active {
    background-color: #007bff;
    color: #fff;
  }

  .filter-count {
    background-color: #fff;
    color: #007bff;
    border: 1px solid #007bff;
    padding: 2px 6px;
    border-radius: 50%;
  }
  .list {
    &:hover {
      background: #F8F8F8;
    }
`;

const FilterDropdown = ({ keyProp , labelProp, options, isValueArray ,onApply, onCancel, activeFilters, dateFilter }) => {
  const [selectedFilters, setSelectedFilters] = useState(activeFilters ? activeFilters : []);
  const [valueField, setValueField] = useState(keyProp ? keyProp : 'value');
  const [labelField, setLabelField] = useState(labelProp ? labelProp : 'label');
  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const handleFilterSelect = (option) => {
    if (!selectedFilters?.includes(option)) {
      setSelectedFilters((prevFilters) => [...prevFilters, option]);
    }
  };

  const handleFilterDeselect = (option, valueField) => {
    let selected = JSON.parse(JSON.stringify(selectedFilters));
    var selectedFilter;
    if(selected[0][valueField]){
      selectedFilter = selected.filter((filter) => filter[valueField] !== option[valueField]);
    }else{
      selectedFilter = selected.filter((filter) => filter !== option);
    }
    setSelectedFilters(selectedFilter);
  };

  const handleApplyFilters = () => {
    onApply(selectedFilters);
  };

  const handleCancel = () => {
    setSearchValue("");
    setSelectedFilters([]);
    onCancel();
  };
  const handleReset = () => {
    setSearchValue("");
    setSelectedFilters([]);
    //onCancel();
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    const sText = e.target.value;
    var filList = options;
   if(sText && sText !=''){
      filList = options.filter((option) => {
         let passed = false;
         let se = sText.toLowerCase();
         if(isValueArray){
            passed = option.toLowerCase().includes(se);
         }else{
            let strL = option[labelField] != null ? String(option[labelField]) : null;
            if(strL){
               passed = strL.toLowerCase().includes(se);
            }
            if(passed == false){
               let str = option[valueField] != null ? String(option[valueField]) : null;
               if(str){
                  passed = str.toLowerCase().includes(se);
               }
            }
         }
         return passed;
      });
   }
   setFilteredOptions(filList);
  };

  return (
    <div className="filter-dropdown">
      {!dateFilter &&
        <>
          <div className="filter-search">
            <input
              style={{ color: "#505050" }}
              type="text"
              placeholder={"Search Label"}
              value={searchValue}
              onChange={(e) => handleSearchChange(e)}
            />
            <img src={SearchIcon} alt="search_icon" />
          </div>
          <div className="filter-options" style={{ height: 'auto', minHeight: '150px', maxHeight: '55vh' }}>
            {filteredOptions.map((option) => {
              const vall = isValueArray ? option : option[valueField];
              const labl = isValueArray ? option : option[labelField];
              return <FormControlLabel
                className="list"
                key={vall}
                control={
                  <Checkbox
                    style={{ color: "#D8D8D8" }}
                    className="filter-checkbox"
                    checked={isValueArray ? selectedFilters?.includes(option) : find(selectedFilters, option) ? true : false}
                    onChange={() => {
                      const selcted = isValueArray ? selectedFilters?.includes(option) : find(selectedFilters, option) ? true : false;
                      if (selcted) {
                        handleFilterDeselect(option, valueField);
                      } else {
                        handleFilterSelect(option);
                      }
                    }}
                    value={vall}
                    name="version_set"
                  />
                }
                label={labl}
              />
            })}
          </div>
        </>
      }
      {dateFilter &&
          <DatePicker
            className="custom-date-picker"
            selected={selectedFilters[0] && selectedFilters[0].startDate ? selectedFilters[0].startDate : null}
            onChange={(dates) => {
              const [start, end] = dates;
              setSelectedFilters([{'startDate': start, 'endDate': end}])
            }}
            startDate={selectedFilters[0] && selectedFilters[0].startDate ? selectedFilters[0].startDate : null}
            endDate={selectedFilters[0] && selectedFilters[0].endDate ? selectedFilters[0].endDate : null}
            selectsRange
            isClearable
            placeholderText="Date Uploaded"
            dropdownMode="select"
            popperPlacement="bottom-start"
            inline
          />
      }
      <div className="filter-footer">
        <div style={{ color: "#BABABA", paddingTop: "10px", paddingRight: "20px", cursor: "pointer" }} onClick={() => handleReset()}>Reset</div>
        <Button className="custom_button cancel_button" onClick={() => handleCancel()}>
          Cancel
        </Button>
        <Button style={{ border: "none" }} className="custom_button apply_button" disabled={selectedFilters?.length < 1 && activeFilters?.length < 1} onClick={() => handleApplyFilters()}>
          Apply
        </Button>
      </div>
    </div>
  );
};

const DropdownFilter = ({ options, dropLabel, keyProp , labelProp ,isValueArray ,activeFilters, onFilterChange,activeFilterDropdown ,setActiveFilterDropdown, dateFilter }) => {
  const [showDropdown, setShowDropdown] = useState(false);
   useEffect(() => {
      setShowDropdown(activeFilterDropdown == dropLabel);
   }, [activeFilterDropdown]);
   useEffect(() => {
    setShowDropdown(false);
 }, []);
   const handleApplyFilters = (selectedFilters) => {
      if(setActiveFilterDropdown){
         setActiveFilterDropdown(null);
      }
      setShowDropdown(false);
      onFilterChange(selectedFilters);
   };
  
   const handleCancel = () => {
      if(setActiveFilterDropdown){
         setActiveFilterDropdown(null);
      }
      setShowDropdown(false);
   };

   const handleClearAllFilters = () => {
      if(setActiveFilterDropdown){
         setActiveFilterDropdown(dropLabel);
      }
      setShowDropdown(!showDropdown);
   };

  return (
    <VersionSetFilterStyles>
      <div className="holder_input">
         <button className= {activeFilters?.length > 0 ?"mainButton-1" : "mainButton"} onClick={handleClearAllFilters} >
            {dropLabel} {activeFilters?.length > 0 && `(${activeFilters?.length})`}
            <img src={ArrowDownIcon} alt="arrow_icon" />
         </button>
        {showDropdown && (
          <FilterDropdown
            dateFilter={dateFilter}
            options={options}
            keyProp={keyProp}
            labelProp={labelProp}
            isValueArray={isValueArray}
            onApply={handleApplyFilters}
            onCancel={handleCancel}
            activeFilters={activeFilters}
          />
        )}
      </div>
    </VersionSetFilterStyles>
  );
};

export default DropdownFilter;