import { Button, ButtonGroup, Hidden } from "@mui/material";
import React, { useState } from "react";
import { useRef } from "react";
import Pdf from "react-to-pdf";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Demo = () => {
  const ref = useRef();

  const generateHeaderFooter = (doc) => {
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      // Add header
      doc.setFontSize(18);
      doc.setTextColor(40);
      // doc.setFontStyle("normal");
      doc.text("Header", doc.internal.pageSize.getWidth() / 2, 30, {
        align: "center",
      });
      // Add footer
      doc.setFontSize(10);
      doc.setTextColor(150);
      //   doc.setFontStyle("normal");
      doc.text(
        `Page ${i} of ${pageCount}`,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() - 10,
        { align: "center" }
      );
    }
  };

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    // Adding the fonts.
    doc.setFont("Inter-Regular", "normal");

    doc.html(ref.current, {
      async callback(doc) {
        generateHeaderFooter(doc);
        await doc.save("Check");
      },
      margin: [10, 0, 0, 10],
      html2canvas: { scale: 0.6 },
      viewportSize: { width: 595, height: 842 },
    });
  };
  // const DownloadPdf = async () => {
  //   const htmlString = document.getElementById("test_html_data");

  //   const pdf = new jsPDF();
  //   pdf.html(htmlString, {
  //     callback: function () {
  //       pdf.save("download.pdf");
  //     },
  //   });

  //   console.log("handle download working");
  // };

  const styles = {
    page: {
      marginLeft: "5rem",
      marginRight: "5rem",
      marginBottom: "5rem",
      "page-break-after": "always",
    },

    columnLayout: {
      display: "flex",
      justifyContent: "space-between",
      margin: "3rem 0 5rem 0",
      gap: "2rem",
    },

    column: {
      display: "flex",
      flexDirection: "column",
    },

    spacer2: {
      height: "2rem",
    },

    fullWidth: {
      width: "100%",
    },

    marginb0: {
      marginBottom: 0,
    },
  };

  return (
    <div className="App" style={{ width: "700px", margin: "0 auto" }}>
      <Pdf targetRef={ref} filename="document.pdf" x={10} y={10}>
        {({ toPdf }) => (
          <button onClick={toPdf} className="button">
            Generate PDF
          </button>
        )}
      </Pdf>

      {/* <ButtonGroup variant="outlined" className="table-btn-group">
        <Button variant="text" component="span" onClick={() => DownloadPdf()}>
          Download Pdf
        </Button>
      </ButtonGroup> */}
      <button className="button" onClick={handleGeneratePdf}>
        Generate PDF raka
      </button>
      <div ref={ref} id="test_html_data">
        <div>
          <div className="page">
            <div
              className="top-deaer"
              style={{ borderBottom: "1px solid #ddd", padding: "5px" }}
            >
              <p>Training Project</p>
            </div>
            {/* <!--              Custom table start--> */}

            <div className="table-box">
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <td>
                            <div id="pspdfkit-header">
                              <table
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tr>
                                  <td
                                    style={{
                                      padding: "5px",
                                      verticalAlign: "top",
                                    }}
                                  >
                                    Checklist Detail
                                    <p style={{ margin: 0 }}>
                                      #101 -
                                      <span
                                        style={{
                                          width: "100%",
                                          color: "#087cd9",
                                        }}
                                      >
                                        Template 1
                                      </span>
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      padding: "5px",
                                      textAlign: "right",
                                    }}
                                  >
                                    <img
                                      src="https://www.wisemindssolutions.com/images/Logo-Color.png"
                                      style={{
                                        height: "60px",
                                        width: "100px",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </table>
                      <table
                        style={{
                          width: "100%",
                          borderTop: "1px solid #ddd",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              padding: "5px",
                              fontWeight: "500",
                              color: " #838383",
                            }}
                          >
                            Status{" "}
                          </td>
                          <td style={{ width: " 70%", padding: "5px" }}>
                            <span
                              style={{
                                display: " inline-flex",
                                height: "13px",
                                width: "4px",
                                background: "#087cd9",
                                borderRadius: " 5px ",
                                marginRight: "10px",
                              }}
                            ></span>
                            Completed{" "}
                          </td>
                        </tr>
                      </table>
                      <table
                        style={{
                          width: " 100%",
                          borderBottom: "1px solid #ddd",
                        }}
                        className="intro-form"
                      >
                        <tr className="intro-form-item-border">
                          <td
                            className="intro-table-title"
                            style={{
                              width: "30%",
                              padding: "5px",
                              fontWeight: "500",
                              color: " #838383",
                            }}
                          >
                            Created date:
                          </td>
                          <td style={{ width: "70%", padding: "5px" }}>
                            <p style={{ margin: 0 }}>Apr 03, 2023</p>
                          </td>
                        </tr>
                      </table>

                      <table
                        style={{
                          width: " 100%",
                          borderBottom: "1px solid #ddd",
                        }}
                        className="intro-form"
                      >
                        <tr className="intro-form-item-border">
                          <td
                            className="intro-table-title"
                            style={{
                              width: "30%",
                              padding: "5px",
                              fontWeight: "500",
                              color: " #838383",
                            }}
                          >
                            Scheduled date:
                          </td>
                          <td style={{ width: "70%", padding: "5px" }}>
                            <p style={{ margin: 0 }}>Apr 03, 2023</p>
                          </td>
                        </tr>
                      </table>

                      <table
                        style={{
                          width: " 100%",
                          borderBottom: "1px solid #ddd",
                        }}
                        className="intro-form"
                      >
                        <tr className="intro-form-item-border">
                          <td
                            className="intro-table-title"
                            style={{
                              width: "30%",
                              padding: "5px",
                              fontWeight: "500",
                              color: " #838383",
                            }}
                          >
                            Started date:
                          </td>
                          <td style={{ width: "70%", padding: "5px" }}>
                            <p style={{ margin: 0 }}>Apr 03, 2023</p>
                          </td>
                        </tr>
                      </table>

                      <table
                        style={{
                          width: " 100%",
                          borderBottom: "1px solid #ddd",
                        }}
                        className="intro-form"
                      >
                        <tr className="intro-form-item-border">
                          <td
                            className="intro-table-title"
                            style={{
                              width: "30%",
                              padding: "5px",
                              fontWeight: "500",
                              color: " #838383",
                            }}
                          >
                            Completed date:
                          </td>
                          <td style={{ width: "70%", padding: "5px" }}>
                            <p style={{ margin: 0 }}>Apr 03, 2023</p>
                          </td>
                        </tr>
                      </table>

                      <table
                        style={{
                          width: " 100%",
                          borderBottom: "1px solid #ddd",
                        }}
                        className="intro-form"
                      >
                        <tr className="intro-form-item-border">
                          <td
                            className="intro-table-title"
                            style={{
                              width: "30%",
                              padding: "5px",
                              fontWeight: "500",
                              color: " #838383",
                            }}
                          >
                            Type:
                          </td>
                          <td style={{ width: "70%", padding: "5px" }}>
                            <p style={{ margin: 0 }}>Quality</p>
                          </td>
                        </tr>
                      </table>

                      <table
                        style={{
                          width: " 100%",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              padding: "5px",
                              fontWeight: "500",
                              color: " #838383",
                            }}
                          >
                            Description
                          </td>
                          <td style={{ width: "70%", padding: "5px" }}>
                            <p style={{ margin: 0 }}>dsggtrgtrgtrgtrsgrs</p>
                          </td>
                        </tr>
                      </table>

                      <table
                        style={{
                          width: " 100%",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              padding: "5px",
                              fontWeight: "500",
                              color: " #838383",
                            }}
                          >
                            Location
                          </td>
                          <td style={{ width: "70%", padding: "5px" }}>
                            <p style={{ margin: 0 }}>Chandigarh</p>{" "}
                          </td>
                        </tr>
                      </table>

                      <table
                        style={{
                          width: " 100%",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              padding: "5px",
                              fontWeight: "500",
                              color: " #838383",
                            }}
                          >
                            Template
                          </td>
                          <td style={{ width: "70%", padding: "5px" }}>
                            <p style={{ margin: 0 }}>Template 1 [V1]</p>
                          </td>
                        </tr>
                      </table>

                      <table
                        style={{
                          width: " 100%",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              padding: "5px",
                              fontWeight: "500",
                              color: " #838383",
                            }}
                          >
                            Linked documents
                          </td>
                          <td style={{ width: "70%", padding: "5px" }}>
                            <p style={{ margin: 0 }}></p>
                          </td>
                        </tr>
                      </table>

                      <table
                        style={{
                          width: " 100%",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              padding: "5px",
                              fontWeight: "500",
                              color: " #838383",
                            }}
                          >
                            Assigned to
                          </td>
                          <td style={{ width: "70%", padding: "5px" }}>
                            <p style={{ margin: 0 }}></p>
                          </td>
                        </tr>
                      </table>

                      <table
                        style={{
                          width: " 100%",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              padding: "5px",
                              fontWeight: "500",
                              color: " #838383",
                            }}
                          >
                            Creator
                          </td>
                          <td style={{ width: "70%", padding: "5px" }}>
                            <p style={{ margin: 0 }}>
                              Patrick Lapuz (Horsepower Electric)
                            </p>
                          </td>
                        </tr>
                      </table>

                      <table
                        style={{
                          width: " 100%",
                          borderBottom: "1px solid #ddd",
                          padding: "5px",
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              width: "30%",
                              padding: "5px",
                              fontWeight: "500",
                              color: " #838383",
                            }}
                          >
                            Section assignees
                          </td>
                          <td style={{ width: "70%", padding: "5px" }}>
                            <p style={{ margin: 0 }}></p>
                          </td>
                        </tr>
                      </table>

                      <table
                        style={{
                          width: "100%",
                          borderBottom: "1px solid #ddd",
                          tableLayout: "fixed",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                borderRight: "1px solid #ddd",
                                borderBottom: "1px solid #ddd",
                                padding: "5px",
                                textAlign: "center",
                              }}
                            >
                              Sections
                            </th>
                            <th
                              style={{
                                borderRight: "1px solid #ddd",
                                borderBottom: "1px solid #ddd",
                                padding: "5px",
                                textAlign: "center",
                              }}
                            >
                              Items
                            </th>
                            <th
                              style={{
                                borderRight: "1px solid #ddd",
                                borderBottom: "1px solid #ddd",
                                padding: "5px",
                                textAlign: "center",
                              }}
                            >
                              Issues
                            </th>
                            <th
                              style={{
                                borderRight: "1px solid #ddd",
                                borderBottom: "1px solid #ddd",
                                padding: "5px",
                                textAlign: "center",
                              }}
                            >
                              Conforming
                            </th>
                            <th
                              style={{
                                borderRight: "1px solid #ddd",
                                borderBottom: "1px solid #ddd",
                                padding: "5px",
                                textAlign: "center",
                              }}
                            >
                              Non-conforming
                            </th>
                            <th
                              style={{
                                borderRight: "1px solid #ddd",
                                borderBottom: "1px solid #ddd",
                                padding: "5px",
                                textAlign: "center",
                              }}
                            >
                              N/A
                            </th>
                            <th
                              style={{
                                borderBottom: "1px solid #ddd",
                                padding: "5px",
                                textAlign: "center",
                              }}
                            >
                              To be answered
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <td
                            style={{
                              borderRight: "1px solid #ddd",
                              borderBottom: "1px solid #ddd",
                              padding: "5px",
                              textAlign: "center",
                            }}
                          >
                            2/2
                          </td>
                          <td
                            style={{
                              borderRight: "1px solid #ddd",
                              borderBottom: "1px solid #ddd",
                              padding: "5px",
                              textAlign: "center",
                            }}
                          >
                            3/3
                          </td>
                          <td
                            style={{
                              borderRight: "1px solid #ddd",
                              borderBottom: "1px solid #ddd",
                              padding: "5px",
                              textAlign: "center",
                            }}
                          >
                            0
                          </td>
                          <td
                            style={{
                              borderRight: "1px solid #ddd",
                              borderBottom: "1px solid #ddd",
                              padding: "5px",
                              textAlign: "center",
                            }}
                          >
                            2
                          </td>
                          <td
                            style={{
                              borderRight: "1px solid #ddd",
                              borderBottom: "1px solid #ddd",
                              padding: "5px",
                              textAlign: "center",
                            }}
                          >
                            1
                          </td>
                          <td
                            style={{
                              borderRight: "1px solid #ddd",
                              borderBottom: "1px solid #ddd",
                              padding: "5px",
                              textAlign: "center",
                            }}
                          >
                            0
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #ddd",
                              padding: "5px",
                              textAlign: "center",
                            }}
                          >
                            0
                          </td>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table
                        style={{
                          width: "100%",
                          borderBottom: "1px solid #ddd",
                          tableLayout: "fixed",
                          marginTop: "10px",
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            <h4 style={{ fontSize: "16px", fontWeight: "900" }}>
                              <span>1.</span> Section 1
                            </h4>
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              borderBottom: "1px solid #ddd",
                              textAlign: "right",
                            }}
                          >
                            <span>Done</span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} style={{ padding: "5px" }}>
                            <b style={{ color: "#838383" }}> Assigned to</b>{" "}
                            .2/2
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table
                        style={{
                          width: "100%",
                          borderBottom: "1px solid #ddd",
                          tableLayout: "fixed",
                        }}
                      >
                        <tr>
                          <td
                            rowspan={7}
                            style={{
                              width: "20%",
                              verticalAlign: "top",
                              padding: "5px",
                            }}
                          >
                            1.1
                          </td>
                          <td
                            style={{
                              width: "80%",
                              borderLeft: "1px solid #ddd",
                              verticalAlign: "top",
                              padding: "5px",
                            }}
                          >
                            Q1
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan={2}
                            style={{ borderLeft: "1px solid #ddd" }}
                          >
                            <table
                              style={{
                                width: "100%",
                                borderTop: "1px solid #ddd",
                                borderBottom: "1px solid #ddd",
                              }}
                            >
                              <tr>
                                <td
                                  style={{
                                    padding: "5px",
                                  }}
                                >
                                  Yes
                                </td>
                                <td
                                  style={{
                                    padding: "5px",
                                  }}
                                >
                                  No
                                </td>{" "}
                                <td
                                  style={{
                                    padding: "5px",
                                  }}
                                >
                                  Nan
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan={2}
                            style={{ borderLeft: "1px solid #ddd" }}
                          >
                            <table
                              style={{
                                width: "100%",
                                tableLayout: "fixed",
                              }}
                            >
                              <tr>
                                <td
                                  style={{
                                    padding: "5px",
                                  }}
                                >
                                  images
                                </td>
                                <td
                                  style={{
                                    padding: "5px",
                                  }}
                                >
                                  <img src="https://www.wisemindssolutions.com/images/Logo-Color.png" />
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table
                        style={{
                          width: "100%",
                          tableLayout: "fixed",
                          pageBreakAfter: "always",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <tr>
                          <td
                            rowspan={7}
                            style={{
                              width: "20%",
                              padding: "5px",
                              verticalAlign: "top",
                            }}
                          >
                            1.2
                          </td>
                          <td
                            style={{
                              width: "80%",
                              borderLeft: "1px solid #ddd",
                              padding: "5px",
                            }}
                          >
                            Q2
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan={2}
                            style={{ borderLeft: "1px solid #ddd" }}
                          >
                            <table
                              style={{
                                width: "100%",
                                borderTop: "1px solid #ddd",
                                borderBottom: "1px solid #ddd",
                              }}
                            >
                              <tr>
                                <td
                                  style={{
                                    padding: "5px",
                                  }}
                                >
                                  Yes
                                </td>
                                <td
                                  style={{
                                    padding: "5px",
                                  }}
                                >
                                  No
                                </td>
                                <td
                                  style={{
                                    padding: "5px",
                                  }}
                                >
                                  Nan
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan={2}
                            style={{ borderLeft: "1px solid #ddd" }}
                          >
                            <table
                              style={{
                                width: "100%",
                                tableLayout: "fixed",
                              }}
                            >
                              <tr>
                                <td style={{ padding: "5px" }}>images</td>
                                <td>
                                  <img src="https://www.wisemindssolutions.com/images/Logo-Color.png" />
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table
                        style={{
                          width: "100%",
                          borderBottom: "1px solid #ddd",
                          tableLayout: "fixed",
                        }}
                      >
                        <tr>
                          <td
                            rowspan={7}
                            style={{
                              width: "20%",
                              padding: "5px",
                              verticalAlign: "top",
                            }}
                          >
                            1.1
                          </td>
                          <td
                            style={{
                              width: "80%",
                              borderLeft: "1px solid #ddd",
                              padding: "5px",
                            }}
                          >
                            Q1
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan={2}
                            style={{ borderLeft: "1px solid #ddd" }}
                          >
                            <table
                              style={{
                                width: "100%",
                                padding: "5px",
                              }}
                            >
                              <tr>
                                <td
                                  style={{
                                    padding: "5px",
                                  }}
                                >
                                  Yes
                                </td>
                                <td
                                  style={{
                                    padding: "5px",
                                  }}
                                >
                                  No
                                </td>
                                <td
                                  style={{
                                    padding: "5px",
                                  }}
                                >
                                  Nan
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan={2}
                            style={{ borderLeft: "1px solid #ddd" }}
                          >
                            <table
                              style={{
                                width: "100%",
                                tableLayout: "fixed",
                              }}
                            >
                              <tr>
                                <td
                                  style={{
                                    padding: "5px",
                                  }}
                                >
                                  images
                                </td>
                                <td
                                  style={{
                                    padding: "5px",
                                  }}
                                >
                                  <img src="https://www.wisemindssolutions.com/images/Logo-Color.png" />
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <!--              Custom table end--> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demo;
