import React, { useState } from "react";
import { Box } from "@mui/material";
import TableWorkflowTemplateList from "./TableWorkflowTemplateList";
import { NoData } from "components/upload-logs/LogsComponents/CommonMethod";

function WorkflowTemplateListTable(props) {
  const [tableColumnValue, setTableColumnValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const { data , columns } = props?.templateTablePorps;
  const dummyData = [ {
      id: 1,
      key: 1,
      title: "Dummy Title 1",
      step: '3-Step Review',
      stepCount: 3,
      status: "Active",
      updatedDate: "2023-05-01",
      createdBy: "John Doe",
      createdDate: "2023-04-01",
      description: "Dummy Descriptions",
    },
  ];
  const dataToRepeat = dummyData[0];
  const repeatedData = [];

  /*for (let i = 0; i < 30; i++) {
    const repeatedObject = { ...dataToRepeat };
    repeatedData.push(repeatedObject);
  }*/
  return (
    <Box component={"div"} sx={{ width: "100%" }}>
      <TableWorkflowTemplateList
        columns={columns}
        data={data}
        handleOpen={props.openFormDetail}
        handleClickMenu={props.handleClickMenu}
        NoDataComponent={<NoData />}
      />
    </Box>
  );
}

export default WorkflowTemplateListTable;
