import axios from 'axios';
const instance = axios.create({
  timeout: 300000, // 5 minutes (in milliseconds)
});
export { instance as axios };
export const fetchUrl = "https://document-dev.construcverse.com";
export const webSocketListener = "wss://document-dev.construcverse.com?param1=test";
//export const webSocketListener = "ws://localhost:6001"
//export const fetchUrl = "http://localhost:3000";
export const inAppLoginUrl = "/auth/login?route=inApp";
export const SF_PROJECTID = "a0fDb0000029loXIAQ";
export const _AWS_ACCESS_KEY ="AKIA6FO5IYHIP3YTEGBM"
export const _AWS_SECRET_ACCESS_KEY ="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"
export const _AWS_BUCKET_NAME ="horsepower-qa"
export const _AWS_REGION ="us-east-1"