import React, { useState, useEffect, useRef } from "react";
import { IconButton, Checkbox, TextField ,Box, Stack , Menu , Paper} from "@mui/material";
import _,{cloneDeep} from "lodash";
import folderIcon from "assets/images/folder.svg";
import pdfRed from "assets/images/pdfRed.svg";
import { Search } from "@mui/icons-material";
import { useSelector } from "react-redux";
import GridViewIcon from "@mui/icons-material/GridView";
import moment from "moment";
import Spinner from "components/spinner";
import WebViewer, { getInstance } from '@pdftron/webviewer';
import { InputAdornment, Tooltip } from "@mui/material";
import { ConfirmDialogCheckbox } from "components/DrawerComponent/DisciplineSettingDialog";
import styled from "styled-components";
import axios from "axios";
import { fetchUrl } from "Urls";
import ViewDeleteSheet from "./ViewDeleteSheet";
import ToastComponent from "components/ToastComponent";
import ReactDataTable from "components/ReactDataTableComponent";
import { highlightText, searchTextInPdfContentWebViewer, getPdfThumbnailFromWebViewer } from "components/SheetsComponent";
const UploadLogsstyles = styled.div`
.MuiDataGrid-virtualScrollerRenderZone > div:nth-child(2n) {
  background: #ecf0f3;
}
.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft
  .MuiBox-root {
  background: none;
}
.MuiDataGrid-virtualScrollerRenderZone > div > div:not(:first-child) {
  border-left: 1px solid #ccc;
}
[data-field="details"] a {
  color: #1bb3c3;
}
.pubishlog_class {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ebf0f4;
  padding: 0 10px;
}
.pubishlog_class .MuiBox-root {
  display: flex;
  gap: 20px;
  align-items: center;
  max-width: 400px;
  width: 100%;
  justify-content: flex-end;
}

.pubishlog_class h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}
.pubishlog_class .MuiButtonGroup-outlined {
  max-width: 130px;
  width: 100%;
  button {
    min-width: 100%;
    height: 45px;
    background: #fff;
  }
}
.view_Class + div {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  border: none;
}
.MuiDataGrid-virtualScrollerRenderZone > div > div > div {
  position: relative;
}
.MuiDataGrid-virtualScrollerRenderZone > div > div:empty {
  display: none;
}
.main_class .pubishlog_class {
  padding: 10px !important;
}
.grid {
  &:hover {
    background: #F8F8F8;
  }
  cursor: pointer;
  align-items: center; 
  background-color: #FFFFFF;
  border: 1px solid;
  border-color: #D8D8D8;
  border-radius: 2px;
  display: inline-flex;
  gap: 8px;
  height: 37px;
  justify-content: center;
  overflow: hidden;
 // padding: 8px 12px;
  position: relative;
  }
  .restore { 
    height: 16px;
    position: relative; 
    width: 16px;
    color: #505050;
  }
    .label {
    letter-spacing: 0;
    line-height: normal; 
    margin-top: -1px;
    position: relative;
    width: fit-content;
    color: #505050;
    }
    .buttons {
      cursor: pointer;
      align-items: center; 
      border-radius: 2px; 
      display: flex;
      height: 24px;
      justify-content: center;
      overflow: hidden;
      position: relative;
      width: 24px;
      }
      .close {
        &:hover {
          background: #F8F8F8;
        }
      height: 16px;
      position: relative;
      width: 16px;
      }
`;
const restoreDialogData = {
  title: 'Restore Sheet/s',
  content: "Are you sure you want to restore selected sheet/s?  Once restored, you'll be able to edit the file in the sheet overview.",
  action1: "Cancel",
  action2: "Restore"
};
export default function RecycleBin({ credential, callFrom,onRestore ,webInstance, ...props }) {
  const { userData = {} } = useSelector((state) => state.userData);
  const [isGridView, setIsGridView] = useState(false);
  const [recycledItems, setRecycledItems] = useState([]);
  const [showSpinner, setSpinner] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedFileRows, setSelectedFileRows] = useState([]);
  const [selectedFolderRows, setSelectedFolderRows] = useState([]);
  const [viewSheet, setViewSheet] = useState(false);
  const [restorePopup, setRestorePopup] = useState(false);
  const [restorePopupData, setRestorePopupData] = useState();
  const [skipRestorePopup, setSkipRestorePopup] = useState(false);
  const [restoreRow, setRestoreRowState] = useState();
  const restoreRowRef = useRef();
  const [toastMessage, setToastMessage] = useState();
  const [rowsPerPage, setRowsPerPage] = useState([]);
  const [tableViewData, setViewTableData] = useState([]);
  const errorMessage = "No deleted files";
  const searchErrorMessage = "None of your files or folders matched this search";
  const [attachmentThumbnail, setAttachmentThumbnail] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [contentSearchRunning, isContentSearchRunning] = useState(false);
  let stopPDFInContentSearch = useRef(null);
  const [searchBoxFilter, setSearchBoxFilter] = useState(false);
  let stopBreakThumbmaking = useRef(null);
  const currentTableRowData = useRef([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [selectedMenuRow, setSelectedMenuRow] = useState();

  useEffect(() => {
    callRecyclebinApi();
  }, []);
  useEffect(() => {
    if (userData?.recycleSkipRestoreDialog) {
      setSkipRestorePopup(true);
    } else {
      setSkipRestorePopup(false);
    }
  }, [userData?.recycleSkipRestoreDialog]);
  useEffect(() => {
    setSearchText('');
    setViewTableData(recycledItems);
  }, [recycledItems]);
  const handleClose = () => {
    setClickRestorePopup(false);
    setRestoreRow(null);
  };
  async function searchListViewFunction(searchTerm) {
    setSearchBoxFilter(false);
    stopPDFInContentSearch.current = true;
    isContentSearchRunning(false);
    setSearchText(searchTerm);
    let orgData = cloneDeep(recycledItems);
    //console.log(searchTerm,'orgData :: '+orgData?.length);
    let searchFoundList = orgData;
    if (orgData) {
      if (searchTerm && searchTerm !== null && searchTerm !== "") {
          searchFoundList = [];
          let pdfFiles = [];
          for (let el of orgData) {
            let fileExtension = el.fileExt;
            if(el.type == 'file' && fileExtension && fileExtension.includes('pdf')){
              pdfFiles.push(el);
            }
            if ((el.name && el.name.toLowerCase().includes(searchTerm)) || (el.title && el.title.toLowerCase().includes(searchTerm)) 
                || (el.updatedBy && el.updatedBy.toLowerCase().includes(searchTerm)) ) {
              if (el.name && el.name.toLowerCase().includes(searchTerm)) {
                el.name_mark = highlightText(el.name, searchTerm);
              }
              if (el.title && el.title.toLowerCase().includes(searchTerm)) {
                el.title_mark = highlightText(el.title, searchTerm);
              }
              if (el.updatedBy && el.updatedBy.toLowerCase().includes(searchTerm)) {
                el.updatedBy_mark = highlightText(el.updatedBy, searchTerm);
              }
              searchFoundList.push(el);
            }
          }
          setSearchBoxFilter(true);
          console.log('pdfFiles :: ',pdfFiles?.length);
          setTimeout(() => {
            if(pdfFiles?.length > 0){
              stopPDFInContentSearch.current = false;
              initiateInContentSearch(cloneDeep(pdfFiles) , searchTerm);
            }
          }, 100);
        }
    }
    setViewTableData(searchFoundList);
  };
  const initiateInContentSearch = async (attachmentRows, searchText) => {
    const promises = [];
    if (attachmentRows?.length > 0) {
      isContentSearchRunning(true);
      stopPDFInContentSearch.current = false;
      for (let file of attachmentRows) {
        if (stopPDFInContentSearch.current === true) {
          break;
        }
        let promise = await searchInPdfContent(file, searchText, file.url);
        promises.push(promise);
        if (stopPDFInContentSearch.current === true) {
          break;
        }
      }
    } else {
      stopPDFInContentSearch.current = true;
    }
    if (promises?.length > 0) {
      Promise.all(promises).then((values) => {
        //console.log('values :: ',values?.length);
        isContentSearchRunning(false);
      });
    } else {
      isContentSearchRunning(false);
    }
  }
  const searchInPdfContent = async (file_, searchTerm, fileUrl) => {
    const file = cloneDeep(file_);
    let pdfContent = await searchTextInPdfContentWebViewer(file.id, searchTerm, fileUrl, webInstance);
    console.log(file.name,'pdfContent ',pdfContent)
    if (pdfContent && pdfContent.attachId && pdfContent.found) {
      setViewTableData((preList) => {
        if (!preList) {
          preList = [];
        }
        const fInd = preList?.findIndex(
          (data) => data.id === file.id
        );
        file.in_content_found = true;
        if (fInd > -1) {
          preList[fInd] = file;
        } else {
          preList.push(file);
        }
        return preList;
      });
      return pdfContent;
    }
    return null;
  }
  const setClickRestorePopup = (value, clickSource) => {
    const curRow = restoreRowRef.current;
    let typeToLabel = { 'file': 'File', 'folder': 'Folder', 'item': 'Item' };
    if (value === false) {
      setRestorePopup(false);
    } else {
      if (clickSource === 'header') {
        setRestoreRow(null);
      }
      let resData;
      if (callFrom === 'FilesComponent') {
        if (clickSource === 'menu') {
          resData = {
            title: `Restore ${typeToLabel[curRow.type]}`,
            content: `Are you sure you want to restore selected ${typeToLabel[curRow.type]}?  Once restored, you'll be able to edit the ${typeToLabel[curRow.type]} in the folders tab.`,
            action1: "Cancel",
            action2: "Restore"
          };
        } else {
          if (selectedRowIds) {
            let type = selectedFileRows?.length > 0 && selectedFolderRows?.length > 0 ? 'item' :
              selectedFolderRows?.length > 0 ? 'folder' : selectedFileRows?.length > 0 && 'file';
            let multi = selectedRowIds?.length > 1;
            resData = {
              title: `Restore ${typeToLabel[type]}${multi ? 's' : ''}`,
              content: `Are you sure you want to restore the selected ${type}${multi ? 's' : ''}? Once restored, you'll be able to edit the ${type}${multi ? 's' : ''} in the folders tab.`,
              action1: "Cancel",
              action2: "Restore"
            };
          }
        }
      } else {
        resData = restoreDialogData;
      }
      if (resData) {
        setRestorePopupData(resData);
        setRestorePopup(true);
      }
    }
  };
  const onRowSelectionAllChange = (isChecked) => {
    setSelectedRowIds([]);
    let rowIds = [];
    if (isChecked === true) {
      //console.log('rowsPerPage :: ',rowsPerPage?.length);
      rowsPerPage.map((row) => rowIds?.push(row.id));
    } else {
      setSelectedRowIds([]);
    }
    setSelectedRowIds(rowIds);
    const selRowIds = new Set(rowIds);
    const seleFolRows = recycledItems.filter((row) =>
      selRowIds.has(row.id.toString()) && row.type === 'folder'
    );
    //console.log("seleFolRows ::", seleFolRows);
    setSelectedFolderRows(seleFolRows);
    const seleFileRows = recycledItems.filter((row) =>
      selRowIds.has(row.id.toString()) && row.type === 'file'
    );
    //console.log("selectedFileRows ::", seleFileRows);
    setSelectedFileRows(seleFileRows);
  }
  const onRowSelectionChange = (rowId, isChecked) => {
    let rowIds = [];
    if (isChecked === true) {
      rowIds = selectedRowIds.length > 0 ? selectedRowIds : [];
      rowIds?.push(rowId);
    } else {
      rowIds = selectedRowIds.length > 0 ? selectedRowIds.filter((item) => item !== rowId) : [];
    }
    setSelectedRowIds(rowIds);
    const selRowIds = new Set(rowIds);
    const seleFolRows = recycledItems.filter((row) =>
      selRowIds.has(row.id.toString()) && row.type === 'folder'
    );
    //console.log("seleFolRows ::", seleFolRows);
    setSelectedFolderRows(seleFolRows);
    const seleFileRows = recycledItems.filter((row) =>
      selRowIds.has(row.id.toString()) && row.type === 'file'
    );
    //console.log("selectedFileRows ::", seleFileRows);
    setSelectedFileRows(seleFileRows);
  };
  const fileNameClick = (rowData) => {
    if (rowData?.type === 'file') {
      setViewSheet(true);
      setRestoreRow(rowData);
      const curRow = rowData;
      let typeToLabel = { 'file': 'File', 'folder': 'Folder', 'item': 'Item' };
      let resData;
      if (callFrom === 'FilesComponent') {
        resData = {
          title: `Restore ${typeToLabel[curRow.type]}`,
          content: `Are you sure you want to restore selected ${typeToLabel[curRow.type]}?  Once restored, you'll be able to edit the ${typeToLabel[curRow.type]} in the folders tab.`,
          action1: "Cancel",
          action2: "Restore"
        };
      } else {
        resData = restoreDialogData;
      }
      if (resData) {
        setRestorePopupData(resData);
      }
    }
  };

  const sheets_component_columns = [
    {
      id: "checkbox",
      accessor: "",
      disableSortBy: true,
      Header:(<Checkbox
          style={{padding: "0px", marginLeft: "20px"}}
          sx={{padding: "0px", "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
          checked={selectedRowIds.length > 0 && selectedRowIds.length === rowsPerPage?.length}
          onChange={(e) =>
            onRowSelectionAllChange(e.target.checked)
          }
        />),
      Cell: (rowInfo) => {
        let selectedData = selectedFileRows.filter((row) => row.id === rowInfo?.row?.original?.id);
        return (
          <Checkbox
            style={{ padding: "0px", marginLeft: "20px" }}
            sx={{ padding: "0px", "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
            type="checkbox"
            className="checkbox-recycle"
            checked={selectedData?.length > 0}
            onChange={(e) =>
              onRowSelectionChange(rowInfo?.row?.original?.id, e.target.checked)
            }
          />
        );
      },
      overrideStyle: {width: "7%"}
    },
    {
      accessor: "name",
      Header: "Number",
      flex: 1,
      width: 1000,
      Cell: (params_) => {
        //console.log("restore params ::", params?.row);
        let params = { id: params_.row.original.id, row: params_.row.original };
        let attachId = params_.row.original.id;
        return (
          <div style={{ cursor: "pointer" }} 
          onContextMenu={(e) => { 
            handleClickMenu(e , params_?.row?.original);
          }}>
            <Box onClick={(e) => { e.stopPropagation(); fileNameClick(params_?.row?.original) }} style={{display: "flex"}} >
            {/* <img id={attachId + '_thumb_img'} src={params.row.thumbnail ? params.row.thumbnail : (attachmentThumbnail && attachmentThumbnail[attachId] ? attachmentThumbnail[attachId] : "icon.svg")} alt="loading_pdf" width="50" height="50"
                  style={params.row.thumbnail ? {} : attachmentThumbnail && attachmentThumbnail[attachId] ? {} : {animation : 'animation-61bdi0 3.5s linear infinite'} }
                  onClick={(evt) => {
                    evt.stopPropagation();
                    if (!attachmentThumbnail[attachId]) {
                      genratePdfThumnail(attachId, params.row.url, true);
                    }
                  }} /> */}
                 <img src={pdfRed} alt="pdfIcon" style={{height: '30px', width: "30px"}}
            
                  onClick={(evt) => {
                    evt.stopPropagation();
                    if (!attachmentThumbnail[attachId]) {
                      genratePdfThumnail(attachId, params.row.url, true);
                    }
                  }} />
              <div className="name_main" style={{ marginLeft: "10px", color: "#505050" ,width : '68%' }}>
                <b style={{ color: "black" }}>
                  <span dangerouslySetInnerHTML={{ __html: params_?.row?.original?.name_mark ? params_?.row?.original?.name_mark : params_.value }} />
                </b>
                <br/>
                <span dangerouslySetInnerHTML={{ __html: params_?.row?.original?.title_mark ? params_?.row?.original?.title_mark : params_?.row?.original?.title }} />
              </div>
              {params_.row?.original.in_content_found && searchBoxFilter && 
                <div style={{marginLeft : 'auto'}}>
                  <img style={{ marginRight: "6px" }} src="/index search.svg" alt="Settig" />
                </div>
              }
            </Box>
          </div>
        );
      },
      overrideStyle: {width: "35%"}
    },
    { accessor: "set", Header: "Set", flex: 1, width: 800, overrideStyle: {width: "30%"} },
    { accessor: "drawingType", Header: "Shop Drawing Type", flex: 1, width: 700,  overrideStyle: {width: "25%"} },
    {
      accessor: "deletedTime", Header: "Deleted By", flex: 1,
      width: 1000,
      Cell: (params) => {
        return (
          <div className="" style={{ display: "flex", alignItems: "center", gap: "10px" }} >
            <img src="/user.svg" alt="restore" />
            <div>
              <span dangerouslySetInnerHTML={{ __html: params?.row?.original?.updatedBy_mark ? params?.row?.original?.updatedBy_mark :params?.row?.original?.updatedBy }} />
              <br/> on {params.value}
            </div>
          </div>
        );
      },
      overrideStyle: {width: "25%"}
    },
    {
      accessor: "location", Header: "Restore", flex: 1,
      sortType: "basic",
      disableSortBy: true,
      Cell: (params) => {
        return (
          <div className="Restore-Table" style={{cursor: "pointer"}} onClick={() => handleRestorePopup(params?.row?.original)}>
            <img src="/restore.svg" alt="thumbnail" />
          </div>
        );
      }, 
       overrideStyle: {width: "5%"}
    },
  ];
  const files_component_columns = [
    {
      id: "checkbox",
      accessor: "",
      disableSortBy: true,
      Header:(<Checkbox
          style={{padding: "0px", marginLeft: "20px"}}
          sx={{padding: "0px", "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
          checked={selectedRowIds.length > 0 && selectedRowIds.length === rowsPerPage?.length}
          onChange={(e) =>
            onRowSelectionAllChange(e.target.checked)
          }
        />),
      Cell: (rowInfo) => {
        let fileData = selectedFileRows.filter(
            (row) => row.id === rowInfo?.row?.original?.id
        );

        let folderData = selectedFolderRows.filter(
            (row) => row.id === rowInfo?.row?.original?.id
        );
        return (
          <Checkbox
            style={{ padding: "0px", marginLeft: "20px" }}
            sx={{ padding: "0px", "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
            type="checkbox"
            className="checkbox-recycle"
            checked={fileData?.length > 0 || folderData?.length > 0}
            onChange={(e) =>
              onRowSelectionChange(rowInfo?.row?.original?.id, e.target.checked)
            }
          />
        );
      },
      overrideStyle: {width: "7%"}
    },
    {
      accessor: "name",
      Header: "Name",
      flex: 1,
      Cell: (params) => {
        return (
          <div style={{ cursor: "pointer"}} onContextMenu={(e) => { handleClickMenu(e , params?.row?.original);}}>
            <Box onClick={(e) => { e.stopPropagation(); fileNameClick(params?.row?.original);}} style={{display: "flex"}}>
              <div style={{width: params.row.in_content_found && searchBoxFilter ? "80%" : "100%", display: "flex"}}>
                <img src={params?.row?.original?.type ==='file' ? pdfRed :folderIcon } alt="icon" style={{marginRight: "5px"}}/>
                <div className="name_main" dangerouslySetInnerHTML={{ __html: params?.row?.original?.name_mark ? params?.row?.original?.name_mark : params.value }} />
              </div>
              {params.row?.original.in_content_found && searchBoxFilter && 
                <Tooltip title={"The file contains text from the search results"} placement="bottom" >
                    <div style={{ marginLeft: "auto" , width: "20%"}}>
                      <img  src="/index search.svg" alt="Settig" />
                    </div>
                </Tooltip>
              }
            </Box>
          </div>
        );
      },
      overrideStyle: {width: "35%"}
    },
    { accessor: "updatedBy", Header: "Deleted By", flex: 1, width: 800,  overrideStyle: {width: "35%"},
      Cell: (params) => {
        return (
          <span dangerouslySetInnerHTML={{ __html: params?.row?.original?.updatedBy_mark ? params?.row?.original?.updatedBy_mark :params?.row?.original?.updatedBy }} />
        );
      }
    },
    { accessor: "deletedTime", Header: "Deleted On", flex: 1, width: 700,  overrideStyle: {width: "35%"} },
    {
      accessor: "location", Header: "Restore", flex: 1,
      sortType: "basic",
      disableSortBy: true,
      Cell: (params) => {
        return (
          <div className="Restore-Table" style={{cursor: "pointer"}} onClick={() => handleRestorePopup(params?.row?.original)}>
            <img src="/restore.svg" alt="thumbnail" />
          </div>
        );
      },
      overrideStyle: {width: "5%"}
    },
  ];

  async function callRecyclebinApi() {
    const { token, instanceUrl, projectId } = credential;
    getRecycledItems(token, instanceUrl, projectId);
  }

  async function getRecycledItems(accessToken, instanceUrl, projectId) {
    setSpinner(true);
    setRestoreRow(null);
    var reqUrl=`${fetchUrl}/recyclebin/${projectId}?accessToken=${accessToken}&instanceUrl=${instanceUrl}`;
    if(callFrom === "FilesComponent"){
      reqUrl +=`&folderFilterQuery=Folder_Type__c='Project File'`;
      reqUrl +=`&fileFilterQuery=Folder__r.Folder_Type__c='Project File'`;
    }else{
      reqUrl +=`&folderFilterQuery=Folder_Type__c='Plan'`;
      reqUrl +=`&fileFilterQuery=Folder__r.Folder_Type__c='Plan'`;
    }
    await axios.get(reqUrl)
    .then((res) => {
      if (res?.status === 200) {
        //console.log("res?.data?.files ::", res?.data?.files);
        let rows = [];
        res?.data?.folders?.forEach((item) => {
          rows.push({
            id: item.Id,
            title: item.Name,
            name: item.Name,
            tooltip: item.Name,
            type: "folder",
            deletedTime: item.Deleted_Date__c ? moment(item.Deleted_Date__c).format("MM/DD/yyyy hh:mm A") : "",
            Deleted_Date__c: item.Deleted_Date__c,
            folderId: item?.Parent_Folder__c,
            location: item?.Parent_Folder__r.Folder_Path__c,
            folderName: item?.Parent_Folder__r.Name,
            updatedBy: item?.LastModifiedBy.Name
          });
        });
        generatePdfThumbnailMapPromise(res?.data?.files);
        res?.data?.files.forEach((item) => {
          rows.push({
            id: item.Id,
            title: item.Document_Title__c ? item.Document_Title__c : item.File_Name__c,
            name: item.Document_Number__c ? item.Document_Number__c : item.File_Name__c,
            tooltip: item.Document_Title__c ? item.Document_Title__c : item.File_Name__c,
            type: "file",
            set: item.Set__c ? item.Set__r.Name : null,
            fileExt: item?.File_Extension__c,
            deletedTime: item.Deleted_Date__c ? moment(item.Deleted_Date__c).format("MM/DD/yyyy hh:mm A") : "",
            Deleted_Date__c: item.Deleted_Date__c,
            folderId: item?.Folder__c,
            location: item?.Folder__r?.Folder_Path__c,
            folderName: item?.Folder__r?.Name,
            amazonPath: item?.Folder__r?.S3_Folder_Path__c,
            updatedBy: item?.LastModifiedBy.Name,
            url: item?.url,
            drawingType: item?.Shop_Drawing_Type__c,
          });
        });
        //rows = sortData("Deleted_Date__c", "desc", rows);
        setRecycledItems(rows);
        handleCloseIcon();
      }
    }).catch((err) => {
      console.log(err);
    });
    setSpinner(false);
  }
  const genratePdfThumnail = async (attachId, fileUrl, updateList) => {
    let pdfThumbnail = await getPdfThumbnailFromWebViewer(attachId, fileUrl, webInstance);
    if (pdfThumbnail && pdfThumbnail.attachId && pdfThumbnail.thumbnail) {
        let imgthumEleId = pdfThumbnail.attachId + '_thumb_img';
        setAttachmentThumbnail((preMap) => {
            if (!preMap) {
                preMap = {};
            }
            preMap[pdfThumbnail.attachId] = pdfThumbnail.thumbnail;
            var imgEle = document.getElementById(imgthumEleId);
            if (imgEle) {
                imgEle.src = pdfThumbnail.thumbnail;
                imgEle.style = {};
            }
            //console.log('gen sse :: ',pdfThumbnail.thumbnail);
            return preMap;
        });
        if (updateList === true) {
            updateThumbnailToCurrentData(pdfThumbnail.attachId, pdfThumbnail.thumbnail);
        }
        return pdfThumbnail;
    }
    return null;
}
function setCurrentFolderTableData(filesData, setInView) {
  currentTableRowData.current = filesData;
  if (setInView === true) {
      setViewTableData(filesData);
  }
  return filesData;
}
const updateThumbnailToCurrentData = (attachId, thumUrl) => {
        
  //setSpinner(true);
  if (attachId) {
      toUpdateElementbyId(attachId, thumUrl, currentTableRowData.current, setCurrentFolderTableData);
      toUpdateElementbyId(attachId, thumUrl, tableViewData, setViewTableData);
      function toUpdateElementbyId(attchId, thubUrl, _list, setToList) {
          let _tempList = cloneDeep(_list);
          if (_tempList && _tempList?.length > 0) {
              const fInd = _tempList?.findIndex(
                  (data) => data.id === attchId
              );
              if (fInd > -1) {
                  _tempList[fInd].thumbnail = thubUrl;
                  if (setToList) {
                      setToList(_tempList);
                  }
              }
          }
          return _tempList;
      }
  }
  //setSpinner(false);
};
  const generatePdfThumbnailMapPromise = async (attachmentRows) => {
    const BATCH_SIZE = 10;
    let promises = [];
    if (attachmentRows?.length > 0) {
      stopBreakThumbmaking.current = false;
      for (let i = 0; i < attachmentRows.length; i++) {
        if (stopBreakThumbmaking.current) break;
  
        const file = attachmentRows[i];
        if (file.url && (!attachmentThumbnail || !attachmentThumbnail[file.id])) {
          promises.push(genratePdfThumnail(file.id, file.url));
        }
  
        if (promises.length === BATCH_SIZE || i === attachmentRows.length - 1) {
          if(promises?.length > 0){
            //console.time(i,'promise starts :: ',promises?.length);
            await Promise.all(promises).then((values) => {
              //console.timeEnd(i,'promise starts :: ',values?.length);
                if (values?.length > 0) {
                  let temList_folFiles = JSON.parse(JSON.stringify(currentTableRowData.current)); let curfolFilePush = false;
                  let temList_viewFiles = JSON.parse(JSON.stringify(tableViewData)); let viewFilePush = false;
                  for (let pdfth of values) {
                      if (pdfth !== null && pdfth.attachId && pdfth.thumbnail) {
                          let atchId = pdfth.attachId;
                          let thumbnail = pdfth.thumbnail;
                          if (temList_folFiles?.length > 0) {
                              const fInd = temList_folFiles?.findIndex(
                                  (data) => data.id === atchId
                              );
                              if (fInd > -1) {
                                  curfolFilePush = true;
                                  temList_folFiles[fInd].thumbnail = thumbnail;
                              }
                          }
                          if (temList_viewFiles?.length > 0) {
                              const fInd = temList_viewFiles?.findIndex(
                                  (data) => data.id === atchId
                              );
                              if (fInd > -1) {
                                  viewFilePush = true;
                                  temList_viewFiles[fInd].thumbnail = thumbnail;
                              }
                          }
                      }
                  }
                  if (curfolFilePush) {
                    setCurrentFolderTableData(temList_folFiles);
                  }
                  if (viewFilePush) {
                    setViewTableData(temList_viewFiles);
                  }
              }
            }).catch(err=>{
              console.log('promise all',err);
            });
          }
          promises = [];
        }
      }
    } else {
      stopBreakThumbmaking.current = true;
    }
}
  function sortData(field, dirc, tableDataList) {
    let tempList = JSON.parse(JSON.stringify(tableDataList));
    let keyValue = (a) => {
      return a[field];
    };
    let isReverse = dirc === "asc" ? 1 : -1;
    tempList.sort((a, b) => {
      a = keyValue(a) ? keyValue(a) : "";
      b = keyValue(b) ? keyValue(b) : "";
      return isReverse * ((a > b) - (b > a));
    });
    return tempList;
  }

  const setRestoreRow = (restoreItem) => {
    setRestoreRowState(restoreItem);
    restoreRowRef.current = restoreItem;
  }
  const handleRestorePopup = async (restoreItem) => {
    setRestoreRow(restoreItem);
    if(skipRestorePopup){
      onClickRestoreItemSingle(restoreItem);
    }else{
      setClickRestorePopup(true , 'menu');
    }
  }
  const onClickRestoreItemSingle = async (restoreItem=restoreRow) => {
    //console.log("onClickRestoreItemSingle_ restoreItem :: ", restoreItem);
    const { token, instanceUrl, projectId } = credential;
    setClickRestorePopup(false);
    if (restoreItem) {
      let typeToLabel = { 'file': 'File', 'folder': 'Folder', 'item': 'Item' }
      setSpinner(true);
      let toastMessage = `Sheet/s has been restored in the ${restoreItem.folderName} tab`;
      if(callFrom === 'FilesComponent'){
        toastMessage = `${typeToLabel[restoreItem.type]} has been restored in the folders tab.`;
      }
      await axios.put(`${fetchUrl}/restore/${restoreItem.type}/${restoreItem.id}`).then(res =>{
        setToastMessage(toastMessage);
        if(onRestore){
          onRestore();
        }
      }).catch((err) => {
        console.log(err);
        setSpinner(false);
      });
      getRecycledItems(token, instanceUrl, projectId);
      if (viewSheet) {
        setViewSheet(false);
      } else {
        // not redirection to folder table
        return;
      }
    }
  };
  const onClickRestoreItems = async (restoreItemIds=selectedRowIds) => {
    //console.log("onClickRestoreItems_ restoreItemIds :: ", restoreItemIds);
    const { token, instanceUrl, projectId } = credential;
    setClickRestorePopup(false);
    if (restoreItemIds.length > 0) {
      setSpinner(true);
      if(callFrom === 'FilesComponent'){
        let typeToLabel = { 'file': 'File', 'folder': 'Folder', 'item': 'Item' }
        let type = selectedFileRows?.length > 0 && selectedFolderRows?.length > 0 ? 'item' :
          selectedFolderRows?.length > 0 ? 'folder' : selectedFileRows?.length > 0 && 'file';
        let multi = restoreItemIds?.length > 1;
        let iName = '';
        if (multi === false) {
          if (selectedFolderRows?.length > 0) {
            iName = selectedFolderRows[0].name;
          } else {
            iName = selectedFileRows[0].name;
          }
        }
        let toDeleteCount = type === 'item' ? 2 : 1;
        let deletedCount = 0;
        if (selectedFileRows?.length > 0) {
          let toDeleteFiles = selectedFileRows?.map(obj => obj.id);
          let reqBody = { recIds: toDeleteFiles };
          await axios.post(`${fetchUrl}/restore/list/file`, reqBody).then(res => {
            deletedCount++;
          }).catch((err) => {
            console.log(err);
            setSpinner(false);
          });
        }
        if (selectedFolderRows?.length > 0) {
          let toDeleteFols = selectedFolderRows?.map(obj => obj.id);
          if (toDeleteFols?.length > 0) {
            let reqBody = { recIds: toDeleteFols };
            await axios.post(`${fetchUrl}/restore/list/folder`, reqBody).then(res => {
              deletedCount++;
            }).catch((err) => {
              console.log(err);
              setSpinner(false);
            });
          }
        }
        if (toDeleteCount === deletedCount) {
          let toastMessage = multi ? `${restoreItemIds?.length} ${typeToLabel[type]}s have been restored in the folders tab` :
            `${iName + ' ' + type} has been restored in the folders tab.`;
          setToastMessage(toastMessage);
          if(onRestore){
            onRestore();
          }
          if (props?.currentFolderName?.id) {
            getRecycledItems(token, instanceUrl, projectId);
            return;
          }
        }
      }else{
        let toastMessage = `Sheet/s has been successfully restored`;
        let reqBody = {recIds : restoreItemIds};
        await axios.post(`${fetchUrl}/restore/list/file`,reqBody).then(res =>{
        }).catch((err) => {
          console.log(err);
          setSpinner(false);
        });
        setToastMessage(toastMessage);
      }
      getRecycledItems(token, instanceUrl, projectId);
    }
  };
  const handleCloseIcon = () => {
    setSelectedRowIds([]);
    setSelectedFileRows([]);
    setSelectedFolderRows([]);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedMenuRow(null);
  };
  const handleClickMenu = (event, row) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedMenuRow(row);
    setAnchorEl({ mouseX: event.clientX, mouseY: event.clientY });
  };
  return (
    <UploadLogsstyles>
      <div className="main_class">
        <div className="table_head main_table">
          <div className="left_side">
            {selectedRowIds.length > 0 && (
              <div className="buttons" onClick={handleCloseIcon}>
                <img className="close" src="close.svg" alt="close" />
              </div>
            )}
            <div style={{ borderRight: "1px solid #EAEAEA", width: "100px", height: "37px", alignItems: "center" }}>
              <h2 style={{ marginTop: "8px",color: "var(--drgray)",fontWeight:'400', fontSize: "14px", lineHeight: "21px" }}>{selectedRowIds.length} Selected</h2>
            </div>
            <div style={{ borderRight: "1px solid #EAEAEA", borderTop: "1px solid #EAEAEA", borderBottom: "1px solid #EAEAEA", borderLeft: "1px solid #EAEAEA", display: "flex" }}>
              {selectedRowIds.length > 0 && (
                <div className="grid" onClick={() => !skipRestorePopup ? setClickRestorePopup(true , 'header') : onClickRestoreItems(selectedRowIds)}>
                  <img className="restore" src="restore.svg" alt="restore" />
                    <div className="label poppins-normal-abbey-14px" style={{fontSize: '13px', fontWeight: '400', paddingTop: '3px'}}>
                      Restore
                    </div>
                </div>
              )}
            </div>
          </div>
          <div className="right_side">
            <TextField sx={{ "& .MuiInputBase-root": { color: "#505050", height: '37px' }, }}
              style={{ color: "#929292" }}
              id="outlined-basic"
              variant="outlined"
              fullWidth
              value={searchText}
              autoComplete="off"
              className="Searchbar-field"
              placeholder={callFrom == "FilesComponent" ? "Search File / Folder" :"Search Sheet Number"}
              onChange={(e) => {
                //convert input text to lower case
                var lowerCase = e.target.value.toLowerCase();
                if (!lowerCase || lowerCase == null || lowerCase === "") {
                  searchListViewFunction(lowerCase);
                }
                setSearchText(lowerCase);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  // Do code here
                  e.preventDefault();
                  searchListViewFunction(searchText);
                }
              }}
              InputProps={{
                maxLength: 255,
                endAdornment: (
                  <InputAdornment position="start">
                    {searchBoxFilter  ? <img style={{width: "25px", cursor: "pointer"}} src="/cancel.svg" alt="close icon"  onClick = {()=>  {searchListViewFunction("");  setSearchText("");}}/> : <Search />}
                  </InputAdornment>
                ),
              }}
            />
            {/* <div className="d-flex">
              <IconButton className={!isGridView ? "list_view active" : "list_view"} onClick={() => setIsGridView(false)} >
                <GiHamburgerMenu />
              </IconButton>
              <IconButton style={{ cursor: "pointer" }} className={isGridView ? "grid_view active" : "grid_view"}
                onClick={() => setIsGridView(true)} >
                <GridViewIcon />
              </IconButton>
            </div> */}
          </div>
        </div>
          {(searchBoxFilter || contentSearchRunning) &&
            <div className="bottom_area" style={{ paddingBottom: "16px", paddingLeft: "16px", fontSize: "14px", fontWeight: 400 }}>
                {searchBoxFilter  && <> {tableViewData.length} Search Results for <b>"{searchText}"</b></>}
                {contentSearchRunning && ' Pdf Search is Running...'}
            </div>
          }
        <Box  style={{ height: searchBoxFilter ? "calc(100vh - 210px)" : "calc(100vh - 180px)",}}>
          {showSpinner && <Spinner />}
          {recycledItems && (
            <ReactDataTable
              tableHeight={(searchBoxFilter || contentSearchRunning) && "calc(98vh - 217px)"}
              errorMessage={callFrom === "FilesComponent" ? searchBoxFilter ? searchErrorMessage : errorMessage : ""}
              pagination={false}
              setRowsPerPage={setRowsPerPage}
              columns_react_table={callFrom === "FilesComponent" ? files_component_columns : sheets_component_columns}
              rowss={tableViewData}
              openMenu={false}
              selectedIds={selectedRowIds}
              searchBoxFilter={searchBoxFilter}
            />
          )}
        </Box>
      </div>
      {restorePopup &&
        <ConfirmDialogCheckbox
          credential={credential}
          nameOfDontShowProperty='recycleSkipRestoreDialog'
          DialogData={restorePopupData}
          onAccept={() => {
            if(restoreRow){
              onClickRestoreItemSingle(restoreRow);
            }else{
              onClickRestoreItems(selectedRowIds);
            }
          }}
          onCancel={() => {
            setClickRestorePopup(false);
          }}
        />
      }
      {viewSheet &&
        <ViewDeleteSheet
          credential={credential}
          skipRestorePopup={skipRestorePopup}
          setViewSheet={setViewSheet}
          onAccept={() => {
            setViewSheet(false);
            onClickRestoreItemSingle(restoreRow);
          }}
          onCancel={() => {
            setViewSheet(false);
          }}
          fileData={restoreRow}
          DialogData={restorePopupData}
        />
      }
      {toastMessage &&
        <ToastComponent message={toastMessage} handleClose={()=>setToastMessage(null)}/>
      }
      {openMenu && selectedMenuRow && (
          <>
            <Stack direction="row" spacing={2}>
                <Paper sx={{ height: 320, maxWidth: "100%" }}>
                    <Menu id="basic-menu" open={openMenu}
                        onClose={handleCloseMenu}
                        autoFocus
                        anchorReference="anchorPosition"
                        anchorPosition={
                            anchorEl
                            ? { top: anchorEl.mouseY, left: anchorEl.mouseX }
                            : undefined
                        }
                        transformOrigin={{
                            horizontal: 'left',
                            vertical: 'top',
                        }}
                        MenuListProps={{ "aria-labelledby": "basic-button" }}
                        onContextMenu={(e)=>{
                            e.preventDefault();
                            e.stopPropagation();
                            handleCloseMenu();
                        }}>
                        <div className="dropdown-list">
                          <div className="text-with-icon-1" onClick={() => {
                              handleRestorePopup(selectedMenuRow);
                              setAnchorEl(null);
                            }}>
                              <img className="restore" src="restore.svg" alt="restore" />{" "}
                              <div className="label-2 poppins-normal-abbey-14px">
                                Restore
                              </div>
                          </div>
                        </div>
                    </Menu>
                </Paper>
            </Stack>
          </>
      )}
    </UploadLogsstyles>
  );
};