import React, { useState } from "react";
import {Box} from "@mui/material";
import TableIssue from "./TableIssue";

function ReviewTable(props) {
  const { viewListData  , columns} = props?.tableProps;
  return (
    <Box component={"div"} sx={{ width: "100%" }}>
      <TableIssue
        columns={columns}
        data={viewListData.length > 0 ? viewListData : []}
        handleOpen={props?.openReviewDetail}
      />
    </Box>
  );
}

export default ReviewTable;
