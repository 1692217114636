import * as React from "react";
import _, { cloneDeep } from "lodash";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import more from "assets/images/more.svg";
import rightPath from "assets/images/right_path.svg";
import errorRed from "assets/images/error-red.svg";
import Spinner from "components/spinner";
import {
  Box,
  Checkbox,
  DialogContentText,
  IconButton,
  Typography,
  OutlinedInput,
  TextField,
  Tooltip,
  Stack,
  Select,
  MenuItem
} from "@mui/material";
import folderIcon from "assets/images/folder.svg";
import CloseIcon from "../../assets/images/close.svg";
import AddIcon from "../../assets/images/add.svg";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserData } from 'redux/features/userDataSlice';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { fetchUrl } from "Urls";
import { BreadcrubFolderView } from "components/FilesComponent/FolderFilesDataTable";
export const DisciplineDialogStyles = styled.div`
  h2.dialog_title.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root {
    color: #000000 !important;
    background: #fff !important;
    font-weight: 600 !important;
    border-bottom: 1px solid rgba(234, 234, 234, 1) !important;
    font-family: var(--common-font) !important;
  }
  .action_button.footer {
    justify-content: space-between !important;
    .checkbox {
      align-items: center !important;
    }
  }
  .img-hover {
    &:hover {
      background: red !important;
    }
  }
  .action_button {
    padding: 12px 20px;
    justify-content: flex-end !important;
    gap: 8px !important;
    border-top: 1px solid rgba(234, 234, 234, 1) !important;
  }
  .action_button-1 {
    gap: 8px !important;
    border-top: 2px solid rgba(234, 234, 234, 1) !important;
  }
    .Mui-disabled {
      color: white;
      background-color: #BABABA !important;
      box-shadow: none ;
      border-radius: 2px ;
      color: rgba(255, 255, 255, 1) ;
      font-family: var(--common-font) ;
      font-weight: 400 ;
      height: 39px ;
    }
  
  .MuiDialogContent-root {
    button.add_discpline {
      &:hover {
        background: #F8F8F8 !important;
      }
      color: var(--text-color) !important;
      text-transform: capitalize !important;
      font-family: var(--common-font) !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      right: 10px !important;
      top: 79px !important;
      display: flex !important;
      position: absolute !important;
      border: none !important;
      gap: 8px !important;
      &:hover {
        background: #F8F8F8 !important;
        svg {
          fill: var(--text-color) !important;
        }
      }
    }
    h6 {
      font-weight: 600 !important;
      font-size: 14px !important;
      margin-bottom: 20px !important;
      margin-top: 20px !important;
    }
  }
  .data_table {
    .MuiOutlinedInput-input {
      color: #929292;
    }
    .MuiOutlinedInput-input:hover {
      border-color: #D8D8D8;
      background-color: #F8F8F8;
      color: #929292;
    }
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: #D8D8D8;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: #D8D8D8;
    }
    .MuiDataGrid-cell{
      display: grid;
    }
    .MuiDataGrid-cell:focus {
      outline: none;
    }
    .MuiDataGrid-root {
    border: none;
    }
    .MuiOutlinedInput-input {
      padding: 10px 14px;
    }
    .MuiDataGrid-cell:focus-within{
      outline:none
    }
    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #D8D8D8;
      }
    }
    svg.MuiSvgIcon-root.MuiDataGrid-iconSeparator{
      fill: white;
    }
    height: 400px !important;
    width: 100% !important;
    min-width: 480px !important;
  }
  .dialog_content {
    var(--common-font)
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      font-family: var(--common-font) !important;
      font-size: 15px !important;
      color: rgba(80, 80, 80, 1) !important;
    }
    .publish_log {
      font-weight: 600 !important;
    }
    .MuiInputBase-root-MuiOutlinedInput-root {
      height: 100px;
    }
  }
  .MuiDataGrid-root {
    color: #505050;
    border: none;
  }
  .MuiDialogContent-root{
    padding: 0px !important;
  }
  .dropZone_containerDiscipline {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    margin-bottom: 16px !important;
  }
  .dropzoneDiscipline {
    text-align: center !important;
    cursor: pointer !important;
    transition: border 0.3s ease !important;
    width: 100% !important;
    min-height: 120px !important;
    display: grid !important;
    place-content: center !important;
    background: rgba(234, 234, 234, 1) !important;
  }
  .dropzoneDiscipline:hover {
    border-color: #1890ff !important;
  }
  .edit-icon {
    display: none;
    cursor: pointer;
    font-size: 18px;
  }
  
  /* Show the edit icon on hover for old rows */
  .MuiDataGrid-row:not(.new-row):hover .edit-icon {
    display: inline-block;
  }
  
  /* Style for the close icon */
  .close-icon {
    display: inline-block;
    cursor: pointer;
    font-size: 18px;
  }
  .MuiDataGrid-row.new-row .close-icon {
    display: inline-block;
  }
  .MuiDataGrid-row.new-row:hover .close-icon {
    display: none;
  }
  .MuiDataGrid-cell--withRenderer img {
    border: none;
    &:hover {
      background: #F8F8F8 !important;
    }
  }
  .buttons {
    align-items: center;
    border-radius: 2px; display: flex;
   // height: 24px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 24px;
    }
    .close {
      &:hover {
        background: #F8F8F8;
      }
    &:hover {
      background: #F8F8F8;
    }
      height: 16px; position: relative;
      width: 16px;
      }
      .modal-header {
      align-items: flex-start;
      background-color: #FFFFFF;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-color: #EAEAEA;
      border-radius: 2px 2px 0px 0px;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      padding: 16px 20px;
      position: relative;
      width: 400px;
      }
      .delete-sheet {
        color: var(--neutralsblack);
        font-family: var(--font-family-poppins);
        font-size: var(--font-size-m);
        font-weight: 600;
        letter-spacing: 0.16px;
        line-height: normal;
        margin-top: -1px;
        position: relative;
        width: fit-content;
      }
  .modal-body {
  align-items: flex-start;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center; 
  padding: 16px 20px;
  position: relative;
  width: 400px;
  }
  .are-you-sure-you-wan {
  color: #505050;
  font-weight: 400;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 360px;
  }
  .buttonsAction {
    &:hover {
      background: #F8F8F8;
    }
  cursor: pointer;
  align-items: center;
  background-color: #FFFFFF; 
  border: 1px solid #D8D8D8;
  border-color: #D8D8D8; 
  border-radius: 2px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  overflow: hidden;
  padding: 9px 16px;
  position: relative;
  }
  .modal-bottom {
    align-items: center;
    background-color: #FFFFFF; 
    border-color: #EAEAEA; 
    border-radius: 0px 0px 2px 2px;
    border-top-style: solid;
    border-top-width: 1px; display: flex;
    gap: 12px;
    justify-content: flex-end;
    overflow: hidden;
    padding: 12px 20px;
    position: relative;
    width: 400px;
    }
    .label {
      letter-spacing: 0; 
      line-height: normal;
      margin-top: -1px;
      position: relative;
      width: fit-content;
      color: #505050;
      }
      button.buttons-1 {
        font-family:var(--common-font);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: none;
        height: 39px !important;
        color: white;
        cursor: pointer;
        align-items: center;
        background-color: #F92828;
        border-radius: 2px;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 10px;
        justify-content: center;
        overflow: hidden;
        padding: 9px 16px;
        position: relative;
      }
      .label-1 {
      letter-spacing: 0;
      line-height: normal;
      margin-top: -1px; 
      position: relative; 
      width: fit-content;
      color: #FFFFFF;
      }
      .file_field-error {
        align-items: center;
        .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
          width: 110px;
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: red !important;
        }
        textarea {
          min-height: 37px;
          border: 1px solid red;
          padding: 8px 12px;
          border-radius: 2px;
          outline: none;
          color: #505050;
        }
        img {
          border: none;
          padding: 0;
        }
      }
  .folder_location {
    padding-top: 15px;
    padding-bottom: 2px;
    color: #505050;
    font-family: var(--common-font);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.12px;
  }
  .folder_path {
    position: relative;
    color: #505050;
    font-family: var(--common-font);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.12px;
  }
  .children-list{
    height: 250px;
    overflow: auto;
    border-top: 2px solid rgba(234, 234, 234, 1);
    border-bottom: 2px solid rgba(234, 234, 234, 1);
    background-color: #F8F8F8;
  }
  .child-item {
    font-family: var(--common-font);
    display:flex;
    gap: 10px;
    position: relative;
    cursor: pointer;
    height: 40px;
    padding: 8px 25px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
  }
  .child-item:hover {
    color:#000;
    font-weight: 600;
    background-color: #f0f0f0;
  }
  .move {
    visibility: hidden;
    margin-left: auto;
    color: #346EF5;
    font-weight: 400;
  }
  .child-item:hover .move {
    visibility: visible;   
  }
  .selection-layout{
    display: flex;
    padding: 16px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
  }
  .empty_folder{
    display: flex;
    height: 240px;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    color:#505050;
  }
  .add-folder-overlay{
    position:absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &.-header{
      display: flex;
      padding: 16px 20px;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
    }
    &.-body{
      display: flex;
      padding: 16px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
    }
    &.-action{
      display: flex;
      padding: 12px 20px;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      align-self: stretch;
    }
  }
  .addFolder {
    &:hover {
      background: #F8F8F8;
    }
    text-transform: none;
    color: #505050;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
const useStyles = makeStyles({
  editIcon: {
    display: "none",
    cursor: "pointer",
    fontSize: "18px",
  },
  closeIcon: {
    display: "none",
    cursor: "pointer",
    fontSize: "18px",
  },
  newRow: {
    display: "inline-block",
    fontSize: "18px",
    cursor: "pointer",
  },
  rowHover: {
    "&:hover $editIcon": {
      display: "inline-block",
    },
    "&.new-row:hover $closeIcon": {
      display: "none",
    },
  },
});
export default function DisciplineDialog(props) {
  const classes = useStyles();
  const { disciplineSettingModal, setDisciplineSettingModal, onRefreshDiscipline, credential } = props;
  const handleEditClick = (psssss) => {
    console.log("p ::", psssss);
  };
  const [rows, setRows] = useState([]);
  const [pdfRowErrors, setPdfRowErrors] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [duplicateError, isDuplicate] = useState(false);
  const [emptyError, isEmptyError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    setRows(props?.disciplineList);
  }, [props?.disciplineList]);
  async function onRowDataChange(id, section, val) {
    //console.log('index :: ',index);
    const _temp = cloneDeep(rows);
    const index = _temp?.findIndex((data) => data.id == id);
    /*console.log('index :: ',index);
    console.log('section :: ',section);
    console.log('text :: ',text);*/
    if (_temp[index]) {
      if (_temp[index][section] != val) {
        _temp[index][section] = val;
        if (section !== 'editMode') {
          _temp[index].changes = true;
        }
        if (section == 'isNewRow') {
          _temp[index]["editMode"] = false;
        }
        if (section == 'Designator__c' || section == 'Name') {
          let errorrEmpty = false;
          let errorrDupli = false;
          const seenValues = new Map();
          for (let i = 0; i < _temp.length; i++) {
            const value = _temp[i]['Designator__c'];
            const value2 = _temp[i]['Name'];
            if (value && value != '' && value2 && value2 != '') {
              console.log("seenValues :", seenValues, value, i);
              if (seenValues.has(value)) {
                console.log("seenValues2.has(value2) ::", seenValues.has(value));
                errorrDupli = true;
                break;
              } else {
                seenValues.set(value, i);
              }
            } else {
              errorrEmpty = true;
              break;
            }
          }
          isEmptyError(errorrEmpty);
          console.log("errorrDupli ::", errorrDupli);
          isDuplicate(errorrDupli);
        }
        setRows(_temp);
        if (section == 'Designator__c') {
          onValidatePdfPageDataChange(_temp, section);
        }
      }
    }
  }
  function onValidatePdfPageDataChange(rowData, section) {
    //console.log("onValidatePdfPageDataChange", rowData);
    const _temp = cloneDeep(rowData);
    //console.log("_temp ::", _temp);
    var errObj;
    if (_temp?.length > 0) {
      //console.log("_temp ::length");
      const seenValues = new Map();

      for (let i = 0; i < _temp.length; i++) {
        //console.log("_temp ::length1");
        const value = _temp[i][section];
        if (value && value != '') {
          //console.log("_temp ::length2");
          if (seenValues.has(value)) {
            //console.log("_temp ::length3");
            if (!errObj) {
              errObj = { duplicates: [] };
            }
            if (!errObj.duplicates) {
              errObj.duplicates = [];
            }
            //console.log("errObj.duplicates2 ::", _temp[i].id);
            //console.log("errObj.duplicates3 ::", seenValues.get(value));
            errObj.duplicates.push(seenValues.get(value));
            //console.log("errObj.seenValues4 ::", seenValues);
          } else {
            seenValues.set(value, _temp[i].id);
          }
        } else {
        }

      }
      setPdfRowErrors(errObj);

    }
  }
  async function onRowRemove(id) {
    //console.log('index :: ',index);
    const _temp = cloneDeep(rows);
    const index = _temp?.findIndex((data) => data.id == id);
    /*console.log('index :: ',index);
    console.log('section :: ',section);
    console.log('text :: ',text);*/
    if (index > -1 && _temp[index]) {
      if (_temp[index].Id) {
        axios.delete(`${fetchUrl}/delete/Discipline__c/${_temp[index].Id}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
          .then((res) => {
            setShouldRefresh(true);
          }).catch((err) => {
            console.log(err);
          });
      }
      _temp.splice(index, 1);
      setRows(_temp);
    }
  }
  const handleAddRow = () => {
    const newRow = {
      id: rows.length,
      Designator__c: "",
      Name: "",
      isNewRow: true,
      editMode: true
    };
    // Add the new row to the 'rows' array
    setRows((_rows) => { return [newRow, ..._rows] });
    setSelectedRows([newRow.id]);
  };
  const handleClose = () => {
    if (shouldRefresh) {
      onRefreshDiscipline();
    }
    setDisciplineSettingModal(false);
    //setRows();
  };
  const handleSave = async () => {
    console.log("duplicateError ::", duplicateError, emptyError);
    if (emptyError || duplicateError) {
      console.log('data validation error.');
      return;
    }
    setIsDisabled(true);
    let toSaveRecords = [];
    for (let row of rows) {
      if (row.changes) {
        toSaveRecords.push({ Id: row.Id, Name: row.Name, Designator__c: row.Designator__c, Project__c: credential.projectId });
      }
    }
    console.log('toSaveRecords :: ' + JSON.stringify(toSaveRecords));
    if (toSaveRecords.length > 0) {
      const hasInvalidRecords = toSaveRecords.some(record => record.Name === "" && record.Designator__c === "");
      if (!hasInvalidRecords) {
        const fd = new FormData();
        fd.append("recLists", JSON.stringify(toSaveRecords));
        await axios.post(`${fetchUrl}/recSave/list/Discipline__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, fd)
          .then((res) => {
            //console.log("count:::: " + JSON.stringify(res));
            if (res.status === 200) {
              onRefreshDiscipline();
              setDisciplineSettingModal(false);
            }
          }).catch((err) => {
            console.log(err);
          });
      }
    } else {
      handleClose();
    }
    setIsDisabled(false);
  };
  const renderDesignatorCell = (params) => {
    var value = params?.value;
    const checkDuplicate = (designator) => {
      const designatorCount = rows.reduce((count, item) => {
        return count + (item.Designator__c === designator ? 1 : 0);
      }, 0);
      return designatorCount > 1;
    };

    return (
      <>
        {!params?.row.editMode && <div>
          {params.value}
        </div>}
        {params?.row.editMode && <>
          <OutlinedInput
            className={checkDuplicate(value) ? "file_field-error" : "file_field"}
            style={{ backgroundColor: "white", height: checkDuplicate(value) && "35px" }}
            variant=""
            defaultValue={value}
            error={params.row.desiDupli || params.row.desiEmpty}
            inputProps={{ style: { textTransform: "uppercase" } }}
            onChange={(event) => {
              let val = event.target.value;
              value = event.target.value;

              onRowDataChange(params?.row.id, 'Designator__c', val.toUpperCase());
            }}
            onKeyDown={(event) => {
              if (event?.key?.toLowerCase() != "tab") {
                event.stopPropagation();
              }
            }}
            onBlur={(event) => {
              let val = event.target.value;
              value = event.target.value;
              onRowDataChange(params?.row.id, 'Designator__c', val.toUpperCase());
            }}
          />
        </>}
        {checkDuplicate(value) && (
          <div style={{ fontSize: "10px" }}>
            <div style={{ color: "red" }}>
              <img style={{ padding: "0px" }} src="error-red.svg" alt="error" />{'Designator Duplicated'}
            </div>
          </div>

        )}
      </>
    );
  };
  function renderNameCell(params) {
    var value = params?.value;
    const checkDuplicate = (designator) => {
      const designatorCount = rows.reduce((count, item) => {
        return count + (item.Designator__c === designator ? 1 : 0);
      }, 0);
      return designatorCount > 1;
    };
    return (
      <>
        {!params?.row.editMode && <>
          {params.value}
        </>}
        {params?.row.editMode && <>
          <OutlinedInput
            className={checkDuplicate(params?.row?.Designator__c) ? "file_field-error" : "file_field"}
            style={{ backgroundColor: "white", height: checkDuplicate(params?.row?.Designator__c) && "35px", marginBottom: checkDuplicate(params?.row?.Designator__c) && "15px" }}
            variant=""
            error={params.row.nameDupli || params.row.nameEmpty}
            defaultValue={value}
            onChange={(event) => {
              let val = event.target.value;
              value = event.target.value;
            }}
            onKeyDown={(event) => {
              if (event?.key?.toLowerCase() != "tab") {
                event.stopPropagation();
              }
            }}
            onBlur={(event) => {
              let val = event.target.value;
              value = event.target.value;
              onRowDataChange(params?.row.id, 'Name', val);
            }}
          />
        </>}
      </>
    );
  };

  const columns = [
    {
      field: "Designator__c",
      headerName: "Designator",
      width: 150,
      renderCell: renderDesignatorCell
    },
    {
      field: "Name",
      headerName: "Name",
      width: 210,
      renderCell: renderNameCell
    },
    {
      field: "edit",
      headerName: "",
      width: 80,
      renderCell: (params) => (
        <span className={`${params.row.isNewRow ? classes.newRow : classes.editIcon} ${params.row.isNewRow ? "new-row" : "edit-icon"}`}>
          {params.row.isNewRow ?
            <div>
              <img clasName="img-hover" src="check-1.svg" alt="pencil" onClick={() => {
                if (!pdfRowErrors) {
                  onRowDataChange(params.row.id, 'isNewRow', false);
                }
              }} />
              <img clasName="img-hover" src="close.svg" alt="pencil" onClick={() => {
                onRowRemove(params.row.id);
              }} />
            </div>
            :
            <div onClick={() => {
              onRowDataChange(params.row.id, 'editMode', !params.row.editMode);
            }}>
              {!params.row.editMode ? <img clasName="img-hover" src="Pencil.svg" alt="pencil" />
                : <img clasName="img-hover" src="check-1.svg" alt="check" />
              }
            </div>
          }
        </span>
      ),
    },
  ];

  return (
    <Dialog open={disciplineSettingModal} onClose={handleClose}>
      <DisciplineDialogStyles style={{ width: "500px" }}>
        <DialogTitle className="dialog_title">
          Discipline Settings
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 12, }} >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="subtitle2" style={{ paddingLeft: "25px", fontSize: "14px", fontWeight: 500, lineHeight: "normal" }} >
              {rows?.length + " " + "Disciplines"}
            </Typography>
            <Button className="add_discpline" onClick={handleAddRow}>
              <img src={AddIcon} alt="icons" />
              Add Discipline
            </Button>
          </Box>
          <Box className="data_table">
            <DataGrid
              style={{ paddingLeft: "10px", paddingRight: '10px' }}
              rows={rows}
              columns={columns}
              checkboxSelection={false}
              hideFooter
              disableColumnMenu
              sortingMode
              disableRowSelectionOnClick
              onRowSelectionModelChange={(newSelection) =>
                setSelectedRows(newSelection)
              }
            />
          </Box>
        </DialogContent>
        <DialogActions className="action_button">
          <Button className="custom_button cancel_button" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="custom_button apply_button" onClick={() => {
            console.log('rows :: ', JSON.stringify(rows));
            handleSave();
          }} disabled={isDisabled || emptyError || duplicateError}>
            Save Settings
          </Button>
        </DialogActions>
      </DisciplineDialogStyles>
    </Dialog>
  );
}
export const SaveDraftDialog = (props) => {
  const { saveAsDraftDialog, setSaveAsDraftDialog, onAccept } = props;
  const handleClose = () => {
    setSaveAsDraftDialog(false);
  };
  return (
    <Dialog open={saveAsDraftDialog} onClose={handleClose} maxWidth="xs">
      <DisciplineDialogStyles>
        <DialogTitle className="dialog_title">
          Save sheets as draft?
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 12,
            }}
          >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content" >
          <DialogContentText style={{ padding: "20px" }}>
            Sheets will be saved as draft and will not be visible to the public.
            You may continue to edit this in your{" "}
            <span className="publish_log">publish logs</span> tab.
          </DialogContentText>
        </DialogContent>
        <DialogActions className="action_button">
          <Button className="custom_button cancel_button" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="custom_button apply_button" onClick={onAccept}>
            Save as Draft
          </Button>
        </DialogActions>
      </DisciplineDialogStyles>
    </Dialog>
  );
};
export const ConfirmDialog = (props) => {
  const { onAccept, onCancel, onAction, DialogData } = props;
  const [applyButtonClass, setApplyButtonClass] = useState(props?.accept_button_class ? props?.accept_button_class : 'red_button');
  const onAction3 = () => {
    if (onAction) {
      onAction();
    }
  }
  return (
    <Dialog open={true}>
      <DisciplineDialogStyles style={{ width: "450px" }}>
        <DialogTitle className="dialog_title">
          {DialogData.title}
          <IconButton
            aria-label="close"
            onClick={onCancel}
            sx={{
              position: "absolute",
              right: 12,
            }}
          >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content">
          <DialogContentText style={{ padding: "16px 20px 16px 20px" }}>
            {DialogData.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="action_button">
          <Button className="custom_button cancel_button" onClick={onCancel}>
            {DialogData.action1}
          </Button>
          <Button className={`custom_button ${applyButtonClass}`} onClick={onAccept}>
            {DialogData.action2}
          </Button>
          {DialogData.action3 &&
            <Button className={`custom_button apply_button`} onClick={onAction3}>
              {DialogData.action3}
            </Button>
          }
        </DialogActions>
      </DisciplineDialogStyles>
    </Dialog>
  );
};
export const ConfirmDialogCheckbox = (props) => {
  const dispatch = useDispatch();
  const { onAccept, onCancel, DialogData, credential, nameOfDontShowProperty } = props;
  const [dontShow, DontShowChecked] = useState(false);
  return (
    <Dialog open={true} onClose={onCancel} fullWidth>
      <DisciplineDialogStyles>
        <DialogTitle className="dialog_title">
          {DialogData.title}
          <IconButton
            aria-label="close"
            onClick={onCancel}
            sx={{ position: "absolute", right: 12 }}
          >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content" >
          <DialogContentText style={{ padding: "20px" }}>
            {DialogData.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="action_button">
          <div className="text-style-for-div" style={{ display: "flex" }}>
            <Checkbox className="checkbox-style" style={{ padding: "0px", margin: "10px 0px" }} onChange={(e) => {
              const dontShow_ = e.target.checked; DontShowChecked(dontShow_);
              if (credential && nameOfDontShowProperty) {
                dispatch(setUserData({ ...credential, [nameOfDontShowProperty]: dontShow_ }));
              }
            }} />
            <div style={{ paddingTop: "12px", paddingLeft: "8px" }}>Don't show again</div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <Button className="custom_button cancel_button" style={{ marginRight: "0.8rem" }} onClick={() => {
              onCancel(dontShow);
            }}>
              {DialogData.action1}
            </Button>
            <Button className={DialogData.action2 == "Restore" ? "custom_button apply_button" : "custom_button red_button"} onClick={() => onAccept(dontShow)}>
              {DialogData.action2}
            </Button>
          </div>
        </DialogActions>
      </DisciplineDialogStyles>
    </Dialog>
  );
};

export const DefineSheetNumberDialog = (props) => {
  const dispatch = useDispatch();
  const { defineSheetNumberDialog, setDefineSheetNumberDialog, section, credential } = props;
  const [dontShow, DontShowChecked] = useState(false);
  const handleClose = () => {
    if (dontShow && credential) {
      dispatch(setUserData({ ...credential, defineSheetTutorialSkip: true }));
    }
    setDefineSheetNumberDialog(false);
  };
  const onDrop = useCallback((acceptedFiles) => {
    console.log("acceptedFiles", acceptedFiles);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".gif",
    multiple: false,
  });
  return (
    <Dialog open={defineSheetNumberDialog} onClose={handleClose} maxWidth="xs">
      <DisciplineDialogStyles>
        <DialogTitle className="dialog_title">
          Define sheet {section}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 12,
            }}
          >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content">
          <section className="dropZone_containerDiscipline">
            <div {...getRootProps({ className: "dropzoneDiscipline" })}>
              <div className="upload_holder">
                <input {...getInputProps()} />
                <div className="drag_drop">
                  Add gif here on how to define the sheet {section}
                </div>
              </div>
            </div>
          </section>
          <DialogContentText style={{ padding: "16px 20px 16px 20px" }}>
            Use the draw area tool to define sheet {section}s for all selected
            sheets. This tool can be found in the toolbar.
          </DialogContentText>
        </DialogContent>
        <DialogActions className="action_button footer">
          <Box className="checkbox">
            <Checkbox onChange={(e) => { DontShowChecked(e.target.checked); }} />
            Don't show again
          </Box>
          <Button className="save_settings" onClick={handleClose}>
            Okay
          </Button>
        </DialogActions>
      </DisciplineDialogStyles>
    </Dialog>
  );
};

export const DeleteMarkupsDialog = (props) => {
  const { onDelete, markupCount, deleteMarkupsDialog, setDeleteMarkupsDialog } = props;
  const handleClose = () => {
    setDeleteMarkupsDialog(false);
  };

  return (
    <Dialog open={deleteMarkupsDialog} onClose={handleClose} maxWidth="xs">
      <DisciplineDialogStyles>
        <DialogTitle className="dialog_title">
          {`Delete ${markupCount} Markup(s)?`}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 12,
            }}
          >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content">
          <DialogContentText style={{ padding: "16px 20px 16px 20px" }}>
            Are you sure you want to delete the selected markups? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions className="action_button">
          <Button className="custom_button cancel_button" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="custom_button red_button" onClick={onDelete}>
            Delete
          </Button>
        </DialogActions>
      </DisciplineDialogStyles>
    </Dialog>
  );
};
export const PublishIssueDialog = (props) => {
  const { onAccept, onCancel } = props;

  return (
    <Dialog open={true} onClose={onCancel} maxWidth="xs">
      <DisciplineDialogStyles>
        <DialogTitle className="dialog_title">
          Publish Issue
          <IconButton
            aria-label="close"
            onClick={onCancel}
            sx={{
              position: "absolute",
              right: 12,
            }}
          >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content">
          <DialogContentText style={{ padding: "16px 20px 16px 20px" }}>
            Are you sure you want to publish this issue? Once
            published. you cannot unpublish but you may edit
            this issue.
          </DialogContentText>
        </DialogContent>
        <DialogActions className="action_button">
          <Button className="custom_button cancel_button" onClick={onCancel}>
            Cancel
          </Button>
          <Button className="custom_button apply_button" onClick={onAccept}>
            Publish Issue
          </Button>
        </DialogActions>
      </DisciplineDialogStyles>
    </Dialog>
  );
};
export const MakeResolution = (props) => {
  const { onAccept, onCancel, dialogData } = props;
  const [resolutonData, setResolutionData] = React.useState(props?.defaultValue ? props?.defaultValue : '');
  return (
    <Dialog open={true} onClose={onCancel} maxWidth="xs">
      <DisciplineDialogStyles>
        <DialogTitle className="dialog_title">
          {dialogData.title}
          <IconButton aria-label="close" onClick={onCancel} sx={{ position: "absolute", right: 12, }} >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content">
          <DialogContentText style={{ padding: "16px 20px 16px 20px" }}>
            {dialogData.content}
            <TextField
              id="resolution"
              inputProps={{ style: { height: "100px", width: "380px", color: "#505050" } }}
              value={resolutonData}
              onChange={(e) => { setResolutionData(e?.target?.value) }}
              multiline
              rows={2}
              maxRows={3}
              onKeyDown={(event) => {
                if (event.key.toLowerCase() != "tab") {
                  event.stopPropagation();
                }
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions className="action_button">
          <Button className="custom_button cancel_button" onClick={onCancel}>
            {dialogData.action1}
          </Button>
          <Button className="custom_button apply_button" disabled={!resolutonData || resolutonData === '' || props?.defaultValue == resolutonData} onClick={() => { onAccept(resolutonData); }}>
            {dialogData.action2}
          </Button>
        </DialogActions>
      </DisciplineDialogStyles>
    </Dialog>
  );
};
export const NewFolderDialog = (props) => {
  const { onAccept, onCancel, dialogData, selectedNode, error, showBreadcrub } = props;
  const [resolutonData, setResolutionData] = React.useState(props?.defaultValue ? props?.defaultValue : '');
  const handleInputChange = (event) => {
    // Define a regular expression to exclude the characters you want to disallow
    const invalidCharacters = /["/:|<>*@?\\]/g;

    // Replace the invalid characters with an empty string
    const newValue = event.target.value.replace(invalidCharacters, '');

    setResolutionData(newValue);
  };
  return (
    <Dialog open={true} onClose={onCancel} maxWidth="xs">
      <DisciplineDialogStyles>
        <DialogTitle className="dialog_title">
          {dialogData.title}
          <IconButton aria-label="close" onClick={onCancel} sx={{ position: "absolute", right: 12, }} >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content">
          <DialogContentText style={{ padding: "16px 20px 16px 20px" }}>
            {dialogData.content}
            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${error && "red"} `
                }
              }}
              id="resolution"
              className="Searchbar-field"
              inputProps={{ maxLength: 255, style: { height: "10px", width: "380px", color: "#505050", borderColor: "red" } }}
              value={resolutonData}
              onChange={handleInputChange}
              onKeyDown={(event) => {
                if (event.key.toLowerCase() != "tab") {
                  event.stopPropagation();
                }
              }}
            />
            {error &&
              <div style={{ fontSize: "12px", color: "red", paddingTop: "5px", fontWeight: 400 }}>
                <img style={{ marginRight: "5px" }} src={errorRed} alt="error" /> Folder name alreay exists. Please choose a new one.
              </div>
            }
            {showBreadcrub &&
              <>
                <div className="folder_location">
                  New Folder Location:
                </div>
                <div className="folder_path">
                  <div className="folder_path">
                    <BreadcrubFolderView
                      selectedNode={selectedNode}
                    />
                  </div>
                </div>
              </>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions className="action_button">
          <Button className="custom_button cancel_button" onClick={onCancel}>
            {dialogData.action1}
          </Button>
          <Button className="custom_button apply_button" disabled={!resolutonData || resolutonData === '' || props?.defaultValue == resolutonData} onClick={() => { onAccept(resolutonData); }}>
            {dialogData.action2}
          </Button>
        </DialogActions>
      </DisciplineDialogStyles>
    </Dialog>
  );
};
export const RecordRenameDialog = (props) => {
  const { onAccept, onCancel, dialogData, orgData } = props;
  const [error, showError] = React.useState(false);
  const [defaultValue, setDefaultValue] = React.useState('');
  const [exte, setExtention] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [limitError, setLimitError] = React.useState('');
  useEffect(() => {
    var inputVal = dialogData?.rowTitleChange ? dialogData?.rowData?.title ? dialogData?.rowData?.title : '' : dialogData?.rowData?.name ? dialogData?.rowData?.name : '';
    var et = '';
    if (inputVal.lastIndexOf('.') > -1 && dialogData?.rowData?.type === 'file' && !dialogData?.rowTitleChange) {
      let pos = inputVal.lastIndexOf('.');
      et = inputVal.substring(pos);
      inputVal = inputVal.substring(0, pos);
    }
    setExtention(et);
    setDefaultValue(inputVal);
    setInputValue(inputVal);
  }, []);
  const handleInputChange = (event) => {
    // Define a regular expression to exclude the characters you want to disallow
    const invalidCharacters = /["/:|<>*@?\\]/g;
    // Replace the invalid characters with an empty string
    const newValue = event.target.value.replace(invalidCharacters, '');
    setInputValue(newValue);
  };
  const onClickSave = (event) => {
    if (inputValue && inputValue != null && inputValue !== "") {
      const saveName = inputValue;
      if (orgData?.length > 0) {
        for (let el of orgData) {
          if (el.type === dialogData?.rowData.type) {
            var preValue = dialogData?.rowTitleChange ? el.title ? el.title : '' : el.name ? el.name : '';
            if (el.type === 'file') {
              if (preValue.lastIndexOf('.') > -1) {
                preValue = preValue.substring(0, preValue.lastIndexOf('.'));
              }
            }
            if ((preValue.toLowerCase().trim() === saveName.toLowerCase().trim())) {
              showError(true);
              return;
            }
            if (dialogData?.rowData?.type === 'folder' && saveName.length > 80) {
              setLimitError('Folder name is too long (max 80 characters)')
              showError(true);
              return;
            } else if (dialogData?.rowData?.type === 'file' && saveName.length > 255) {
              setLimitError('File name is too long (max 255 characters)')
              showError(true);
              return;
            } else {
              setLimitError("");
            }

          }
        }
      }
      showError(false);
      let objName = dialogData?.rowData?.type === 'file' ? 'Amazon_S3_Attachment__c' : 'Folder__c';
      onAccept({ Id: dialogData?.rowData?.id, [`${objName === 'Amazon_S3_Attachment__c' ? 'File_Name__c' : 'Name'}`]: saveName + exte }, objName, defaultValue, saveName);
    }

  };
  return (
    <Dialog open={true} onClose={onCancel} maxWidth="xs">
      <DisciplineDialogStyles>
        <DialogTitle className="dialog_title">
          {dialogData.title}
          <IconButton aria-label="close" onClick={onCancel} sx={{ position: "absolute", right: 12, }} >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content">
          <DialogContentText style={{ padding: "16px 20px 16px 20px" }}>
            {dialogData.content}
            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${error && "red"} `
                }
              }}
              id="resolution"
              className="Searchbar-field"
              inputProps={{ maxLength: 255, style: { height: "10px", width: "380px", color: "#505050", borderColor: "red" } }}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={(event) => {
                if (event.key.toLowerCase() !== "tab") {
                  event.stopPropagation();
                }
              }}
            />
            {error &&
              <div style={{ fontSize: "12px", color: "red", paddingTop: "5px", fontWeight: 400 }}>
                <img style={{ marginRight: "5px" }} src={errorRed} alt="error" /> {limitError ? limitError : dialogData.errorMessage}
              </div>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions className="action_button">
          <Button className="custom_button cancel_button" onClick={onCancel}>
            {dialogData.action1}
          </Button>
          <Button className="custom_button apply_button" disabled={!inputValue || inputValue === '' || defaultValue === inputValue} onClick={() => { onClickSave(); }}>
            {dialogData.action2}
          </Button>
        </DialogActions>
      </DisciplineDialogStyles>
    </Dialog>
  );
};

export const AssignDialog = (props) => {
  const { onAccept, onCancel, dialogData, picklistValue } = props;
  const [assignValue, setAssignValue] = React.useState(null);

  return (
    <Dialog open={true} onClose={onCancel} maxWidth="xs">
      <DisciplineDialogStyles>
        <DialogTitle className="dialog_title">
          {dialogData.title}
          <IconButton aria-label="close" onClick={onCancel} sx={{ position: "absolute", right: 12, }} >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content">
          <DialogContentText style={{ padding: "16px 20px 16px 20px" }}>
            <div style={{ display: 'flex', gap: '8px', marginBottom: '5px' }}>
              <div>
                {dialogData.content} :
              </div>
              <div>{dialogData.contentValue}</div>
            </div>
            <div style={{ marginBottom: '5px' }}>
              {dialogData.selectLabel}
            </div>
            <div>
              <Select id="type" style={{ height: "37px", width: "380px", color: "#505050" }}
                value={assignValue ? assignValue : ''}
                onChange={(event) => {
                  setAssignValue(event?.target?.value);
                }}
              >
                {picklistValue &&
                  picklistValue.map(
                    (opt, index) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    )
                  )}
              </Select>
            </div>

          </DialogContentText>
        </DialogContent>
        <DialogActions className="action_button">
          <Button className="custom_button cancel_button" onClick={onCancel}>
            {dialogData.action1}
          </Button>
          <Button className="custom_button apply_button" disabled={!assignValue} onClick={() => {
            console.log("call");
            onAccept(assignValue);
          }}>
            {dialogData.action2}
          </Button>
        </DialogActions>
      </DisciplineDialogStyles>
    </Dialog>
  );
};

export const DisciplineSettingDialog = (props) => {
  const { onAccept, onCancel, dialogData, disciplineList, selectedRow, disciplineAction } = props;
  const [error, showError] = useState(false);
  const [designatorError, setDesignatorError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [disciplineValues, setDisciplineValues] = useState({});
  useEffect(() => {
    if (selectedRow) {
      let obj = cloneDeep(selectedRow);
      setDisciplineValues(obj);
    }
  }, [selectedRow]);
  const handleInputChange = (event, field) => {
    let nameValidation = false;
    let designatorValidation = false;
    // Define a regular expression to exclude the characters you want to disallow
    const invalidCharacters = /["/:|<>*@?\\]/g;
    // Replace the invalid characters with an empty string
    let newValue;
    if (field == "Designator__c") {
      newValue = event.target.value.toUpperCase().replace(invalidCharacters, '');
    } else {
      newValue = event.target.value.replace(invalidCharacters, '');
    }
    let tempObj = cloneDeep(disciplineValues);
    console.log("tempObj 1 ::", tempObj);
    tempObj[field] = newValue;
    let values = JSON.parse(JSON.stringify(tempObj))
    setDisciplineValues(values);
    if (tempObj.Name != "" && tempObj.Designator__c != "") {
      showError(false);
      console.log("tempObj ::", tempObj);
      if (disciplineList?.length > 0) {
        for (let el of disciplineList) {
          var preValue = el.Name ? el.Name : '';
          var preValue_2 = el.Designator__c ? el.Designator__c : '';
          if (selectedRow) {
            if (selectedRow.id != el.id) {
              if ((tempObj && tempObj.Name && preValue.toLowerCase().trim() === tempObj.Name.toLowerCase().trim())) {
                nameValidation = true;
                setNameError(true);
              } else {
                if (!nameValidation) {
                  setNameError(false);
                }
              }
              if ((tempObj && tempObj.Designator__c && preValue_2.toLowerCase().trim() === tempObj.Designator__c.toLowerCase().trim())) {
                designatorValidation = true;
                setDesignatorError(true);
              } else {
                if (!designatorValidation) {
                  setDesignatorError(false);
                }
              }
            }
          } else {
            if ((tempObj && tempObj.Name && preValue.toLowerCase().trim() === tempObj.Name.toLowerCase().trim())) {
              nameValidation = true;
              setNameError(true);
            } else {
              if (!nameValidation) {
                setNameError(false);
              }
            }
            if ((tempObj && tempObj.Designator__c && preValue_2.toLowerCase().trim() === tempObj.Designator__c.toLowerCase().trim())) {
              designatorValidation = true;
              setDesignatorError(true);
            } else {
              if (!designatorValidation) {
                setDesignatorError(false);
              }
            }
          }
        }
      }
    } else {
      showError(true);
    }
  };
  return (
    <Dialog open={true} onClose={onCancel} maxWidth="xs">
      <DisciplineDialogStyles>
        <DialogTitle className="dialog_title">
          {dialogData.title}
          <IconButton aria-label="close" onClick={onCancel} sx={{ position: "absolute", right: 12, }} >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content">
          <DialogContentText style={{ padding: "16px 20px 16px 20px" }}>
            <div style={{ marginBottom: "12px" }}>{dialogData.content1}</div>
            <div style={{ marginBottom: "4px" }}>{dialogData.content2}</div>
            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${designatorError && "red"} `
                }
              }}
              id="resolution"
              className="Searchbar-field"
              inputProps={{ maxLength: 80, style: { height: "10px", width: "380px", color: "#505050", borderColor: "red", textTransform: "uppercase" } }}
              value={disciplineValues && disciplineValues.Designator__c ? disciplineValues.Designator__c : ""}
              onChange={(event) => {
                handleInputChange(event, "Designator__c");
              }}
              onKeyDown={(event) => {
                if (event.key.toLowerCase() !== "tab") {
                  event.stopPropagation();
                }
              }}
            />
            {designatorError &&
              <div style={{ fontSize: "12px", color: "red", paddingTop: "5px", fontWeight: 400 }}>
                <img style={{ marginRight: "5px" }} src={errorRed} alt="error" /> Duplicate designator.
              </div>
            }
            <div style={{ marginBottom: "4px", marginTop: "12px" }}>{dialogData.content3}</div>
            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${nameError && "red"} `
                }
              }}
              id="resolution"
              className="Searchbar-field"
              inputProps={{ maxLength: 80, style: { height: "10px", width: "380px", color: "#505050", borderColor: "red" } }}
              value={disciplineValues && disciplineValues.Name ? disciplineValues.Name : ""}
              onChange={(event) => {
                handleInputChange(event, "Name");
              }}
              onKeyDown={(event) => {
                if (event.key.toLowerCase() !== "tab") {
                  event.stopPropagation();
                }
              }}
            />
            {nameError &&
              <div style={{ fontSize: "12px", color: "red", paddingTop: "5px", fontWeight: 400 }}>
                <img style={{ marginRight: "5px" }} src={errorRed} alt="error" /> Duplicate name.
              </div>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions className="action_button">
          <Button className="custom_button cancel_button" onClick={onCancel}>
            {dialogData.action1}
          </Button>
          {dialogData.action == "edit" &&
            <Button className="custom_button apply_button" disabled={(disciplineValues.Name == selectedRow.Name && disciplineValues.Designator__c == selectedRow.Designator__c) || designatorError || nameError || error} onClick={() => { onAccept(disciplineValues, dialogData.action); }}>
              {dialogData.action2}
            </Button>
          }
          {dialogData.action == "add" &&
            <Button className="custom_button apply_button" disabled={!disciplineValues || !disciplineValues.Name || !disciplineValues.Designator__c || designatorError || nameError || error} onClick={() => { onAccept(disciplineValues, dialogData.action); }}>
              {dialogData.action2}
            </Button>
          }
        </DialogActions>
      </DisciplineDialogStyles>
    </Dialog>
  );
};
export const SetNameSettingDialog = (props) => {
  const { onAccept, onCancel, dialogData, disciplineList, selectedRow } = props;
  const [error, showError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [disciplineValues, setDisciplineValues] = useState({});
  useEffect(() => {
    if (selectedRow) {
      let obj = cloneDeep(selectedRow);
      setDisciplineValues(obj);
    }
  }, [selectedRow]);
  const handleInputChange = (value, field) => {
    let nameValidation = false;
    let newValue;
    newValue = value;
    let tempObj = cloneDeep(disciplineValues);
    tempObj[field] = newValue;
    let values = JSON.parse(JSON.stringify(tempObj))
    setDisciplineValues(values);
    if (tempObj.name) {
      showError(false);
      if (disciplineList?.length > 0) {
        for (let el of disciplineList) {
          var preValue = el.name ? el.name : '';
          if (selectedRow && selectedRow.name) {
            if (selectedRow.value != el.value) {
              if ((tempObj && tempObj.name && preValue.toLowerCase().trim() === tempObj.name.toLowerCase().trim())) {
                nameValidation = true;
                setNameError(true);
              } else {
                if (!nameValidation) {
                  setNameError(false);
                }
              }

            }
          } else {
            console.log(preValue.toLowerCase().trim(), "fffff", tempObj.name.toLowerCase().trim());
            if ((tempObj && tempObj.name && preValue.toLowerCase().trim() === tempObj.name.toLowerCase().trim())) {
              nameValidation = true;

              setNameError(true);
            } else {
              if (!nameValidation) {
                setNameError(false);
              }
            }

          }
        }
      }
    } else {
      showError(true);
    }
  };
  return (
    <Dialog open={true} onClose={onCancel} maxWidth="xs">
      <DisciplineDialogStyles>
        <DialogTitle className="dialog_title">
          {dialogData.title}
          <IconButton aria-label="close" onClick={onCancel} sx={{ position: "absolute", right: 12, }} >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content">
          <DialogContentText style={{ padding: "16px 20px 16px 20px" }}>
            <div style={{ marginBottom: "12px" }}>{dialogData.content1}</div>
            <div style={{ marginBottom: "4px" }}>{dialogData.content2}</div>
            <TextField
              id="resolution"
              className={nameError == true ? "Searchbar-field-red" : "Searchbar-field"}
              inputProps={{ maxLength: 80, style: { height: "10px", width: "380px", color: "#505050", } }}
              value={disciplineValues && disciplineValues.name ? disciplineValues.name : ""}
              onChange={(event) => {
                handleInputChange(event.target.value, "name");
              }}
              onKeyDown={(event) => {
                console.log("nameError ::", nameError);
                if (event.key.toLowerCase() !== "tab") {
                  event.stopPropagation();
                }
              }}
            />
            {nameError &&
              <div style={{ fontSize: "12px", color: "red", paddingTop: "5px", fontWeight: 400 }}>
                <img style={{ marginRight: "5px" }} src={errorRed} alt="error" /> Duplicate name.
              </div>
            }
            <div style={{ marginBottom: "4px", marginTop: "12px" }}>{dialogData.content3}</div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={0} >
                <DesktopDatePicker
                  className={"Searchbar-field"}
                  value={disciplineValues && disciplineValues.date ? disciplineValues.date : ""}
                  inputFormat="MM/dd/yyyy"
                  inputProps={{ maxLength: 80, style: { height: "10px", width: "380px", color: "#505050", } }}
                  onChange={(newValue) => {
                    console.log("newValue :", newValue);
                    handleInputChange(newValue, "date");
                  }}
                  placeholder="Select Date"
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="action_button">
          <Button className="custom_button cancel_button" onClick={onCancel}>
            {dialogData.action1}
          </Button>
          {dialogData.action == "edit" &&
            <Button className="custom_button apply_button" disabled={(disciplineValues.name == selectedRow.name) || nameError || error || !disciplineValues.date} onClick={() => { onAccept(disciplineValues, dialogData.action); }}>
              {dialogData.action2}
            </Button>
          }
          {dialogData.action == "add" &&
            <Button className="custom_button apply_button" disabled={!disciplineValues || !disciplineValues.name || !disciplineValues.date || nameError || error} onClick={() => { onAccept(disciplineValues, dialogData.action); }}>
              {dialogData.action2}
            </Button>
          }
        </DialogActions>
      </DisciplineDialogStyles>
    </Dialog>
  );
};
export const MoveDialog = ({ ...props }) => {
  const { currentFolderName, onAccept, onCancel, dialogData, credential, _getFolderApi } = props;
  const { allFolderDataTree, allFileData, findFolderPath, _getNodeChildren } = props?.sectionHook;
  const [currentFolderTree, setCurrentFolderTree] = useState();
  const [selectedNode, setSelectedNode] = useState([]);
  const [targetToFolder, setSetTargetToFolder] = useState();
  const [targetInItems, setTargetInItems] = useState(false);
  const [openAddFolder, setOpenAddFolder] = useState(false);
  const [operationItems, setOperationItems] = useState(dialogData.items);
  const [duplicateError, setDuplicateError] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [tooltipMsg, setToolMsg] = useState();
  const [addFolder, setAddFolder] = useState();
  const [newFolderName, setNewFolderName] = React.useState(null);
  const [error, showError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [newFolderId, setNewFolderId] = React.useState(false);

  useEffect(() => {
    //console.log('operationItems :: ',JSON.stringify(operationItems));
    onSelectFolder({ Id: currentFolderName?.id });
  }, []);
  useEffect(() => {
    if (loading && newFolderId) {
      const curFolderTree = _getNodeChildren(allFolderDataTree, newFolderId);
      onSelection(curFolderTree.value);
    }
  }, [allFolderDataTree]);
  useEffect(() => {
    let _targetInItems = false;
    let hierarchyDispute = operationItems?.filter((item) => item.id === targetToFolder?.Id);
    if (hierarchyDispute?.length > 0) {
      _targetInItems = true;
    }
    setTargetInItems(_targetInItems);
  }, [targetToFolder]);
  const onSelectFolder = async (curFolder) => {
    const curFolderTree = _getNodeChildren(allFolderDataTree, curFolder.Id);
    setCurrentFolderTree(curFolderTree);
    const nodePath = findFolderPath(allFolderDataTree, curFolder.Id, [], true);
    setSelectedNode(nodePath);
    setLoading(false);

  };
  const onSelection = (folder) => {
    console.log("folder ::", folder);
    setToolMsg(null);
    onSelectFolder(folder);
    setSetTargetToFolder(folder);
  };
  const onAccept_ = (_targetToFolder = targetToFolder) => {
    setToolMsg(null);
    //console.log('_targetToFolder :: ',_targetToFolder);
    let uniqObject = { targetFolder: _targetToFolder };
    let currentTargetFiles = allFileData?.filter((file) => file.folderId === _targetToFolder?.Id);
    let currentTargetFolders = [];
    const _currentFolderTree = _getNodeChildren(allFolderDataTree, _targetToFolder?.Id);
    if (_currentFolderTree?.children?.length > 0) {
      for (let fol_ of _currentFolderTree?.children) {
        const fol = fol_?.value;
        currentTargetFolders.push(fol);
      }
    }
    let uniqFolders = operationItems?.filter((item) => {
      let pass = false;
      if (item?.type === 'folder') {
        let fileInd = currentTargetFolders?.findIndex((fol) => fol.Name === item.name);
        if (!(fileInd > -1)) {
          if (!uniqObject?.folders) {
            uniqObject.folders = [];
            pass = true;
          }
          uniqObject.folders.push(item);
        } else {
          if (!uniqObject?.existing_folders) {
            uniqObject.existing_folders = [];
          }
          uniqObject.existing_folders.push(item);
        }
      }
      return pass;
    });
    let uniqFiles = operationItems?.filter((item) => {
      let pass = false;
      if (item?.type === 'file') {
        let fileInd = currentTargetFiles?.findIndex((file) => file.name === item.name);
        if (!(fileInd > -1)) {
          if (!uniqObject?.files) {
            uniqObject.files = [];
            pass = true;
          }
          uniqObject.files.push(item);
        } else {
          if (!uniqObject?.existing_files) {
            uniqObject.existing_files = [];
          }
          uniqObject.existing_files.push(item);
        }
      }
      return pass;
    });

    if (uniqObject?.existing_folders?.length > 0 || uniqObject?.existing_files?.length > 0) {
      let toolMessag = `${dialogData.label2} ${dialogData.label2 === 'Items' ? 'are' : 'is'} already in this folder.`
      setToolMsg(toolMessag);
      return;
    }
    onAccept(uniqObject, setSpinner);
  };
  const handleInputChange = (event) => {
    // Define a regular expression to exclude the characters you want to disallow
    const invalidCharacters = /["/:|<>*@?\\]/g;

    // Replace the invalid characters with an empty string
    const newValue = event.target.value.replace(invalidCharacters, '');
    setNewFolderName(newValue);
  };
  const handleAddNewFolder = (event) => {
    const _currentFolderTree = _getNodeChildren(allFolderDataTree, targetToFolder?.Id ? targetToFolder?.Id : currentFolderName?.id);
    console.log("_currentFolderTree ::", _currentFolderTree);
    if (_currentFolderTree.children?.length > 0) {
      for (let el of _currentFolderTree.children) {
        var preValue = el.value.Name ? el.value.Name : '';
        if ((preValue.toLowerCase().trim() === newFolderName.toLowerCase().trim())) {
          showError(true);
          return;
        }
      }
    }
    saveFolder(newFolderName.trim());
  };
  async function saveFolder(folderName) {
    setAddFolder(false)
    setLoading(true);
    const reqBody = { Name: folderName };
    await axios.post(`${fetchUrl}/folder/${targetToFolder?.Id ? targetToFolder?.Id : currentFolderName?.id}/subfolder/add/?accessToken=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, reqBody)
      .then(async (res) => {
        if (res?.status === 200) {
          setNewFolderName("");
          //console.log('res :: '+JSON.stringify(res));
          await _getFolderApi({ id: res.data?.Parent_Folder__c });
          setNewFolderId(res.data?.Id);
        } else {
          setLoading(false);
        }
      }).catch(err => {
        setLoading(false);
        console.error(err);
      });
  }
  const handleCloseAddFolder = (event) => {
    setNewFolderName("");
    showError(false);
    setAddFolder(false);
  }

  return (
    <Dialog open={true} onClose={onCancel} fullWidth>
      <DisciplineDialogStyles>
        <DialogTitle className="dialog_title">
          {dialogData.title}
          <IconButton aria-label="close" onClick={onCancel} sx={{ position: "absolute", right: 12, }} >
            <img src={CloseIcon} alt="icons" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content">

          <DialogContentText style={{ width: '100%', position: "relative" }}>
            {showSpinner && <Spinner />}
            <div style={{ padding: '16px 20px' }}>
              <BreadcrubFolderView
                selectedNode={selectedNode}
                onSelection={onSelection}
              />
            </div>
            <ul className="children-list">
              {loading &&
                <div style={{ paddingLeft: "280px", paddingTop: "100px" }}>
                  <div style={{ paddingLeft: "20px" }}>
                    <img style={{ animation: 'rotate360 0.5s linear infinite' }} src="/icon.svg" alt="loading icon" />
                  </div>
                  <div>
                    Loading...
                  </div>
                </div>
              }
              {currentFolderTree?.children?.length > 0 && !loading &&
                <>
                  {currentFolderTree?.children.map((folder, index) => {
                    const isCurrenFolder = (currentFolderName?.id === folder?.value?.Id);
                    const disable = (operationItems?.filter((item) => item.id === folder?.value?.Id).length > 0);
                    const sty = disable ? { filter: 'opacity(0.5)' } : {};
                    return <li key={index} onClick={() => { disable ? console.log('cant move') : onSelection(folder?.value); }} >
                      <Tooltip
                        className="version-action-tooltip"
                        title={(disable ? `Can’t ${dialogData.operation} folder into itself or its sub-folder` : undefined)}
                        placement="bottom"
                        arrow
                      >
                        <div className="child-item">
                          <img src={folderIcon} alt="folicon" style={sty}></img>
                          <span style={sty}>{folder?.value.Name}</span>
                          {(!isCurrenFolder && !disable) &&
                            <span className="move" onClick={(e) => { e.stopPropagation(); onAccept_({ Id: folder?.value?.Id, Name: folder?.value.Name }) }}>
                              {dialogData.operation == "copy" ? "Copy" : "Move"} <img src={'/right.svg'} alt="move"></img>
                            </span>
                          }
                        </div>
                      </Tooltip>
                    </li>
                  })}
                </>
              }
              {!currentFolderTree?.children?.length > 0 && !loading && <>
                <div className="empty_folder">
                  <div>
                    <img src="/folder_open.svg" alt="folicon" height={40} width={40}></img>
                  </div>
                  <div>
                    This Folder is Empty.
                  </div>
                </div>
              </>}
            </ul>
            <div className='selection-layout'>
              <div>
                Current location : <b>{currentFolderName?.name}</b>
              </div>
              <div>
                {dialogData.operation == "copy" ? "Copy" : "Move"} location : <b>{targetToFolder?.Name}</b>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        {openAddFolder === false &&
          <DialogActions className="action_button">
            <Button className="addFolder" style={{ marginRight: "auto" }} onClick={() => setAddFolder(true)}>
              Add Folder
            </Button>
            <div style={{ gap: "10px", display: "flex" }}>
              <Button className="custom_button cancel_button" onClick={onCancel}>
                {dialogData.action1}
              </Button>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                  className: "version-action-tooltip"
                }}
                open={tooltipMsg ? true : false}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={tooltipMsg}
                placement="bottom-end"
              >
                <span> </span>
              </Tooltip>
              <Button className="custom_button apply_button" disabled={!targetToFolder || currentFolderName?.id === targetToFolder?.Id || targetInItems}
                onClick={() => onAccept_(targetToFolder)}>
                {dialogData.action2}
              </Button>
            </div>
          </DialogActions>
        }
        {addFolder &&
          <Dialog open={addFolder} fullWidth style={{ marginTop: '290px' }}>
              <DialogTitle className="dialog_title">
                New Folder
                <IconButton style={{ borderRadius: "0px" }} aria-label="close" onClick={handleCloseAddFolder} sx={{ position: "absolute", right: 12, }} >
                  <img src={CloseIcon} alt="icons" />
                </IconButton>
              </DialogTitle>
              <DialogContent className="dialog_content">
                <DialogContentText style={{ padding: "16px 20px 16px 20px" }}>
                  <div style={{ paddingBottom: "4px", color: "#505050" }}>
                    Folder Name
                  </div>
                  <TextField
                    id="resolution"
                    className="Searchbar-field"
                    inputProps={{ maxLength: 255, style: { height: "10px", width: "530px", color: "#505050", borderColor: "red" } }}
                    value={newFolderName}
                    onChange={handleInputChange}
                    onKeyDown={(event) => {
                      if (event.key.toLowerCase() != "tab") {
                        event.stopPropagation();
                      }
                    }}
                  />
                  {error &&
                    <div style={{ fontSize: "12px", color: "red", paddingTop: "5px", fontWeight: 400 }}>
                      <img style={{ marginRight: "5px" }} src={errorRed} alt="error" /> Folder name alreay exists. Please choose a new one.
                    </div>
                  }
                </DialogContentText>
              </DialogContent>
              <DialogActions className="action_button">
                <Button className="custom_button cancel_button" onClick={handleCloseAddFolder}>
                  Cancel
                </Button>
                <Button disabled={!newFolderName} className="custom_button apply_button" onClick={handleAddNewFolder}>
                  Add New Folder
                </Button>
              </DialogActions>
          </Dialog>
        }
      </DisciplineDialogStyles>
    </Dialog>
  );
};