import React, { useState, useEffect, useRef, useReducer } from "react";
import { cloneDeep } from "lodash";
import axios from "axios";
import { fetchUrl } from "Urls";
import {
  ButtonGroup,
  Button,
  TextField,
  IconButton,
  Grid,
  Divider,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  RadioGroup, Radio,FormControlLabel
} from "@mui/material";
import { Close, Delete } from "@mui/icons-material";
import Spinner from "components/spinner";
import moment from "moment"

const defaultTemplate = { Name : '' , 
                          Workflow_Step__c : '1-Step Review' ,
                          Description__c : ''} ;
const defaultStep = { Name : '' ,  Days_Allowed__c : 3 ,
                      Reviewer_Type__c : '',
                      Participant_Type__c : '', Approval_Type__c : '',
                      Approval_By__c : '',
                      teams : [] , teamRecords : [] , userRoles : []} ;

function CreateWorkflowTemplate({ workflowId, credential, handleClose, ...props }) {
  const [showWarning, enableWarningModal] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [sectionCurrentListWithTeam, dispatch] = useReducer(reducerF, []);
  const [sfObj, setsfObj] = React.useState(null);
  const [name, setName] = useState(null);
  const [desc, setDesc] = useState(null);
  const [templeteLocked , setTemplateLocked] = useState(false);
  var __sfObj = useRef(null);

  useEffect(() => {
    if(workflowId){
      //console.log('workflowId',workflowId);
      (async () => {
        setSpinner(true);
        await axios
          .get( `${fetchUrl}/workflowDetail/${workflowId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
          .then((res) => {
            //console.log("count:::: " + JSON.stringify(res));
            if (res.status === 200) {
              //{formObj : cObj , docDetail : docReturn}
              if (res.data?.workflowObj) {
                setTemplateLocked(res.data?.reviewCreated)
                setVariables(res.data.workflowObj);
                if (res.data.steps) {
                  dispatch({ type: "HANDLE_SECTION_REPLACE", sectionList: res.data.steps});
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        setSpinner(false);
      })();
    }else{
      if(props?.stepSelected){
        handleNumberOfStepSelection(props?.stepSelected);
      }
    }
  }, []);
  function reducerF(state, action) {
    //console.log('action.type :: '+action.type);
    switch (action.type) {
      case "HANDLE_SECTION_REPLACE":
        //console.log("replace called");
        //replace current state with new state (action.sectionList)
        return action.sectionList;
      case "HANDLE_CHANGE_STEP_FIELD":
        const tmpSec_ans = cloneDeep(state);
        //console.log('action.value :: ' + action.value);
        //console.log('ans[action.fieldKey] :: ' + tmpSec_ans[action.stepIndex][action.fieldKey]);
        let jsB = action.value;
        if ( (!action.value && action.value != 0) || action.value == null || action.value == "" ) {
          jsB = null;
        }
        if ( tmpSec_ans[action.stepIndex][action.fieldKey] != jsB && tmpSec_ans[action.stepIndex][action.fieldKey] != action.value ) {
          //console.log('go');
          tmpSec_ans[action.stepIndex][action.fieldKey] = jsB;
          return tmpSec_ans;
        }
        return tmpSec_ans;
      default:
      return state;
    }
  }
  const getStepCount = (step)=>{
    return Number(step.substring(0, step.indexOf("-Step Review")));
  }
  const onClickSave = async ( ) => {
    //console.log(" sfObj :: ",JSON.stringify(sfObj));
    //console.log(" onClickSave_ __sfObj  :: ",JSON.stringify(sectionCurrentListWithTeam));
    let toSave = sfObj; let process = true;
    //console.log("onClickSaveChecklist called process :: " + process);
    if (process) { //process
      enableWarningModal(false);
      setSpinner(true);
      const sendObj = { workflowObj: toSave, steps: sectionCurrentListWithTeam };
      if (true) {
        const formData = new FormData();
        //let issueObj = sfIssueObj;
        formData.append("workflowData", JSON.stringify(sendObj));
        await axios
          .post( `${fetchUrl}/SaveWorkflowWithStep?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData )
          .then((res) => {
            //console.log("count:::: " + JSON.stringify(res));
            if (res.status === 200) {
              toSave.Id = res.data.templateId;
              setVariables(toSave);
              handleClose(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setSpinner(false);
    }
  };
  const handleNumberOfStepSelection = (step)=>{
    let loopCount = getStepCount(step);
    let steps = [];
    for(let s = 1 ; s <= loopCount ; s++){
      let ste = JSON.parse(JSON.stringify(defaultStep)) ;
      if(props?.stepPickWrap){
        ste.Reviewer_Type__c = props?.stepPickWrap?.Reviewer_Type__c?.default;
        ste.Approval_Type__c = props?.stepPickWrap?.Approval_Type__c?.default;
        ste.Participant_Type__c = props?.stepPickWrap?.Participant_Type__c?.default;
        ste.Approval_By__c = props?.stepPickWrap?.Approval_By__c?.default;
      }
      ste.Step_Number__c = s ;
      ste.Name = 'Initial '+ste.Step_Number__c ;
      steps.push(ste);
    }
    let defTemp = JSON.parse(JSON.stringify(defaultTemplate)) ;
    setVariables({...defTemp , Workflow_Step__c : step });
    dispatch({ type: "HANDLE_SECTION_REPLACE", sectionList: steps });
  }
  function setVariables(_sfObj) {
    // need to set it to variables
    setName(_sfObj.Name);
    setDesc(_sfObj.Description__c);
    __sfObj.current = _sfObj;
    //requiredMissing.current = !checkReadyToSave(__sfObj.current);
    setsfObj(_sfObj);
    // setsfObj((prv) => { return {...prv , ..._sfObj} } ); // dosent work
  }

  return (
    <>
      <div style={{ width: "100%", position: "relative"}}>
        {showSpinner && <Spinner />}
        <Grid container style={{ display: "flex" }} justifyContent="space-between" >
          <Grid item xs={6} style={{ paddingBottom: "0.5rem", paddingTop: "0.5rem", paddingLeft: "1rem" , background: "#fefefe"}}>
            <div style={{ fontSize: "24px", fontWeight: "500"}}>
              Create an approval template
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ textAlign: "right", paddingBottom: "0.5rem", paddingTop: "0.5rem", paddingRight: "1rem", background: "#fefefe" }} >
              <ButtonGroup variant="outlined" className="table-btn-group">
                <Button component="span" onClick={handleClose} >
                  Cancel
                </Button>
                <Button variant="contained" component="span" onClick={() => { onClickSave() ; return;enableWarningModal(true); }} disabled={templeteLocked} >
                  Save
                </Button>
              </ButtonGroup>
            </div>
          </Grid>
          {sfObj && 
            <Grid item xs={7} style={{ margin: "auto" ,  height: "80vh" , overflow : 'auto'}}>
              <Grid item>
                <div style={{ display: 'flex' , padding : '1rem'}}>
                  <div style={{width : '20%'}}>
                    General Information
                  </div>
                  <div style={{ width: '80%' }}>
                    <div style={{ width: '90%' }}>
                      <div style={{ paddingBottom: "10px" }}>
                        <TextField
                          id="title"
                          variant="outlined"
                          label="Name"
                          required
                          value={name}
                          fullWidth
                          onChange={(e) => {
                            let fff = sfObj;
                            fff.Name = e?.target?.value;
                            setVariables(fff);
                          }}
                          onKeyDown={(event) => {
                            if (event.key.toLowerCase() != "tab") {
                              event.stopPropagation();
                            }
                          }}
                          disabled={templeteLocked}
                        />
                      </div>
                      <div style={{ paddingBottom: "10px" }}>
                        <TextField
                          className="common_class"
                          id="Description"
                          variant="outlined"
                          label="Description"
                          value={desc}
                          multiline
                          rows={1}
                          maxRows={3}
                          fullWidth
                          onChange={(e) => {
                            let fff = sfObj;
                            fff.Description__c = e?.target?.value;
                            setVariables(fff);
                          }}
                          onKeyDown={(event) => {
                            if (event.key.toLowerCase() != "tab") {
                              event.stopPropagation();
                            }
                          }}
                          disabled={templeteLocked}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Divider/>
              </Grid>
              {sectionCurrentListWithTeam && sectionCurrentListWithTeam?.length > 0 &&
                <>
                  <div style={{ display: 'flex' , padding : '1rem'}}>
                    <div style={{width : '20%'}}>
                      Steps
                    </div>
                    <div style={{ width: '80%' }}>
                      <div style={{ width: '90%' }}>
                        <div style={{ marginBottom: "10px" , background: "#fefefe" , height:'100px'}}>
                        </div>
                      </div>
                      {sectionCurrentListWithTeam.map((stepObj, sIndex) => (
                        <Grid item>
                          <StepRender dispatchObj={dispatch} credential={props?.credential} stepIndex={sIndex} stepObj={stepObj}
                            userList={props?.userList}
                            stepPickWrap={props?.stepPickWrap} 
                            projectTeamRoles={props?.projectTeamRoles} 
                            templeteLocked={templeteLocked}/>
                          <Divider/>
                        </Grid>
                      ))}
                    </div>
                  </div>
                </>
              }
            </Grid>
          }
        </Grid>
      </div>
      {showWarning && (
        <Dialog fullWidth open={showWarning} scroll="paper">
          <DialogTitle>
            <Typography>
                Complete Form
            </Typography>
            <IconButton aria-label="close" className="dialog-header-close" onClick={() => { enableWarningModal(false); }} >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography>
                You will not be able to make any changes after completing this form
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                enableWarningModal(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={() => { onClickSave() }} >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
export function StepRender(props){
  var rolesUserValues = ['Assistant Foreman',
    'Assistant Project Manager',
    'Document Control',
    'Engineers',
    'Lead Foreman',
    'Lead Mechanic',
    'Project Manager',
    'Sub-Foreman',
    'Project Executive'];
  const { stepIndex ,stepObj ,dispatchObj , stepPickWrap , userList , templeteLocked ,projectTeamRoles} = props;
  const [reviewerTypeOptions, setReviewerTypeOp] = useState(stepPickWrap?.Reviewer_Type__c?.valueList) ;
  const [approvalTypeOptions, setApprovalTypeOp] = useState(stepPickWrap?.Approval_Type__c?.valueList) ;
  const [participantTypeOptions, setParticipantTypeOp] = useState(stepPickWrap?.Participant_Type__c?.valueList) ;
  const [approvalByOptions, setApprovalByOptions] = useState(stepPickWrap?.Approval_By__c?.valueList) ;
  const daysAllowed = [1, 2 ,3 ,4,5,6,7,8,9,10] ;
  const [name, setName] = React.useState(null);
  const [sfObj, setsfObj] = React.useState(null);
  const [team, setTeam] = React.useState(null);
  const [teamList, setTeamList] = React.useState([]);
  const [teamRecords, setTeamRecords] = React.useState([]);
  const [userRoles, setUserRoles] = React.useState([]);
  var __sfObj = useRef(null);
  useEffect(() => {
    setVariables(stepObj);
    //console.log('fasdf');
  }, []);
  function setVariables(_sfObj) {
    // need to set it to variables
    setName(_sfObj.Name);
    let _teamRecords = [];
    let _teamList = [];
    let _team ={};
    if(_sfObj?.teams && _sfObj?.teams.length > 0 ){
      _teamList = _sfObj?.teams;
      _team = JSON.parse(JSON.stringify(_teamList[0]));
    }
    if(_sfObj?.teamRecords && _sfObj?.teamRecords.length > 0 ){
      _teamRecords = _sfObj?.teamRecords;
    }
    let _userRoles = [];
    if(_sfObj?.User_Role__c && _sfObj?.User_Role__c != ''){
      _userRoles = _sfObj?.User_Role__c.split(';');
    }
    setUserRoles(_userRoles); 
    //console.log('_team' ,JSON.stringify(_team));
    //console.log('_teamList' ,JSON.stringify(_teamList));
    setTeam(_team);
    setTeamList(_teamList);
    setTeamRecords(_teamRecords);
    __sfObj.current = _sfObj;
    setsfObj(_sfObj);
  }
  function handleTeamChange(listTeam) {
    // need to set it to variables
    handleChange({ target: { name: "teams", value: listTeam } });
  }
  const handleChange = e => {
    if(templeteLocked){
      return;
    }
    let fff = sfObj;
    let fieldKey = e.target.name;
    let value = e.target.value;
    fff[fieldKey] = value;
    setVariables(fff);
    dispatchObj({
      type: 'HANDLE_CHANGE_STEP_FIELD',
      stepIndex : stepIndex,
      fieldKey: fieldKey,
      value: value
    });
    if(fieldKey == 'Reviewer_Type__c'){
      if(value != 'Multiple Reviewer'){
        handleTeamChange(team ? [team] : []);
      }
    }
  };
  return ( <>
    {sfObj &&
      <div style={{ display: 'flex', paddingLeft: '0px', padding: '1rem', }}>
        <div style={{ width: '25%', paddingRight: '3%' }}>
          <TextField id="title" variant="outlined" label="Name" value={name}
            fullWidth required
            onChange={(e) => {
              let fff = __sfObj.current;
              fff.Name = e?.target?.value;
              setVariables(fff);
            }}
            onBlur={(e) => {
              handleChange({ target: { name: 'Name', value: e?.target?.value } });
            }}
            onKeyDown={(event) => {
              if (event.key.toLowerCase() != "tab") {
                event.stopPropagation();
              }
            }}
            disabled={templeteLocked}
          />
          <div style={{ paddingTop: "10px" }}>
            <Autocomplete
              value={sfObj?.Participant_Type__c}
              onChange={(event, newValue) => {
                handleChange({ target: { name: "Participant_Type__c", value: newValue } });
              }}
              options={participantTypeOptions}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField fullWidth id="reviewer" {...params} label="Participant Type" />
              )}
              disabled={templeteLocked}
            />
          </div>
        </div>
        <div style={{ width: '58%', paddingRight: '3%' }}>
          <div style={{ display: 'flex'}}>
            <div style={{ paddingBottom: "10px", width: sfObj?.Approval_By__c == 'User' ? '48%' : '100%', marginRight: '2%' }}>
              <Autocomplete
                value={sfObj?.Approval_By__c}
                onChange={(event, newValue) => {
                  handleChange({ target: { name: "Approval_By__c", value: newValue } });
                }}
                options={approvalByOptions}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField fullWidth id="reviewer" {...params} label="Approval By" />
                )}
                disabled={templeteLocked}
              />
            </div>
            { sfObj?.Approval_By__c == 'User' &&
              <div style={{ paddingBottom: "10px" ,width: '50%'}}>
                <Autocomplete
                  value={sfObj?.Reviewer_Type__c}
                  onChange={(event, newValue) => {
                    handleChange({ target: { name: "Reviewer_Type__c", value: newValue } });
                  }}
                  options={reviewerTypeOptions}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField fullWidth id="reviewer" {...params} label="Reviewer Type" />
                  )}
                  disabled={templeteLocked}
                />
              </div>
            }
          </div>
          { sfObj?.Approval_By__c == 'User Role' && <>
            <div style={{ paddingBottom: "10px" }}>
              <Autocomplete
                multiple
                value={userRoles}
                options={projectTeamRoles?.length > 0 ? projectTeamRoles : rolesUserValues}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField fullWidth id="autocompleteUr" {...params} label="User Role(s)" />
                )}
                onChange={(event, newValue) => {
                  let uList = [...newValue];
                  let uroles;
                  if(uList && uList.length > 0){
                    uroles = uList.join(';');
                  }
                  /*let fff = __sfObj.current;
                  fff.User_Role__c = uroles;
                  setVariables(fff);*/
                  handleChange({ target: { name: 'User_Role__c', value: uroles } });
                }}
                disabled={templeteLocked}
              />
            </div>
          </>}
          { sfObj?.Approval_By__c == 'User' && <>
            {sfObj?.Reviewer_Type__c != 'Multiple Reviewer' && <>
              <div style={{ paddingBottom: "10px" }}>
                <Autocomplete
                  value={team}
                  options={userList}
                  getOptionLabel={(option) => option.label || ""}
                  renderInput={(params) => (
                    <TextField fullWidth id="autocomplete0" {...params} label="Reviewer" />
                  )}
                  onChange={(event, newValue) => {
                    handleTeamChange(newValue ? [newValue] : []);
                  }}
                  disabled={templeteLocked}
                />
              </div>
            </>}
            {sfObj?.Reviewer_Type__c == 'Multiple Reviewer' && <>
              <div style={{ paddingBottom: "10px" }}>
                <Autocomplete
                  multiple
                  value={teamList}
                  options={userList}
                  getOptionLabel={(option) => option.label || ""}
                  renderInput={(params) => (
                    <TextField fullWidth id="autocomplete" {...params} label="Reviewer(s)" />
                  )}
                  onChange={(event, newValue) => {
                    handleTeamChange([...newValue]);
                  }}
                  disabled={templeteLocked}
                />
              </div>
              <RadioGroup aria-labelledby="preconfigured-answer-responses" name="Approval_Type__c"
                value={sfObj?.Approval_Type__c} onChange={handleChange} row={false} >
                {approvalTypeOptions.map((r) => (
                  <FormControlLabel key={r} value={r} control={<Radio />} label={r} disabled={templeteLocked}/>
                ))}
              </RadioGroup>
            </>}
          </>}
        </div>
        <div style={{ width: '12%' }}>
          <Autocomplete
            value={sfObj?.Days_Allowed__c}
            onChange={(event, newValue) => { handleChange({ target: { name: "Days_Allowed__c", value: newValue } }); }}
            getOptionLabel={(option) => '' + option}
            options={daysAllowed}
            renderInput={(params) => (
              <TextField fullWidth id="autocomplete" {...params} label="Days Allowed" />
            )}
            disabled={templeteLocked}
          />
        </div>
      </div>
    }
  </>);
}
export default CreateWorkflowTemplate;
