import React, { useState,useEffect, useRef , useReducer } from "react";
import { Grid, Typography, Button, TextField, FormControl, InputLabel, Select, MenuItem,IconButton,
  Dialog, DialogTitle, DialogContent , DialogActions } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { cloneDeep } from 'lodash';
import axios from "axios";
import { fetchUrl } from "Urls";
import QuestionCard from './QuestionCard';
import { deleteRecord } from "api";
import Spinner from "components/spinner";


// data object
const sectionQuetion = {
  /** Id, Name , Response_Type__c , Numbering_Sequence__c , Description__c ,Form_Template_Section__c ,Response_Options__c, Question__c */
  Numbering_Sequence__c: 1,
  Question__c: 'Placeholder Question',
  Description__c: 'Question Description',
  Response_Options__c: JSON.stringify([{ label: 'Yes', value: 'Yes', key: 'Yes'},
                                       { label: 'No', value: 'No', key: 'No'},
                                       { label: 'NA', value: 'NA', key: 'NA'}]),
  responseOptions: [{ label: 'Yes', value: 'Yes', key: 'Yes'},
                    { label: 'No', value: 'No', key: 'No'},
                    { label: 'NA', value: 'NA', key: 'NA'}],
  Response_Type__c: 'Preconfigured',
  Optional__c: false,
};
const sectionObject = {
  /**Id,Name,Description__c,Form_Template__c,Numbering_Sequence__c,items */
  Numbering_Sequence__c: 1,
  Name: 'Placeholder Title',
  Description__c: 'Placeholder Description',
  items: [sectionQuetion],
};

const sectionObjectInitialState = [{
  ...sectionObject
}];


const Index = ({ formTemplateId ,credential ,handleClose ,...props }) => {

  const [showSpinner, setSpinner] = useState(false);

  const [typeOptions, setTypeOptions] = useState(props.templateTypeOptions);
  const [type, setType] = useState(props.defaultType);
  const [responseOptions, setResponseOptions] = useState(props.itemResponseTypeOptions);

  //const reducerr = reducer;
  const [dispatchjDetail, setDispatchDetail] = useState(null);
  const [sectionCurrentList, dispatch] = useReducer(reducerF, []);

  const [sfObj, setsfObj] = useState(defaultObj());
  const [title, setTitle] = useState('');
  const [requiredMissing, setRequiredMissing] = React.useState(true);


  var __sfObj = useRef(null) ;
  var checklistServerId = useRef(null) ;
  

  useEffect(() => {
    if(formTemplateId){
      (async () => {
        setSpinner(true);
        await axios.get(`${fetchUrl}/getFormTemplate/${formTemplateId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
        .then((res) => {
          //console.log("count:::: " + JSON.stringify(res));
          if (res.status === 200) {
            //{formObj : cObj , docDetail : docReturn}
            if(res.data?.formObj){
              checklistServerId.current = formTemplateId;
              setVariables(res.data.formObj , res.data.docDetail );
              return;
            }
          }
        }).catch((err) => {
          console.log(err);
        });
        setSpinner(false);
      })();
    }
  },[]);
  function reducerF(state, action) {
    //console.log('action.type :: '+action.type);
    switch (action.type) {
      case 'ADD_SECTION':
        const nextS = cloneDeep(sectionObject);
        nextS.Numbering_Sequence__c = state.length + 1;
        const tmpList = cloneDeep(state);
        if(!tmpList){
          tmpList = [] ;
        }
        tmpList.push(nextS);
        return tmpList;
      case 'REM_SECTION_WARNING':
        setDispatchDetail({ for : 'SEC' , ...action , type : 'REM_SECTION' });
        return cloneDeep(state);
      case 'REM_SECTION':
        const tmpSec_r = cloneDeep(state);
        if(tmpSec_r){
          let id_ ;
          if(tmpSec_r[action.sectionIndex]?.Id){
            id_ = tmpSec_r[action.sectionIndex].Id ;
          }
          if(id_){
            onRemoveSection(id_);
          }
          tmpSec_r.splice(action.sectionIndex, 1);
          if( tmpSec_r?.length > 0 && action.sectionIndex < tmpSec_r?.length){
            for(let i = action.sectionIndex ; i < tmpSec_r?.length ; i++ ){
              tmpSec_r[i].Numbering_Sequence__c = i + 1 ;
            }
          }
          setDispatchDetail(null);
        }
        return tmpSec_r;
      case 'MOVE_SECTION':
        const tmpSec_ms = cloneDeep(state);
        let f = tmpSec_ms.splice(action.from, 1)[0];
        tmpSec_ms.splice(action.to, 0, f);
        for(let i = action.from < action.to ? action.from : action.to ; i < tmpSec_ms.length ; i++ ){
          tmpSec_ms[i].Numbering_Sequence__c = i + 1 ;
        }
        return tmpSec_ms;
      case 'SECTION_VAL_CHANGE':
        const tmpSec_scv = cloneDeep(state);
        tmpSec_scv[action.sectionIndex][action.fieldKey] = action.value;
        return tmpSec_scv;
      case 'HANDLE_SECTION_REPLACE':
        //replace current state with new state (action.sectionList)
        return action.sectionList;
      case 'ADD_QUESTION':
        const tmpState = cloneDeep(state);
        const selectedSectionItem = tmpState[action.sectionIndex];
        const nextQ_aq = cloneDeep(sectionQuetion);
        nextQ_aq.Numbering_Sequence__c = selectedSectionItem.items.length + 1 ;
        selectedSectionItem.items.push(nextQ_aq);
        return tmpState;
      case 'HANDLE_CHANGE_QUESTION_FIELD': 
        const tmpSec = cloneDeep(state);
        const qArr = tmpSec[action.sectionIndex].items;
        qArr[action.questionIndex][action.fieldKey] = action.value;
        if(action.fieldKey == 'responseOptions' ){
          let jsB ;
          if(action.value && action.value.length > 0 ){
            jsB = JSON.stringify(action.value);
          }
          qArr[action.questionIndex]['Response_Options__c'] = jsB;
        }
        return tmpSec;
      case 'REM_QUESTION_WARNING':
        setDispatchDetail({ for : 'QUE' , ...action , type : 'REM_QUESTION'});
        return cloneDeep(state);
      case 'REM_QUESTION': 
        const tmpSec_rq = cloneDeep(state);
        if(tmpSec_rq[action.sectionIndex].items){
          let id_ ;
          if(tmpSec_rq[action.sectionIndex].items[action.questionIndex]?.Id){
            id_ = tmpSec_rq[action.sectionIndex].items[action.questionIndex].Id ;
          }
          if(id_){
            onRemoveQuestion(id_);
          }
          tmpSec_rq[action.sectionIndex].items.splice(action.questionIndex, 1);
          if(tmpSec_rq[action.sectionIndex].items?.length > 0 && action.questionIndex < tmpSec_rq[action.sectionIndex].items?.length ){
            for(let i = action.questionIndex ; i < tmpSec_rq[action.sectionIndex].items?.length ; i++ ){
              tmpSec_rq[action.sectionIndex].items[i].Numbering_Sequence__c = i + 1 ;
            }
          }
          setDispatchDetail(null);
        }
        return tmpSec_rq; 
      case 'MOVE_QUESTION': 
        const tmpSec_mq = cloneDeep(state);
        if(tmpSec_mq[action.sectionIndex].items){
          //console.log('action.sectionIndex '+action.sectionIndex);
          //console.log('tmpSec_mq[action.sectionIndex].items?. '+tmpSec_mq[action.sectionIndex].items?.length);
          let f = tmpSec_mq[action.sectionIndex].items.splice(action.from, 1)[0];
          // insert stored item into position `to`
          tmpSec_mq[action.sectionIndex].items.splice(action.to, 0, f);
          //console.log('tmpSec_mq[action.sectionIndex].items?.length '+tmpSec_mq[action.sectionIndex].items?.length);
          for(let i = action.from < action.to ? action.from : action.to ; i < tmpSec_mq[action.sectionIndex].items?.length ; i++ ){
            tmpSec_mq[action.sectionIndex].items[i].Numbering_Sequence__c = i + 1 ;
            //console.log('i '+i);
          }
        }
        return tmpSec_mq;
      default:
        return state;
    }
  }
  function setVariables(_sfObj , _sectionList = sectionCurrentList) {
    //setType({ label: _sfObj?.Template_Category__c, value: _sfObj?.Template_Category__c });
    setType( _sfObj?.Template_Category__c);
    setRequiredMissing(!checkReadyToSave(_sfObj));
    //console.log('_sfObj.Name 0 :: ' + _sfObj.Name);
    __sfObj.current = _sfObj;
    setsfObj(_sfObj);
    //console.log('setVariables sectionCurrentList :: '+JSON.stringify(_sectionList));
    dispatch({type: 'HANDLE_SECTION_REPLACE' , sectionList : _sectionList});
  }
  async function handleClickSave() {
    //console.log('sfObj :: '+JSON.stringify(sfObj));
    //console.log('_sfObj :: '+JSON.stringify(__sfObj.current));
    //console.log('sectionCurrentList :: '+JSON.stringify(sectionCurrentList));
    setSpinner(true);
    const sendObj = {checkObj : sfObj , sections : sectionCurrentList};
    if(true){
      const formData = new FormData();
      //let issueObj = sfIssueObj;
      formData.append("checklistData", JSON.stringify(sendObj));
      await axios.post(`${fetchUrl}/SaveCheckListTemplate?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,formData)
      .then((res) => {
        //console.log("count:::: " + JSON.stringify(res));
        if(res.status === 200){
          handleClose(true);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
    setSpinner(false);
  }
  async function onRemoveSection( sectionId ) {
    let reValue = false;
    await axios.post(`${fetchUrl}/removeSection/${sectionId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
    .then((res) => {
      //console.log("count:::: " + JSON.stringify(res));
      if(res.status === 200){
        reValue = true;
      }
    }).catch((err) => {
      console.log(err);
    });
    return reValue;
  }
  async function onRemoveQuestion( questionId ) {
    let reValue = false;
    await axios.post(`${fetchUrl}/removeSectionItem/${questionId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
    .then((res) => {
      //console.log("count:::: " + JSON.stringify(res));
      if(res.status === 200){
        reValue = true;
      }
    }).catch((err) => {
      console.log(err);
    });
    return reValue;
  }
  function checkReadyToSave(_sfObj) {
    //console.log('checkReadyToSave');
    let reqfields = ['Template_Category__c', 'Name'];
    if (_sfObj) {
      for (let f of reqfields) {
        if (!_sfObj[f] || _sfObj[f] == '' || _sfObj[f] == null) {
          //console.log('checkReadyToSave :: '+f);
          return false;
        }
      }
    }
    return true;
  }
  function defaultObj(){
    //console.log('props.defaultType :: '+props.defaultType);
    //console.log("props.templateTypeOptions  :: "+ JSON.stringify(props.templateTypeOptions));
    return {Name : '' , Template_Category__c : props.defaultType , Form_Type__c : props.formType};
  }
  // add new section
  const handleAddNewSection = () => {
    dispatch({type: 'ADD_SECTION'});
  };

  return (
    <>
      <Grid container className="checklist_form_wrapper">
      {showSpinner && <Spinner />}
        <Grid item xs={12}>
          <Grid container>
            <Grid container item xs={6} justifyContent="flex-start">
              <Typography variant="subtitle1" gutterBottom>
                Form Builder
              </Typography>
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end">
              <Button variant="outlined" className="btn_checklist" onClick={() => handleClose(false)} >
                Cancel
              </Button>
              <Button variant="contained" className="btn_checklist" disabled={requiredMissing} onClick={() =>{handleClickSave();}} >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Form Details
          </Typography>
          {sfObj &&
            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" >
              <Grid item xs={3}>
                <TextField id="template-title" label="Template Title" variant="outlined" required value={sfObj?.Name}
                  onChange={(e) => {
                    let fff = JSON.parse(JSON.stringify(sfObj)); fff.Name = e?.target?.value; setVariables(fff);
                  }}
                  onKeyDown={(event) => {
                    if (event.key.toLowerCase() != "tab") {
                      event.stopPropagation();
                    }
                  }}
                  fullWidth />
              </Grid>
              <Grid item xs={5}>
                <TextField id="description" label="Description" variant="outlined" inputProps={{ className: "desc_input" }} value={sfObj?.Description__c}
                  onChange={(e) => {
                    let fff = JSON.parse(JSON.stringify(sfObj)); fff.Description__c = e?.target?.value; setVariables(fff);
                  }}
                  onKeyDown={(event) => {
                    if (event.key.toLowerCase() != "tab") {
                      event.stopPropagation();
                    }
                  }}
                  fullWidth />
              </Grid>
              <Grid item xs={3}>
                {typeOptions &&
                  <FormControl fullWidth size="small">
                    <InputLabel id="checklist_template_type">
                      Template Category
                    </InputLabel>
                    <Select labelId="checklist_template_type" id="template-type-select" value={sfObj.Template_Category__c} label="Template type"
                      onChange={(e) => {
                        let fff = JSON.parse(JSON.stringify(sfObj)); fff.Template_Category__c = e?.target?.value; setVariables(fff);
                      }} >
                      {typeOptions.map((q, index) => (
                        <MenuItem value={q.value}>{q.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
              </Grid>
            </Grid>
          }
        </Grid>
        {/* questions section */}
        <Grid item xs={12} className="checklist_questions_wrapper">
          {/* repeat this */}
          {sectionCurrentList && sectionCurrentList.map((q, index) => (
            <QuestionCard key={q.Numbering_Sequence__c} totalSections={sectionCurrentList.length} sectionData={q} index={index} dispatchObj={dispatch} responseOptions={responseOptions} defaultResponse={props.defaultItemResponse}/>
          ))}
        </Grid>
        {/* add new question section button */}
        <Grid item xs={12} className="add_new_question">
          <Button variant="contained" className="btn_checklist" onClick={handleAddNewSection} >
            Add New Section
          </Button>
        </Grid>
      </Grid>
      {dispatchjDetail &&
        <Dialog fullWidth open={true} scroll="paper">
          <DialogTitle>
            <Typography>
              {dispatchjDetail.for == 'QUE' ? ' Delete Section Item?' : 'Delete Section?' }
            </Typography>
            <IconButton aria-label="close" onClick={()=>{setDispatchDetail(null);}}>
                <CloseRounded />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography>
              {dispatchjDetail.for == 'QUE' ? `Are you sure you want to delete item ${(dispatchjDetail.sectionIndex + 1) +'.'+ (dispatchjDetail.questionIndex + 1)}?`
                  : `Are you sure you want to delete section ${(dispatchjDetail.sectionIndex + 1)} ?` }
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={()=>{ setDispatchDetail(null); }}>Cancel</Button>
            <Button variant="contained" onClick={()=>{dispatch(dispatchjDetail)}}>Delete</Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
};

export default Index;
