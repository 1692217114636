import { GetToken } from "api";
import { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import { fetchUrl } from "Urls";
import moment from "moment";

const useReviewTabHook = (props) => {
  const { projectId, tokenInfo } = props;

  const [showSpinner, setSpinner] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [tableDataList, setTableDataList] = useState([]);
  const [loadingProgress, setLodingProgress] = useState(false);
  const [credential, setCredential] = useState(tokenInfo);
  const [workflowTemplateOptions, setWorkflowTemplates] = useState([]);

  const [docRvwPickWrapper, setDocRvwPickWrap] = useState(null);
  const [userList, setUserList] = useState([]);
  const [stageList, setStageList] = useState([]);

  useEffect(() => {
    //console.log('projectId :: '+projectId);
    if (projectId) {
      getInitData();
    }
  }, [projectId]);

  async function getInitData() {
    if (projectId) {
      setSpinner(true);
      await fetchReviewLists(credential);
      workFlowTemplate(credential)
    }
    //setSpinner(false);
  }

  function _handleFilterDisplay() {
    setShowFilter(!showFilter);
  }
  function workFlowTemplate(_credential = credential) {
    axios.get( `${fetchUrl}/workflowTemplateList?token=${_credential?.token}&instanceUrl=${_credential?.instanceUrl}`).then((res) => {
     if (res?.status === 200) {
       const listValue = res.data;
       if (listValue?.status === 200) {
         var values = [];
         if(listValue?.templates?.length >  0 ){
           for(let row of listValue?.templates){
             if(row.Status__c =='Active'){
               values.push({label : row.Name , subtitle : row.Description__c , value : row.Id});
             }
           }
         }
         setWorkflowTemplates(values);
       }
     }
   }).catch((err) => {
     console.error(err);
   });
  }
  async function fetchReviewLists(_credential = credential) {
    setSpinner(true);
    axios
      .get(`${fetchUrl}/getReviewList/${_credential?.projectId}?token=${_credential?.token}&instanceUrl=${_credential?.instanceUrl}`)
      .then((res) => {
        if (res?.status === 200) {
          const listValue = res.data;
          if (listValue?.status === 200) {
            setDocRvwPickWrap(listValue.docReviewTeamPickWrapper);
            setUserList(listValue?.userList);
            setStageList(listValue?.stageData);
            const values = _.map(listValue?.reviews, (row) => {
              //console.log("workFlowTemplate ::", row);
              return prepareTableRowElement(row);
            });
            setTableDataList(values);
          }
        }
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        console.error(err);
      });
  }
  const closeAndRefresh = async function (refresh) {
    console.log("closeAndRefresh :: " + refresh);
    if (refresh == true) {
      await fetchReviewLists(credential);
    }
  };
  const updateTableDataWithRow = async (rowEle) => {
    
    if (rowEle.id) {
      let _tempClonedList = JSON.parse(JSON.stringify(tableDataList));
      if (_tempClonedList?.length > 0) {
        const fInd = _tempClonedList?.findIndex((data) => data.id == rowEle.id);
        if (fInd > -1) {
          _tempClonedList[fInd] = rowEle;
          setTableDataList(_tempClonedList); // init folder files
        }
      }
    }
  };
  const prepareTableRowElement = (rec) => {
    let tablePayload = {
      record: rec,
      id: rec?.Id ? rec?.Id : "",
      key: rec?.Id ? rec?.Id : "",
      number: rec?.Numbering_Sequence__c ? rec?.Numbering_Sequence__c : "",
      title: rec?.Name ? rec?.Name : "-",
      createdBy: rec?.CreatedById ? rec?.CreatedBy?.Name : "-",
      createdById: rec?.CreatedById ? rec?.CreatedById : "-",
      createdDate: rec?.CreatedDate ? moment(rec?.CreatedDate).format("DD MMM YYYY HH:mm") : "-",
      stage: rec?.Stage__c ? rec?.Stage__c : "",
      notes: rec?.Workflow_Template__r.Name ? rec?.Workflow_Template__r.Name : "",
      docCount: rec?.Total_Document__c ,
      completedDate: rec?.Completed_Date__c ? moment(rec?.Completed_Date__c).format("DD MMM YYYY") : "-",
      approvedCount: rec?.Stage__c === "Closed" ? rec?.Approved_Document_Count__c : "",
      rejectedCount: rec?.Stage__c === "Closed" ? rec?.Rejected_Document_Count__c : "",
      activeApprovers : rec?.activeApprovers ? rec?.activeApprovers.join(', ') : '-',
      dueDate : rec?.Due_Date__c ? moment(rec?.Due_Date__c).format("DD MMM YYYY") : "-",
    };
    return tablePayload;
  };

  return {
    tableDataList,
    credential,
    closeAndRefresh,
    updateTableDataWithRow,
    showSpinner,
    setSpinner, docRvwPickWrapper, userList, stageList, workflowTemplateOptions
  };
};
export default useReviewTabHook;
