import styled from "styled-components";

export const Styles = styled.div`
  .cards-container {
    display: flex;
    border-radius: 2px;
    color: #333;
    padding: 1px;
    box-shadow: 0 0 1px #000;
    overflow-x: auto;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .card {
    background: #fff;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;
    img {
      width: 100px;
      height: 100px;
      object-fit: center;
    }
    
    &:hover :hover .title {
      /*becomes visible on hover*/
        opacity: 1;       
    }
  }
  .title {
    background-color: rgb(0, 0, 0, 0.3);
    position: absolute;
    bottom: 0;           
    color: white;
    width: 100px;
    padding: 5px 5px;
    text-align: center;
    opacity: 0;           
    transition: 0.6s;
    
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .card:hover .title {
      opacity: 1;       
  }

  .card-content {
    padding: 1px;
  }

`;
