import { fetchUrl } from "Urls";
import { getFolderFileApi, getFolderFilesApi } from "api";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  AppBar,
  Grid,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { FolderOpenTwoTone } from "@mui/icons-material";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from "axios";
import { useParams } from "react-router-dom";
import _, { zip } from "lodash";

const Share = ({ source }) => {
  console.log('source :: '+source);
  const params = useParams();
  const [downloadable, setdownloadable] = useState();
  const [loaderState, setLoaderState] = useState(false);
  const [totalChild, setTotalChild] = useState();
  const [currentFolderShare, setCurrentFolderShare] = useState({
    name: "",
    type: "",
    url: "",
    children: [],
  });
  const shareId = params?.shareId;
  function addallfiles(datas, zipp) {
    datas?.forEach(async (val, index) => {
      if (val.type === "folder") {
        const fold = zipp.folder(val.name);

        if (val?.child.length > 0) {
          addallfiles(val?.child, fold);
        }
      } else {
        let imageBlob = await fetch(val.url).then((response) =>
          response.blob()
        );
        // create a new file from the blob object
        let imgData = new File([imageBlob], val.name);
        zipp.file(val.name, imgData, { base64: true });
        console.log(
          "Object.keys(zipp.files).length",
          Object.keys(zipp.files).length
        );

        if (Object.keys(zipp.files).length === totalChild) {
          setLoaderState(false);
          zipp.root = "";
          zipp.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, `${downloadable.name}.zip`);
          });
        }
      }
    });
  }

  async function downloadzip() {
    setLoaderState(true);
    console.log("downloadable???????", downloadable);
    const zip = new JSZip();
    addallfiles(downloadable.children.reverse(), zip);
  }

  useEffect(() => {
    setLoaderState(true);
    _getFolderApi();
  }, []);

  async function PassChildren(children) {
    const data = _.map(children, async (row) => {
      let value = {
        id: row?.value?.Id,
        name: row?.value?.Name,
        children: row?.children,
        child: [],
        type: "folder",
        url: "",
      };
      const Files = await handleFolderData(row?.value?.Id);
      if (Files?.length > 0) {
        value["child"] = Files;
      }
      return value;
    });
    return await Promise.all(data);
  }
  let count = 0;

  async function childrenSet(data) {
    const { id, name, children, type } = data;

    let folder = {
      id,
      name,
      children: [],
      type: "folder",
      url: "",
    };
    const Files = await handleFolderData(id);
    if (Files?.length > 0) {
      folder["children"] = Files;
      count = Files?.length > 0 ? Files?.length : 0;
    }
    const v = await PassChildren(children);
    count += v?.length > 0 ? v?.length : 0;
    folder["children"] = [...folder["children"], ...v];
    _.map(v, async (row) => {
      if ((row?.type === "folder") & (row?.children?.length > 0)) {
        let value = await PassChildren(row?.children);
        row["child"] = [...row["child"], ...value];
        count += value?.length > 0 ? value?.length : 0;
        count += row["child"]?.length > 0 ? row["child"]?.length : 0;
      }
    });
    console.log(count, "cccccccccccccccccc");
    setTotalChild(count);
    // setting downloadable data in state
    setLoaderState(false);

    setdownloadable(folder);
  }

  function _getCurrentIdChildren(VALUE) {
    _.forEach(VALUE, ({ value, children }) => {
      if (value?.Id === shareId) {
        console.log("value<<<<<<<<<<", value);
        setCurrentFolderShare({
          id: shareId,
          name: value?.Name,
          children,
          type: "folder",
        });
        childrenSet({
          id: shareId,
          name: value?.Name,
          children,
          type: "folder",
        });
      } else {
        if (Array.isArray(children)) {
          _getCurrentIdChildren(children);
        }
      }
    });
  }

  async function _getFolderApi() {
    await axios
      .get(`${fetchUrl}/folder`)
      .then((res) => {
        if (res?.status === 200) {
          const { tree } = res?.data;
          _getCurrentIdChildren(tree?.children);
        }
      })
      .catch((err) => {
        setLoaderState(false);
        console.log(err);
      });
  }

  const handleFolderData = async (id) => {
    let data = [];
    const foldersData = await getFolderFileApi(id);
    foldersData?.files?.map(async (folder, index) => {
      const folderName = folder?.File_Name__c;
      const folderId = folder?.Folder__c;
      data = [
        ...data,
        {
          child: [],
          url: folder?.url,
          id: folder?.Id,
          name: folderName,
          type: "file",
        },
      ];
    });
    return data;
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderState}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box>
        <AppBar
          style={{ background: "#2E3B55", height: "80px" }}
          position="static"
        >
          {currentFolderShare?.name}
        </AppBar>
      </Box>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            padding={5}
            sx={{ gap: "13px" }}
          >
            <Box>
              <FolderOpenTwoTone sx={{ fontSize: "112px" }} />
            </Box>
            <Box sx={{ marginTop: "-23px", fontSize: "19px" }}>
              {currentFolderShare?.name}
            </Box>
            <Box>
              <Button
                variant="contained"
                onClick={() => {
                  downloadzip();
                }}
              >
                Download
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default Share;
