import pspdfkit_Imp from 'pspdfkit';
import React, { useState ,useEffect ,useRef } from "react";
import axios from "axios";
import { fetchUrl } from "Urls";
import { Box ,ButtonGroup ,Button, TextField, IconButton,  Grid,Paper, Divider, Checkbox, Tooltip, Autocomplete} from "@mui/material";
import { styled } from "@mui/material/styles";
import Spinner from "components/spinner";
const Input = styled("input")({
  display: "none",
});

function CreateTemplate( {formTemplateId, credential, handleClose , ...props}) {
  let instance = useRef(null);
  const containerRef = useRef(null);
  //const [saveFile.current, setSaveFile] = useState(false);
  const [instanceObject, setInstanceObject] = useState(null);
  //const [uploadedFile, setUploadedFile] = useState(null);
  const [s3Url, setS3Url] = useState(null);
  const [typeOptions, setTypeOptions] = useState(props.templateTypeOptions);
  const [type, setType] = useState({ label: props.defaultType, value: props.defaultType });
  const [sfObj, setsfObj] = useState(defaultObj());

  const [showSpinner, setSpinner] = useState(false);

  var fileTitle = useRef(null) ;
  var existingFileName = useRef(null) ;
  var saveFile = useRef(null) ;
  var docTriggerChanges = useRef(null) ;
  var uploadedFile = useRef(null) ;
  var __sfObj = useRef(null) ;
  
  useEffect(() => {
    if(formTemplateId){
      (async () => {
        setSpinner(true);
        await axios.get(`${fetchUrl}/getFormTemplate/${formTemplateId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
        .then((res) => {
          //console.log("count:::: " + JSON.stringify(res));
          if (res.status === 200) {
            //{formObj : cObj , docDetail : docReturn}
            if(res.data?.formObj){
              setVariables(res.data.formObj);
              if(res.data.docDetail){
                saveFile.current = false;
                //setSaveFile(false);
                existingFileName.current = res.data.docDetail.File_Name__c ;
                fileTitle.current = existingFileName.current ;
                setS3Url(res.data.docDetail.end_url) ;
                initLoadPDF(res.data.docDetail.end_url);
              }
              return;
            }
          }
        }).catch((err) => {
          console.log(err);
        });
        setSpinner(false);
      })();
    }
  },[]);
  function defaultObj(){
    //console.log('props.defaultType :: '+props.defaultType);
    //console.log("props.templateTypeOptions  :: "+ JSON.stringify(props.templateTypeOptions));
    return {Name : '' , Template_Category__c : props.defaultType , Form_Type__c : props.formType};
  }

  const [requiredMissing, setRequiredMissing] = React.useState(true);

  function setVariables(_sfObj) {
    setType({ label: _sfObj?.Template_Category__c, value: _sfObj?.Template_Category__c });
    __sfObj.current = _sfObj;
    setRequiredMissing(!checkReadyToSave(_sfObj));
    setsfObj(_sfObj);
  }
  function checkReadyToSave(_sfObj) {
    //console.log('checkReadyToSave');
    let reqfields = ['Template_Category__c', 'Name'];
    if (_sfObj) {
      for (let f of reqfields) {
        if (!_sfObj[f] || _sfObj[f] == '' || _sfObj[f] == null) {
          //console.log('checkReadyToSave :: '+f);
          return false;
        }
      }
    }
    return true;
  }

  const uploadMedia = async (options) => {
    if(options && options.length > 0 ){
      saveFile.current = true;
      //setSaveFile(true);
      fileTitle.current = options[0].name;
      uploadedFile.current = options[0];
      //setUploadedFile(options[0]);
      initLoadPDF(URL.createObjectURL(options[0]));
    } else{
      fileTitle.current = '';
      uploadedFile.current = null;
      //setUploadedFile(null);
    }
    if(existingFileName && existingFileName.current && existingFileName.current != ''){
      fileTitle.current = existingFileName.current ;
    }
  }
  const onClickSave = async (_instance = instanceObject) => {
    let fileHaveChanges =  docTriggerChanges.current ;//_instance.hasUnsavedChanges();
    //console.log('fileHaveChanges :: '+fileHaveChanges);
    let _saveFile = saveFile.current ;
    if(saveFile.current == false && fileHaveChanges){
      _saveFile = true;
    }
    let toSave = __sfObj.current ;
    /*console.log(" onClickSave __sfObj  :: "+ JSON.stringify(__sfObj.current));
    console.log(" onClickSave sfObj  :: "+ JSON.stringify(sfObj));
    console.log(" onClickSave toSave  :: "+ JSON.stringify(toSave));*/
    console.log('verdict amazon save :: '+_saveFile);
    if(true){
      setSpinner(true);
      await _instance.exportPDF().then(async (buffer) => {
        const blob = new Blob([buffer], { type: "application/pdf" });
        const fName = fileTitle.current;
        //console.log('fName :: '+fName);
        const formData = new FormData();
        formData.append( "templateFile", blob, fName );
        formData.append( "fileTitle", fName );
        formData.append( "putOnAmazon", _saveFile);
        formData.append( "formRec", JSON.stringify(toSave));
        if(true){
          await axios.post(`${fetchUrl}/saveFormTemplate/Form_Template__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,formData )
          .then((res) => {
            //console.log("count:::: " + JSON.stringify(res));
            if (res.status === 200) {
              handleClose(true);
            }
          }).catch((err) => {
            console.log(err);
          });
        }
      });
      setSpinner(false);
    }
  }

  async function initLoadPDF(fileURI) {
    try{
      ( pspdfkit_Imp && containerRef ) && pspdfkit_Imp.unload('#pdfcontainer')
    }catch (e){
      console.log('unload error');
    }
    docTriggerChanges.current = false;
    instance.current = await pspdfkit_Imp.load({ container: containerRef.current,
      document : fileURI,
      baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
      initialViewState: new pspdfkit_Imp.ViewState({
        sidebarMode: pspdfkit_Imp.SidebarMode.ANNOTATIONS,
        zoom: pspdfkit_Imp.ZoomMode.AUTO,
        formDesignMode: true,
        interactionMode: pspdfkit_Imp.InteractionMode.FORM_CREATOR,
      }),
      toolbarItems : [{type : 'sidebar-thumbnails'},
      {type : 'sidebar-annotations'},{type : 'pan'},{type : 'form-creator'},
      {type : 'zoom-out'},{type : 'zoom-in'}]
    });
    setInstanceObject(instance.current);
    if(instance.current){
      const btn = document.getElementById("saveButton");
      btn.onclick = function (event) {
        //console.log("onclick sfObj  :: "+ JSON.stringify(sfObj));
        //console.log("onclick sfObj  :: "+ JSON.stringify(sfObj));
        //console.log("onclick __sfObj  :: "+ JSON.stringify(__sfObj));
        onClickSave(instance.current);
      };
      instance.current.addEventListener("annotations.change", (event) => {
        docTriggerChanges.current = true;
      });
      instance.current.addEventListener("annotations.delete", (event) => {
        docTriggerChanges.current = true;
      });
      setInstanceObject(instance.current);
    }
  }
  return (
    <div style={{  width: '100%' , position: "relative" , height: "80vh" , overflow : 'auto' }}>
      {showSpinner && <Spinner />}
      <div className="table_head">
        <div className="left_side">
          <div>
            <label htmlFor="fileupload">
              <Input
                accept="application/pdf"
                id="fileupload"
                type="file"
                onChange={(e) => uploadMedia(e.target.files)}
                onClick={(e) => (e.target.value = "")}
              />
              <Button variant="contained" component="span">
                Upload
              </Button>
            </label>
          </div>
          {sfObj && 
            <div>
              <TextField id="title" variant="outlined" label="Template Title" required value={sfObj?.Name}
                onChange={(e) => {
                  let fff = sfObj; fff.Name = e?.target?.value; setVariables(fff);
                }}
                onKeyDown={(event) => {
                  if (event.key.toLowerCase() != "tab") {
                    event.stopPropagation();
                  }
                }} />
            </div>
          }
          
          {sfObj && /* keeping in two block to separate element in header*/
            <div style={{width:'20rem' }}>
              <Autocomplete
                disablePortal
                className='inlineAutocomplete'
                value={type}
                getOptionLabel={(option) => option?.label ? option?.label : ''}
                onChange={(event, newValue) => {
                  let fff = sfObj; fff.Template_Category__c = newValue?.value ; setVariables(fff);
                }}
                options={typeOptions}
                renderInput={(params) => (
                  <div>
                    <TextField id="type" variant="outlined" label="Template Type"  required {...params} />
                  </div>
                )}
              />
            </div>
          }
        </div>
        <div className="right_side">
          <ButtonGroup variant="outlined" className="table-btn-group" >
            <Button variant="text" component="span" onClick={()=>handleClose(false)}>
              Cancel
            </Button>
            <Button id="saveButton" variant="contained" component="span" disabled={(requiredMissing || !instanceObject)}>
              Save
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <>
        <Grid container style={{ position: "relative"}}>
          <Grid item style={{ flexGrow: 1 }}>
            <div id="pdfcontainer" ref={containerRef} style={{height: "80vh"}}></div>
          </Grid>
        </Grid>
      </>
    </div>
  );
}

export default CreateTemplate;
