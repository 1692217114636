import React, { useMemo, useEffect, useState } from "react";
import styled from "styled-components";
import 'react-tooltip/dist/react-tooltip.css';
import { makeStyles } from '@mui/styles';
import {
  useTable,
  useBlockLayout,
  useResizeColumns,
  useSortBy,
  usePagination,
} from "react-table";
import { MoreVert } from "@mui/icons-material";
import { IconButton, Button,  Dialog, DialogActions} from "@mui/material";
//console.log("reRender2");
const useStyles = makeStyles({
  customDialog: {
    width: 300,
    height: 300// Set the desired width in pixels
  },
});
const Styles = styled.div`
  ${"" /* These styles are suggested for the table fill all available space in its containing element */
  }
  width: 100%;
  display: block;
  ${"" /* These styles are required for a horizontaly scrollable table overflow */
  }
  padding: 0 10px;
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100vh - 280px);
  .table {
    border-spacing: 0;
    background: #fff;
    /* width: 100vw; */
    width: 100%;
    background-color: #fff;
    position: relative;
    box-sizing: border-box;
    // height: 100vh;
    z-index: 0;
    div {
      color: rgba(0, 0, 0, 0.6);
      font-size: 13px;
    }
    .thead {
      overflow-y: auto;
      overflow-x: hidden;
      /* min-width: 1570px; */
      min-width: 100%;
      position: sticky;
      top: 0;
      z-index: 999;
      background: #fff;
    }
    .setting {
      &:hover {
        background: #F8F8F8;
      }
    }
    .th,
    .td {
      &:nth-child(1),
      &:nth-child(2) {
        //   background: #f7f9fa;
        //   position: sticky !important;
        // z-index: 99;
        // left: 0;
      }
      &:nth-child(2) {
        // min-width: 200px;
        //  box-shadow: 3px 0px 3px 0 rgb(129 144 153 / 19%);
        //  box-sizing: border-box;
        //  left: 60px;
      }
      &:nth-child(3) {
        margin-left: 250px;
      }
      &:nth-child(5) {
        margin-left: 150px;
      }
    }
    .tbody {
      background: #fff;
    }

    .tr {
      width: 100% !important;
      &:hover {
      //  background: rgba(237, 240, 242, 0.75);
      }
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      border-bottom: 1px solid #eaeaea;
    }
    .tr2 {
      width: 100% !important;
      &:hover {
       // background: rgba(237, 240, 242, 0.75);
      }
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      border-bottom: 1px solid #ddd;
      background: #E8EFFF;
    }
    .th {
      font-weight: 300;

      &:hover {
        background: rgba(237, 240, 242, 0.75);
        .resizer {
          opacity: 1;
        }
      }
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 91px;
      /* border-right: 1px solid #ddd; */
      overflow: hidden;
      .name_main {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      ${"" /* In this example we use an absolutely position resizer,
=======
      border-right: 1px solid black;

      ${"" /* In this example we use an absolutely position resizer,
>>>>>>> f71325a628771521b2a0110e52cf23d2dc6ef79b
     so this is required. */
  }
      position: relative;

      :last-child {
        border-right: 0;
      }
      &:first-child {
        width: 80px !important;
      }

      .resizer {
        opacity: 0;
        right: 0;
        background: #717171;
        width: 3px;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action :none;

        &.isResizing {
          background: red;
        }
      }
    }
    button.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
      margin: 0;
      padding: 8px 10px;
    }
    div[title="Toggle SortBy"] {
      font-weight: 600;
    }
  }
  .hoverStyle {
    &:hover {
      background: #f8f8f8;
    }
    cursor: pointer;
  }
`;

const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) =>
  getStyles(props, cell.column.align, cell.column.className);

const getStyles = (props, align = "left", className = "") => [
  props,
  {
    style: {
      // justifyContent: align === "right" ? "flex-end" : "flex-start",
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
    },
    className: "td " + className,
  },
];

function Table({
  columns,
  data,
  handleClickMenu,
  openMenu,
  setCurrentFileId,
  selectedIds,
  NoDataComponent,
  listCount,
  setRowsPerPage
}) {
  const classes = useStyles();
  // Use the state and functions returned from useTable to build your UI
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 180,
      maxWidth: 370,
    }),
    []
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [
          {
            id: "type",
            desc: true,
          },
          {
            id: "name",
            desc: false,
          },
        ],
        hiddenColumns: ["type"],
        pageIndex: 0,
        pageSize: 100,
      },
    },

    useBlockLayout,
    useResizeColumns,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    const startIndex = state.pageIndex * state.pageSize;
  const endIndex = startIndex + state.pageSize;
  const originalDataForPage = rows.slice(startIndex, endIndex).map(row => row.original);
    setRowsPerPage(originalDataForPage)
}, [rows.length > 0, state.pageIndex])

  const filteredColumns = allColumns.slice(1, -1);
  //console.log("state", state, "pageCount", pageCount, page);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const toggleColumnSettings = () => {
    setPopoverOpen(prevState => !prevState);
  };
  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };
  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup, hind) => (
            <tr
              {...headerGroup.getHeaderGroupProps({
                // style: { paddingRight: '15px' },
              })}
              className="tr"
              key={headerGroup.id + hind}
            >
              {headerGroup.headers.map((column, columnIndex) => (
                <th key={headerGroup.id + column.Header + columnIndex} {...column.getHeaderProps(headerProps)} className="th">

                  {column.Header === 'settings' ? (
                    <div style={{ color: "white", size: 1000, cursor: 'pointer' }} className="setting"
                      id="columnSettingsButton"
                      onClick={toggleColumnSettings}><img
                        style={{ height: "20px" }}
                        src="/settings.svg" alt="Settig" /></div>
                  ) : (
                    <div>
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                      </span>
                    </div>
                  )}
                  {/* {column.render("Header")} */}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${column.isResizing ? "isResizing" : ""
                        }`}
                    />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="tbody">
          {rows?.length === 0 && (
            <tr
              className="no_data_found"
              style={{ textAlign: "center" }}
            >
              <td colSpan={columns?.length}>
                {NoDataComponent}
              </td>
            </tr>
          )}
          {page.map((row, rInd) => {
            prepareRow(row);
            let params = { id: row.original.id, row: row.original };
            //console.log("prss ::",row.original.id);
            return (
              <>
                <tr data-key={'body_row' + rInd} key={row.original.id + 'body_row' + rInd} {...row.getRowProps()}
                  className={selectedIds.includes(row.original.id) ? "tr2" : "tr"}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td key={row.original.id + 'body_row' + cell.value} {...cell.getCellProps(cellProps)}>
                        {cell.render("Cell")}
                        {index === 8 && (
                          <IconButton
                            onClick={(e) => handleClickMenu(e, params)}
                            size="small"
                            sx={{ marginRight: "150px" }}
                            className="menu_open_button"
                            aria-controls={openMenu ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openMenu ? "true" : undefined}
                          >
                            <MoreVert
                              onClick={() => {
                                setCurrentFileId({
                                  id: params.row.id,
                                  name: params.row.name,
                                  type: params?.row?.type
                                    ? params?.row?.type
                                    : "folder",
                                });
                              }}
                            />
                          </IconButton>
                        )}
                      </td>
                    );
                  })}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
      {isPopoverOpen &&  // Render the popover conditionally based on isPopoverOpen state
        <div>
          <Dialog open={isPopoverOpen} classes={{ paper: classes.customDialog }} style={{ left: 1600, bottom: 90 }}

            BackdropProps={{ invisible: true, onClick: () => (setPopoverOpen(false)) }}>
            {filteredColumns.map((column) => (
              <div key={column.id} style={{ marginTop: 10, marginLeft: 15 }}>
                <label style={{ display: 'flex', alignItems: 'center' }}>
                  <input type='checkbox' {...column.getToggleHiddenProps()} style={{ marginRight: 5 }} />
                  <span style={{ color: '#505050' }}>{column.Header}</span>
                </label>
              </div>
            ))}
            <DialogActions style={{ borderTop: "1px solid #EAEAEA", marginTop: 10 }}>
              <Button style={{ color: "#346EF5", backgroundColor: "#ffff", borderColor: "#346EF5", textTransform: "none" }} onClick={() => (setPopoverOpen(false))} color="primary">
                Cancel
              </Button>
              <Button style={{ color: "#ffff", backgroundColor: "#346EF5", textTransform: "none" }} color="primary">
                Apply
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      }

      {rows?.length === 0 ? (
        <></>
      ) : (
        <div style={{ display: "flex", justifyContent: "space-between", position: "fixed", bottom: "15px", width: "85%" }}>
          <div style={{ fontSize: 15, color: "#505050" }}>
            Items per page
            <select
              style={{ marginLeft: "20px", marginRight: "20px", cursor: 'pointer' }}
              value={state.pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[100, 150, 200, 300].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            {(state.pageSize * (state.pageIndex + 1)) - (state.pageSize) + 1 + " "}-{" " + (state.pageSize * (state.pageIndex + 1) < listCount ? state.pageSize * (state.pageIndex + 1) : listCount) + " "} of{" "}
            {listCount + " "} items
          </div>
          <div style={{ justifyContent: "flex-end", position: "relative", display: "flex", }}>
            <div style={{ marginRight: "20px" }}>
              <div style={{ fontSize: 15, display: "flex" }}>
                <div className="hoverStyle"><img onClick={() => gotoPage(0)} style={{ filter: state.pageIndex > 0 ? 'invert(39%) sepia(0%) saturate(1741%) hue-rotate(147deg) brightness(79%) contrast(99%)' : "" }} src="/skip previous.svg" alt="e2" /></div> <div style={{ color: !canPreviousPage ? "#BABABA" : "#505050" }} onClick={() => previousPage()} className="hoverStyle"> <img style={{ filter: canPreviousPage ? 'invert(39%) sepia(0%) saturate(1741%) hue-rotate(147deg) brightness(79%) contrast(99%)' : "" }} src="/navigate previous.svg" alt="e" /> Previous</div>
              </div>
            </div>
            <div style={{ marginRight: "20px" }}>
              <input style={{ width: "30px" }}
                type="number"
                value={state.pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />{" "}
              of {pageOptions.length}
            </div>
            <div>
              <div style={{ fontSize: 15, display: "flex" }}>
                <div style={{ color: !canNextPage ? "#BABABA" : "#505050" }} onClick={() => nextPage()} className="hoverStyle" >Next<img style={{ filter: !canNextPage ? 'invert(82%) sepia(2%) saturate(0%) hue-rotate(52deg) brightness(93%) contrast(89%)' : "" }} src="/navigate next.svg" alt="e1" /></div>  <div className="hoverStyle"><img style={{ filter: state.pageIndex == pageCount - 1 ? 'invert(82%) sepia(2%) saturate(0%) hue-rotate(52deg) brightness(93%) contrast(89%)' : "" }} onClick={() => gotoPage(pageCount - 1)} src="/skip next.svg" alt="e11" /></div>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
}
const NoData = () => {
  return <div style={{ 'textAlign': 'center', 'paddingTop': '200px' }}>No Data Found</div>;
};
function ShopDrawings({
  columns_react_table,
  rowss,
  handleClickMenu,
  openMenu,
  selectedIds,
  setCurrentFileId,
  listCount,
  setRowsPerPage

}) {
  //console.log("shopdrawings", columns_react_table);
  //console.log("reRender1");

  return (
    <Styles>
      {rowss !== true && (
        <>
          <Table
            columns={columns_react_table}
            data={rowss}
            openMenu={openMenu}
            setCurrentFileId={setCurrentFileId}
            handleClickMenu={handleClickMenu}
            selectedIds={selectedIds}
            NoDataComponent={<NoData />}
            listCount={listCount}
            setRowsPerPage={setRowsPerPage}
          />
        </>
      )}
    </Styles>
  );
}

export default ShopDrawings;