import { createGlobalStyle } from "styled-components";

const GlobalStyled = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
 
:root{
    --btn-blue:#1976d2;
    --disable: #a5a5a5;
    --common-font: 'Poppins', sans-serif; 
    --drblue: #346EF5;
    --drgray: #929292;
    --border-gray: #D8D8D8; 
    --text-color: #505050;
    --body-emphasize-font-family: "Poppins-SemiBold", Helvetica;
    --body-emphasize-font-size: 14px;
    --body-emphasize-font-style: normal;
    --body-emphasize-font-weight: 600;
    --body-emphasize-letter-spacing: 0.14px;
    --body-emphasize-line-height: normal;
    --body-regular-font-family: "Poppins-Regular", Helvetica;
    --body-regular-font-size: 14px;
    --body-regular-font-style: normal;
    --body-regular-font-weight: 400;
    --body-regular-letter-spacing: 0.14px;
    --body-regular-line-height: normal;
    --body-small-font-family: "Poppins-Regular", Helvetica;
    --body-small-font-size: 12px;
    --body-small-font-style: normal;
    --body-small-font-weight: 400;
    --body-small-letter-spacing: 0.12px;
    --body-small-line-height: normal;
    --brandblue: rgba(52, 110, 245, 1);
    --brandlight-blue: rgba(232, 239, 255, 1);
    --dropdown: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    --neutralsblack: rgba(0, 0, 0, 1);
    --neutralsdark-grey: rgba(80, 80, 80, 1);
    --neutralslight-grey-2: rgba(234, 234, 234, 1);
    --neutralslight-grey-3: rgba(216, 216, 216, 1);
    --neutralswhite: rgba(255, 255, 255, 1);
    --on-hover: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    --statusred: rgba(249, 40, 40, 1);
    --body-emphasize-font-family: "Poppins-SemiBold", Helvetica;
    --body-emphasize-font-size: 14px;
    --body-emphasize-font-style: normal;
    --body-emphasize-font-weight: 600;
    --body-emphasize-letter-spacing: 0.14px;
    --body-emphasize-line-height: normal;
    --body-regular-font-family: "Poppins-Regular", Helvetica;
    --body-regular-font-size: 14px;
    --body-regular-font-style: normal;
    --body-regular-font-weight: 400;
    --body-regular-letter-spacing: 0.14px;
    --body-regular-line-height: normal;
    --body-small-font-family: "Poppins-Regular", Helvetica;
    --body-small-font-size: 12px;
    --body-small-font-style: normal;
    --body-small-font-weight: 400;
    --body-small-letter-spacing: 0.12px;
    --body-small-line-height: normal;
    --brandblue: rgba(52, 110, 245, 1);
    --brandlight-blue: rgba(232, 239, 255, 1);
    --neutralsblack: rgba(0, 0, 0, 1);
    --neutralsdark-grey: rgba(80, 80, 80, 1);
    --neutralsgrey: rgba(146, 146, 146, 1);
    --neutralslight-grey-1: rgba(248, 248, 248, 1);
    --neutralslight-grey-2: rgba(234, 234, 234, 1);
    --neutralslight-grey-3: rgba(216, 216, 216, 1);
    --neutralswhite: rgba(255, 255, 255, 1);
    --on-hover: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    --statusgreen: rgba(10, 161, 16, 1);
    --statusred: rgba(249, 40, 40, 1);
    --statusstatus: rgba(255, 170, 43, 1);
    --neutralslight-grey-4: rgba(186, 186, 186, 1);
  }
}
*{
    font-family: var(--common-font);
}
html {
  overflow: auto;
}
.children {
    padding-left: 16px;
}
.css-ahj2mt-MuiTypography-root {
    font-family: var(--common-font);
}

.table_head {
    height: 69px;
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    padding: 0 16px;
    .right_side {
        display: flex;
         
    } 
}
.MuiPaper-root.MuiPaper-elevation div>ul {
   // height: calc(100vh - 200px);
    overflow-x: hidden;
}
.MuiList-root.MuiList-padding.publish_items{
    height: unset;
    min-height: 150px !important;
    max-height: 300px !important;
}
 
.MuiDrawer-paper {
    box-sizing: border-box;
    padding-top: 80px;
    width: 300px;
    margin-top: 0;
    z-index: 0;
    position: absolute;
    top: 0;
}

header.App-header {
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
}


.MuiToggleButtonGroup-root {
    width: 100%;
}

.MuiToggleButtonGroup-root>button {
    width: 100%;
}

.toggle_section button.Mui-selected {
    background: #909090;
    color: #fff;
}
[role="tabpanel"]>.MuiBox-root {
    padding: 0;
}
.toggle_section h3 {
    background: #fcfcfc;
    margin-bottom: 10px;
    padding: 5px 10px;
    font-weight: 600;
    color: #1976d2;
    border-radius: 5px;
    box-shadow: 0 0 5px 0px rgb(0 0 0 / 10%);
}
.toggle_section .MuiToggleButtonGroup-root {
    width: 100%;
    gap: 10px;
}
.MuiToggleButtonGroup-root > button {
    border: 1px solid #ddd !important;
    border-radius: 0;
}
.MuiDrawer-docked hr.MuiDivider-root {
    display: none;
}
main.MuiBox-root,.MuiDrawer-paper {
 
    background: #fff;
}
.MuiDataGrid-main {
    background: #fff;
}

.left_side,
.table_head .right_side {
    display: flex;
    gap: 10px;
    align-items: center;
}

 .left_side >button.MuiButtonBase-root:first-child {
    background: #d1d1d1;
}
.table_head{ 
.right_side{
    button.active{
        background: #E8EFFF;
    }
    button { 
   // border-radius: 2px;
    //padding: 7px;
    transition: 0.3s ease all;
   // border: 1px solid #D8D8D8;
    &:hover{
      //  background-color: var(--btn-blue);
        svg{
            fill: #fff;
            polygon {
                    stroke: #fff;
            }
        }
    }
   
&.activeLogs{
    background:  #0c2c55;
    svg{
        fill: #fff;
    }

}
&.activeRecyclebin{
    background:  #0c2c55;
    svg{
        fill: #fff;
    }

}
.MuiBadge-anchorOriginTopRight{
    transform: scale(1) translate(95%, -85%);
}
}
} 
}

.MuiDataGrid-columnHeadersInner {
    background: #f5f5f5;
}
.MuiDrawer-paper {
    padding-top: 10px;
}
.MuiDrawer-docked hr.MuiDivider-root {
    display: block;
    margin: 20px 0;
}
.left_side >button.MuiButtonBase-root:first-child svg {
    fill: #fff;
}
.dialog_paper_custom {
    width: 100%;
    .stepper_div {
        display: flex;
        justify-content: center;

        .stepperClass{
            width: 50%;
        }
    }
}

h2.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root {
    background: #ededed;
    position: sticky;
    top: 0;
    z-index: 99;
}
.stepper_div.MuiBox-root.css-8atqhb span.MuiStepLabel-label.Mui-active {
    color: #1976d2;
    font-weight: 600;
}
.MuiStepConnector-alternativeLabel span {
    height: 12px;
    background: #ededed;
    border: 1px solid #ccc;
    position: relative;
}
.stepperClass  .MuiStepIcon-root {
    color: #fff;
    background:#ededed;
    border: 1px solid #bebebe;
    border-radius: 30px;
    padding: 4px;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root text.MuiStepIcon-text {
    background: #fff;
    border: 1px solid #dfdfdf;
    padding: 10px;
    fill: #000;
}
.stepper_div.MuiBox-root {
    max-width: 980px;
    margin: 0 auto;
}
h2.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root .continueButton {
    position: absolute;
    right: 90px;
    top: 50%;
    transform: translateY(-50%);
}
.continueButton button.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    background: var(--btn-blue);
    color: #fff;
    border-radius: 5px;
    padding: 8px 15px; 
}

hf2.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root button.MuiButtonBase-root.MuiIconButton-root {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
h2.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root button.MuiButtonBase-root.MuiIconButton-root.deck-buttons {
    position: unset;
    right: unset;
    top: unset;
    transform: unset;
}
div.MuiGrid-root.MuiGrid-item div.show-pdf-header-end button.MuiButtonBase-root.MuiIconButton-root {
    position: relative;
    right: inherit;
    top: auto;
    transform: none;
}
.uploadTitle h3 {
    font-size: 30px;
    font-weight: 600;
    margin: 0 0;
}
.dialog_paper_custom.MuiDialog-paper .MuiDialogContent-root.MuiDialogContent-dividers {
    padding: 0;
    min-height: 420px;
    display: flex;
    /* flex-wrap; */
    align-items: flex-start;
}
.dialog_paper_custom.MuiDialog-paper .MuiDialogContent-root.MuiDialogContent-dividers>div{
    width: 100%;
}
 
.uploadTitle {
    padding: 15px 20px;
    box-shadow: 0 1px 6px 2px rgb(0 0 0 / 16%);
    display: flex;
    justify-content: space-between;
}
.folderTitle_section {
    padding: 10px 20px;
}
.icon svg {
    stroke: hsl(204deg 2% 55%);
    color: hsl(213deg 21% 90%);
}
.folder,.fileLoding_addFile {
    display: flex;gap: 20px;align-items: center;
}
.folderTitle_section {
    display: flex;
    justify-content: space-between;
}
.fileLoding_addFile button {
    color: var(--btn-blue);
    padding: 5px 10px;
    border: 1px solid #ddd;    background: #fff;
}
span.MuiCheckbox-root.MuiCheckbox-colorPrimary {
    padding: 10px 18px;
}

.MuiDataGrid-columnHeadersInner {
    background: #fff;
}
.MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
}
svg.MuiSvgIcon-root.MuiDataGrid-iconSeparator {
    fill: hsl(195deg 7% 89%);
}

.MuiDataGrid-row {
    padding: 0 4px;
}
.main-prog {
    padding: 10px 50px 40px;
}
.MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel {
    margin: 0 -5px;
    z-index: 0;
}
.css-vnkopk-MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel { 
    z-index: 1;
}
.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-completed span.MuiStepConnector-line {
    background: var(--btn-blue);
}
.Finished svg.MuiSvgIcon-root {
    fill: #008000;
    font-size: 180px;
}
.Finished h3 {
    font-size: 26px;
    text-transform: uppercase;
    letter-spacing: 14px;
    font-weight: 800;
    margin-top: 0;
}

label[for=fileupload]{
    span {
        height: 100%;
    }
}
/* 01-07-2022 */
.main_template_div{
    fieldset {
    border: none;
}
.MuiSelect-select.MuiSelect-outlined { 
    font-size: 14px;
    background: #1976d2;
    border-radius: 5px;
    color: #fff;
    padding: 9px 30px 10px 15px;
}
.MuiOutlinedInput-root svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    fill: #fff;
}
.MuiDataGrid-cell {
    padding-left: 5px;
}
}
button.MuiButtonBase-root.Mui-disabled {
    background: var(--disable);
}
.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
    //max-height: 200px;
    overflow-y: auto;
}
.progrssSection_div {
    max-width: 500px;
    margin: 180px auto 0;
    text-align: center; 
    .progress_bar.MuiBox-root {
        margin: 0 auto;
    }               
}
 .dialog_paper_custom.MuiDialog-paper .MuiDialogContent-root.MuiDialogContent-dividers {
    overflow-y: visible;
    min-height: auto;
    height: 100%;
    min-height: 100vh;
} 
.buttonselectnumbertitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
span.MuiLinearProgress-root.MuiLinearProgress-colorPrimary.MuiLinearProgress-determinate {
    margin-bottom: 10px;
}
 

.Extractpdfnumbertitle  .dialog_paper_custom.MuiDialog-paper .MuiDataGrid-row  {
    height: 100px;
    max-height: initial !important;
    min-height: 150px !important;
    align-items: center;
}

.Extractpdfnumbertitle .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editable {
    max-height: 180px !important;
    height: 100%;
    min-height: 180px !important;
    img {
    border: 1px solid #ddd;
    padding: 10px;
    height: 123px;
    border-radius: 5px;
}
}
 


.dialog_paper_custom .MuiDataGrid-columnHeader:not(:first-child) ,.dialog_paper_custom .MuiDataGrid-cell--withRenderer:not(:first-child) {
    width: 100% !important;
    max-width: calc(100%/3) !important;
    min-width: auto !important;
}

.dialog_paper_custom .MuiDataGrid-row.MuiDataGrid-row--lastVisible,.dialog_paper_custom.dialog_paper_custom 
.MuiDataGrid-virtualScrollerRenderZone,.dialog_paper_custom .MuiDataGrid-columnHeadersInner {
    width: 100%;
}
.dialog_paper_custom .MuiDataGrid-cell:empty {
    display: none;
}
 
.dialog_paper_custom .MuiDataGrid-row:hover {
    background: none;
}
.dialog_paper_custom .MuiDataGrid-cell--withRenderer:focus,.dialog_paper_custom .MuiDataGrid-cell--withRenderer:focus-within ,.MuiDataGrid-columnHeader:where(:focus-within,:focus),.dialog_paper_custom .MuiDataGrid-cell:where(:focus-within,:focus),.dialog_paper_custom .main_template_div:where(:focus-within,:focus) {
    outline: none !important;box-shadow: none  !important;
}

.buttonselectnumbertitle {
    position: sticky;
    bottom: 10px;
}

.Template_main {
    background: #f1f1;
    padding: 30px 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.Template_main label {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
    font-family: var(--common-font);
    color: #0498dc;
    text-transform: uppercase;
    font-weight: 700;
}

.Template_main input[type="text"] {
    padding: 10px;
    max-width: 300px;
    width: 100%;
    border-radius: 5px;
    outline: none;
    border: 1px solid #ddd;
} 

.MuiDataGrid-virtualScrollerContent  .MuiDataGrid-row {
    padding: 0 4px;
    width: 100% !important;
}
.Extractpdfnumbertitle .MuiDataGrid-cellCheckbox.MuiDataGrid-cell--withRenderer {
    height: 100%;
    min-height: 180px!important;
    max-height: 100% !important;
}
.Mui-completed svg.MuiSvgIcon-root>path {
    color: var(--btn-blue);
}
.stepper_div .Mui-completed span.MuiStepLabel-labelContainer span {
    color: #1976d2;
    font-weight: 600;
}
.stepper_div .Mui-completed+div span.MuiStepConnector-line {
    background: var(--btn-blue);
}
.new_template_text_one {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0; 

    label {
        text-align: center;
        line-height: 35px;
        font-size: 14px;
        color: var(--btn-blue);
        text-transform: uppercase;
        font-weight: 600;
        margin-right: 2px;
    }
input[type="text"] {
    max-width: 200px;
    height: 35px;
    padding: 10px;
    border: 1px solid #ddd;
    margin-right: 4px;
    margin-left: 2px;
}
} 

.crop_pdf_container .progress_bar {
    margin: 0 auto;
    padding: 80px 20px;
    text-align: center;
}

.crop_pdf_container .progress_bar>div {
    margin-top: 20px;
}
 

.main-prog {
    height: 100%; 
}

textarea {
    border: 1px solid #ddd;
    padding: 10px;
    resize: none;
    min-height: 80px;
    font-family: var(--common-font);
}
.MuiDataGrid-cell--withRenderer img {
    border: 1px solid #ddd;
    padding: 10px;
}

.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-columnHeaderCheckbox {
    width: 100px !important;
    max-width: max-content !important;
    min-width: 67px !important;
}
.Finished {
    text-align: center;    margin-top: 110px;
}

/* .dialog_set_cls .MuiDialog-paperWidthLg{
min-width: 600px;
} */
.dialog_set_cls .dialog_body{
    margin: 0 auto;
    padding-bottom: 20px;
}
.dialog_set_cls .dialog_body .dialog_heading{
    font-weight: 400;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom:20px;
    color:#7a817a;
}
.dialog_set_cls .dialog_body h5{
    font-weight: 400;
    color:#7a817a;
}
.dialog_set_cls .dialog_body .dialog_body_radio_btns{
    display: flex;
    align-items: center;
}
.dialog_set_cls .dialog_body .dialog_body_radio_btns_upload{
    display: grid;
    align-items: center;
}
.dialog_set_cls .dialog_body .dialog_body_radio_btns_existing{
    display: grid;
    align-items: center;
}
.dialog_set_cls .dialog_body .dialog_body_radio_btns_upload h5{
    margin-left: 15px;
}
.dialog_set_cls .dialog_body .dialog_body_radio_btns h5{
    margin-left: 15px;
}
.dialog_set_cls .dialog_body .dialog_body_radio_btns_existing h5{
    margin-left: 15px;
    margin-right: 10px;
}


/* 08-7-2022 */ 
.dialog_set_cls {
        .MuiDialog-paperWidthLg {
        min-width: auto;
        max-width: 950px;
        width: 100%;
    }
    .dialog_body {
        padding: 10px 40px;
        h5.dialog_heading {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
    }
    .dialog_body_radio_btns_upload h5 {
        margin-top: 0;
        margin-bottom: 0;
    }   
    .MuiDataGrid-cell:empty {
        display: none !important;
    }

    }  
    .MuiDataGrid-row { 
   .MuiDataGrid-cell:nth-child(3) {
    width: 100% !important;
    max-width: 150px !important;
    gap: 10px;
}
}
} 


.MuiDataGrid-main .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerContent .MuiDataGrid-virtualScrollerRenderZone {
    width: 100%;
}
 
.main-wrapp-sidebar
{
    position: relative;
        li.MuiTreeItem-root.children {
            padding-right: 20px;
        }
        &>svg.MuiSvgIcon-root {
            position: absolute;
            right: 0px;
            top: 15px;
            transform: translateY(-50%);
            /*background: #f7f7f7;*/
            z-index: 9;
        }
        &>svg.hide-dot {
            visibility : hidden;
        }

}
.main-wrapp-sidebar:hover
{
    &>svg.hide-dot {
        visibility : visible;
    }

}

.main-wrapp-sidebar li.MuiTreeItem-root.children {
    padding-right: 0px;
    padding-left: 12px;
}

.MuiPopover-root{
    .MuiMenu-list {
    padding: 0;
    border-radius: 0;
   .MuiSvgIcon-root {
    fill: var(--btn-blue);
}

svg[data-testid="DeleteIcon"] {
    fill: #f00;
}
}
.main-wrapp-sidebar:focus>svg.MuiSvgIcon-root {
    background: #dce7f3;
}

.MuiMenu-list .MuiPaper-elevation.MuiPaper-rounded ul.MuiList-root {
    padding: 0;
    height: auto;
}
  li:not(:last-child) {border-bottom: 1px dotted #ddd;}
}
 /* 13-07-2022 */
 .MuiTreeItem-content{
    padding-left: 0;
 }
 ul.MuiList-root[role="menu"]>li>div {
    flex-wrap: wrap;
}
header .MuiContainer-root {
    max-width: 100vw;
}
.main-prog > div {
    flex: 100%;
}


.dialog_paper_custom.MuiDialog-paper .MuiDialogContent-root.MuiDialogContent-dividers > div {
    height: 100vh;
}

 

/* .dialog_paper_custom.MuiDialog-paper .MuiDialogContent-root.MuiDialogContent-dividers > div > div >div {
    height: auto !important;
} */



/* 13-07-2022 */
  /* .dialog_paper_custom.MuiDialog-paper .MuiDataGrid-virtualScroller {
    height: 550px !important;     overflow: visible !important;
} */
 
.dialog_paper_custom.MuiDialog-paper .MuiDataGrid-main { 
    min-height: 100vh;
} 
.dialog_paper_custom.MuiDialog-paper .LOader_club .MuiDialogContent-root.MuiDialogContent-dividers > div > div >div {
    min-height: 540px !important;
}   
.LOader_club .MuiBox-root {
    justify-content: center;
    align-items: center;     margin: auto;
}

.LOader_club {
    height: 100%;
    margin: auto 0;
    min-height: inherit;
    display: flex;
}

.version-num-dialog .version-num {
    min-width: auto;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1;
    background-color: #eeeeee;
    color: #3c3c3c;
}
.version-dash {
    min-width: auto;
    padding: 6px 8px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1;
}
.version-dialog-actions {
    display: flex;
    justify-content: space-between;
}
.ver-dg-filename {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.9rem;
}
.versions-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    min-height : 300px;
}
.versions-list li {
    border: 1px solid #eee;
}
.file-data {
    height: 64px;
    padding: 0.5rem;
}
.file-version {
    border-right: 1px solid #eee;
    line-height: 52px;
    font-weight: bold;
    text-align: center;
}
.file-desc span {
    font-weight: bold;
}
span.current-version {
    color: #1976d2;
    border: 1px solid #1976d2;
    border-radius: 3px;
    padding: 2px 8px;
    text-transform: uppercase;
    font-size: .87rem;
    letter-spacing: 1px;
}
.old-version {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.file-icon-btn {
    font-size: 1rem;
    color: #1976d2;
}
.file-icon-btn svg {
    font-size: 1.2rem;
}
.version-action-tooltip .MuiTooltip-tooltip {
    /* background-color: #000074; */
    color: #fff;
    font-weight: bold;
}
.version-action-tooltips .MuiTooltip-tooltip {
    /* background-color: #000074; */
    color: #fff;
    font-family: var(--common-font);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.14px;
}
.version-action-tooltip .MuiTooltip-arrow::before {
    /* background-color: #000074; */
}
.set-current-text {
    padding: 3rem 2rem;
}
.set-current-check {
    color: rgba(0, 0, 0, 0.6);
}

.btn-min-width {
    min-width: 130px;
}
.browser-warning-export {
    padding: 2rem 2rem;
}
.export-wrapper {
    padding: 2rem;
}
.font-bold {
    font-weight: 600;
}
.export-caption-text {
    margin-bottom: 1.5rem;
    span {
        float: right;
    }
}
.export-caption {
    position: relative;
    padding-left: 20px;
    &::before {
        position: absolute;
        content: 'i';
        height: 14px;
        width: 14px;
        left: 0;
        top: 50%;
        font-size: 12px;
        line-height: 14px;
        background: #4f7bff;
        text-align: center;
        color: #fff;
        font-weight: 600;
        border-radius: 50%;
        transform: translateY(-50%);
    }
}

.compare-files-header {
    background-color: #343434;
    &-close {
        color: #fff;
        text-transform: capitalize;
        border: 1px solid #fff;
    }
}
.compare-body {
    height: 100%;
    position: relative;
    &-file-names {
        position: absolute;
        left: 1rem;
        top: 7rem;
        color: #fff;
        background-color: #000;
        opacity: 0.5;
        padding: 8px;
        border-radius: 4px;
    }
}
.compare-draggable {
    background: #fff;
    border: 2px solid #ddd;
    border-radius: 3px;
}
.file-visibility-title {
    cursor: move;
    font-size: 1rem;
    background: #ddd;
    padding: 0.5rem;
    .close-comp-file-drag {
        float: right;
        padding: 0;
    }
}
.visibility-content {
    padding: 0;
    width: 370px;
}
.visibility-actions {
    border-top: 1px solid #ddd;
    &-buttons {
        padding: 0.5rem;
        background: #eee;
    }
    &-files {
        padding: 0.5rem;
    }
    .file-info {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        margin-bottom: 0.5rem;
        padding: 4px;
        &-name {
            display: block;
            font-weight: 600;
            font-size: 0.85rem;
            line-height: 2rem;
        }
        &-author {
            display: block;
            font-weight: 500;
            font-size: 0.7rem;
            color: #aaa;
            position: relative;
            .compare-visibility-icon {
                float: right;
            }
        }
        &-date {
            display: block;
            font-weight: 500;
            font-size: 0.8rem;
            color: #aaa;
        }
    }
    .red {
        border-left: 4px solid red;
    }
    .blue {
        border-left: 4px solid blue;
    }
}

.table-btn-group {
    border: 1px solid #ddd;
}

[aria-label="mailbox folders"] .MuiDrawer-paper{
    padding-top: 73px;
}
 
.name_main{
     .MuiBox-root {
    display: flex; 
    align-items: center;
}
}
.MuiBox-root.css-0 {
    display: flex;
}
.Main_Table{
 .MuiDataGrid-cell--withRenderer:nth-child(2),
  .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable:nth-child(2) {
    width: 220px !important;
    min-width: 220px !important;
    max-width: 100%;
}
  .MuiDataGrid-virtualScroller button.MuiButtonBase-root {
    margin-left: 0;
}
  .MuiDataGrid-cell--withRenderer:nth-child(2)>div {
    align-items: center;
}
  .MuiDataGrid-virtualScroller{overflow-x: auto !important;}
}
.left_side {
    svg {
    color: #333;
}
.grid_view,&>.MuiButtonBase-root {
    background: #ededed;
    padding: 10px;
    border-radius: 3px;
}
  .active {
    background: #0c2c55;
    border-color: #000;
    &:hover{
    background: #1976d2;
    }
}
 .active svg {
    fill: #fff;
}
}

.right_side{
label +.MuiInputBase-formControl .MuiOutlinedInput-input {
    height: 21px;
    padding: 8px 12px !important;
    line-height: normal;
    //color: #D8D8D8;
}
 .MuiFormControl-root {
label {line-height: normal;
    top: -7px;
    font-family: var(--common-font);
    font-size: 14px;
}
.MuiInputBase-formControl {
    border-radius: 2px;
}
} 
}
.tab_wrapper {
    border-bottom: 1px solid #EAEAEA;
}
.view-pdf-sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 33px;
    background: #ddd;
}

.versions-sidebar-Issue {
    width: 165px;
    padding-left: 10px;
    height: 100%;
    border-right: 1px solid #ddd;
    box-shadow: 0 0 20px #afafaf;
    &-header {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        &-title {
            font-size: 1.2rem;
            line-height: 1.2rem;
        }
        &-close {
            padding: 0;
        }
    }
    &-body {
        height: calc(100vh - 200px);
        overflow: auto;
    }
    &-footer {
        padding: 0.5rem;
        font-size: 1.2rem;
        font-weight: 300;
        text-align: center;
        border: 1px solid #1976d6;
        &:hover {
            background: #1976d2;
        }
    }
    &-filename {
        padding: 1rem;
        font-size: 0.95rem;
        line-height: 1.2rem;
        display: block;
    }
    &-filecard {
        padding: 1rem;
        &:hover {
            background: #ddd;
        }
        &.active {
            border: 1px solid #1976d2;
        }
        .datetime {
            font-weight: 600;
        }
        &-detail {
            display: block;
            padding-left: 0.2rem;
            &-row {
                display: block;
            }
        }
        &-title {
            font-weight: 400;
        }
        &-version {
            display: block;
            line-height: 1.7rem;
            .current {
                padding: 2px 4px;
                color: #1976d2;
                border-radius: 3px;
                border: 1px solid #1976d2;
                font-size: 0.6rem;
                line-height: 1.2rem;
                letter-spacing: 1px;
                float: right;
            }
            .status {
                padding: 0px 0.6rem;
                color: white;
                border-radius: 1rem;
                font-size: 0.8rem;
                font-weight: 600;
                line-height: 1.2rem;
                letter-spacing: 1px;
                float: right;
                text-transform: uppercase;
            }
        }
    }
    &-compare-div {
        padding: 1rem;
        text-align: right;
    }
    &-checkbox {
        padding: 0px 4px 0px 0px!important;
    }
}

.versions-sidebar {
    width: 370px;
    padding-left: 10px;
    height: 100%;
    border-right: 1px solid #ddd;
    box-shadow: 0 0 20px #afafaf;
    &-header {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        &-title {
            font-size: 1.2rem;
            line-height: 1.2rem;
        }
        &-close {
            padding: 0;
        }
    }
    &-body {
        height: calc(100vh - 200px);
        overflow: auto;
    }
    &-footer {
        padding: 0.5rem;
        font-size: 1.2rem;
        font-weight: 300;
        text-align: center;
        border: 1px solid #1976d6;
        &:hover {
            background: #1976d2;
        }
    }
    &-filename {
        padding: 1rem;
        font-size: 0.95rem;
        line-height: 1.2rem;
        display: block;
    }
    &-filecard {
        padding: 1rem;
        &:hover {
            background: #ddd;
        }
        &.active {
            border: 1px solid #1976d2;
        }
        .datetime {
            font-weight: 600;
        }
        &-detail {
            display: block;
            padding-left: 0.2rem;
            &-row {
                display: block;
            }
        }
        &-title {
            font-weight: 400;
        }
        &-version {
            display: block;
            line-height: 1.7rem;
            .current {
                padding: 2px 4px;
                color: #1976d2;
                border-radius: 3px;
                border: 1px solid #1976d2;
                font-size: 0.6rem;
                line-height: 1.2rem;
                letter-spacing: 1px;
                float: right;
            }
            .status {
                padding: 0px 0.6rem;
                color: white;
                border-radius: 1rem;
                font-size: 0.8rem;
                font-weight: 600;
                line-height: 1.2rem;
                letter-spacing: 1px;
                float: right;
                text-transform: uppercase;
            }
        }
    }
    &-compare-div {
        padding: 1rem;
        text-align: right;
    }
    &-checkbox {
        padding: 0px 4px 0px 0px!important;
    }
}
.not-current {
    font-size: 0.8rem;
    padding: 2px 18px;
    border: 1px solid #ffbd00;
    line-height: 1.9rem;
    border-radius: 20px;
    font-weight: 600;
    background: #f9f0d5;
    svg {
        font-size: 18px;
        display: inline-block;
        margin-right: 8px;
        margin-top: -4px;
        fill: #ffbd00;
    }
}

/* headerstyle */
 /* .MuiPaper-elevation0 {
    max-width: 300px;
    width: 100%;
  } */
   nav.MuiBox-root.css-1rzncui {
    z-index: 0;
  }
  .custommenu .MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 {
    max-width: 300px;
    width: 100%;
    top: 65px !important;
    padding-top: 30px;
    height: auto;
  }
  

  .profileMenu_pic {
    max-width: 100px;
    flex: 100%;
    width: 100%;
  }
  
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8
    ul
    li {
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8
    ul
    li:nth-child(2) {
  }

  .profile-pr {
    margin-top: 20px;
  }

  .profile-pr a {
    font-size: 16px;
    display: block;
  }
  /* button.MuiButton-root.MuiButton-text.MuiButton-textPrimary {  
    //margin-top: 30px;
    color: #bcc9d1;
    background: #eef1f8;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid #d3d8e4;
    white-space: nowrap;
    font-size: 13px;
    line-height: 1.53846;
    border-radius: 4px; 
    user-select: none;
    border-radius: 2px;
    height: 35px;
    font-size: 13px;
    padding: 8px 13px;   
  } */

 .custommenu .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8 {
    padding-top: 0;
    top: 30px !important;
    left: auto !important;
    right: 120px;
  }
  .profilemenu_main {
    display: flex;
    flex-wrap: wrap;
  }
  .profile_data {
    max-width: calc(100% - 100px);
    width: 100%;
    flex: 100%;
    span {
      font-size: 16px;
      display: block;
      font-weight: 700;
    }
  }
  .PSPDFKit-DocumentComparison-Magnifier {
  display: none !important;
}

.upload-rogress {
    position: fixed;
    z-index: 99;
    right: 16px;
    bottom: 4px;
    width: 400px;
    border: 1px solid #9b9b9b;
    border-radius: 4px;
    background: #fff;
    font-family: Poppins;
    &-header {
        padding: 0.5rem;
        background: #ddd;
        &-title {
            font-weight: 600;
        }
        &-actions {
            float: right;
            .btn {
                padding: 0;
            }
        }
    }
    &-body {
        &-item {
            padding: 0.5rem;
            .file-name {
                font-size: 0.85rem;
                display: block;
            }
            .file-process {
                font-size: 0.8rem;
                color: #525252;
                span {
                    margin: 0 8px 0 0;
                }
            }
        }
        &-item + &-item {
            border-top: 1px solid #ddd;
        }
    }
}
.share-menu-item {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;

    &-icon {
        margin-right: 1.5rem;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #dbeef9;
        line-height: 30px;
        text-align: center;
    }
    &-text {
        font-size: 1rem;
        span {
            display: block;
            font-size: 0.85rem;
            color: #444;
        }
    }
}
.share-doc {
    &-link-expiry {
        display: block;
        margin: 1rem 0;
        color: #444;
    }
    &-link-date {
        color: #000;
    }
}
.share-input-value {
    margin-bottom: 8px;
}
.share-input-copy {
    width: 80%;
    margin-right: 1rem;
    height: 36px;
}
.share-copy {
    &-meta {
        color: #777;
        font-size: 0.9rem;
    }
}
.txt-btn {
    text-transform: none; 
}
.share-file-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.9rem;
}
.share-version {
    border: none;
}
.datatable-txt-btn {
    text-transform: capitalize !important;
    color: inherit !important;
    font-size: inherit !important;
}
.datatable-txt-btn:hover {
    background-color: transparent !important;
}
/* button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.Mui-disabled {
    background: #ccc;
    color: #000;
    margin-top: 15px;
} */
.steps_name .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel{
top: 5px;
    left: calc(-50% + 15px);
    right: calc(50% + 15px);
}

.sf-detail-container .MuiFormControl-root {
    max-width: 100%; 
}
 
.model_subscribe_paperClass {
    overflow: unset;
    max-width: 800px; 
    .MuiDialogContent-dividers{
        min-height: 300px !important;
    }
    .MuiDialogActions-root{
        display: flex;
        gap: 10px;
        button {
            margin-top: 0 !important;
        }
        .submitionButton{
            background-color: #1976d2 !important;
            color: #fff !important;
        }
        
    }
    
}
 .subscribe_main {
    .MuiDialog-paperScrollPaper {
      max-width: 800px;
      width: 100%;
      h2 {
    border-bottom: 1px solid #ddd;
}
 h2 + div{
    padding: 40px;
 }
.MuiDialogActions-root {
    border-top: 1px solid #ddd;
    padding: 10px 20px 20px;
}
span.MuiButtonBase-root.MuiRadio-root {
    padding: 4px 10px;
} 
     /* button.MuiButton-root.MuiButton-text.MuiButton-textPrimary{
            padding: 10px;
            background: none;
            border-radius: 0;
      }
         button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.submitionButton {
            background: var(--btn-blue);
            padding: 10px 30px;
            color: #fff;
        }
       */
 label#suscribe_row_radio {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}
    } 
  }
  .react_table_new {
  	/* 20 Feb 2023 */
    /* margin-top: 40px; */
    /* border: 1px solid #ddd; */
    overflow: hidden;
  }
  
  .model_imgpdf_paperClass {
        overflow: hidden !important;
    }
    
       main.MuiBox-root {
          //  overflow-y: auto;
        }

/* remove */

.MuiPaper-root.MuiPaper-elevation::-webkit-scrollbar {
  display: none;
} 

/* remove */

/* 04-09-2022 */
 .stepper_div.MuiBox-root .stepperClass {
    max-width: 480px;
    margin: 0 auto;
  }

  .publichButton_Container {
    position: absolute;
    right: 30px;
    top: 0;
    gap: 20px;
  }
  .view_detail_section {
    max-width: 1430px;
    margin-top: 10px;
    min-width: 70vw;
    width: 100%;
    .MuiDialogContent-root  .MuiBox-root {
    flex-wrap: wrap;
    justify-content: center; 
}
.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-cell--withRenderer {
    padding: 20px 10px;
    //min-height: 200px !important;
    max-height: 200px !important;
    align-items: flex-start;
}
input[type="text"], textarea { 
    outline: none !important;
    box-shadow: none;
    width: 100% !important;
    max-width: 100% !important;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
}
  textarea {
    min-height: 80px;
    max-width: calc(100% -  20px) !important; 
    padding: 8px;
}
.MuiDataGrid-columnHeaderTitle {
    font-size: 16px;
    font-weight: 600;
}
 .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-cell--withRenderer{
    &:active{
        outline: none
    }
    &>div, .MuiTextField-root {
    width: 100%;
    max-width: 100%;
}
}
h2#alert-dialog-title+.MuiDialogContent-root {
    background: #f7f9fa;
}
.first_Section {
    background: #fff;
    padding: 0px 10px;
    margin: 10px 0;
    text-align: right;
    margin-bottom: 20px;
}   
 .first_Section h3 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .first_Section, .second_Section {
    width: 100%;
}
.folderNameSection {
    display: flex; 
    align-items: center;
    gap: 10px;
}
section.left_section {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    .saveButton button {
    margin-top: 0;
    /* margin-bottom: 10px; */
    background: var(--btn-blue);
    color: #fff;
}
}
.publichButton_Container button.MuiButtonBase-root:last-child {
    background: var(--btn-blue);
    color: #fff;
}
.second_Section {
    margin-bottom: 15px;
}
  }
 
  .MuiAutocomplete-paper{
    .MuiAutocomplete-listbox {
    max-height: none;font-size: 14px;
    span.MuiCheckbox-root.MuiCheckbox-colorPrimary {
    padding: 0; 
}
.MuiListSubheader-root.MuiListSubheader-gutters.MuiListSubheader-sticky {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #edf0f2;
    font-weight: 500;
    padding: 4px 10px;
    font-size: 13px;
    line-height: normal;
}
  }
  }
  .pagination * {
    color: #819099;
    font-weight: 500;
}
 
  div#github-label{
    box-shadow: 0 8px 24px rgb(149 157 165 / 20%);
    border-radius: 0;
    border:  none;
  .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon{
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    padding: 0px;
    border-radius: 0;
    width: 100%;
    max-width: 100%;
    border: none;
  }

  .MuiFormControl-root {
    width: 100%;
    border-radius: 0;
  }

  .MuiAutocomplete-input {
    border: none;
    fieldset.MuiOutlinedInput-notchedOutline {
      border: none;
      border-radius: 0;
      input {
        border-radius: 0;
      }
    }
  }
}
.MuiAutocomplete-paper .MuiAutocomplete-listbox{
    padding-top: 0;
}
}
.model_SelectDocument_popup_paperClass {
    width: 100%;
    max-width: 800px;height: 500px;
    overflow: visible !important;
 }

    .dialog_paper_custom.model_issueCreated_popup_paperClass {

 h2 {
      background: #ededed;
      padding: 20px 25px;
      color: #819099;
    }
    width: 100%;
    max-width: 800px;
    .MuiDialogContent-root.MuiDialogContent-dividers {  
      height: unset;
      min-height: unset;
      overflow: auto;
    }
    .issuesAction_section {
    justify-content: space-between;
    width: 100%;
}
.checkBox_section {
    align-items: center;
}
 
  }
  .model_SelectDocument_popup_paperClass{
    .buttons_section_select_document {
        gap: 10px;
    button.MuiButtonBase-root {
      margin: 0;
      background: #fff;
      color: #819099;
      border: none;
      text-transform: capitalize;
      &:last-child {
        background-color: #0696d7;
        border-color: #0696d7;
        color: #fff;
      }
    }
  }
  }
  .issuesAction_section{
 
  .buttons_section {
    button.MuiButtonBase-root {
      margin: 0;
      background: #fff;
      color: #819099;
      border: none;
      text-transform: capitalize;
      &:last-child {
      background-color: #0696d7;
      border-color: #0696d7;
      color: #fff;
    }
    } 
  }
}
 .dialog_paper_custom.model_issueDetail_popup_paperClass {
    max-width: 700px;
    overflow: auto;
    @media (max-width: 1600px){
        .MuiDialogContent-root.MuiDialogContent-dividers {  
            max-height: inherit;
            overflow-y: auto;
            min-height: 380px;
        }
    }

    h2{
        background-color: orange !important; 
    }
 h3.subHeading {
    width: 100%;
    color: #fff;
    font-size: 13px;
}

 .IssueDetail_class {
    padding: 0 !important;
 }
 button.MuiButtonBase-root { 
    svg{
        fill: #fff;
    }
}
.MuiDialogContent-root.MuiDialogContent-dividers 
 :where(button.MuiButtonBase-root.MuiIconButton-root.right_icon,button.MuiButtonBase-root.MuiIconButton-root.left_icon) {
    position: absolute; left: calc(100% + 20px);right: auto; 
    top: 50%;
    transform: translateY(50%);
    color: "black";
}
  .MuiDialogContent-root.MuiDialogContent-dividers button.MuiButtonBase-root.MuiIconButton-root.right_icon{
       right: calc(100% + 20px);
    left: auto;
}

}
 .dialog_paper_custom.model_SelectDocument_popup_paperClass.MuiDialog-paperScrollPaper  .MuiDialogContent-root.MuiDialogContent-dividers {
    overflow-y: visible;
    min-height: auto;
    height: 340px;
    /* min-height: 100vh; */
}

.pspdf-floating-container {
    position: absolute ;
    top: 12.5rem;
    left : 45%;
    background: #fff;
    display:flex;
    border: 2px solid #ddd;
    border-radius: 3px;
    padding: 2px;
}
.pspdf-floating-header-move {
    cursor: grab;
    font-size: 1rem;
    padding: 0.2rem;
    &:active{
        cursor: grabbing;
    }
}
.pspdf-floating-button-container {
    display:flex;
    &-item {
        margin-right: 0.03rem;
        margin-left: 0.03rem;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0.05rem;
        }
    }
}
.showpdf-content-body.css-ypiqx9-MuiDialogContent-root {
    padding: 2px 24px;
}
/* .MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root{
    padding-top: 24px !important;
} */
.inlineAutocomplete .MuiOutlinedInput-root {
    padding: 2px !important;
}

.model_template_type_selection_paperClass {
    height: 270px;
    width: 70%;
    .template_type_selection_wrapper {
        padding: 4em 2em;
    }
}
.checklist_form_wrapper {
    overflow-y: auto;
    max-height: calc(100vh - 115px);
    padding: 1em;
    .btn_checklist {
        text-transform: capitalize;
        + .btn_checklist {
            margin-left: 1em;
        }
    }
    .MuiFormControl-root {
        max-width: unset;
    }
    .desc_input {
        width: 100%;
    }

    .checklist_questions_wrapper {
        margin-top: 1em;
        .question_card {
            + .question_card {
                margin-top: 1em;
            }
            border: 1px solid #ddd;
            border-radius: 3px;
            .pad-8 {
                padding: 1em;
            }
            &_header {
                border-bottom: 1px solid #ddd;
            }
            &_desc {
                background: #fff;
                border-bottom: 1px solid #ddd;
            }
            &_section {
                background: #fff;
                border-bottom: 1px solid #ddd;

                &_add_new {
                    background: #fff;
                }

                &_fields {
                    :nth-child(even) {
                        background: #eee;
                    }
                    :nth-child(odd) {
                        background: #fff;
                    }

                    ul.response_types_dd li:nth-child(2) {
                        margin-bottom: 0px;
                        padding-bottom: 0px;
                    }
                    ul.response_types_dd li:not(:last-child) {
                        border-bottom: none;
                    }
                }
            }
        }
        .question_desc {
            margin-bottom: 1em;
        }
    }
    .add_new_question {
        padding: 1em;
    }

    .preconfigured_btns {
        + .preconfigured_btns {
            margin-left: 1em;
        }
    }
}
.response_type_renderer-wrapper {
    .dropdown_response_answers {
        list-style: decimal;
        li {
            margin-bottom: 1em;
            margin-left: 1.2em;
        }
    }
    .add_more_option_btn {
        border: none!important;
        background: transparent!important;
        color: #1976d2!important;
        text-transform: capitalize;
        font-size: 0.95em!important;
    }
}
.checklist-form-render {
    height: calc(99vh - 200px);
    overflow: auto;
    &.pad-8 {
        padding: 1em;
    }
    .section-heading {
        &-icon {
            color: #888;
            margin-right: 1em;
        }
    }
    .section-questions {
        padding: 1em 0 1em 0;
        &-icon {
            color: #888;
            margin-right: 1em;
        }
        &-blank-icon {
            min-width: 3.5em;
            height: auto;
            display: inline-block;
        }
    }

    .answer-renderer-wrapper {
        padding-left: 3em;
        margin-bottom: 2em;
    }
    .margin-top-8 {
        margin-top: 1em;
    }
}

.hide-menu-dot
{
    &>button.menu_open_button {
        visibility : hidden;
    }

}
.hide-menu-dot:hover
{
    &>button.menu_open_button {
        visibility : visible;
    }

}
    .custom-alert {
        margin: 10px 0px;
        padding:12px;
        border-radius: 5px;
        font-size: .9rem;
        font-weight: 300;
        letter-spacing: 1px;
    }

    .custom-alert:hover {
        cursor: pointer;
    }

    .custom-alert:before {
        padding-right: 12px;
    }
    
    .custom-alert:after {
    float: right;
    padding: 3px;
        &:hover {
            cursor: pointer;
        }
    }

    .custom-alert-info {
    color: #00529B;
    background-color: #BDE5F8;
    border: 1px solid darken(#BDE5F8, 15%);
    }
 

    .custom-alert-warn {
        color: #9F6000;
        background-color: #FEEFB3;
        border: 1px solid darken(#FEEFB3, 15%);
    }

    .custom-alert-warn:before {
        content: '\f071';
    }

    .custom-alert-error {
        color: #D8000C;
        background-color: #FFBABA;
        border: 1px solid darken(#FFBABA, 15%);
    }

    .custom-alert-error:before {
        content: '\f057';
    }

    .custom-alert-success {
        color: #4F8A10;
        background-color: #DFF2BF;
        border: 1px solid darken(#DFF2BF, 15%);
    }

    .custom-alert-success:before {
        content: '\f058';
    }
    /* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        padding : 10px 9px !important;
    } */
    .css-16awh2u-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input{
        width:unset !important;
    }
    .dialog-header-close {
        position: absolute !important;  
        right: 8px;
        top: 8px;
    }

    .containert {
        display: flex;
        flex-direction: column;
    }
    
    .bodyt {
        flex-grow: 1;
        overflow-y: auto;
        max-height: calc(100vh - 500px);
    }
    
    .footert {
        flex-shrink: 0;
    }
    .toggle_section {
    display: none;
}
.d-flex {
    display: flex;
}
html,body{
    height: 100%;
}
main.MuiBox-root {
    height: 100vh;
}
.MuiInputBase-root div{
    font-family: var(--common-font);
}
.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root{
    font-family: var(--common-font) !important;
}

.MuiFormControl-root .MuiInputBase-input {
    font-weight: 400;
    font-size: 14px;
    font-family: var(--common-font);
}
li.MuiListSubheader-root {
    font-weight: 600;
    font-family: var(--common-font);
    text-transform: uppercase;
    color: var(--drgray);
    padding: 0 7px;
}
.pagination {
    justify-content: space-between;
    margin-top: 20px;
    .left_sec,.right_sec {
    display: flex;
    gap: 10px;
    align-items: center;
    button {
    background: none;
    border: none;
}
button[disabled] {
    color: #BABABA;
}
}
.left_sec{
    color: #505050;
    select {
    height: 30px;
    border-color: #D8D8D8;
}
}
.holder { 
    color: var(--drgray);
    display: flex;
    gap: 10px;
    align-items: center; 
    span.active_page {
    display: inline-grid;
    width: 30px;
    height: 30px;
    border: 1px solid var(--drgray);
    place-content: center;
    border-radius: 2px;
} 
}
}
.MuiDayPicker-monthContainer,.MuiPopover-root li {
    font-family: var(--common-font);
  }
  .MuiPopover-root li{
    font-size: 14px;margin:0
  }
  .dropdown-list {
    align-items: flex-start;
    background-color: var(--neutralswhite); 
    box-shadow: 0px 4px 12px #00000040;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 241px;
    }
.text-with-icon-1 {
        &:hover{
            background-color: #F8F8F8;
        }
        cursor: pointer;
    align-items: center; 
    display: inline-flex;
    flex: 0 0 auto; 
    gap: 8px;
    position: relative;
    width: 100%;
    height: 30px;
    padding: 0px 12px;
    }

    .share,
    .pencil,
    .download-source,
    .submit-for-review { 
    height: 16px;
    position: relative;
    width: 16px;
    }
    .label-1,
    .label-2,
    .label-3,
    .submit-for-review-1 {
    letter-spacing: 0; 
    line-height: normal;
    margin-top: -1px; 
    position: relative; 
    width: fit-content;
    color: #505050;
}
.MuiModal-root { 
    top: 7px;
    left: 50px;
}
.version_head {
    font-family: var(--common-font), Helvetica;
    font-size: 12px;
   font-weight: 600;
    letter-spacing: 0.12px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}
.version_name {
    padding: 5px 0px 5px 0px
    color: #505050;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.12px;
    line-height: normal;
    position: relative;
    width: fit-content;
}
.version_date {
    color: #929292;
    font-family: "Poppins-Regular", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.12px;
    line-height: normal;
    position: relative;
    width: fit-content;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.version_menu_props_class{
    ul li:nth-child(2){
        margin-bottom: 0;
        padding-bottom: 0;
    }
}
.ant-picker-dropdown{
    z-index: 1300;
}
.drawer_closed{
    width: 0px;
}
.title_link{
    color: black;
}
.drawer_Open.MuiDrawer-docked.MuiDrawer-root {
    width: 400px;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 16px ;
    margin-left: 65px;
    margin-top: 55px;
    font-family: var(--common-font);
    .MuiBackdrop-root {
        background-color: rgba(255, 255, 255, 0);
    }
    .MuiButtonBase-root.MuiIconButton-root{
        &:hover {
            border-radius: 4px;
        }
    }
    .action_button {
    justify-content: flex-end !important;
        gap: 8px !important;
        border-top: 2px solid rgba(234, 234, 234, 1) !important;
    bottom: 70px;
    width: 400px;
    position: absolute;
    padding-right: 10px;
    padding-top: 10px;
    .Mui-disabled {
      background: rgba(255, 255, 255, 1) !important;
      color: #50505070 !important;
      border: 2px solid var(--border-gray) !important;
        }
   }
    .MuiDrawer-paper{
        width: 400px;
        box-shadow: none;
        margin-left: 65px;
        margin-top: 57px;
        border-right: none;
        border-left: 1px solid rgba(0, 0, 0, 0.12);
        z-index:90;
    }
      .markups_head {
        padding: 0px 18px 8px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        border-bottom: 1px solid #EAEAEA;
        .filter_icons{
            display: "flex";
            gap:8px;
        }
        .filter_title{
            margin-left: 0px;
            display: "flex";
            gap:6px;
            img{
                height:21px;
                width:10px
            }
        }
        .markup_title{
        font-family: var(--common-font);
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.01em;
        text-align: left;
        }
      }
      .form_group{
        
        padding: 0px 20px;
        margin-bottom: 10px;
        .ant-picker.ant-picker-range.ant-picker-large{
            width: 100%;
            border-radius: 4px;
            border-color: #D8D8D8;
            &:hover {
                background: #F8F8F8;
                border-color: #D8D8D8;
              }
        }
        .MuiOutlinedInput-input {
            color: #929292;
          }
          .MuiOutlinedInput-input:hover {
            border-color: #D8D8D8;
            background-color: #F8F8F8;
            color: #929292;
          }
          .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
            border-color: #D8D8D8;
          }
          .MuiOutlinedInput-notchedOutline {
            border-color: #D8D8D8;
          }
          
          .MuiFormControl-root {
            label:not(.Mui-focused) {
              margin: -8px 0 0;
              /* top: -9px; */
            }
            
          }
        }
        .MuiSelect-select {
          padding: 8px 12px;
          font-size: 14px;
         // background: #eaeaea;
          color: #505050;
          border-color: #bababa;
          em {
            font-family: var(--common-font);
            font-style: normal;
          }
        }
        .select_cstm {
          border: 1px solid #bababa;
          .MuiOutlinedInput-notchedOutline {
            border: none;
          }
        }
        .Mui-focused.select_cstm {
          border: 1px solid var(--drblue);
        }
        label{
            color:#505050;
            font-family: var(--common-font);
            margin-bottom: 5px;
            font-size: 14px;
        }
        .created_by{
            width: 100%;
            height: 37px;
        }
      }
      .public_markup{
        padding: 0px 18px 20px;
        font-family: var(--common-font);
      }
      .private_markup{
        padding: 10px 18px;
        font-family: var(--common-font);
      }
      .mouseHover {
        &:hover { 
            background-color: #F8F8F8;
        }
      }
      .markup_list{
        .MuiListItem-root{
            &:hover {
                background: #F8F8F8;
              }
            padding-right: 0px;
            padding-left: 25px;
        }
            ul.MuiList-root{
                li.MuiListItem-root.issue_list{
                padding-right: 35px;
                    display: block;
                    border-bottom: 1px solid #EAEAEA;
                    .issue_status{
                        margin-bottom:6px;
                    }
                    .status{
                        padding: 1px 4px 1px 4px;
                        border-radius: 2px;
                        font-size: 11px;
                    }
                    .status.Draft{
                        background: #AFD9FF;
                    }
                    .status.Pending{
                        background: #FFAEAE;
                    }
                    .status.Answered{
                        background: #CFB1FF;
                    }
                    .status.Closed{
                        background: #91D494;
                    }
                    .status.Amend.Resolution{
                        background: #FFAA2B;
                    }
                    .status.Void{
                        background: #c0c0c0;
                    }
                    .MuiListItemText-root{
                        p.MuiListItemText-secondary{
                            color: #505050;
                        }
                    }
                    .assigned_date{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 6px;
                        img{
                            margin-right: 6px;
                        }
                        span.username{
                            font-size: 12px;
                            color: #505050;
                        }
                    }
                }
                li.MuiListItem-root.checkbox_selected{
                        background: #E8EFFF;
                    }
                li.MuiListItem-root{
                    img{
                        margin-right: 20px;
                    }
                    .MuiListItemText-primary{
                        font-family: var(--common-font);
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 18px;
                        letter-spacing: 0.01em;
                        text-align: left;
                        color: #000000;
                    }
                    .MuiListItemText-secondary{
                        font-family: var(--common-font);
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0.01em;
                        text-align: left;
                    }
                    button{
                        img{
                            margin-left: 20px;
                        }
                    }
                }
            }
            ul.MuiList-root.issue_main_ul{
            height: calc(100vh - 216px);
            }
        }
        .markup_list_off {
        .MuiListItem-root{
            opacity: 0.5;
            &:hover {
                background: #F8F8F8;
              }
            padding-right: 0px;
            padding-left: 25px;
        }
            ul.MuiList-root{
                li.MuiListItem-root.checkbox_selected{
                        background: #E8EFFF;
                    }
            li.MuiListItem-root{
                    img{
                        margin-right: 20px;
                    }
             .MuiListItemText-primary{
                        font-family: var(--common-font);
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 18px;
                        letter-spacing: 0.01em;
                        text-align: left;
                        color: #000000;
                    }
                    .MuiListItemText-secondary{
                        font-family: var(--common-font);
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0.01em;
                        text-align: left;
                        color: #505050;
                    }
                    button{
                        img{
                            margin-left: 20px;
                        }
                    }
                }
                }
            }
      .markup_list.markup_list_off{
        ul{
            color:#929292;
        }
      }
      .textfff{
        &:hover {
            background: #F8F8F8;
            border-color: red;
          }
          .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
            border-color: #D8D8D8;
          }
          .MuiOutlinedInput-notchedOutline {
            border-color: #D8D8D8;
          }
          .MuiOutlinedInput-notchedOutline.Mui-focused {
            border-color: #D8D8D8;
        }
          .Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                border-color: #D8D8D8;
            }
          }
      }
      .markups_subhead {
        .ellipse {
            background-color: #346EF5;
            border: 1px solid;
            border-color: #FFFFFF;
            border-radius: 4px;
            height: 8px;
            left: 18px;
            position: absolute;
            top: 9px;
            width: 8px;
          }
        padding: 1px 18px 5px 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #EAEAEA;
      }
    }
    .Searchbar-field-red {
        .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
            border-color: red;
            }
        &:hover{ 
            .MuiInputBase-root{ 
             background-color: #f8f8f8; }
            
            .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
              border-color: red;}
        
        }
        .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            color: #505050;
            border-color: red;
          
          }
    }
    ::-webkit-scrollbar {
       // width: 6px;
      //  background-color: #F8F8F8;
    }
    ::-webkit-scrollbar-thumb {
    //    background-color: #00000080;
      //  border-radius: 4px;
    }
    .outer-div {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
      }

span.MuiCheckbox-root.MuiCheckbox-colorPrimary {
    color : #D8D8D8;
}
span.MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-checked {
    color : #346EF5;
}      
span.MuiCheckbox-root.MuiCheckbox-colorPrimary:hover {
    background : #F8F8F8;
}
.text-style-for-div {
    color: #505050;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.publish-logs {
    align-items: flex-start; 
    display: inline-flex; 
    flex-direction:
    column; 
    gap: 8px;
    position: relative;
    }
    .text-and-kpi {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto; 
    gap: 6px;
    justify-content: center; 
    position: relative;
    }
    .label {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px; 
    position: relative; 
    width: fit-content;
    }
    .kpi {
    align-items: center;
    background-color: #F92828; 
    border-radius: 16px;
    display: inline-flex;
    flex: 0 auto;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    padding: 3px 6px; position: relative;
    }
    .x1 {
    letter-spacing: 0.1px;
    line-height: 10px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap; 
    width: fit-content;
    color: #ffff
    }
    .dropable-zone{
        border: 2px solid #2563EB;
    }
    .dropable-zone-table{
        table{
            height : 100%;
        }
        tbody{
            height : 100%;
            pointer-events: none;
            border: 2px solid #2563EB;
            background-color: #E8EFFF !important;
            tr{
                background-color: #E8EFFF ;
                pointer-events: none;
                &:hover{
                    background-color: #E8EFFF !important;
                }
            }
        }
    }
.toast-message-container {
    display: flex;
    bottom: 40px;
    width: 100%;
    justify-content: center;

    position: fixed;
    z-index: 9990;
    left: 0;
    bottom: 40px;
    animation: slideIn 0.2s ease-out forwards;
}
.toast-message {
    display: flex;
    min-width: 550px;
    border-radius: 4px;
    background-color: #EBFFEC;
    color: #0AA110;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid rgb(10, 161, 16);
}  

@keyframes slideIn {
    from {
        bottom: 0px;
    }
    to {
        bottom: 40px;
    }
}
@keyframes rotate360 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  h2.dialog_title.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root {
    color: #000000;
    font-size: 16px;
    background: #fff;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.16px;
    border-bottom: 1px solid rgba(234, 234, 234, 1);
    font-family: var(--common-font);
  }
  .action_button {
    display:flex;
    padding: 12px 20px;
    justify-content: flex-end;
    gap: 8px !important;
    border-top: 2px solid rgba(234, 234, 234, 1);
      .save_settings {
        text-transform: none;
        background: #346ef5;
        // padding: 9px 16px 9px 16px;
        box-shadow: none;
        border-radius: 2px;
        color: rgba(255, 255, 255, 1);
        font-family: var(--common-font);
        font-weight: 400;
        height: 39px;
        width: 85px;
        &:hover {
            background: #346ef5;
          }
      }
      button.MuiButtonBase-root.Mui-disabled {
        background: var(--disable);
        color: white;
    }
  }
  .issue_label_name {
    color: #505050;
    font-family: var(--common-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 4px;
    padding-top: 16px;
  }
  .children-list{
    height: 250px;
    overflow: auto;
    border-top: 2px solid rgba(234, 234, 234, 1);
    border-bottom: 2px solid rgba(234, 234, 234, 1);
    background-color: #F8F8F8;
  }
  .child-item {
    font-family: var(--common-font);
    display:flex;
    gap: 10px;
    position: relative;
    cursor: pointer;
    height: 40px;
    padding: 8px 25px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
  }
  .child-item:hover {
    color:#000;
    font-weight: 600;
    background-color: #f0f0f0;
  }
  .issuedropdown{
    &:hover{ 
        fieldset{
         border: 1px solid #d8d8d8 !important;
       }
       background-color: #f8f8f8;
   }
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #929292 !important;
   }
   .text_no-wrap {
       overflow: hidden;
       white-space: nowrap;
       text-overflow: ellipsis;
   }
`;

export default GlobalStyled;
