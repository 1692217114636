import React from "react";
import { Grid, TextField , Stack } from "@mui/material";
import SingleSelectRender from './SingleSelectRender';
import MultiSelectRender from './MultiSelectRender';
import DropdownRender from './DropdownRender';
import SignatureRender from './SignatureRender';
import NotesAndAttachment from './NotesAndAttachment';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import moment from 'moment';

const AnswersRender = (props) => {
  const { question ,formId , name, responseOptions,dispatchObj,sectionInd,questionInd , ansObj ,defaultValue , checklistFormobj} = props;
  const [fValue, setFValue] = React.useState(defaultValue);
  const handleChange = e => {
    let fieldKey = e.target.name;
    let value = e.target.value;
    dispatchObj({
      type: 'HANDLE_CHANGE_ANS_FIELD',
      sectionIndex : sectionInd,
      questionIndex : questionInd,
      fieldKey: fieldKey ? fieldKey : 'Response__c',
      value: value
    });
  };
  return (
    <Grid item xs={12} className="answer-renderer-wrapper">
      {(question.Response_Type__c === "Text" || question.Response_Type__c === "Number") && (
        <TextField name={name} id={question.Id + sectionInd + questionInd} type={question.Response_Type__c === "Number" ? "number" : "text"}
          variant="outlined" size="small" className="margin-top-8" value={fValue}
          onChange={(e) => {
            if(checklistFormobj.Status__c !== "Completed"){
            setFValue(e?.target?.value);}
          }}
          onBlur={(e) => {
            if(checklistFormobj.Status__c !== "Completed"){
            //console.log('e?.target?.value :: '+e?.target?.value);
            handleChange({target : {name: 'Response__c' , value: e?.target?.value}});
            }
          }} required={!question.Optional__c}
          error={!(defaultValue && defaultValue !== '') && !question.Optional__c}
          helperText={ (question.Optional__c ||( fValue && fValue !== '')) ? undefined : "* Field is required" }/>
      )}
      {question.Response_Type__c === 'Date' &&
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3} className="margin-top-8">
            <DesktopDatePicker
              className="common_class"
              disablePast={false}
              placeholder="Date"
              inputFormat="MM/dd/yyyy"
              value={(defaultValue ? new Date(defaultValue) : null)}
              label="Date"
              onChange={(newValue) => {
                if(checklistFormobj.Status__c !== "Completed"){
                let vvvv = null;
                if (newValue) {
                  vvvv = moment(newValue).format("YYYY-MM-DD");
                }
                //defaultValue = vvvv;
                //console.log('vvvv :: '+vvvv);
                if(vvvv == null || vvvv == ''){
                  handleChange({target : {name: 'Response__c' , value: vvvv}});
                }
               } }}
              onAccept={(newValue) => {
                if(checklistFormobj.Status__c !== "Completed"){
                let vvvv = null;
                if (newValue) {
                  vvvv = moment(newValue).format("YYYY-MM-DD");
                }
                //defaultValue = vvvv;
                //console.log('vvvv :: '+vvvv);
                handleChange({target : {name: 'Response__c' , value: vvvv}});
               } }}
              renderInput={(params) => 
                <div>
                  <TextField id={question.Id +"dateInput"} variant="outlined" label="Date" required={!question.Optional__c} {...params} 
                    error={!(defaultValue && defaultValue !== '') && !question.Optional__c}
                    helperText={ (question.Optional__c ||( defaultValue && defaultValue !== '')) ? undefined : "* Date is required" } />
                </div>
              }
            />
          </Stack>
        </LocalizationProvider>
      }
      {(question.Response_Type__c === 'Single Select' || question.Response_Type__c === 'Preconfigured') && <SingleSelectRender responseOptions={responseOptions} type={question.Response_Type__c} 
        req={!question.Optional__c} handleChange={handleChange} defaultValue={defaultValue} checklistFormobj={checklistFormobj}/>}
      {question.Response_Type__c === 'Multi-Select' && <MultiSelectRender responseOptions={responseOptions} 
        req={!question.Optional__c} handleChange={handleChange} defaultValue={defaultValue} checklistFormobj={checklistFormobj}/>}
      {question.Response_Type__c === 'Dropdown' && <DropdownRender responseOptions={responseOptions} 
        req={!question.Optional__c} handleChange={handleChange} defaultValue={defaultValue} checklistFormobj={checklistFormobj}/>}
      {question.Response_Type__c === 'Signature' && <SignatureRender responseOptions={responseOptions} 
        req={!question.Optional__c} ansObj={ansObj} handleChange={handleChange} defaultValue={defaultValue} checklistFormobj={checklistFormobj}/>}
      { true && <NotesAndAttachment formId={formId} credential={props?.credential} ansObj={ansObj} handleChange={handleChange} checklistFormobj={checklistFormobj}/>}
    </Grid>
  );
};

export default AnswersRender;
