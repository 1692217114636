import React, { useEffect, useState , useRef} from "react";
import { useSelector } from "react-redux";
import WebViewer from '@pdftron/webviewer';
import ZoomButtons from "components/ZoomButtons";
import { APRYSE_L_KEY } from "api";

const ShowFile = () => {
  const { canvasData = {} } = useSelector((state) => state.canvasData);
  const [urls, setUrls] = useState([]);
  useEffect(() => {
    //console.log('canvasData' , canvasData);
    if(canvasData?.action && canvasData?.urls?.length >0){
      setUrls(canvasData?.urls);
    }
  }, [canvasData]);
  return (
    <>
      <>{ `Total File : `+urls?.length }</>
      {urls?.length > 0 &&
        <>
          {urls.map((file, index) => {
            return (
              <div key={file.url} >
                {canvasData.isAndroid &&
                  <PreviewFile file={file}/>
                }
                {!canvasData.isAndroid &&
                  <>
                    <iframe src={file.url} width="100%" height="600" title={index}/>
                    {/*(file?.type === 'template' || urls?.length > 1) &&
                      <iframe src={file.url} width="100%" height="600" title={index}/>
                    }
                    {(file?.type !== 'template' && urls?.length == 1) &&
                        <PreviewFile file={file}/>
                    */}
                  </>
                }
              </div>
            )
          })}
        </>
      }
    </>
  );
};
export const PreviewFile = ({ file, ...props })=> {
  const viewerRef= useRef();
  const [fffValue, setFff] = useState();
  useEffect(() => {
    setFff('inUseEffect '+file?.type);
    setTimeout(()=>{
      setFff('TimeOut');
      loadDocument(file.url);
    },500);
  }, []);
  const loadDocument = async (url) => {
    let fff ; 
    setFff('loadDocument');
    if (viewerRef.current) {
      setFff('loaded');
       const disElement = [
          "header",
          "toolbarGroup-View",
          "toolsHeader",
          "toolbarGroup-Annotate",
          "toolbarGroup-Shapes",
          "toolbarGroup-Insert",
          "toolbarGroup-Measure",
          "toolbarGroup-Edit",
          "toolbarGroup-FillAndSign",
          "toolbarGroup-Forms",
          "toolbarGroup-Redact",
          "annotationGroupButton",
          "linkButton",
          "contextMenuPopup",
          "annotationCommentButton",
          "annotationDeleteButton",
          "annotationStyleEditButton",
          "textSignaturePanel",
          "textSignaturePanelButton",
          "imageSignaturePanelButton",
          "textSignaturePanel",
          "imageSignaturePanel",
          "textPopup",
          "text-signature-font-dropdown"
       ];/* */
       //console.log('fileUrl ::',fileUrl);
       if(true){
          await WebViewer({fullAPI: true,path: "/webviewer/public",
            initialDoc: url,
            disabledElements: disElement,
            licenseKey: APRYSE_L_KEY,
          },viewerRef.current).then(async (instance) => {
            //console.log('instance ::',instance);
            instance.UI.disableElements(disElement);
          });
       }
    }
  };
  return (
    <>
      <div ref={viewerRef}  style={{ position:'relative', height: "600px" }}>
      </div>
    </>
  );
};
export default ShowFile;
