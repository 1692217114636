import React, {useEffect,useState,useRef} from "react";
import {Grid, Button, TextField,ButtonGroup, IconButton,Box,Dialog, DialogTitle, DialogContent, DialogActions, Typography,
  MenuItem ,Menu ,Stack ,Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Close, Delete ,MoreHoriz } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { fetchUrl } from "Urls";
import { Styles } from "./NotesAndAttachment.style";
import Spinner from "components/spinner";
const Input = styled("input")({
  display: "none",
});

const NotesAndAttachment = ({ handleChange , req , ...props}) => {
  const [showSpinnerM, setSpinnerM] = useState(false);
  const {  formId ,ansObj,credential, checklistFormobj } = props;
  const [sobj , setSobj] = useState(ansObj);
  //const [notes , setNotes] = useState(null);
  const [showNotes , setShowNotes] = useState(false);
  const [showUpload , setShowUpload] = useState(false);
  const [selectedImgObj , setSelectedImgObj] = useState(null);
  var notes = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAttachId, setSelectedAttachId] = useState(null);
  const columns = [
    { field: "id", headerName: "Id", flex: 1, hideable: true },
    { field: "File_Name__c", headerName: "File Name", flex: 1,
    renderCell: (params) => {
      return (
          <Box className="hide-menu-dot name_main" style={{ cursor: "pointer", display: "flex" }} >
            <Box>{params.value}</Box>
            <IconButton
              onClick={(e) => handleClickMenu(e, params.row.id)}
              className="menu_open_button"
              size="small"
              sx={{ ml: 2 }}
              aria-controls={selectedAttachId ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={selectedAttachId ? "true" : undefined}
            >
            <MoreHoriz />
            </IconButton>
          </Box>
      );
    }, },
    { field: "fileurl", headerName: "Preview", align:'center', flex: 1, width:100,
      renderCell: (params) => {
        return (
            <div onClick={(event) => {
              event.stopPropagation();
              const fInd = sobj?.attachments?.findIndex((res) => res.Id == params.row.id);
              if (fInd > -1) {
                onImageSelect(fInd);
              }
            }}>
            <img src={ params.value } alt="ImageTable" height="100" width="100"/>
          </div>
        );
      },
    },
  ];
  const handleClickMenu = (event ,attId) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedAttachId(attId);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
   // console.log("handleCloseMenu handleCloseMenu");
    setSelectedAttachId(null);
    setAnchorEl(null);
    //setSelectedMenuRow(null);
  };
  useEffect(() => {
    if(ansObj.Id != sobj?.Id && ansObj.Id && ansObj.Id != '' && (!sobj?.Id ) ){
      let cloneAnsObj = JSON.parse(JSON.stringify(sobj)) ; 
      cloneAnsObj.Id = ansObj.Id ;
      setSobj(cloneAnsObj);
    }
  }, [ansObj.Id]);
  useEffect(() => {
    if(sobj?.Notes__c && sobj?.Notes__c != ''){
      setShowNotes(true);
      notes.current = sobj?.Notes__c;
    }
  }, []);
  const uploadMedia = async (options) => {
    //console.log("sdsdsds", options);
    if(options && options.length > 0){
      const videoUploaderOptions = {
        fileName: options[0].name,
        file: options[0],
      };
      setSpinnerM(true);
      const formData = new FormData();
      formData.append( "attachFile", videoUploaderOptions?.file, videoUploaderOptions?.file?.name );
      formData.append( "mimeType", videoUploaderOptions?.file.type );
      formData.append( "s3Key", `${formId}/response/${sobj?.Id}` );
      await axios.post(`${fetchUrl}/upload/attachment/Form_Response__c/${sobj?.Id}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,formData)
      .then(async (res) => {
        if(res.status == 200){
          let cloneAnsObj = JSON.parse(JSON.stringify(sobj)) ; 
          if(!cloneAnsObj?.attachments){
            cloneAnsObj.attachments = [] ;
          }
          let cloned = JSON.parse(JSON.stringify(res.data.ret)) ;
          cloned.id = res.data.ret.Id ;
          cloneAnsObj.attachments.push(cloned);
          setSobj(cloneAnsObj);
          handleChange({target : {name: 'attachments' , value: cloneAnsObj.attachments}});
          setShowUpload(false);
        }
      }).catch((err) => {
        // setIsLoading0(false);
        console.log("err", err);
      });
      setSpinnerM(false);
    }
  };
  const Card = (props) => (
    <div title={props.title} className="card" onClick={(event) => { event.stopPropagation(); onImageSelect(props.index); }}>
      <img src={ props.imgUrl } alt={ props.alt || 'Image' } />
      <div className="title" >{ props.title }</div>
    </div>
  );
  const CardContainer = (props) => (
    <div className="cards-container">
      {
        props.cards.map((card , index) => (
          <Card index={index} title={ card.File_Name__c } imgUrl={ card.fileurl }/>
        ))
      }
    </div>
  );
  const onImageSelect = async ( imageIndex )=>{
    if(sobj?.attachments && sobj?.attachments[imageIndex]){
      setSelectedImgObj(sobj?.attachments[imageIndex]);
    }
  };
  return (
    <Styles>
      <div>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <ButtonGroup variant="outlined" className="table-btn-group" >
              <Button onClick={() => {
                  if(checklistFormobj.Status__c !== "Completed"){
                if(notes.current && notes.current != ''){
                  setShowNotes(true);
                  return;
                }
                setShowNotes(!showNotes);}
              }} >
                Notes
              </Button>
              { sobj?.Id && sobj?.Id !='' && formId && formId !='' &&
                <>
                  <Button onClick={()=>{
                    if(checklistFormobj.Status__c !== "Completed"){
                    setShowUpload(!showUpload);
                    }
                  }}>
                    Photos
                  </Button>
                </>
              }
            </ButtonGroup>
          </Grid>
          <Grid item xs={7}>
            { showNotes && 
              <Box className="searchField">
                <TextField id="name" variant="outlined" placeholder="Notes" defaultValue={sobj?.Notes__c}
                  onChange={(e) => {
                    if(checklistFormobj.Status__c !== "Completed"){
                    let fff = sobj; fff.Notes__c = e?.target?.value; setSobj(fff);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key.toLowerCase() != "tab") {
                      event.stopPropagation();
                    }
                  }}
                  onBlur={(e) => {
                    if(checklistFormobj.Status__c !== "Completed"){
                    //console.log('e?.target?.value :: '+e?.target?.value);
                    let val = e?.target?.value ;
                    if(val === ''  || !val){
                      val = null;
                    }
                    notes.current = val;
                    handleChange({target : {name: 'Notes__c' , value: val}});
                    setShowNotes(val == null ? false : true);
                  }
                  }} />
              </Box>
            }
          </Grid>
          {sobj && sobj?.attachments && sobj?.attachments?.length > 0 &&
            <Grid item xs={6}>
              <div style={{width : 'fit-content' , maxWidth:'100%'}}>
                <CardContainer cards={sobj?.attachments}/>
              </div>
            </Grid>
          }
        </Grid>
        {showUpload &&
          <Dialog fullWidth open={showUpload} scroll="paper">
            <DialogTitle>
              <Typography>
                Photos
              </Typography>
              <IconButton aria-label="close" onClick={() => { setShowUpload(false); }}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ width: '100%', position: "relative" }}>
              {showSpinnerM && <Spinner />}
              {formId && formId !='' && 
                <label htmlFor="attach-upload">
                  <Input
                    accept="image/x-png,image/jpeg,image/jpg"
                    id="attach-upload"
                    type="file"
                    onChange={(e) => uploadMedia(e.target.files)}
                    onClick={(e) => (e.target.value = "")}
                  />
                  <Button variant="contained" component="span">
                    Upload
                  </Button>
                </label>
              }
              <Typography>
                {sobj && sobj?.attachments && sobj?.attachments?.length > 0 ? (
                  <>
                    <div style={{ height: 400, width: "100%" }}>
                      <DataGrid
                        rows={sobj?.attachments}
                        columns={columns}
                        loading={showSpinnerM}
                        disableColumnFilter={true}
                        disableColumnSelector={true}
                        disableSelectionOnClick={true}
                        hideFooter={true}
                        autoPageSize={true}
                        showCellVerticalBorder={true}
                        columnVisibilityModel={{
                          id: false,
                        }}
                        rowHeight={100}
                      />
                    </div>
                  </>
                ):(
                  <>
                    No Photo Data.
                  </>
                )}
              </Typography>
            </DialogContent>
            {/*<DialogActions>
            </DialogActions>*/}
          </Dialog>
        }
        {selectedImgObj &&
          <Dialog fullWidth open={true} scroll="paper">
            <DialogTitle>
              <Typography>
                {selectedImgObj.File_Name__c}
              </Typography>
              <IconButton aria-label="close" onClick={() => { setSelectedImgObj(null); }}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div style={{textAlign: 'center' , paddingTop : '10px'}}>
                <img src={selectedImgObj.fileurl} alt="ImageFile" />
              </div>
            </DialogContent>
            {/*<DialogActions>
            </DialogActions>*/}
          </Dialog>
        }
      </div>
      {selectedAttachId && selectedAttachId != '' && (
        <>
          <Stack direction="row" spacing={2}>
            <Paper sx={{ maxHeight: 320, maxWidth: "100%" }}>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={true}
                onClose={handleCloseMenu}
                MenuListProps={{ "aria-labelledby": "basic-button" }}
              >
                <MenuItem
                  onClick={(e) => {
                    handleCloseMenu();
                    setSpinnerM(true);
                    axios.delete(`${fetchUrl}/delete/Amazon_S3_Attachment__c/${selectedAttachId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
                      .then((res) => {
                        //console.log("count:::: " + JSON.stringify(res));

                        let cloneAnsObj = JSON.parse(JSON.stringify(sobj));
                        if (!cloneAnsObj?.attachments) {
                          cloneAnsObj.attachments = [];
                        }
                        const fInd = cloneAnsObj?.attachments?.findIndex((res) => res.Id == selectedAttachId);
                        if (fInd > -1) {
                          cloneAnsObj.attachments.splice(fInd, 1);
                          setSobj(cloneAnsObj);
                          handleChange({ target: { name: 'attachments', value: cloneAnsObj.attachments } });
                        }
                        setSpinnerM(false);
                      }).catch((err) => {
                        console.log(err);
                        setSpinnerM(false);
                      });
                  }}
                >
                  <Delete />
                  Delete
                </MenuItem>
              </Menu>
            </Paper>
          </Stack>
        </>
      )}
    </Styles>
  );
};

export default NotesAndAttachment;
