import React from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useTable, useBlockLayout, useResizeColumns, useSortBy, usePagination } from "react-table";
import { useSticky } from "react-table-sticky";
import { Styles } from "./IssueTable.style";
import { NoData } from "components/upload-logs/LogsComponents/CommonMethod";
import styled from "styled-components";

const getStyles = (props, align = "left") => [props];
const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

function ReviewDetailTable({ columns, data, onClose ,handleOpen }) {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    
    { columns, data, initialState: { pageIndex: 2, hiddenColumns: data[0] && data[0].currentStep &&  data[0].currentStep.participantType == "Reviewer" ? "status" : "id" } },
    useBlockLayout,
    useResizeColumns,
    useSortBy,
    usePagination,
    useSticky
  );
  const handleOpenDetail = (row) => {
    handleOpen(row.original);
  };



  return (
    <Styles>
      <div {...getTableProps()} className="table tabreview">
        <div className="thead">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps({})} className="tr">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps(headerProps)} className="th">
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <KeyboardArrowDown />
                        ) : (
                          <KeyboardArrowUp />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  {column.canResize && (
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? "isResizing" : ""
                      }`}
                    />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="tbody">
          {rows?.length === 0 && (
            <div className="no_data_found" style={{ textAlign: "center", marginTop: "200px" }}>
              <NoData/> <a onClick={onClose}>close</a>
            </div>
          )}
          {rows.map((row) => {
            prepareRow(row);
            return (
              <>
                <div
                  onClick={() => handleOpenDetail(row)}
                  {...row.getRowProps()}
                  className="tr"
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <div {...cell.getCellProps(cellProps)} className="td">
                          {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </Styles>
  );
}

export default ReviewDetailTable;
