import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  CircularProgress,
  Box,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import _ from "lodash";
import { fetchUrl } from "Urls";
import { GetToken } from "api";

const usePdfViewerHook = (props) => {
  const [layoutOption, setLayoutOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [preSelectedRowIds, setPreselectedRows] = useState([]);

  const columns = [
    { field: "id", headerName: "ID", flex: 1, hideable: true },
    { field: "name", headerName: "File Name", flex: 1 },
    { field: "type", headerName: "File Format", flex: 1 },
    {
      field: "template",
      headerName: "Template",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="main_template_div">
            {loading && (
              <div className="loaderSection">
                <div className="loading">
                  <CircularProgress />
                </div>
                <div className="value">{params.value}</div>
              </div>
            )}
            {layoutOption?.length > 0 && !loading && (
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <Select
                    className="SlectTEmplate"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={
                      props?.selectedTemplate?.length > 0 ? props?.selectedTemplate[0].id : ""
                      /*_.find(props?.selectedTemplate, { id: params?.id })
                        ? _.find(props?.selectedTemplate, { id: params?.id })
                            ?.template
                        : ""*/
                    }
                    onChange={(e) => {
                      if (e.target.value === "") {
                        props?.handleSelect(null);
                      } else {
                        let tempList = JSON.parse(JSON.stringify(layoutOption));
                        if (tempList?.length > 0) {
                          const fInd = tempList?.findIndex(
                            (data) => data.id == e.target.value
                          );
                          if (fInd > -1) {
                            props?.handleSelect({
                              template: tempList[fInd].title,
                              id: tempList[fInd].id,
                            });
                          }else{
                            props?.handleSelect(null);
                          }
                        }
                        /*
                        props?.handleSelect({
                          ...params,
                          template: e.target.value,
                        });*/
                      }
                    }}
                  >
                    <MenuItem value="">Create New Layout</MenuItem>
                    {layoutOption?.map((row, index) => {
                      return (
                        <MenuItem key={index} value={row?.id}>
                          {row?.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            )}
            {layoutOption?.length == 0 && !loading && (
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <Select
                    className="SlectTEmplate"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={
                      props?.selectedTemplate?.length > 0 ? props?.selectedTemplate[0].id : ""
                      /*_.find(props?.selectedTemplate, { id: params?.id })
                        ? _.find(props?.selectedTemplate, { id: params?.id })
                            ?.template
                        : ""*/
                    }
                    onChange={(e) => {
                      if (e.target.value === "") {
                        props?.handleSelect(null);
                      } else {
                        props?.handleSelect(null);
                        /*props?.handleSelect({
                          ...params,
                          template: e.target.value,
                        });*/
                      }
                    }}
                  >
                    <MenuItem value="">Create New Layout</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (props?.document) {
      let withfile = [];
      let seleIds = [];
      props?.document?.map((row , index) => {
        let elele = {
          id: index + 1,
          file: props?.document[index],
          name: row?.name,
          type: row?.type,
          template: "file uploading...",
        } ;
        withfile.push(elele);
        seleIds.push(elele.id);
      });
      setRows(withfile);
      setPreselectedRows(seleIds);
    }
  }, []);

  useEffect(() => {
  }, []);

  return { loading, layoutOption, columns, rows, preSelectedRowIds , setPreselectedRows};
};

export default usePdfViewerHook;
