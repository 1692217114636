import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {TreeItem, treeItemClasses } from '@mui/x-tree-view';
import Typography from '@mui/material/Typography';
import folder from "assets/images/folder.svg";
import folderDark from "assets/images/folder_dark.svg"; 

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: { 
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        // [`& .${treeItemClasses.content}`]: {
        //     paddingLeft: theme.spacing(4),
        // },
    },
}));


//default component
function StyledTreeItem(props) {
    const {
        bgColor,
        color,
        labelInfo,
        labelText,
        classStyle,
        ...other
    } = props;
    const typographyStyle = {
        whiteSpace: 'nowrap',        // Adjust the maximum width as needed
      };
    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0}}>
                    <img style={{marginRight: "5px"}} src={classStyle == "folder_list-LastSelect" ? folderDark : folder} alt="folder icon" />
                    <Typography className = {classStyle} variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }} style={typographyStyle}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={{'--tree-view-color': color,}}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    classStyle: PropTypes.string,
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
};

export default StyledTreeItem;
