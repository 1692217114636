import styled from "styled-components";

const ImagesCss = styled.div`
  & .split_images {
    display: flex;
    flex-direction: row;
    padding: 0 20px;
    gap: 20px;

    .image {
      img {
        width: 120px;
      }
    }
  }
`;

const WithoutTemplateSection = styled.div`
  .new_taplate_text_one {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 10px;

    label {
      font-weight: 600;
    }
    input {
      width: 100%;
      max-width: 190px;
      padding: 10px;
      border: 2px solid #0498dc;
    }
  }

  .slds-card__footer {
    position: sticky;
    bottom: 0;
    padding: 20px;
    background: #ededed;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 30px;

    button {
      padding: 13px 25px;
      min-width: 160px;
      font-size: 16px;
      border-radius: 25px;
      border: 1px solid #0498dc;
      color: #fff;
      background: #0498dc;
      cursor: pointer;
    }
  }
`;
const GridViewStyle = styled.div`
  margin-top: 16px;
  padding-right: 35px;
  padding-left: 10px;
  overflow-y: auto;

  .main_gridview {
    .MuiGrid-root.MuiGrid-container {

      .MuiGrid-root {

        .MuiBox-root {
          justify-content: center;
          align-items: center;
          height: 100%;

          svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeLarge {
            opacity: 0.3;
          }
        }

        .MuiCardActions-root.MuiCardActions-spacing {
        }
      }

      .MuiCardContent-root {
        padding: 0;
      }

      .MuiCardActions-root {
        padding: 0;
      }

      .MuiPaper-elevation.MuiPaper-rounded {
        position: relative;
        justify-content: space-between;

        & > img {
          margin: auto;
          height: 55%;
          object-fit: cover;
          width: 92%;
        }

        span.version {
          padding: 2px 6px;
          background-color: #edf0f2;
          border-radius: 2px;
          max-width: fit-content;
        }

        &:hover {
          .on_hover_Class,
          .on_hover_Class-2 {
            visibility: visible;
          }
        }

        .on_hover_Class,
        .on_hover_Class-2 {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 26px;
          color: #fff;
          display: flex;
          visibility: hidden;
          padding-bottom: 50px;
        }

        .on_hover_Class {
          background-image: linear-gradient(#00000029, #00000000);
          padding-right: 100%;
          height: 10px;
        }

        .on_hover_Class-2 {
          padding-right: 100%;
          height: 10px;
        }

        span.MuiCheckbox-root.MuiCheckbox-colorPrimary {
          padding: 10px;
          position: absolute;
          z-index: 99;
          display: flex;
          justify-content: center;
          left: 3px;
          top: 5px;
        }
      }

      p.value {
        text-align: left;
        margin-top: 10px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    span.MuiCheckbox-root.MuiCheckbox-colorPrimary {
      padding: 10px;
    }

    .leftflex {
      padding-bottom: 0;

      svg {
        font-size: 20px;
        color: #bcc9d1;
      }
    }
  }

  .version {
    align-items: center;
    background-color: #e8efff;
    border: 1px solid #346ef5;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: fit-content;
  }

  .version-1 {
    color: #346ef5;
    font-weight: 400;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: 70px;
  }

  .cardColor {
    background-color: #e8efff;
    border: 1px solid #346ef5;
  }

  .cardColor-1 {
    border: 1px solid #d8d8d8;
  }

  .cardColor-2 {
    &:hover {
      background-color: #eaeaea;
    }

    background-color: #f8f8f8;
    border: 1px solid #d8d8d8;
  }

  .tr2 {
    position: absolute;
  }

  .checking {
    transform: scale(1.5);
  }

  .folder-list {
    border-radius: 2px;
    display: flex;
    padding: 10px 12px;
    background-color: #f8f8f8;
    border: 1px solid #d8d8d8;
    height: 45px;
    justify-content: space-between;

    &:hover {
      background-color: #eaeaea;
    }
  }

  .folder-list-select {
    display: flex;
    padding: 12px 12px;
    background: linear-gradient(0deg, #e8efff, #e8efff);
    border: 1px solid #d8d8d8;
    height: 45px;
    justify-content: space-between;
  }

  span.MuiCheckbox-root.MuiCheckbox-colorPrimary {
    background: white;
    border-radius: 0px;
    width: 18px;
    height: 18px;
  }

  span.MuiCheckbox-root.MuiCheckbox-colorPrimary:hover {
    background: #f8f8f8;
  }

  .MuiGrid-root {
    margin-left: 0px;
    margin-top: 0px;
  }
`;


const FolderTabledesign = styled.div``;

export { ImagesCss, WithoutTemplateSection, FolderTabledesign, GridViewStyle };