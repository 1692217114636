import React, { useState } from "react";
import {Button,IconButton,Typography,Dialog,
       DialogTitle,DialogContent,DialogActions,Autocomplete,TextField} from "@mui/material";
import { Close } from "@mui/icons-material";
const DelegateReview = (props) => {
  const { userList, handleClosed ,onSave} = props;
  const [teamList, setTeamList] = React.useState([]);

  //console.log("propsDelegateReview ::", userList);
  return (
    <>
      <Dialog fullWidth open={true} scroll="paper">
        <DialogTitle>
          Delegate Review?
          <IconButton aria-label="close" className="dialog-header-close" onClick={handleClosed}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {` The review record will be made available for others to review. Please select a participant to delegate the review :`}
              <Autocomplete
                multiple
                className="inlineAutocomplete"
                value={teamList}
                options={userList}
                getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => (
                  <TextField fullWidth id="autocomplete" {...params} label="User" />
                )}
                onChange={(event, newValue) => {
                  setTeamList([...newValue]);
                }}
              />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined"  >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => { 
            const approvers = teamList.map(obj => obj.value);
            onSave(approvers);
            }} disabled={!(teamList?.length > 0)} >
            Delegate
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DelegateReview;
