import React,{ useEffect, useState, useRef } from "react";
import _, { find, get } from "lodash";
import moment from "moment";
import axios from "axios";
import { fetchUrl } from "Urls";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const Contract_Folder_Name = 'Contract Drawings';
const Shop_Folder_Name = 'Shop Drawings';
const useFolderTableHook = (props) => {
  var instance = useRef(null);
  let PSPDFKit;

  let timeOutObj;
  let interValTime = (60 * 1000);
  let selectedFilePageCount = 0;

  const { projectId , tokenInfo} = props;

  const [sourceFolderDetail, setSourceFolder] = useState({});
  const [folderNameToIdMap, setSubFolderMap] = useState(null);

  const [pdfImages, setPdfImages] = useState([]);
  const [miniMizeLoading, setMinimizeLoading] = useState(false);
  const [preSelectedPdfImages, setPreSelectedPdfImages] = useState([]);
  const [pdfFile, setPdfFile] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpenMainPopup] = useState(false);
  var open_main = useRef(false);
  const [duplicateAvailable, setDuplicateValidation] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [selectedSplitData, setSelectedSplitData] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [popupLoader, setPopupLoader] = useState(false);
  const [continueOffline, setContinueOffline] = useState(false);
  const [buttonloading, setButtonloading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [review_meta_data_title, setReviewMetaDataTitle] = useState([]);
  const [review_meta_data_number, setReviewMetaDataNumber] = useState([]);
  const [firstCountCall, setFirstCountCall] = useState(true);
  const [readyForReviewCount, setReadyForReviewCount] = useState(0);
  const [CropLabel, setCropLabel] = useState({
    titleCropBox: {},
    numberCropBox: {},
  });
  const [cropBox, setCropBox] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const [newTemplateName, setNewTemplateName] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [isSelectionTitleLoading, setIsSelectionTitleLoading] = useState(false);
  const [isSelectionNumberLoading, setIsSelectionNumberLoading] = useState(false);
  const [templateNumber, setTemplateNumber] = useState("");
  const [pdfIns, setPdfIns] = useState(null);
  const [allpageintance, setAllpageintance] = useState(null);

  const [templateCheck, setTemplateCheck] = useState(false);
  const containerRef = useRef(null);

  const [credential, setCredential] = useState({
    sessionId: "",
    instanceUrl: "",
    attachementId: "",
  });

  const [titleArray, setTitleArray] = useState([]);
  const [numberArray, setNumberArray] = useState([]);
  const [errorState, setErrorState] = useState([]);
  const [errorNumberState, setErrorNumberState] = useState({});
  const [selectedradio, setSelectedRadio] = useState({
    id: "",
    date: "",
    date_sf: "",
    name: "",
  });
  const [uploadWithoutSet, setUploadWithoutSet] = useState(true);
  const [workflowTemplateOptions, setWorkflowTemplates] = useState([]);
  const [shopDrawingTypeOptions, setShopDrawingTypes] = useState([]);
  const [disciplineOptions, setDisciplineOptions] = useState([]);
  const [setOptions , setSetOptions] = useState([]);
  const [reviewStageOptions , setReviewStageOptions] = useState([]);

  useEffect(() => {
    //console.log("FolderTable Hook projectId>>>>>", projectId);
    //console.log("FolderTable Hook credential>>>>>", credential?.projectId );
    if (credential?.projectId != projectId) {
      (async function () {
        await _connnectionFunc();
      })();
    }
    return () => {
      //console.log('Clear Interval Called intervalId :: ' + timeOutObj);
      clearInterval(timeOutObj)
    };
  }, [projectId]);

  const columnsB = [
    { field: "id", headerName: "ID", sortable: false, width: 300 },
    {
      field: "image",
      headerName: "Image",
      width: 300,
      height: 500,
      sortable: false,
      renderCell: (param) => {
        return <img src={param?.value} />;
      },
    },
    {
      field: "src",
      headerName: "Title / Number",
      width: 300,
      height: 500,
      sortable: false,
      renderCell: (param) => {
        return (
          <>
            <div>
              <div>
                <img src={param?.row.titleSrc} />
              </div>
              <div>
                <img src={param?.row.numberSrc} />
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: "number",
      headerName: "Number",
      sortable: false,
      renderCell: (param) => {
        const Data = _.find(errorNumberState, { id: param?.id });
        return (
          <div style={{ display: "block !important" }}>
            <TextField
              type="text"
              value={param?.value}
              onChange={(e) => {
                _changeSplitValue(param?.id, "number", e?.target?.value);
              }}
              onKeyDown={(event) => {
                if (event?.key?.toLowerCase() != "tab") {
                  event.stopPropagation();
                }
              }}
            />
            {Data && duplicateAvailable && (
              <p className="error" style={{ color: "red" }}>
                {Data && Data?.error}
              </p>
            )}
          </div>
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      renderCell: (param) => {
        const Data = _.find(errorState, { id: param?.id });
        return (
          <div style={{ display: "block !important" }}>
            <TextareaAutosize
              type="textarea"
              value={param?.value}
              onChange={(e) => {
                _changeSplitValue(param?.id, "title", e?.target?.value);
              }}
              onKeyDown={(event) => {
                if (event?.key?.toLowerCase() != "tab") {
                  event.stopPropagation();
                }
              }}
            />
            {/*Data && duplicateAvailable && (
              <p className="error" style={{ color: "red" }}>
                {Data && Data?.error}
              </p>
            )*/}
          </div>
        );
      },
    },
  ];
  function _changeSplitValue(id, section, value) {
    console.log("id, section, value", id, section, value, pdfImages);
    let _pdfImages = pdfImages;
    const FIND = _.find(_pdfImages, { id: id });
    let _REJECT_DATA = _.reject(_pdfImages, (row) => row?.id === id);
    FIND[section] = value;
    _pdfImages = [..._REJECT_DATA, FIND];
    if (section === "title") {
      if (value) {
        //console.log("value", pdfImages, value);
        //var checkDuplicatesTitle = _.filter(_pdfImages,  (row) => (row[section] === value && row.id != id ));
      }
      _duplicateCheck({
        value,
        //checkAvailabelValues: checkDuplicatesTitle,
        section: section,
        setErrorState: setErrorState,
        errorState: errorState,
        arrayList: _pdfImages,
        setArray: setTitleArray,
        FIND: FIND,
      });

    } else if (section === "number") {
      if (value) {
        //console.log("value", pdfImages, value);
        var checkDuplicates = _.filter(_pdfImages, (row) => (row[section] === value && row.id != id));
        //console.log("ckko val ", JSON.stringify(checkDuplicates));
      }
      _duplicateCheck({
        value,
        checkAvailabelValues: checkDuplicates,
        section: section,
        setErrorState: setErrorNumberState,
        errorState: errorNumberState,
        arrayList: _pdfImages,
        setArray: setNumberArray,
        FIND: FIND,
      });
    }
  }

  function _duplicateCheck(para) {
    const { value, checkAvailabelValues, section, setErrorState, errorState, arrayList, setArray, FIND } = para;
    //console.log("_duplicateCheck checkAvailabelValues ", JSON.stringify(checkAvailabelValues));
    //console.log(`_duplicateCheck ${section} :: ${checkAvailabelValues}`);
    let REJECT_DATA;
    let ERROR = false;
    let errorList = _onLoadError(arrayList, section);
    //console.log('errorList :: '+JSON.stringify(errorList));
    setErrorState(errorList);

    FIND[section] = value;

    if (!ERROR && value) {
      REJECT_DATA = _.reject(arrayList, (row) => row?.id === FIND?.id);
      let nnn = [...REJECT_DATA, FIND];
      setArray(nnn);
      //console.log("arrayList ", JSON.stringify(nnn));
    }
    //console.log('errorList :: '+JSON.stringify(errorList));
    if (errorList.length > 0) {
      setDuplicateValidation(true);
    } else {
      setDuplicateValidation(false);
    }
  }

  function _handleSelect(value) {
    if (value) {
      /*const CHECK_VALUE = _.find(selectedTemplate, { id: value?.id });
      //console.log("selectedTemplate>>>>>>>>>>>>", selectedTemplate);
      if (CHECK_VALUE) {
        CHECK_VALUE["template"] = value?.template;
        const REJECT = _.reject(selectedTemplate, CHECK_VALUE);
        setSelectedTemplate([...REJECT, CHECK_VALUE]);
      } else {
        setSelectedTemplate([...selectedTemplate, value]);
      }*/
      setSelectedTemplate([{ id: value?.id, template: value?.template }]);
    } else {
      setSelectedTemplate([]);
    }
  }
  //console.log("selectedTemplate>>>>>>>>>>>>>>>>>>>>", selectedTemplate);

  async function _connnectionFunc() {
    //console.log('_connnectionFunc tokenInfo :: ',JSON.stringify(tokenInfo?.userList));
    setCredential(tokenInfo);
    getUnPublishBinderCount(tokenInfo); // first call
    fetchWTemplateList(tokenInfo);
    getAttachmentFilterOptions(tokenInfo);
    if(!fetchUrl.includes('localhost')){
      checkAndSetAutoGetCountTimeout(tokenInfo);
    }
    //console.log('Folder Table credential :: '+JSON.stringify(data?.data));
  }
  function checkAndSetAutoGetCountTimeout(_credential = credential) {
    clearAutoSaveGetCountInterval();
    let ss = Date.now();
    //console.log('timeOutObj ',timeOutObj);  
    //console.log('timeout start '+ss);        
    timeOutObj = setInterval(() => {
      //console.log('timeout setted '+Date.now());
      //console.log('timeout completed '+ Math.floor( (Date.now() - ss )  / 1000));
      //console.log('timeout credential '+_credential?.projectId);
      getUnPublishBinderCount(_credential);
    }, interValTime);
    console.log('timeIntervalId start ' + timeOutObj);
    //setCountIntervalObj(timeOutObj);
  }
  function clearAutoSaveGetCountInterval() {
    if (timeOutObj) {
      clearInterval(timeOutObj);
      //setCountIntervalObj(undefined);
      //console.log('timeout cleared '+Date.now());
    }
  }
  async function getUnPublishBinderCount(_credential = credential) {

    await axios.get(`${fetchUrl}/unpublish/${_credential.projectId}?token=${_credential?.token}&instanceUrl=${_credential?.instanceUrl}`)
      .then((res) => {
        //console.log("count:::: " + JSON.stringify(res));
        if (res.status === 200) {
          if (readyForReviewCount !== res?.data?.expr0 && firstCountCall == false) {
            if (readyForReviewCount || readyForReviewCount == 0) {
              /*toast.success("Published log review updated.", {
                position: toast.top - toast.right,
              });*/
            }
          }
          setReadyForReviewCount(res?.data?.expr0);
          setFirstCountCall(false);
        } else {
          setReadyForReviewCount(0);
        }
      }).catch((err) => {
        console.log(err);
      });
  }
  async function getAttachmentFilterOptions(_credential = credential) {
    //setLoading(true);
    const instanceUrl = _credential.instanceUrl;
    const token = _credential.token;
    const projectId = _credential.projectId;
    if(!shopDrawingTypeOptions || shopDrawingTypeOptions?.length == 0){
      var req_body = {fields:['Shop_Drawing_Type__c']};
      var shopValues =[];
      axios.post(`${fetchUrl}/picklist/valueObj/Amazon_S3_Attachment__c?token=${token}&instanceUrl=${instanceUrl}`,req_body)
      .then((res) => {
        if (res?.status === 200) {
          let retData = res.data;
          if(retData?.pickObj?.Shop_Drawing_Type__c?.valueList){
            shopValues = retData?.pickObj?.Shop_Drawing_Type__c?.valueList;
          }
        }
        setShopDrawingTypes(shopValues);
      }).catch((err) => {
        console.log(err);
        setShopDrawingTypes(shopValues);
      });
    }
    if(!disciplineOptions || disciplineOptions?.length == 0){
      var disValues =[];
      axios.get(`${fetchUrl}/disciplineOptions/${projectId}?token=${token}&instanceUrl=${instanceUrl}`)
      .then((res) => {
        if (res?.status === 200) {
          let retData = res.data;
          disValues =retData?.options;
        }
        setDisciplineOptions(disValues);
      }).catch((err) => {
        console.log(err);
        setDisciplineOptions(disValues);
      });
    }
    if(!setOptions || setOptions?.length == 0){
      var setValues =[];
      axios.get(`${fetchUrl}/sets/${projectId}?token=${token}&instanceUrl=${instanceUrl}`)
      .then((res) => {
        if (res?.status === 200) {
          res?.data?.records?.map((val, index) => {
            if (val?.Issuance_Date__c != null) {
              setValues.push({ value: val?.Id, label: val?.Name, date: moment(val?.Issuance_Date__c).format("MMM DD YYYY") });
            }
          });
          setSetOptions(setValues);
        }
      }).catch((err) => {
        console.log(err);
        setSetOptions(setValues);
      });
    }
    if(!reviewStageOptions || reviewStageOptions?.length == 0){
      var rewValues =[];
      var req_body = {fields:['Stage__c']};
      axios.post(`${fetchUrl}/picklist/valueObj/Review__c?token=${token}&instanceUrl=${instanceUrl}`,req_body)
      .then((res) => {
        if (res?.status === 200) {
          let retData = res.data;
          if(retData?.pickObj?.Stage__c?.valueList){
            rewValues = retData?.pickObj?.Stage__c?.valueList;
          }
        }
        setReviewStageOptions(rewValues);
      }).catch((err) => {
        console.log(err);
        setReviewStageOptions(rewValues);
      });
    }
  }

  async function callBack(selectedTemplateId) {
    PSPDFKit = await import("pspdfkit");
    PSPDFKit.load({
      //   licenseKey: "pdf_live_hPNXlcly6tKTVzQJ1Xun3f9bWvG3BMvQlirG1ouwVzm",
      baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
      document: URL.createObjectURL(selectedRowData[0]?.file),
      headless: true,
    }).then((ins) => {
      instance.current = ins;
      printCurrentTime("it loaded... ");
      //splitBinder(CropLabel , fileUrl , selectedTemplateId); // commented as backgroundprocess is started in save template api
      setTimeout(() => {
        checkBinderTemplateStatusAndProcessSplit();
      }, 10000);
      setProgress(0);
      setPopupLoader(false);
      setTemplateCheck(true);
    });
  }

  const saveNewTemplate = async () => {
    const { titleCropBox, numberCropBox } = CropLabel;
  };

  //console.log("selectedTemplate>>>>>>>>>>", fileUrl);

  const handleNext = async (section) => {
    //console.log('selectedradio :: ',selectedradio);
    //console.log('uploadWithoutSet :: ',uploadWithoutSet);
    const formData = new FormData();
      formData.append("setId", selectedradio?.id);
      formData.append("setName", selectedradio?.name);
      formData.append("issueDate", selectedradio?.date_sf);
      formData.append("uploadWithoutSet", uploadWithoutSet);
    if(selectedradio?.name && selectedradio?.date && !selectedradio?.id){
      formData.append("uploadSet", true);
    }
    //console.log('formData :: ',formData);
    //return;
    // setIsLoading0(true);
    //console.log('active step :: '+activeStep);
    setActiveStep((prevActiveStep) => { 
      //console.log('prevActiveStep :: '+prevActiveStep);
      return prevActiveStep + 1;
    });
    setPopupLoader(true);

    setButtonloading(true);
    // if (selectedTemplate.length > 0 && section === "continoueOffline") {
    //   console.log("hereisSuspense");
    //   // setButtonloading(true);
    //   // setPopupLoader(true);

    //   setActiveStep((prevActiveStep) => prevActiveStep);
    // } else {
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }

    // backgroundUploadWorker
    if (selectedRowData?.length > 0) {
      formData.append(
        "binderFile",
        selectedRowData[0]?.file,
        selectedRowData[0]?.file?.name
      );
    }
    PSPDFKit = await import("pspdfkit");
    await PSPDFKit.load({
      // licenseKey: "pdf_live_hPNXlcly6tKTVzQJ1Xun3f9bWvG3BMvQlirG1ouwVzm",
      baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
      document: URL.createObjectURL(selectedRowData[0]?.file),
      headless: true,
    }).then((ins) => {
      instance.current = ins;
      selectedFilePageCount = ins.totalPageCount;
    });
    // need to check active step
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //console.log('selectedTemplate :: '+JSON.stringify(selectedTemplate));
    await axios.post(`${fetchUrl}/upload/binder/${projectId}/${sourceFolderDetail?.id}?token=${credential?.sessionId}&instanceUrl=${credential?.instanceUrl}&total_count=${instance.current.totalPageCount}&upload_type=${section}&template_id=${selectedTemplate[0]?.id}`,formData)
    .then(async (res) => {
        //console.log("attachementId", res?.data);
        setButtonloading(false);
        let _fileUrl = res?.data?.fileUrl;
        let _attachementId = res?.data?.ret?.id;
        setFileUrl(_fileUrl);
        setCredential({ ...credential, attachementId: _attachementId });
        if (selectedTemplate.length > 0) {
          await axios.get(`${fetchUrl}/template/${selectedTemplate[0]?.id}/?token=${credential?.sessionId}&instanceUrl=${credential?.instanceUrl}`)
            .then((res) => {
              if (res.status === 200) {
                //console.log("cccc", res?.data);
                let cropObj = { titleCropBox: JSON.parse(res?.data.Title_Area_Json__c), numberCropBox: JSON.parse(res?.data.Number_Area_Json__c) };
                setCropLabel({ ...CropLabel, ...cropObj });
                splitBinder(cropObj, _fileUrl, selectedTemplate[0]?.id, _attachementId);
              }
              // setIsLoading0(false);
            }).catch((err) => {
              // setIsLoading0(false);
              setButtonloading(false);
              console.log(err);
            });
        } else {
          setPopupLoader(false);

          if (section === "continoueOffline") {
            setContinueOffline(true);
          }
          setProgress(10);
          setProgress(30);
          setProgress(40);
          (async function () {
            PSPDFKit = await import("pspdfkit");
            let curinst;
            await PSPDFKit.load({
              //  licenseKey: "pdf_live_hPNXlcly6tKTVzQJ1Xun3f9bWvG3BMvQlirG1ouwVzm",
              // Container where PSPDFKit should be mounted.
              // We have to check this container requirement.
              container: containerRef.current,
              initialViewState: new PSPDFKit.ViewState({
                sidebarMode: PSPDFKit.SidebarMode.THUMBNAILS,
              }),
              // The document to open.
              document: URL.createObjectURL(selectedRowData[0]?.file),
              // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
              baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
              headless: true,
            }).then((ins) => {
              curinst = ins;
              // setPdfIns(ins);
              setAllpageintance(curinst);
              setProgress(70);
            });

            // setIsLoading0(false);

            curinst
              .exportPDFWithOperations([
                {
                  type: "keepPages",
                  pageIndexes: [0],
                },
              ])
              .then(async (file) => {
                await PSPDFKit.load({
                  //  licenseKey: "pdf_live_hPNXlcly6tKTVzQJ1Xun3f9bWvG3BMvQlirG1ouwVzm",
                  // Container where PSPDFKit should be mounted.
                  container: containerRef.current,
                  initialViewState: new PSPDFKit.ViewState({
                    sidebarMode: PSPDFKit.SidebarMode.THUMBNAILS,
                    interactionMode: "PAN"
                  }),
                  // The document to open.
                  document: file,
                  // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
                  baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
                }).then((ins) => {
                  setPdfIns(ins);
                  setProgress(70);
                  const items = ins.toolbarItems;
                  let includeButtons = [
                    "document-crop",
                    "pan",
                    "zoom-in",
                    "zoom-out",
                    "sidebar-thumbnails",
                    "zoom-mode",
                  ];
                  ins.setToolbarItems(
                    items.filter(
                      (itm) =>
                        includeButtons.includes(itm.type) ||
                        includeButtons.includes(itm.id)
                    )
                  );
                  ins.addEventListener("cropArea.changeStart", (opts) => {
                    //console.log("11", JSON.stringify(opts.cropBox));
                  });
                  ins.addEventListener("cropArea.changeStop", (opts) => {
                    //console.log("22-", JSON.stringify(opts.cropBox));
                    setCropBox(opts?.cropBox);
                  });
                  setProgress(100);
                  setButtonloading(false);
                });
              });
          })();
        }
      })
    .catch((err) => {
      // setIsLoading0(false);
      toast.error('Binder Not Uploaded');
      console.log("err", err);
    });
  };

  function _handleUploadFile(file) {
    if (file?.length > 0) {
      setPdfFile([...pdfFile, file[0]]);
      closeMainPopup(true);
    }
  }

  function _handleClose() {
    if (selectedTemplate.length === 0 && !templateCheck && continueOffline) {
      console.log("handle close cancle on continue offline");
      updatePublishStatusOnBinder(credential.attachementId, 'Cancelled');
    }
    console.log("handle close");
    closeMainPopup(false);
    setProgress(0);
    setFileUrl("");
    setTemplateCheck(false);
    setPdfImages([]);
    setPreSelectedPdfImages([]);
    setSelectedRowData([]);
    setUploadWithoutSet(true);
    setSelectedRadio({ id: "", date: "", date_sf: "", name: "" });
    setNewTemplateName("");
    setTemplateName("");
    setTemplateNumber("");
    setSelectedTemplate([]);
    setSelectedSplitData([]);
    setPdfFile([]);
    setActiveStep(0);
    selectedFilePageCount = 0;
    setPopupLoader(false);
    setIsSelectionTitleLoading(false);
    setIsSelectionNumberLoading(false);
  }

  function printCurrentTime(msg) {
    var currentdate = new Date();
    var datetime =
      "Last Sync: " +
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      " @ " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();

    console.log(msg, datetime);
  }

  async function splitBinder(_cropLabel = CropLabel, _fileUrl = fileUrl, selectTemplateId = selectedTemplate[0]?.id, _attachementId = credential?.attachementId) {
    setProgress(0);
    setPopupLoader(false);
    const totalPageCount = instance.current?.totalPageCount;
    const numberTextMap = new Map();
    const titleTextMap = new Map();

    console.log("totalPageCount :: " + totalPageCount);

    console.log("_cropLabel :: " + JSON.stringify(_cropLabel));
    console.log("_fileUrl :: " + _fileUrl);
    console.log("selectTemplateId :: " + selectTemplateId);

    const is_empty_titleCropBox = _.isEmpty(_cropLabel?.titleCropBox);
    const is_empty_numberCropBox = _.isEmpty(_cropLabel?.numberCropBox);
    //console.log("is_empty_titleCropBox :: " + is_empty_titleCropBox);
    //console.log("is_empty_numberCropBox :: " + is_empty_numberCropBox);
    const recttitle = is_empty_titleCropBox
      ? new PSPDFKit.Geometry.Rect({
        left: 3294.790399466736,
        top: 2478.118820076991,
        width: 92.04701324282861,
        height: 34.82862638271508,
      })
      : _cropLabel?.titleCropBox;
    const rectnumber = is_empty_numberCropBox
      ? new PSPDFKit.Geometry.Rect({
        left: 3157.1344048346955,
        top: 2299.8293767521986,
        width: 224.72761183507936,
        height: 51.41368981869059,
      })
      : _cropLabel?.numberCropBox;

    // clone the original document and load it again to make changes to it.
    console.log("recttitle :: " + JSON.stringify(recttitle));
    console.log("rectnumber :: " + JSON.stringify(rectnumber));

    const reqBody = { template_id: selectedTemplate[0]?.id, fileUrl: _fileUrl, attachId: _attachementId , projectId : projectId};
    await axios.post(`${fetchUrl}/background_process_upload?token=${credential?.sessionId}&instanceUrl=${credential?.instanceUrl}`, reqBody).then((response) => {
      if (response?.status === 200) {
        //toast.success(response?.data?.message);
        setTimeout(() => {
          checkBinderTemplateStatusAndProcessSplit(_attachementId, _cropLabel, _fileUrl);
        }, 10000);
      } else {
        console.log(response?.status);
      }
    }).catch((err) => {
    });
  }
  async function checkBinderTemplateStatusAndProcessSplit(_attachementId, _CropLabel, _fileUrl) {
    //console.log('checkBinderTemplateStatusAndProcessSpli t called ');
    let coun = 0;
    let countLimit = Number((instance.current.totalPageCount > 0 ? instance.current.totalPageCount : 5) + 5);
    let attachementId = _attachementId ? _attachementId : credential?.attachementId;
    let fUrl = _fileUrl ? _fileUrl : fileUrl;
    let _cropLabel = _CropLabel ? _CropLabel : CropLabel;

    let attInter = setInterval(async () => {
      if (!open_main.current) {
        //breaking as soon as pop up closed
        clearInterval(attInter);
        return;
      }
      coun++;
      console.log('templateStatus countLimit', countLimit, 'coun', coun);
      if (countLimit == coun) {
        //console.log('clear attInter '+attInter);
        clearInterval(attInter);
        checkJSONDataAndContinue(_cropLabel, fUrl);
      } else {
        //console.log('check time coun '+coun);
        //console.log('attInter '+attInter);
        //console.log('attachementId '+attachementId);
        await axios.get(`${fetchUrl}/attachment/${attachementId}?token=${credential?.sessionId}&instanceUrl=${credential?.instanceUrl}`)
          .then((res) => {
            if (res.status === 200) {
              if(res.data.attachment){
                let status = res.data.attachment.Publishing_Status__c;
                if (status == 'For Review') {
                  clearInterval(attInter);
                  checkJSONDataAndContinue(_cropLabel, fUrl);
                }
              }
            }
            // setIsLoading0(false);
          }).catch((err) => {
            console.log(err);
          });
      }
    }, 7000);

    /**/
  }
  async function checkJSONDataAndContinue(_CropLabel, _fileUrl) {
    //console.log('checkJSONDataAndContinue called ');
    let coun = 0;
    let countLimit = Number((instance.current.totalPageCount > 0 ? instance.current.totalPageCount : 5) + 2);
    let fUrl = _fileUrl ? _fileUrl : fileUrl;
    let _cropLabel = _CropLabel ? _CropLabel : CropLabel;
    let attInter = setInterval(async () => {
      if (!open_main.current) {
        //breaking as soon as pop up closed
        clearInterval(attInter);
        return;
      }
      coun++;
      console.log('jsonStatus countLimit', countLimit, 'coun', coun);
      if (countLimit == coun) {
        clearInterval(attInter);
        handleMinimize();
      } else {
        //console.log('json data call coun :: '+coun);
        let preseldata;
        try {
          const datasec = await _getJsonData(fUrl);
          const jsondata = JSON.parse(datasec?.data);
          //console.log("datasec", jsondata?.split);
          preseldata = jsondata?.split;
          clearInterval(attInter);
          _handleContinue2(_cropLabel, fUrl);
        } catch (err) {
          //console.log('_getJsonData err ',err);
          preseldata = [];
        }
      }
    }, 5000);

    /**/
  }
  async function _getJsonData(data) {
    //console.log("_getJsonData url >>> "+data);
    let obj = { url: data };
    //console.log("checking data form obj"+data);
    return await axios.post(`${fetchUrl}/publishlog/json`, obj).then((res) => {
      //console.log("res checking::::>>>"+res);
      //console.log("res stringify::::>>>"+ JSON.stringify(res));
      return res;
    }).catch((err) => {
      console.log('error', err)
      return []
    });
  }

  async function saveSplit() {
    setIsDisabled(true);
    setPopupLoader(!popupLoader);
    let upload_length = 0;
    PSPDFKit = await import("pspdfkit");
    PSPDFKit.load({
      document: URL.createObjectURL(selectedRowData[0]?.file),
      baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
      headless: true,
    }).then((ins) => {
      instance.current = ins;
      const totalPageCount = instance.current.totalPageCount;
      const selectedData = _.map(selectedSplitData, "id");
      const promises = [];

      if (totalPageCount === 1) {
        promises.push(instance.current.exportPDF());
      } else {
        for (let i = 0; i < totalPageCount; i++) {
          if (selectedData.includes(i)) {
            console.log("splitting - " + i);
            let removePageIndexes = [];
            for (let j = 0; j < totalPageCount; j++) {
              if (j !== i) {
                removePageIndexes.push(j);
              }
            }
            promises.push(
              instance.current.exportPDFWithOperations([
                {
                  type: "removePages",
                  pageIndexes: removePageIndexes,
                },
              ])
            );
          }
        }
      }

      Promise.all(promises).then((values) => {
        values.forEach((file, i) => {
          let TITLE = _.find(titleArray, { id: i }) ? _.find(titleArray, { id: i })?.title : selectedSplitData?.[i]?.title;
          let NUMBER = _.find(numberArray, { id: i }) ? _.find(numberArray, { id: i })?.number : selectedSplitData?.[i]?.number;
          const fd = new FormData();
          TITLE = TITLE.trim();
          NUMBER = NUMBER.trim();
          if (!NUMBER.toLowerCase().endsWith(".pdf")) {
            NUMBER = (NUMBER + '.pdf');
          }
          fd.append("splitFile", new Blob([file]), NUMBER);
          fd.append("fileTitle", TITLE);
          // console.log(fd)
          axios
            .post(
              `${fetchUrl}/upload/split/${projectId}/${credential?.attachementId}/${sourceFolderDetail?.id}/?token=${credential?.sessionId}&instanceUrl=${credential?.instanceUrl}`,
              fd
            )
            .then((res) => {
              if (res.status === 200) {
                upload_length += 1;
                console.log("upload_length", upload_length, "promises.length", promises.length);
                if (upload_length === promises.length) {
                  axios.put(`${fetchUrl}/publish/finish/${credential?.attachementId}?token=${credential?.sessionId}&instanceUrl=${credential?.instanceUrl}`)
                    .then((res) => {
                      setPopupLoader(false);
                      setIsDisabled(false);
                      setActiveStep(3);
                      toast.success("Success Notification !", {
                        position: toast.top - toast.right,
                      });
                      getUnPublishBinderCount(credential);
                    })
                    .catch((err) => {
                      console.log("error", err);
                    });
                }
              }
            })
            .catch((err) => {
              console.log("error", err);
            });
        });
      });
    });
  }
  async function _handleContinue2(_CropLabel = CropLabel, _fileUrl = fileUrl) {
    setPdfImages([]);
    setTitleArray([]);
    setNumberArray([]);



    // const file_url =
    //   "https://horsepower-qa.s3.amazonaws.com//767676/Plan/bablu_test_new.pdf?AWSAccessKeyId=AKIA6FO5IYHIP3YTEGBM&Expires=1663826266&Signature=JNEkqOPCkIJEL3yoCbMNsmvs2Ik%3D"; //`${rowData.fileUrl}${rowData.signedPath}`

    const recttitle = _CropLabel?.titleCropBox, rectnumber = _CropLabel?.numberCropBox;
    console.log("handleContinue2 recttitle :: " + JSON.stringify(recttitle));
    console.log("handleContinue2 rectnumber :: " + JSON.stringify(rectnumber));
    console.log('_handleContinue2 fileUrl :: ' + _fileUrl);
    if (selectedRowData?.length > 0 && _fileUrl) {
      const documentBlobObjectUrl = URL.createObjectURL(selectedRowData[0]?.file);
      PSPDFKit = await import("pspdfkit");
      await PSPDFKit.load({
        headless: true,
        document: documentBlobObjectUrl,
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
      }).then(async (instance2) => {
        // console.log("Test123#");
        instance.current = instance2;
        let forCropIns = instance2;
        var promises = [];
        let totalPageCount = instance2.totalPageCount;
        let mainPageCount = 0;
        let cropTitleCount = 0;
        let cropNumberCount = 0;
        let preseldata;
        try {
          const datasec = await _getJsonData(_fileUrl);
          const jsondata = JSON.parse(datasec?.data);
          //console.log("datasec", jsondata);
          preseldata = jsondata?.split;
          //console.log('prseledata::>>', preseldata);
        } catch (err) {
          preseldata = [];
        }
        printCurrentTime("loop start");
        for (let i = 0; i < totalPageCount; i++) {
          if (!open_main.current) {
            //breaking as soon as pop up closed
            break;
          }
          let title = ((preseldata[i]?.title === "" || preseldata[i]?.title === undefined) ? `no data page ${i + 1}` : preseldata[i]?.title);
          let number = ((preseldata[i]?.number === "" || preseldata[i]?.number === undefined) ? `no data page ${i + 1}` : preseldata[i]?.number);
          let dffObj = { title: title.trim(), number: number.trim(), id: i, pageIndex: i };
          promises.push(dffObj);
          for (let o = 0; o < 2; o++) {
            if (!open_main.current) {
              //breaking as soon as pop up closed
              break;
            }
            //0 for title // 1 for number
            let cBox = o == 0 ? recttitle : rectnumber;
            if (cBox != null) {
              forCropIns.exportPDFWithOperations([{ type: "cropPages", cropBox: cBox }, { type: "keepPages", pageIndexes: [i], }])
                // eslint-disable-next-line no-loop-func
                .then((croppedDoc) => {
                  PSPDFKit.load({
                    baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
                    document: croppedDoc,
                    headless: true,
                  }).then((cropIns) => {
                    cropIns.renderPageAsImageURL({ width: 200 }, 0).then((src) => {
                      if (o === 0) {
                        const fInd = promises?.findIndex((data) => data.pageIndex == i);
                        if (fInd > -1) {
                          promises[fInd].titleSrc = src;
                        } else {
                          promises.push({ ...dffObj, ...{ pageIndex: i, titleSrc: src } });
                        }
                        cropTitleCount++;
                      } else if (o === 1) {
                        const fInd = promises?.findIndex((data) => data.pageIndex == i);
                        if (fInd > -1) {
                          promises[fInd].numberSrc = src;
                        } else {
                          promises.push({ ...dffObj, ...{ pageIndex: i, numberSrc: src } });
                        }
                        cropNumberCount++;
                      }
                      setValuesToValues(promises);
                      if (mainPageCount == totalPageCount && cropTitleCount == totalPageCount && cropNumberCount == totalPageCount) {
                        setValuesToValues(promises);
                      } else {
                        console.log((i == 0 ? 'title ' : 'number '), "mainPageCount", mainPageCount, "cropTitleCount", cropTitleCount, "cropNumberCount", cropNumberCount, "open", open_main.current);
                      }
                    }).catch((err) => {
                      if (o === 0) {
                        cropTitleCount++;
                      } else if (o === 1) {
                        cropNumberCount++;
                      }
                      console.log('cropIns render as Image');
                      console.log(err);
                    });
                  }).catch((err) => {
                    cropTitleCount++;
                    cropNumberCount++;
                    console.log('croppedDoc load');
                    console.log(err);
                  });
                })
              // eslint-disable-next-line no-loop-func
              .catch((err) => {
                cropTitleCount++;
                cropNumberCount++;
                console.log('forCropIns load');
                console.log("error", err);
              });
            }
            if (!open_main.current) {
              //breaking as soon as pop up closed
              break;
            }
          }
          instance2.renderPageAsImageURL({ width: 200 }, i)
            // eslint-disable-next-line no-loop-func
            .then(async (src) => {
              const fInd = promises?.findIndex((data) => data.pageIndex == i);
              if (fInd > -1) {
                promises[fInd].image = src;
              } else {
                promises.push({ ...dffObj, ...{ image: src } });
              }
              setValuesToValues(promises);
              mainPageCount++;
              if (mainPageCount == totalPageCount && cropTitleCount == totalPageCount && cropNumberCount == totalPageCount) {
                setValuesToValues(promises);
              } else {
                //setProgress((prv) => 10 * mainPageCount);
                console.log("mainPageCount", mainPageCount, "cropTitleCount", cropTitleCount, "cropNumberCount", cropNumberCount, "open", open_main.current);
              }
            });
          if (!open_main.current) {
            //breaking as soon as pop up closed
            break;
          }
        }
      });
      function setValuesToValues(_promises) {
        //console.log('_promises :: ', _promises);
        //console.log('_promises stringify :: ', JSON.stringify(_promises));
        const ERROR_TITLE = _onLoadError(_promises, "title");
        const ERROR_NUMBER = _onLoadError(_promises, "number");
        setErrorState(ERROR_TITLE);
        setErrorNumberState(ERROR_NUMBER);
        if (ERROR_NUMBER?.length > 0) { // ERROR_TITLE?.length > 0 || ERROR_NUMBER?.length > 0
          setDuplicateValidation(true);
        } else {
          setDuplicateValidation(false);
        }
        let seleIds = [];
        _promises?.map((row) => {
          seleIds.push(row.id);
        });
        setPdfImages(_promises);
        //for pre selection on datagrid :start
        setSelectedSplitData(_promises);
        setPreSelectedPdfImages(seleIds);
        //for pre selection on datagrid :end
        setTitleArray(_promises);
        setNumberArray(_promises);
        setProgress(100);
      }
    }
  }

  function _onLoadError(promises, section = "title") {
    let data = [];
    let ERROR = [];
    _.map(promises, (row) => {
      let valuee = row[section];
      let eType = 'Duplicate';
      if (!valuee || valuee == '') {
        eType = 'Empty';
      }
      let FIND = _.find(data, (_row) => (_row[section] === row[section] || (eType == 'Empty')));
      if (FIND) {
        ERROR = [...ERROR, { id: FIND?.id, error: `${eType} ${section}` }];
        ERROR = [...ERROR, { id: row?.id, error: `${eType} ${section}` }];
      } else {
        data = [...data, row];
      }
    });
    return ERROR;
  }

  function range(start, end) {
    let length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i - 1);
  }
  async function extractText1(type, cropArea, section) {
    console.log("rect area :: " + JSON.stringify(cropArea));

    const reqBody = { fileUrl: fileUrl, cropArea: cropArea };
    await axios.post(`${fetchUrl}/extractFileText?token=${credential?.sessionId}&instanceUrl=${credential?.instanceUrl}`, reqBody).then((res) => {

      let extractText = '';
      let payableDataString = res?.data?.responseFromLambda2?.Payload;
      const jsondata1 = JSON.parse(payableDataString);
      const jsondata2 = JSON.parse(jsondata1.body);
      extractText = jsondata2?.split[0].title;
      if (type === "templateName") {
        setTemplateName(extractText);
        toast.success("Title is successfully selected");
      } else if (type === "templateNumber") {
        setTemplateNumber(extractText);
        toast.success("Number is successfully selected");
      }
      if (section === "Title") {
        setIsSelectionTitleLoading(false);
      } else {
        setIsSelectionNumberLoading(false);
      }

    }



    )
  }

  function _selectTitleAndNumber(section = "title") {
    const Text = section === 'title' ? 'Title' : 'Number';
    const Template = section === 'title' ? 'templateName' : 'templateNumber';
    if (_.isEmpty(cropBox)) {
      toast.error("Please crop and select" + section);
    } else {
      if (section === "title") {
        setIsSelectionTitleLoading(true);
      } else {
        setIsSelectionNumberLoading(true);
      }
      console.log("cropBox", cropBox);
      setCropLabel({ ...CropLabel, [section + 'CropBox']: cropBox });
     
	  if (section === "title") {
         extractText1("templateName", cropBox, Text);
      } else {
         extractText1("templateNumber", cropBox, Text);
      }
    }
  }
  async function updatePublishStatusOnBinder(attachId, publishStatus) {
    axios.put(`${fetchUrl}/putPublishStatus/${attachId}/${publishStatus}?token=${credential?.sessionId}&instanceUrl=${credential?.instanceUrl}`)
      .then((res) => {
        console.log(res, " binder published checked?>>>>>");
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  const handleMinimize = async () => {
    axios.put(`${fetchUrl}/publish/start/${credential?.attachementId}`).then((res) => {
        setMinimizeLoading(true);
        _handleClose();
        setMinimizeLoading(false);
        toast.success('Background process started');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  async function closeMainPopup(_open) {
    setOpenMainPopup(_open);
    open_main.current = _open;
  }
  async function fetchWTemplateList(_credential = credential) {
    //console.log('fetchWTemplateList called ::',_credential?.projectId)
    await axios.get( `${fetchUrl}/workflowTemplateList?token=${_credential?.token}&instanceUrl=${_credential?.instanceUrl}`).then((res) => {
      if (res?.status === 200) {
        const listValue = res.data;
        if (listValue?.status === 200) {
          var values = [];
          if(listValue?.templates?.length >  0 ){
            for(let row of listValue?.templates){
              if(row.Status__c =='Active'){
                values.push({label : row.Name , subtitle : row.Description__c , value : row.Id});
              }
            }
          }
          setWorkflowTemplates(values);
        }
      }
    }).catch((err) => {
      console.error(err);
    });
  }

  return {
    pdfFile,
    pdfImages,
    activeStep,
    open,
    setOpenMainPopup : closeMainPopup,
    progress,
    credential,
    newTemplateName,
    templateName,
    templateNumber,
    containerRef,
    selectedRowData,
    selectedTemplate,
    selectedSplitData,
    templateCheck,
    selectedradio,
    duplicateAvailable,
    preSelectedPdfImages,
    handleNext,
    _handleUploadFile,
    _handleClose,
    setSelectedRowData,
    _handleSelect,
    setSelectedTemplate,
    setProgress,
    setPdfImages,
    setPreSelectedPdfImages,
    saveSplit,
    _changeSplitValue,
    setSelectedSplitData,
    setActiveStep,
    _selectTitleAndNumber,
    setNewTemplateName,
    saveNewTemplate,
    columnsB,
    setIsLoading,
    isloading,
    popupLoader,
    setSelectedRadio,
    setUploadWithoutSet,
    isSelectionTitleLoading,
    isSelectionNumberLoading,
    buttonloading,
    setButtonloading, readyForReviewCount, getUnPublishBinderCount,
    handleMinimize,
    fileUrl,
    miniMizeLoading,
    workflowTemplateOptions,
    sourceFolderDetail,
    setSourceFolder,
    folderNameToIdMap, setSubFolderMap,
    Contract_Folder_Name,
    Shop_Folder_Name,
    shopDrawingTypeOptions,
    disciplineOptions,
    setOptions,
    reviewStageOptions
  };
};

export default useFolderTableHook;
