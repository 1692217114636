import { GetToken } from "api";
import { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import { fetchUrl } from "Urls";
import moment from "moment";

const useFormTabHook = (props) => {
  const { projectId } = props;
  const [showSpinner, setSpinner] = useState(false);
  const [tableFormList, setTableFormList] = useState([]);
  const [projectTemplateFormList, setProjectTemplateFormList] = useState([]);
  // filter selection
  const [credential, setCredential] = useState(props?.credentials);
  const [itemTypeOption, setTypeOption] = useState(null);
  const [checkListOptions, setCheckListOptions] = useState(null);

  useEffect(() => {
    //console.log('projectId :: '+ JSON.stringify(props));
    if(projectId){ // && credential?.projectId != projectId
      getInitData();
    }
  }, [projectId]);
  
  async function getInitData() {
    if(projectId){
      setSpinner(true);
      //console.log('useFormTabHook :: '+projectId);
      await fetchFormLists(credential);
    }
    setSpinner(false);
  };

  async function fetchFormLists(_credential = credential) {
    setSpinner(true);
    await axios.get(`${fetchUrl}/formTemplateLists?token=${_credential?.token}&instanceUrl=${_credential?.instanceUrl}&projectId=${_credential?.projectId}`)
    .then((res) => {
      if (res?.status === 200) {
        const reData = res.data;
        if (reData?.status === 200) {
          if(!checkListOptions){
            //console.log('reData?.checkListOptions :: '+JSON.stringify(reData?.checkListOptions));
            setCheckListOptions(reData?.checkListOptions);
          }
          if(!itemTypeOption){
            setTypeOption(reData?.itemTypeOptions);
          }
          const values = _.map(reData?.formTemplates, (row) => {
            return prepareFormTemplateObj(row);
          });
          setTableFormList(values);
          //console.log('reData?.projectFormTemplates :: '+JSON.stringify(reData?.projectFormTemplates));
          const values2 = _.map(reData?.projectFormTemplates, (row) => {
            return prepareFormTemplateObj(row);
          });
          setProjectTemplateFormList(values2);
        }
      }
    }).catch((err) => {
      setSpinner(false);
      console.log('formTemplateLists :: ');
      console.error(err);
    });
    setSpinner(false);
  }
  function prepareFormTemplateObj(row){
    const tablePayload = {
      createdBy: row?.CreatedById ? row?.CreatedBy?.Name : "-",
      createdDate: row?.CreatedDate ? row?.CreatedDate : "-",
      lastUpdatedBy: row?.LastModifiedById ? row?.LastModifiedBy?.Name : "-",
      lastUpdatedDate: row?.LastModifiedDate ? moment(row?.LastModifiedDate).format("MMM D, YYYY") +' at '+moment(row?.LastModifiedDate).format("h:mm A") : "-",
      id: row?.Id ? row?.Id : "-",key: row?.Id ? row?.Id : "-",
      title: row?.Name ? row?.Name : "-",
      formType: row?.Form_Type__c ? row?.Form_Type__c : "-",
      type: row?.Template_Category__c ? row?.Template_Category__c : "-",
      publishCount: row?.Published_To_Project_Count__c > 0 ? row?.Published_To_Project_Count__c : 0,
      itemCount: row?.Number_of_Active_Items__c > 0 ? row?.Number_of_Active_Items__c : 0,
      formCount: row?.Number_of_Forms__c > 0 ? row?.Number_of_Forms__c : 0
    };
    return tablePayload;
  }
  return { tableFormList, projectTemplateFormList,  credential, _fetchFormLists:fetchFormLists ,
    sectionItemResponseOptions : itemTypeOption?.options  ,
    defaultItemResponse : itemTypeOption?.default ,
    templateTypeOptions : checkListOptions?.Template_Category__c?.options  ,
    defaultTemplateType : checkListOptions?.Template_Category__c?.default
  };
  /** ,
    forTabHook : { tableFormList,  credential, _fetchFormLists:fetchFormLists ,
          sectionItemResponseOptions : itemTypeOption?.options  ,
          defaultItemResponse : itemTypeOption?.default ,
          templateTypeOptions : checkListOptions?.Template_Category__c?.options  ,
          defaultTemplateType : checkListOptions?.Template_Category__c?.default } 
  */
};
export default useFormTabHook;
