import { GetToken } from "api";
import { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import { fetchUrl } from "Urls";
import {prepareAmazonRecordRow} from "components/SheetsComponent";

const useIssueTabHook = (props) => {
  const { projectId ,tokenInfo } = props;

  const [showSpinner, setSpinner] = useState(false);
  const [open, setOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [tableIssueList, setTableIssueList] = useState([]);
  // filter selection
  const [typeFilter, setTypeFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [treeFolderStructure, setTreeFolderStructure] = useState([]);
  const [loadingProgress, setLodingProgress] = useState(false);
  const [filesData, setFilesData] = useState();
  const [credential, setCredential] = useState(tokenInfo);

  

  useEffect(() => {
    //console.log('projectId :: '+projectId);
    if(projectId){
      getInitData();
    }
  }, [projectId]);
  
  async function getInitData() {
    if(projectId){
      setSpinner(true);
      await getFilesApi(credential);
      //await fetchIssueLists(credential);
      await _getFolderApi(credential);
    }
    setSpinner(false);
  };

  function _handleOpen(status) {
    setOpen(status);
  }

  function _handleFilterDisplay() {
    setShowFilter(!showFilter);
  }

  //on Add document Click
  function _onAddDocClick() {}

  async function getFilesApi(_credential = credential) {
    let additionalQuery = `Folder__r.Folder_Type__c='Plan'` ; 
    let withfile = await fetchFilesApi(_credential , additionalQuery).catch((err) => {
      console.log("err", err);
    });
    if(withfile?.length > 0 ){
      setFilesData(withfile);
    }else{
      setFilesData([]);
    }
  }
  //console.log("filesData", filesData);

  async function _getFolderApi(_credential = credential) {
    setLodingProgress(true);
    let additionalQuery = `Folder_Type__c='Plan'` ; 
    let reData = await fetchFolderApi(_credential , additionalQuery).catch((err) => {
      console.log(err);
      console.error(err);
      setLodingProgress(false);
    });
    if(reData?.length > 0){
      setTreeFolderStructure(reData);
    }else{
      setTreeFolderStructure([]);
    }
    setLodingProgress(false);
  }

  const closeAndRefresh= async function (refresh) {
    console.log('closeAndRefresh :: '+refresh);
    _handleOpen(false);
    if(refresh == true){
      //await fetchIssueLists(credential);
    }
  }
  const updateIssueTableStateDataWithRow = async (rowEle) => {
    
    if(rowEle.id){
      let _tempClonedList = JSON.parse(JSON.stringify(tableIssueList)) ;
      if( _tempClonedList?.length > 0 ){
        const fInd = _tempClonedList?.findIndex((data) => ( data.id == rowEle.id ));
        if (fInd > -1) {
          _tempClonedList[fInd] = rowEle ;
          setTableIssueList(_tempClonedList); // init folder files
        }
      }
    }
  };
  const updateFolderFilesTableStateDataWithRow = async (rowEle) => {
    
    if(rowEle.id){
      let _currentFolderFiles = JSON.parse(JSON.stringify(filesData)) ;
      if( _currentFolderFiles?.length > 0 ){
        const fInd = _currentFolderFiles?.findIndex((data) => ( data.id == rowEle.id ));
        if (fInd > -1) {
          _currentFolderFiles[fInd] = rowEle ;
          setFilesData(_currentFolderFiles); // init folder files
        }
      }
    }
  };

  return {
    open,
    loadingProgress,
    showFilter,
    tableIssueList,
    _handleOpen,
    _handleFilterDisplay,
    typeFilter,
    credential,
    statusFilter,
    closeAndRefresh,
    _onAddDocClick,updateIssueTableStateDataWithRow,
    updateFolderFilesTableStateDataWithRow,
    treeFolderStructure,
    filesData,
    showSpinner, setSpinner , getFilesApi,
    //fetchIssueLists
  };
};
export async function fetchFilesApi(_credential , additionalQuery) {
  let withfile = [];
  await axios.get(`${fetchUrl}/getallFiles/${_credential?.projectId}?token=${_credential?.token}&instanceUrl=${_credential?.instanceUrl}&filterQuery=${additionalQuery}`)
  .then((res) => {
    if (res?.status === 200) {
      const tree = res?.data?.tree ;
      tree?.map((file) => {
        withfile.push(prepareAmazonRecordRow(file , _credential));
      });
      //console.log('fetchFilesApi tree :: '+ tree?.length);
    }
  });
  return withfile;
};
export async function fetchFolderApi(_credential , additionalQuery) {
  const Data = [];
  await axios.get(`${fetchUrl}/folder?projectId=${_credential?.projectId}&filterQuery=${additionalQuery}`)
  .then(async (res) => {
    if (res?.status === 200) {
      const { tree } = res?.data;
      if (_.keys(tree)?.length > 0) {
        _.forEach(tree?.children, (row) => {
          Data.push(row);
        });
      }
    }
  });
  return Data;
}
export function prepareSobjectToIssueTableRowElement(issue) {
  return issue;
}
export default useIssueTabHook;
