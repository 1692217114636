import React, { useEffect, useRef, useState } from "react";
import ReviewTable from "./components/ReviewTable";
//import ReviewStyle from "./Review.style";
import ReviewHeadSection from "./components/ReviewHeadSection";
import ViewReviewDetails from "./ViewReviewDetails";
import useReviewTabHook from "hooks/ReviewTab.hook";
import { Close, Settings } from "@mui/icons-material";
import {
  IconButton, Tooltip, Box, Button, TextField, Grid, Divider, Autocomplete, 
} from "@mui/material";
import Spinner from "components/spinner";
import styled from "styled-components";
import axios from "axios";
import { fetchUrl } from "Urls";
//import { Toast } from "react-toastify/dist/components";
import { toast } from "react-toastify";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
//import 'react-datepicker/dist/react-datepicker.css';
//import 'react-date-range/dist/styles.css'; // main style file
//import 'react-date-range/dist/theme/default.css'; // theme css file
import Stack from '@mui/material/Stack';
import moment from "moment";
const ReviewTab = (props) => {
  let data = [];

  const { credential, showSpinner, tableDataList, closeAndRefresh, updateTableDataWithRow, setSpinner
    , ...hookProps } = useReviewTabHook(props);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewListData, setViewListData] = useState(tableDataList);
  const [showFilter, setShowFilter] = useState(false);
  const [date, setDate] = useState([]);
  const [temp, setTemp] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  
  var filterObject = useRef([]);
  var filteredList = useRef([]);
  const columns = [
    {
      accessor: "id",
      Header: "Id",
      Cell: (params) => (
        <div className="table_id">
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  position: "absolute",
                  marginLeft: "6px",
                  willChange: "transform",
                  top: "0px",
                  left: "0px",
                  transform: "translate3d(11px, 116px, 0px)",
                  backgroundColor: "#ffa600",
                  borderColor: "#ffa600",
                  padding: "5px 10px",
                  color: "#fff",
                },
              },
            }}
            className="tooltip"
            title="Open"
            placement="right-start"
          >
            <div className="icon_left"></div>
          </Tooltip>
          <Box className="id_content">{params.value}</Box>
        </div>
      ),
    },
    { accessor: "number", Header: "Id", flex: 1, },
    { accessor: "title", Header: "Title", flex: 1, },
    { accessor: "notes", Header: "Workflow template", flex: 1 },
    { accessor: "stage", Header: "Stage", flex: 1, },
    { accessor: "activeApprovers", Header: "Active Participant", flex: 1, },
    { accessor: "docCount", Header: "Total Document", flex: 1, },
    { accessor: "approvedCount", Header: "Approved", flex: 1, },
    { accessor: "rejectedCount", Header: "Rejected", flex: 1, },
    { accessor: "dueDate", Header: "Due Date", flex: 1 },
    { accessor: "createdDate", Header: "Created Date", flex: 1 },
    { accessor: "completedDate", Header: "Completed Date", flex: 1 },
  ];
  const [reviewId, openReviewId] = useState(null);
  const [createPopEnable, setCreatePopEnable] = useState(null);
  const [selectedMenuRow, setSelectedMenuRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);


  function _handleFilterDisplay() {
    setShowFilter(!showFilter);
  }


  const handleClickMenu = (event, row) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedMenuRow(row);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    // console.log("handleCloseMenu handleCloseMenu");
    setAnchorEl(null);
    setSelectedMenuRow(null);
  };
  useEffect(() => {
    filterView();
  }, [tableDataList]);
  const handleChange = (newValue, _filteredList = filteredList.current) => {
    let newDate = moment(newValue.$d).format("DD MMM YYYY");
    setDate(newValue);
    let orgData = filterObject.current && filterObject.current.length > 0 ? JSON.parse(JSON.stringify(_filteredList)) : JSON.parse(JSON.stringify(tableDataList));
    let filterData = orgData;
    if (orgData && newDate && newDate != null && newDate !== "") {
      filterData = [];
      for (let el of orgData) {
        console.log("el", el);
        if ((el.completedDate && el.completedDate.includes(newDate)) || (el.completedDate && el.completedDate.includes(newDate))) {
      
          filterData.push(el);
        }
      }
    }
    if (!filterData) {
      filterData = [];
    }
    filterObject.current = filterData

    setViewListData(filterData);
  };

  function searchFilterFunction(event, _searchTerm, fieldName, _filteredList = filteredList.current) {
    const valuee = _searchTerm;

    let toTemp = [];
    let staticValues = [];
    if (valuee && valuee.length > 0) {
      for (let obj of valuee) {
        staticValues.push(obj.value);
      }
    }
    if (
      filterObject &&
      filterObject.current &&
      filterObject.current.length > 0
    ) {
      toTemp = JSON.parse(JSON.stringify(filterObject.current));
    }
    const fInd = toTemp?.findIndex((data) => data.fieldName == fieldName);
    if (fInd > -1) {
      toTemp[fInd].ele_values = valuee;
      toTemp[fInd].values = staticValues;
    } else {
      toTemp.push({
        fieldName: fieldName,
        values: staticValues,
        ele_values: valuee,
      });
    }
    const fInd2 = toTemp?.findIndex((data) => data.fieldName == fieldName);
    if (fInd2 > -1) {
      if (!(toTemp[fInd2].values && toTemp[fInd2].values.length > 0)) {
        toTemp.splice(fInd2, 1);
      }
    }
    //setFilterObject(toTemp);
    filterObject.current = toTemp;
    setTemp(toTemp);
    let orgData =  viewListData? JSON.parse(JSON.stringify(viewListData)) : JSON.parse(JSON.stringify(tableDataList));
    let filterData = orgData;
    if (orgData && _searchTerm[0] && _searchTerm[0].Name && _searchTerm[0].Name != null && _searchTerm[0].Name !== "") {
     
      filterData = [];
      for (let el of orgData) {
       
        if ((el.activeApprovers && el.activeApprovers.includes(_searchTerm[0].Name)) || (el.activeApprovers && el.activeApprovers.includes(_searchTerm[0].Name))) {
          
          filterData.push(el);
        }
      }
    } else
      if (orgData && _searchTerm[0] && _searchTerm[0].label && _searchTerm[0].label != null && _searchTerm[0].label !== "") {
       
        filterData = [];
        for (let el of orgData) {
       
          if ((el.notes && el.notes.includes(_searchTerm[0].label)) || (el.notes && el.notes.includes(_searchTerm[0].label))) {
           
            filterData.push(el);
          }
        }
      }
    setViewListData(filterData);
  };


  function _handleFilterSelection(event, newValue, fieldName) {
    const valuee = newValue;

    let toTemp = [];
    let staticValues = [];
    if (valuee && valuee.length > 0) {
      for (let obj of valuee) {
        staticValues.push(obj.value);
      }
    }
    if (
      filterObject &&
      filterObject.current &&
      filterObject.current.length > 0
    ) {
      toTemp = JSON.parse(JSON.stringify(filterObject.current));
    }
    const fInd = toTemp?.findIndex((data) => data.fieldName == fieldName);
    if (fInd > -1) {
      toTemp[fInd].ele_values = valuee;
      toTemp[fInd].values = staticValues;
    } else {
      toTemp.push({
        fieldName: fieldName,
        values: staticValues,
        ele_values: valuee,
      });
    }
    const fInd2 = toTemp?.findIndex((data) => data.fieldName == fieldName);
    if (fInd2 > -1) {
      if (!(toTemp[fInd2].values && toTemp[fInd2].values.length > 0)) {
        toTemp.splice(fInd2, 1);
      }
    }
    //setFilterObject(toTemp);
    setTemp(toTemp);
    filterObject.current = toTemp;
    filterView();
  }

  function filterView(_filterObject = filterObject.current) {
    let filterData = [];
    setSearchTerm(null);
    if (tableDataList.length > 0) {
      if (_filterObject && _filterObject.length > 0) {
        for (let item of tableDataList) {
          let filCount = 0;
          let matchCount = 0;
          for (let fil of _filterObject) {
            if (fil.values && fil.values.length > 0) {
              filCount++;
              for (let check of fil.values) {
                if (item[fil.fieldName]?.toLowerCase().indexOf(check.toLowerCase()) > -1) {
                  matchCount++;
                }
              }
            }
          }
          if (filCount == matchCount) {
            filterData.push(item);
          }
        }
      }
    }
    //setFilterList(filterData);
    filteredList.current = filterData;
    filterDataFuntion("");
  }
  function filterDataFuntion(_searchTerm, _filteredList = filteredList.current) {
    let orgData = filterObject.current && filterObject.current.length > 0 ? JSON.parse(JSON.stringify(_filteredList)) : JSON.parse(JSON.stringify(tableDataList));
    let filterData = orgData;
    if (orgData && _searchTerm && _searchTerm != null && _searchTerm !== "") {
      filterData = [];
      for (let el of orgData) {
        if ((el.name && el.name.toLowerCase().includes(_searchTerm)) || (el.title && el.title.toLowerCase().includes(_searchTerm))) {
          filterData.push(el);
        }
      }
    }
    if (!filterData) {
      filterData = [];
    }
    setViewListData(filterData);
  }
  function filterData(filterDatas) {
    setViewListData(filterDatas);
  }

  function getFilterObject(fieldName) {
    const fInd = filterObject.current?.findIndex(
      (data) => data.fieldName == fieldName
    );
    if (fInd > -1) {
      if (
        filterObject.current[fInd].values &&
        filterObject.current[fInd].values.length > 0
      ) {
        return filterObject.current[fInd];
      }
    }
    return;
  }
  function getFilterValues(fieldName, asOption) {
    let values = [];
    let obj = getFilterObject(fieldName);
    if (obj) {
      values = obj.values;
      if (asOption) {
        values = obj.ele_values;
      }
    }
    return values;
  }

  const ReviewStyle = styled.div`
  .table {
    .thead {
      background: #f7f9fa;
      .tr {
        div {
          font-weight: 600;
          box-shadow: none;
          text-align: left;
        }
      }
    }
    .tbody > .tr {
      background: #fff;
    }
  }
  .container {
    .heading {
      font-weight: 600;
      background: #f1f1f1;
      padding: 10px 20px;
      font-size: 18px;
      font-family: "circular";
      display: flex;
      gap: 10px;
      .btn.btn-ornage {
        background: #ffa600;
        color: #fff;
        padding: 3px 5px;
        border-radius: 30px;
        font-size: 12px;
        font-weight: 600;
        height: 30px;
      }
    }
    .heading + div {
      max-height: calc(100vh - 250px);
    }
  }
  .table.tabreview .td {
    font-size: 12px;
    box-shadow: none;
  }

  .table_id {
    .id_content.MuiBox-root {
      width: 120px;
      margin-left: 0;
      transform: translateX(0);
      display: block;
    }
  }
  .container {
    padding: 0 10px;
  }
`;
  const handleCompleteDate = (date ) => {
    if (startDate && endDate) {
      let orgData =  viewListData? JSON.parse(JSON.stringify(viewListData)) : JSON.parse(JSON.stringify(tableDataList));
      const filtered = orgData.filter((item) => {
        const itemDate = new Date(item.completedDate);
        return itemDate >= startDate && itemDate <= endDate;
      });
      setViewListData(filtered);
      if(temp){
        filterObject.current = temp;
        }else{
          filterObject.current = filtered;
        }
    }
  };
  const handleDueDate = (date) => {
    if (startDate && endDate) {
      let orgData =  viewListData? JSON.parse(JSON.stringify(viewListData)) : JSON.parse(JSON.stringify(tableDataList));
      const filtered = orgData.filter((item) => {
        const itemDate = new Date(item.dueDate);
        return itemDate >= startDate && itemDate <= endDate;
      });
      setViewListData(filtered);
      if(temp){
        filterObject.current = temp;
        }else{
          filterObject.current = filtered;
        }
    }
  };
  
  const handleStartDateChange = (date) => {
    //let newDate = moment(date.$d).format("DD MMM YYYY");
    setStartDate(date.$d);
  };

  const handleEndDateChange = (date) => {
   // let newDate = moment(date.$d).format("DD MMM YYYY");
    setEndDate(date.$d);
  };
  return (
    <ReviewStyle>
      <div className="container">
        {!createPopEnable && (<>
          <div style={{ backgroundColor: "white" }}>
            <ReviewHeadSection
              headProp={{
                setShowFilter,
                _handleOpen: () => { console.log('handleOpen clicked') },
                filterDataFuntion,
                _handleFilterDisplay,
                searchTerm,
                setSearchTerm,
                filterView,
                onClearFilter: () => {
                  filterObject.current = [];
                  filterView([]);
                  setShowFilter(false);
                },
                _filteredList: filteredList.current,
                _filterObject: filterObject.current,
              }}
              _filterList={filterData}
            />
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {showFilter && (
              <div style={{ width: '20%' }}>
                <div className="versions-sidebar-header">
                  <span className="versions-sidebar-header-title">Filter</span>
                  <IconButton
                    size="small"
                    className="versions-sidebar-header-close"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <Close />
                  </IconButton>
                </div>
                <Divider variant="middle" />
                <div
                  className="versions-sidebar-body"
                  style={{ height: "calc(91.7vh - 200px)" }}
                >
                  <Grid
                    container
                    rowSpacing={2}
                    style={{ width: "100%", paddingTop: "15px" }}
                    className="sf-detail-container"
                  >
                    <Grid item xs={12}>
                      {hookProps?.stageList.Stage__c.options && hookProps?.stageList.Stage__c.options.length > 0 && (
                        <div style={{ paddingTop: "10px" }}>
                          <Autocomplete
                            multiple
                            className="inlineAutocomplete"
                            options={hookProps?.stageList.Stage__c.options}
                            getOptionLabel={(option) => option?.label}
                            defaultValue={getFilterValues("stage", true)}
                            onChange={(e, newValue) => {
                              _handleFilterSelection(e, newValue, "stage");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Stage"
                              />
                            )}
                          />
                        </div>
                      )}
                      {hookProps?.userList && hookProps?.userList.length > 0 && (
                        <div style={{ paddingTop: "10px" }}>
                          <Autocomplete
                            multiple
                            className="inlineAutocomplete"
                            options={hookProps?.userList}
                            getOptionLabel={(option) => option?.label}
                            defaultValue={getFilterValues("activeApprovers", true)}
                            onChange={(e, newValue) => {
                              searchFilterFunction(e, newValue, "activeApprovers");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Active Participant"
                              />
                            )}
                          />
                        </div>
                      )}
                      {hookProps?.userList && hookProps?.userList.length > 0 && (
                        <div style={{ paddingTop: "10px" }}>
                          <Autocomplete
                            multiple
                            className="inlineAutocomplete"
                            options={hookProps?.userList}
                            getOptionLabel={(option) => option?.label}
                            defaultValue={getFilterValues("createdById", true)}
                            onChange={(e, newValue) => {
                              _handleFilterSelection(e, newValue, "createdById");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Created by"
                              />
                            )}
                          />
                        </div>
                      )}
                      {hookProps?.workflowTemplateOptions && hookProps?.workflowTemplateOptions.length > 0 && (
                        <div style={{ paddingTop: "10px" }}>
                          <Autocomplete
                            multiple
                            className="inlineAutocomplete"
                            options={hookProps?.workflowTemplateOptions}
                            getOptionLabel={(option) => option?.label}
                            defaultValue={getFilterValues("notes", true)}
                            onChange={(e, newValue) => {
                              searchFilterFunction(e, newValue, "notes");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Workflow Template"
                              />
                            )}
                          />
                        </div>
                      )}
                      <div style={{ paddingTop: "10px" }}>
                      Completed Date
                      <div style={{display: "flex"}}>
                        <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} className="margin-top-8">
                            <DesktopDatePicker
                              inputFormat="DD/MMM/YYYY"
                              value={startDate}
                              onChange={handleStartDateChange}
                              renderInput={(params) => <TextField {...params}
                                label="Start Date"
                              />}
                            />

                          </Stack>
                        </LocalizationProvider>
                        </div>
                        <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} className="margin-top-8">
                            <DesktopDatePicker
                              inputFormat="DD/MMM/YYYY"
                              value={endDate}
                              onChange={handleEndDateChange}
                              renderInput={(params) => <TextField {...params}
                                label="End Date"
                              />}
                            />

                          </Stack>
                        </LocalizationProvider>
                        </div>
                        </div>
                        <Button variant="contained" onClick={handleCompleteDate}>
                          Filter
                        </Button>
                      </div>
                      <div style={{marginTop: "10px"}}>
                      <Divider />
                      </div>
                      <div style={{ paddingTop: "10px" }}>
                      Due Date
                      <div style={{display: "flex"}}>
                        <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} className="margin-top-8">
                            <DesktopDatePicker
                              inputFormat="DD/MMM/YYYY"
                              value={startDate}
                              onChange={handleStartDateChange}
                              renderInput={(params) => <TextField {...params}
                                label="Start Date"
                              />}
                            />

                          </Stack>
                        </LocalizationProvider>
                        </div>
                        <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} className="margin-top-8">
                            <DesktopDatePicker
                              inputFormat="DD/MMM/YYYY"
                              value={endDate}
                              onChange={handleEndDateChange}
                              renderInput={(params) => <TextField {...params}
                                label="End Date"
                              />}
                            />

                          </Stack>
                        </LocalizationProvider>
                        </div>
                        </div>
                        <Button variant="contained" onClick={handleDueDate}>
                          Filter
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>

            )}

            <div style={{ width: showFilter ? '80%' : "100%" }}>
              {showSpinner && <Spinner />}

              <ReviewTable
                credential={credential}
                tableProps={{ viewListData, columns, showFilter }}
                openReviewDetail={(row) => {
                  openReviewId(row.id);
                  setCreatePopEnable(true);
                }}
                handleClickMenu={handleClickMenu}
              />
            </div>
          </div>
        </>)}
        {createPopEnable && (
          <>
            <ViewReviewDetails
              credential={credential}
              reviewId={reviewId}
              userList={hookProps?.userList}
              teamStatusValues={hookProps?.docRvwPickWrapper?.Review_Status__c?.valueList}
              handleClose={(refresh) => {
                closeAndRefresh(refresh);
                openReviewId(false);
                setCreatePopEnable(false);
              }}
            />
          </>
        )}
      </div>

    </ReviewStyle>
  );
};

export default ReviewTab;
