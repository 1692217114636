import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  TextField,
  Box,
  ClickAwayListener,
  Chip,
} from "@mui/material";
import {
  FilterAlt,
  Search,
  ArrowDropDown,
  ArrowDropUp,
} from "@mui/icons-material";
//import HeadIssueStyle from "./ReviewHeadSection.style";
import ExportDialog from "./ExportDialog";
import styled from "styled-components";

const ReviewHeadSection = (props) => {
  const {
    _handleOpen,
    _handleFilterDisplay,
    searchTerm,
    setSearchTerm,
    filterDataFuntion,
    _filterObject,
    _filteredList,
    onClearFilter,
  } = props?.headProp;
  const [filteredList, setFilteredList] = useState(_filteredList);
  const [filterObject, setFilterObject] = useState(_filterObject);
  const [exportOpen, setExportOpen] = useState(false);
  const [dropDownValue, setDropDownValue] = useState("");
  const [openExport, setOpenExport] = useState(false);
  useEffect(() => {
    //console.log('projectId :: '+projectId);
    setFilterObject(_filterObject);
  }, [_filterObject]);
  const handleDropDown = (e) => {
    setDropDownValue(e.target.value);
    if (e.target.value === 0 || e.target.value === 1) {
      setOpenExport(true);
    }
  };
  const handleClose = () => {
    setOpenExport(false);
    setExportOpen(false);
  };
  const handleClickAway = () => {
    setExportOpen(false);
  };

  const HeadIssueStyle = styled.div`
  .issueHead_section {
    display: flex;
    margin: 15px -4px;
    width: 100%;
    justify-content: space-between;
    .firstSection,
    .secondSection {
      display: flex;
      max-width: 50%;
    }
    .firstSection {
      max-width: 500px;
    }
    .secondSection {
      .MuiFormControl-root,
      button {
        margin: 0 !important;
      }
    }
  }
`;

  return (
    <HeadIssueStyle>
      <Box className="issueHead_section">
        <Box className="firstSection">
          <Box className="filter_button">
            <IconButton onClick={() => _handleFilterDisplay()}>
              <FilterAlt />
            </IconButton>
          </Box>
          <Box className="searchField">
            <IconButton>
              <Search />
            </IconButton>
            <TextField
              placeholder="Search"
              onChange={(e) => {
                //convert input text to lower case
                var lowerCase = e.target.value.toLowerCase();
                if (!lowerCase || lowerCase == null || lowerCase === "") {
                  //console.log(lowerCase);
                  filterDataFuntion(lowerCase, filteredList);
                }
                setSearchTerm(lowerCase);
              }}
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  // Do code here
                  e.preventDefault();
                  filterDataFuntion(searchTerm, filteredList);
                }
              }}
            />
          </Box>
          {filterObject?.length > 0 && (
            <div>
              <Chip
                color="success"
                label="Filtering is on"
                onDelete={onClearFilter}
              />
            </div>
          )}
        </Box>
        <Box className="secondSection">
          {/* <SelectComponent data={{
                        selectValue: "",
                        handleChange: (e) => console.log(e.target.value),
                        label: "Export",
                        options: [
                            { value: "pdf", label: "PDF report" },
                            { value: "xlsx", label: "XLSX report" },
                            { value: "csv", label: "CSV report" }
                        ]
                    }} /> */}
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box
              className={`Dropdown ProjectViewExport__export_dropdown ${
                exportOpen ? "open" : ""
              }`}
            >
              <Button
                onClick={() => setExportOpen(!exportOpen)}
                className="export_button "
              >
                <span className="export_text">Export</span>
                <span className="export_button-icon">
                  {exportOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                </span>
              </Button>

              {exportOpen && (
                <ul className="export_dropdown_list">
                  <Box onClick={handleDropDown}>
                    <li value="0">PDF report</li>
                  </Box>
                  <Box onClick={handleDropDown}>
                    <li value="1" onClick={handleDropDown}>
                      XLSX report
                    </li>
                  </Box>
                  <Box onClick={handleDropDown}>
                    <li value="2" onClick={handleDropDown}>
                      CSV report
                    </li>
                  </Box>
                </ul>
              )}
            </Box>
          </ClickAwayListener>

          {/* <Box>
            <Button variant="contained" onClick={() => _handleOpen(true)}>
              Create issue
            </Button>
          </Box> */}
        </Box>
        {/* <ExportDialog
          title={`Export ${dropDownValue === 0 ? "PDF" : "XLSX"} `}
          open={openExport}
          handleClose={handleClose}
        /> */}
      </Box>
    </HeadIssueStyle>
  );
};

export default ReviewHeadSection;
