import Styled from "styled-components";
const SplitButtonActionStyle = Styled.div`
.Button-1 {
    &:hover {
      background: #f8f8f8;
    }
    width: auto;
    height: 32px;
    text-transform: none;
    border-radius: 2px;
    border-top: 1px solid #D8D8D8;
    border-left: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    gap: 8px;
    background: #ffffff;
    color: #505050;
    box-shadow: none;
    font-weight: 400;
    font-family: var(--common-font);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .Button-dropDown {
    &:hover {
        background: #f8f8f8;
      }
      cursor: pointer;
      width: 25px;
      height: 32px;
      text-transform: none;
      border-radius: 2px;
      border-top: 1px solid #D8D8D8;
      border-left: 1px solid #D8D8D8;
      border-bottom: 1px solid #D8D8D8;
      border-right: 1px solid #D8D8D8;
      gap: 8px;
      background: #ffffff;
      color: #505050;
      box-shadow: none;
      font-weight: 400;
      font-family: var(--common-font);
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  .dropdown-menu {
    position: absolute;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    z-index: 101;
  }
  .listView {
    &:hover {
      background: #f8f8f8;
    }
    cursor: pointer;
  }
  .Button-container {
    width: auto;
    height: 39px;
    text-transform: none;
    border-radius: 4px;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    gap: 8px;
    background: #346EF5;
    color: white;
    box-shadow: none;
    font-weight: 400;
    font-family: var(--common-font);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .Button-container-dropDown {
    cursor: pointer;
      width: 25px;
      height: 39px;
      text-transform: none;
      border-radius: 2px;
      border-top: 1px solid white;
      border-left: 1px solid white;
      border-bottom: 1px solid white;
      border-right: 1px solid white;
      gap: 8px;
      background: #346EF5;
      color: #505050;
      box-shadow: none;
      font-weight: 400;
      font-family: var(--common-font);
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
  }
  .list-style {
    color: #505050;
    font-family: var(--common-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export default SplitButtonActionStyle;