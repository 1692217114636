import React from "react";
import { Grid , TextField , Autocomplete} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import moment from 'moment';

const ChecklistItem = ({ item , dispatchObj , ...props }) => {
  //console.log('********ChecklistItemDetails ', ChecklistItemDetails);
  const [sfObj, setsfObj] = React.useState({});
  const [assignTo, setAssignTo] = React.useState(null);
  const [formDate, setFormDate] = React.useState(new Date());

  const handleChange = (_sfObj) => {
    //console.log('handleChange sfObj :: '+JSON.stringify(sfObj));
    //console.log('handleChange _sfObj :: '+JSON.stringify(_sfObj));
    dispatchObj({type: 'HANDLE_REPLACE_ITEM' , sectionIndex : props.index , value : _sfObj});
  };

  React.useEffect(() => {
    setVariables(item);
  },[item]);

  function setVariables(_sfObj) {
    setAssignTo({label : _sfObj?.Ball_in_Court__c ? _sfObj?.Ball_in_Court__r?.Name : '' , value : _sfObj?.Ball_in_Court__c});
    if(_sfObj.Date__c){
      setFormDate(new Date(_sfObj.Date__c));
    }else{
      setFormDate(null);
    }
    setsfObj(_sfObj);
  }
  
  return (
    <>
    <Grid item xs={2}></Grid>
    <Grid item xs={3}>
      <TextField id={"template-title "+props.index} fullWidth label="Template Title" variant="outlined" size="small" required value={sfObj?.Name}
        onChange={(e) => {
          let fff = JSON.parse(JSON.stringify(sfObj)); fff.Name = e?.target?.value; setVariables(fff);
          handleChange(fff);
        }}
        onKeyDown={(event) => {
          if (event.key.toLowerCase() != "tab") {
            event.stopPropagation();
          }
        }} />
    </Grid>
    <Grid item xs={3}>
      <Autocomplete
        className="common_class inlineAutocomplete"
        disablePortal
        value={assignTo}
        getOptionLabel={(option) => option?.label ? option?.label : ''}
        onChange={(event, newValue) => {
          let fff = sfObj; fff.Ball_in_Court__c = newValue?.value;
          fff.Ball_in_Court__r = { Id: newValue?.value, Name: newValue?.label };
          setVariables(fff);
          handleChange(fff);
        }}
        options={[...[{ label: 'None..', value: null }], ...props?.userList]}
        renderInput={(params) => (
          <div>
            <TextField id={"assignedTo "+props.index} variant="outlined" label="Assigned To" {...params} />
          </div>
        )}
      />
    </Grid>
    <Grid item xs={3}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          className="common_class"
          disablePast={false}
          placeholder="Form Date"
          inputFormat="MM/dd/yyyy"
          value={formDate}
          onChange={(newValue) => {
            let fff = sfObj;
            if (newValue) {
              fff.Date__c = moment(newValue).format("YYYY-MM-DD");
            } else {
              fff.Date__c = null;
            }
            setVariables(fff);
            handleChange(fff);
          }}
          renderInput={(params) =>
            <div>
              <TextField id={"formDate "+props.index} variant="outlined" label="Form Date" required {...params} />
            </div>
          }
        />
      </LocalizationProvider>
    </Grid>
    <Grid item xs={1}></Grid>
    </>
  );
};

export default ChecklistItem;
