import React from "react";
import axios from "axios";
import { fetchUrl } from "Urls";

import {
  Button,
  TextField,
  IconButton,
  Grid,
  Divider,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close, Delete, Comment } from "@mui/icons-material";
import Spinner from "components/spinner";

const useStyles = makeStyles({
  formControl: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
});

function PublishTemplateModal({
  formTemplateId,
  credential,
  handleClose,
  ...props
}) {
  //console.log('********ChecklistItemDetails ', ChecklistItemDetails);
  const [publishedDetails, setPublishedDetails] = React.useState([]);
  const [publishedDetails_view, setPublishedDetails_view] = React.useState([]);
  const [availableProjects, setAvailableProjects] = React.useState([]);
  const [availableProjects_view, setAvailableProjects_view] = React.useState(
    []
  );
  const [showSpinner, setSpinner] = React.useState(false);
  const [refreshTemplates, setRefreshTemplates] = React.useState(false);
  const [searchText, setSearchText] = React.useState(null);
  const [selectedProjectIds, setSelectedProjects] = React.useState([]);

  const classes = useStyles();
  React.useEffect(() => {
    getInitData();
  }, []);
  async function getInitData() {
    setSearchText(null);
    setSelectedProjects(null);
    setSpinner(true);
    await axios
      .get(
        `${fetchUrl}/formtemplate/publishdata/${formTemplateId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`
      )
      .then((res) => {
        //console.log("count:::: " + JSON.stringify(res));
        if (res.status === 200) {
          setPublishedDetails(res.data.publishedList);
          setPublishedDetails_view(res.data.publishedList);
          setAvailableProjects(res.data.availableProjects);
          setAvailableProjects_view(res.data.availableProjects);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setSpinner(false);
  }
  async function onPublishClick() {
    setSpinner(true);
    let toSaveRecords = [];
    if (selectedProjectIds && selectedProjectIds.length > 0) {
      for (let projectId of selectedProjectIds) {
        toSaveRecords.push({
          Project__c: projectId,
          Form_Template__c: formTemplateId,
        });
      }
    }
    const formData = new FormData();
    //let issueObj = sfIssueObj;
    formData.append("recLists", JSON.stringify(toSaveRecords));
    await axios
      .post(
        `${fetchUrl}/recSave/list/Project_Form_Template__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,
        formData
      )
      .then(async (res) => {
        //console.log("count:::: " + JSON.stringify(res));
        if (res.status === 200) {
          setRefreshTemplates(true);
          await getInitData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setSpinner(false);
  }
  async function handleSearch(searchText) {
    setSearchText(searchText);
    let pubDetails = publishedDetails
      ? JSON.parse(JSON.stringify(publishedDetails))
      : undefined;
    //console.log('pubDetails :: '+pubDetails?.length);
    if (
      searchText &&
      searchText != "" &&
      publishedDetails &&
      publishedDetails.length > 0
    ) {
      pubDetails = [];
      for (let obj of publishedDetails) {
        if (
          obj.Project__r.Name.toLowerCase().includes(
            searchText.toLowerCase()
          ) ||
          obj.Project__r.Project_Number__c.toLowerCase().includes(
            searchText.toLowerCase()
          )
        ) {
          pubDetails.push(obj);
        }
      }
    }
    setPublishedDetails_view(pubDetails);
    let projDetails = availableProjects
      ? JSON.parse(JSON.stringify(availableProjects))
      : undefined;
    if (
      searchText &&
      searchText != "" &&
      availableProjects &&
      availableProjects.length > 0
    ) {
      projDetails = [];
      for (let obj of availableProjects) {
        if (
          obj.Name.toLowerCase().includes(searchText.toLowerCase()) ||
          obj.Project_Number__c.toLowerCase().includes(
            searchText.toLowerCase()
          )
        ) {
          projDetails.push(obj);
        }
      }
    }
    setAvailableProjects_view(projDetails);
  }

  return (
    <>
      <Dialog fullWidth scroll="paper" open={true}>
        <DialogTitle>
          <Typography>Publish Template to project ?</Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              handleClose(refreshTemplates);
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: "20px", position: "relative" }}>
          {showSpinner && <Spinner />}
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={10}>
              <TextField
                label="Search Project"
                variant="outlined"
                size="small"
                inputProps={{ className: "desc_input" }}
                className="question_desc"
                fullWidth
                value={searchText}
                onChange={(e) => {
                  handleSearch(e?.target?.value);
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <Divider textAlign="left" variant="fullWidth">
                Projects Using this template
              </Divider>
              <List
                className="publish_items"
                sx={{ width: "100%", bgcolor: "background.paper" }}
              >
                {publishedDetails_view &&
                  publishedDetails_view.length > 0 &&
                  publishedDetails_view.map((q, index) => {
                    const labelId = `checkbox-list-label-${q.Project__r.Name}`;
                    return (
                      <ListItem
                        key={q.Id}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="comments"
                            onClick={async () => {
                              console.log("delete");
                              setSpinner(true);
                              await axios
                                .delete(
                                  `${fetchUrl}/delete/Project_Form_Template__c/${q.Id}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`
                                )
                                .then(async (res) => {
                                  setRefreshTemplates(true);
                                  await getInitData();
                                })
                                .catch((err) => {
                                  console.log(err);
                                });
                              setSpinner(false);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        }
                        disablePadding
                      >
                        <ListItemButton role={undefined} dense>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                              disabled
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={`${q.Project__r.Project_Number__c}-${q.Project__r.Name}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
            </Grid>
            <Grid item xs={10}>
              <Divider textAlign="left" variant="fullWidth">
                Available Projects
              </Divider>
              <List
                className="publish_items"
                sx={{ width: "100%", bgcolor: "background.paper" }}
              >
                {availableProjects_view &&
                  availableProjects_view.length > 0 &&
                  availableProjects_view.map((q, index) => {
                    const labelId = `checkbox-list-label-${q.Name}`;
                    return (
                      <ListItem key={q.Id} disablePadding>
                        <FormControlLabel
                          className={classes.formControl}
                          control={
                            <ListItemButton role={undefined} dense>
                              <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                                onChange={(event) => {
                                  let check = event.target.checked;
                                  let selectedIds = selectedProjectIds;
                                  if (check) {
                                    if (!selectedIds) {
                                      selectedIds = [];
                                    }
                                    if (!selectedIds.includes(q.Id)) {
                                      selectedIds.push(q.Id);
                                    }
                                  } else {
                                    if (!selectedIds) {
                                      selectedIds = [];
                                    }
                                    let i = selectedIds.indexOf(q.Id);
                                    if (i > -1) {
                                      selectedIds.splice(i, 1);
                                    }
                                  }
                                  //console.log('selectedIds :: ' + JSON.stringify(selectedIds));
                                  setSelectedProjects(selectedIds);
                                }}
                              />
                            </ListItemButton>
                          }
                          label={`${q.Project_Number__c} - ${q.Name}`}
                        />
                        {/* </ListItemButton> */}
                      </ListItem>
                    );
                  })}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              handleClose(refreshTemplates);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onPublishClick();
            }}
          >
            Publish
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PublishTemplateModal;
