import { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import { fetchUrl } from "Urls";
import moment from "moment";

const useWorkflowTemplateTabHook = (props) => {
  const { projectId, tokenInfo } = props;

  const [showSpinner, setSpinner] = useState(false);
  const [tableTemplateList, setTableTemplateList] = useState([]);
  // filter selection
  const [credential, setCredential] = useState(tokenInfo);

  const [templatePickWrap, setTemplatePickWrap] = useState(null);
  const [stepPickWrap, setStepPickWrap] = useState(null);
  const [userList, setUserList] = useState([]);
  const [projectTeamRoles, setSetProjectTeamRoles] = useState([]);

  useEffect(() => {
    //console.log('projectId :: '+projectId);
    if (projectId) {
      getInitData();
    }
  }, [projectId]);

  async function getInitData() {
    if (projectId) {
      await fetchWTemplateList(credential);
    }
    setSpinner(false);
  }
  async function fetchWTemplateList(_credential = credential) {
    setSpinner(true);
    await axios.get( `${fetchUrl}/workflowTemplateList?token=${_credential?.token}&instanceUrl=${_credential?.instanceUrl}&type=fetch_picklist`).then((res) => {
      if (res?.status === 200) {
        const listValue = res.data;
        if (listValue?.status === 200) {
          setTemplatePickWrap(listValue.templatePickWrapper);
          setStepPickWrap(listValue.stepPickWrapper);
          setUserList(listValue.userList);
          setSetProjectTeamRoles(listValue.projectTeamRoles);
          const values = _.map(listValue?.templates, (row) => {
            return prepareRowElement(row);
          });
          setTableTemplateList(values);
        }
      }
    }).catch((err) => {
      setSpinner(false);
      console.error(err);
    });
    setSpinner(false);
  }
  const closeAndRefresh = async function (refresh) {
    //console.log("closeAndRefresh :: " + refresh);
    if (refresh == true) {
      await fetchWTemplateList(credential);
    }
  };
  const updateTableStateDataWithRow = async (rowEle) => {
    
    if(rowEle.id){
      let _tempClonedList = JSON.parse(JSON.stringify(tableTemplateList)) ;
      if( _tempClonedList?.length > 0 ){
        const fInd = _tempClonedList?.findIndex((data) => ( data.id == rowEle.id ));
        if (fInd > -1) {
          _tempClonedList[fInd] = rowEle ;
          setTableTemplateList(_tempClonedList); // init folder files
        }
      }
    }
  };
  const prepareRowElement = (sObj) => {
    let tablePayload = {
      record: sObj,
      id: sObj?.Id ? sObj?.Id : "",
      key: sObj?.Id ? sObj?.Id : "",
      name: sObj?.Name ? sObj?.Name : "",
      reviewCreated: sObj?.Reviews__r?.length > 0,
      createdBy: sObj?.CreatedById ? sObj?.CreatedBy?.Name : "-",
      createdById: sObj?.CreatedById ? sObj?.CreatedById : "-",
      createdDate: sObj?.CreatedDate ? moment(sObj?.CreatedDate).format("DD MMM YYYY HH:mm") : "-",
      updatedBy: sObj?.LastModifiedById ? sObj?.LastModifiedBy?.Name : "-",
      updatedDate: sObj?.LastModifiedDate ? moment(sObj?.LastModifiedDate).format("DD MMM YYYY HH:mm") : "-",
      status: sObj?.Status__c ? sObj?.Status__c : "-",
      description: sObj?.Description__c ? sObj?.Description__c : "",
      step: sObj?.Workflow_Step__c ? sObj?.Workflow_Step__c : "",
      stepCount: sObj?.Workflow_Step__c ? Number(sObj?.Workflow_Step__c.substring(0, sObj?.Workflow_Step__c.indexOf("-Step Review"))) : "-",
    };
    return tablePayload;
  };

  return { showSpinner ,  tableTemplateList , updateTableStateDataWithRow ,closeAndRefresh , credential , templatePickWrap , stepPickWrap, userList, projectTeamRoles};
};
export default useWorkflowTemplateTabHook;
