import React, { useState, useEffect, useRef } from "react";
import { cloneDeep } from "lodash";
import _ from "lodash";
import axios from "axios";
import { fetchUrl } from "Urls";
import {
  ButtonGroup,
  Button,
  IconButton,
  Typography,
  Box,
  Autocomplete,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Close, Settings } from "@mui/icons-material";
import Spinner from "components/spinner";
import moment from "moment";
import ReviewDetailTable from "../components/ReviewDetails";
//import { getMarkupBreakDownFromInstantJSON } from "components/FolderTable";
import DocumentComments from "./DocumentComments";
import DelegateReview from "./DelegateReview";
import styled from "styled-components";


function ViewReviewDetails({ reviewId, credential, handleClose, ...props }) {
  const [showSpinner, setSpinner] = useState(false);
  const [updatingState, setUpdateState] = useState(false);
  const [updatingDelegateUser, setUpdateDelegateUser] = useState(false);
  const [userId, setUserId] = useState({ label: credential?.userFullName, value: credential?.userId });
  const [documentReviewList, setDocumentReviewList] = useState([]);
  const [sfObj, setsfObj] = React.useState(null);
  const [name, setName] = useState(null);
  const [desc, setDesc] = useState(null);
  var __sfObj = useRef(null);
  const [voidConfirm, setVoidConfrim] = useState(false);
  const [delegateConfirm, setDelegateConfirm] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const statusOptions = ['Approved', 'Rejected', 'Void']; // 'Approved','Rejected','Void'
  const [currentCommentRow, setSelectedCommentRow] = useState(null);
  const [toSubmitDetails, setSubmitDetails] = useState(null);
  const [toStepChangeDocReviews, setStepChangeDocReviews] = useState([]);
  const [docOnCurrentStepId, setDocOnCurrentStepId] = useState(null); // this contains id of workflow step if all doc on same step
  const [currentStepApprovers, setCurrentStepApprovers] = useState([]);
  const [avalableUserList, setAvalableUserList] = useState([]);
  const columns = [
    { accessor: "docName", Header: "Name", flex: 1, disableSortBy: true },
    { accessor: "folderPath", Header: "Folder path", flex: 1, disableSortBy: true, },
    {
      accessor: "commentsText", Header: "Comments", flex: 1, disableSortBy: true, width: 100,
      Cell: (params) => {
        const [curDoc, setCurDoc] = useState(params.row.original);
        return (
          <div className="" onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
            <div>
              {params.row.original.comments.length} comment/s
            </div>
          </div>
        )
      }
    },
    {
      accessor: "status", Header: "Review Status", flex:0,width: 250,
      Cell: (params) => {
        const [curDoc, setCurDoc] = useState(params.row.original);
        const [sts, setStatus] = useState(curDoc?.currAppStep?.Review_Status__c);
        return (
          <div className="dd" onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
            {curDoc?.currAppStep && curDoc?.currentStep?.participantType == 'Approver' &&
              <Autocomplete
                id={curDoc.Id}
                className="inlineAutocomplete"
                value={sts}
                getOptionLabel={(option) => option}
                onChange={async (e, newValue) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if(newValue){
                    let toSave = {Id : curDoc?.currAppStep.Id , Review_Status__c : newValue } ;
                    await updateCurrentStep(curDoc?.currAppStep , toSave , null , null, setStatus);
                    return;
                  }
                }}
                disabled={sfObj.Stage__c == "Closed"}
                options={statusOptions}
                renderInput={(params) => (
                  <div>
                    <TextField id={'number of step '+curDoc.Id} {...params} />
                  </div>
                )}
              />
            }

          </div>
        )
      },
      disableSortBy: true,
    },
    { accessor: "docTitle", Header: "Title", flex: 1, disableSortBy: true },
    { accessor: "description", Header: "Description", flex: 1, disableSortBy: true },
    { accessor: "version", Header: "Version", flex: 1, disableSortBy: true, width: 100 },
    { accessor: "markup", Header: "Total Markup", flex: 1, disableSortBy: true, width: 100 },
    { accessor: "dueDate", Header: "Due Date", flex: 1, disableSortBy: true},
    

    { accessor: "currentParticipant", Header: "Active Participants", flex: 1, disableSortBy: true,
      Cell: (params) => {
        const [curDoc, setCurDoc] = useState(params.row.original);
        return (
          <>
            {curDoc.toActiveApproversNames?.length > 0 && curDoc.toActiveApproversNames.map(aprover => (
              <tr >
                {aprover}
              </tr>
            ))}
          </>
        )
      }
    },
    { accessor: "Setting", Header: <Settings />, flex: 1, disableSortBy: true,
      Cell: (params) => {
        const [curDoc, setCurDoc] = useState(params.row.original);
        return (
          <div className="" onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
            {curDoc?.docAppInfo &&
              <Box className="id_content" style={{ color: 'red' }}>{curDoc?.docAppInfo.msg}</Box>
            }
            {!curDoc?.docAppInfo &&
              <>
                {curDoc?.currAppStep && ( curDoc?.currentStep?.participantType == 'Reviewer' || curDoc?.currentStep?.participantType == 'Approver') &&
                  <>
                    {sfObj.Stage__c !== "Closed" &&
                      <Button variant="contained" size="small" onClick={async (e)=>{e.preventDefault(); e.stopPropagation();
                          let toSave = {Id : curDoc?.currAppStep.Id , Review_Status__c : 'Reviewed'} ;
                          if(curDoc?.currentStep?.participantType == 'Approver'){
                            toSave = {Id : curDoc?.currAppStep.Id} ;
                          }
                          toSave.Review_Captured__c = true;
                          toSave.Actual_Approver__c = userId?.value;
                          let toSubDet = {'cStep' : curDoc?.currAppStep , toSave : toSave , nextAppStepDoc : curDoc?.toUpdateForNextApprovalState ,
                                        toSubmitAnyApproverTeam :  curDoc?.toSubmitAnyApproverTeam, setvalue : null};
                            setSubmitDetails(toSubDet);
                          //await updateCurrentStep(curDoc?.currAppStep , toSave  , null);
                          }
                        }
                        disabled={(curDoc?.currentStep?.participantType == 'Approver'
                                  && curDoc?.currAppStep?.Review_Status__c != 'Approved'
                                  && curDoc?.currAppStep?.Review_Status__c != 'Rejected'
                                  && sfObj.Stage__c !== "Void" )}
                        >
                        Submit Review
                      </Button>
                    }
                  </>
                }
              </>
            }
          </div>
        )
      }
    },
    { accessor: "commentsAdd", Header: "", flex: 1, disableSortBy: true,
      Cell: (params) => {
        const [curDoc, setCurDoc] = useState(params.row.original);
        return (
          <div className="" onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
            {/*curDoc?.currAppStep && sfObj.Stage__c !== "Void" && (curDoc?.currentStep?.participantType == 'Approver' || curDoc?.currentStep?.participantType == 'Reviewer') */ true &&
              <Button variant="contained" size="small" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setSelectedCommentRow(curDoc) }}>
                {curDoc?.currAppStep && sfObj.Stage__c !== "Void" && (curDoc?.currentStep?.participantType == 'Approver' || curDoc?.currentStep?.participantType == 'Reviewer') && curDoc?.docAppInfo?.msg != 'Completed' ?
                  <>
                    View/Add Comment/s
                  </> :<>
                    View Comment/s
                  </>
                }
              </Button>
            }
          </div>)
      }
    },
  ];
  useEffect(() => {
    console.log('reviewId');
    //console.log('props.teamStatusValues',props?.teamStatusValues);
    if (reviewId) {
      getReviewDetails();
    }
  }, []);
  useEffect(() => {
    let ff= [];
    if (props.userList && props.userList?.length > 0 ) {
      ff = _.filter(props.userList, obj => !_.some(currentStepApprovers, exisTeam => obj.Id === exisTeam.Approver__c) );
    }
    setAvalableUserList(ff);
  }, [currentStepApprovers]);
  const updateCurrentStep = async (cStep , saveRec , nextAppStepDoc , toUpdateAnyApproverTeams, setvalue) => {
    setUpdateState(true);
    const formData = new FormData();
    formData.append("recObj", JSON.stringify(saveRec));
    await axios.post(`${fetchUrl}/recSave/Document_Review_Team__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,formData)
    .then(async (res) => {
      //console.log("count:::: " + JSON.stringify(res));
      if(res.status === 200){
        if(setvalue){
          setvalue(saveRec.Review_Status__c);
        }
        if( toUpdateAnyApproverTeams && toUpdateAnyApproverTeams?.length > 0 && saveRec.Review_Captured__c === true){
          for(let ff of toUpdateAnyApproverTeams){
            ff.Review_Captured__c = saveRec?.Review_Captured__c ;
            ff.Actual_Approver__c = saveRec?.Actual_Approver__c ;
          }
          const formData2 = new FormData();
          formData2.append("recLists", JSON.stringify(toUpdateAnyApproverTeams));
          await axios.post(`${fetchUrl}/recSave/list/Document_Review_Team__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,formData2)
          .then((res) => {
            //console.log("count:::: " + JSON.stringify(res));
            if(res.status === 200){
            }
          }).catch((err) => {
            console.log(err);
          });
        }
        if(nextAppStepDoc){
          const formData2 = new FormData();
          formData2.append("recObj", JSON.stringify(nextAppStepDoc));
          await axios.post(`${fetchUrl}/recSave/Document_Review__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,formData2)
          .then((res) => {
            //console.log("count:::: " + JSON.stringify(res));
            if(res.status === 200){
            }
          }).catch((err) => {
            console.log(err);
          });
        }
        getReviewDetails();
      }
    }).catch((err) => {
      console.log(err);
    });
    setUpdateState(false);
  };
  const getReviewDetails = async () => {
    setSpinner(true);
    axios.get(`${fetchUrl}/reviewDetail/${reviewId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.reviewObj) {
            setVariables(res.data.reviewObj);
            const values = _.map(res.data?.documents, (row) => {
              return prepareTableRowElement(row);
            });
            //setDocumentReviewList(values);
            setCurrentUserStep(values, userId);
          }
        }
        setUpdateDelegateUser(false);
        setSpinner(false);
      }).catch((err) => {
        setSpinner(false);
        setUpdateDelegateUser(false);
        console.log(err);
      });
  };
  const setCurrentUserStep = async(_documentReviewList = documentReviewList , _userId = userId) => {
    let uId = _userId?.value;
    let toStepChange = [] ;
    
    let totalDoc = 0 ;
    let _step;
    let docOnsameStep = 0 ;
    let currentStepApprovers = [];
    if(_documentReviewList?.length > 0 && uId){
      totalDoc = _documentReviewList.length ;
      for(let doc of _documentReviewList){
        let stepId = doc.record.Current_Approval_Step__c;
        console.log('stepId',stepId);
        let docAppInfo;
        let csInd;
        let caInd;
        let currentStep;
        let currAppStep;
        let dueDate ;
        let docRecForStepChange;
        let toActiveApproversNames = [];
        let toSubmitAnyApproverTeam = [];
        if(stepId && doc?.record?.steps?.length > 0){
          if(!_step){
            _step = stepId ;
          }
          const fInd = doc.record.steps?.findIndex((data) => data.stepId == stepId);
          if (fInd > -1) {
            let step = doc.record.steps[fInd];
            currentStep = {...step} ;
            if(_step == stepId){
              docOnsameStep++;
              //if(currentStepApprovers.length == 0){
                for(let ap of doc.record.steps[fInd].approvers){
                  currentStepApprovers.push(ap);
                }
              //}
            }
            //console.log('step',JSON.stringify(step));
            csInd = fInd;
            const aInd = doc.record.steps[fInd].approvers.findIndex((data) => data.Approver__c == uId);
            if (aInd > -1) {
              let appU = doc.record.steps[fInd].approvers[aInd];
              caInd = aInd;
              //console.log('appU',JSON.stringify(appU));
              dueDate = appU?.Due_Date__c ? moment(appU?.Due_Date__c).format("DD MMM YYYY") : '-'
              currAppStep = {Id : appU.Id , Review_Status__c : appU.Review_Status__c};
              if( appU.Review_Captured__c ){
                if(step.reviewType != 'Multiple Reviewer' || step.approvalType != 'Every member must review'){
                  // need to move to next step or approver
                }else{
                  docAppInfo = {msg : `Action pending from next ${step.participantType ? step.participantType.toLowerCase() : 'user'}.`}
                }
              }
              let allChecked = false ;
              let co = 0;
              for(let app of doc.record.steps[fInd].approvers){
                //if( ['Reviewed',...statusOptions].indexOf(app.Review_Status__c) >-1 ){
                if( app.Review_Captured__c ){
                  co++;
                  if(step.reviewType != 'Multiple Reviewer' || step.approvalType != 'Every member must review'){
                    allChecked = true;
                    break;
                  }
                }
              }
              if(!allChecked){
                allChecked = (co === doc.record.steps[fInd].approvers.length) ;
              }
              if(allChecked){
                docAppInfo = {msg : `Current Step Completed`} ;
                // need to move to next step 
                let nextStepInd = Number(fInd + 1);
                let neApp ;
                for( nextStepInd ; nextStepInd <doc.record.steps.length ; nextStepInd++ ){
                  if(doc.record.steps[nextStepInd].participantType && doc.record.steps[nextStepInd].participantType != 'Watcher'){
                    neApp = doc.record.steps[nextStepInd] ;
                    break;
                  }
                }
                if(neApp){
                  docRecForStepChange = { Id : doc.record.Id , Current_Approval_Step__c : neApp.stepId}
                }
              }
              if(allChecked && !docRecForStepChange){
                docAppInfo = {msg : `Completed`}
              }
            }else{
              docAppInfo = {msg : `Viewer not in current ${step.participantType ? step.participantType.toLowerCase() : 'user'} list.`}
            }

            // to update all user in current step if review type is multiple and approval type is any member
            for(let app of doc.record.steps[fInd].approvers){
              if( !app.Review_Captured__c ){
                toActiveApproversNames.push(app.Approver__r?.Name);
                if(currAppStep?.Id && currAppStep.Id != app.Id){
                  if(step.reviewType === 'Multiple Reviewer' && step.approvalType === 'Any member can review'){
                    toSubmitAnyApproverTeam.push({Id : app.Id, Review_Status__c : currAppStep.Review_Status__c});
                  }
                }
              }
            }
          } else {
            docAppInfo = { msg: 'Current active step not found.' }
          }
        } else {
          docAppInfo = { msg: 'Current active step not found.' }
        }
        console.log('currAppStep', JSON.stringify(currAppStep));
        console.log('docAppInfo', JSON.stringify(docAppInfo));
        console.log('docRecForStepChange', JSON.stringify(docRecForStepChange));
        console.log('toActiveApproversNames', JSON.stringify(toActiveApproversNames));
        console.log('toSubmitAnyApproverTeam', JSON.stringify(toSubmitAnyApproverTeam));
        if(_step == stepId){
          if(docRecForStepChange){
            toStepChange.push(docRecForStepChange);
          }
        }
        // doc.toUpdateForNextApprovalState = docRecForStepChange; // now updating with single button
        doc.currentStep = currentStep;
        doc.currAppStep = currAppStep;
        doc.docAppInfo = docAppInfo;
        doc.toActiveApproversNames = toActiveApproversNames;
        doc.toSubmitAnyApproverTeam = toSubmitAnyApproverTeam;
        if(dueDate){
          doc.dueDate = dueDate ;
        }
        doc.csInd = csInd;
        doc.caInd = caInd;
      }
      setDocumentReviewList(_documentReviewList);
    }
    let touu = toStepChange.length == totalDoc ? toStepChange : [] ;
    //console.log('touu :: '+JSON.stringify(touu));
    setStepChangeDocReviews( touu );
    let isAllDocOnCurrentStep_ = ((totalDoc == docOnsameStep) && _step ) ? _step : null ;
    setDocOnCurrentStepId( isAllDocOnCurrentStep_ );
    setCurrentStepApprovers( currentStepApprovers );
    setUpdateState(false);
  };
  const closeAndRefresh = async function (refresh) {
    console.log("closeAndRefresh :: " + refresh);
    if (refresh == true) {
      await getReviewDetails();
    }
  };
  const prepareTableRowElement = (rec) => {
    let tablePayload = {
      record: rec,
      id: rec?.Id ? rec?.Id : "",
      key: rec?.Id ? rec?.Id : "",
      title: rec?.Name ? rec?.Name : "-",
      docId: rec?.Document__c,
      comments: rec?.comments,
      commentsText: rec?.comments.length > 0 ? getCommenttext(rec?.comments) : "-",
      createdBy: rec?.CreatedById ? rec?.CreatedBy?.Name : "-",
      createdById: rec?.CreatedById ? rec?.CreatedById : "-",
      createdDate: rec?.CreatedDate ? moment(rec?.CreatedDate).format("DD MMM YYYY HH:mm") : "-",
      status: rec?.Review_Status__c ? rec?.Review_Status__c : "-",
      dueDate: rec?.Due_Date__c ? moment(rec?.Due_Date__c).format("DD MMM YYYY") : "-",
      approvedCount: rec?.Approved_Count__c,
      rejectedCount: rec?.Rejected_Count__c
    };
    function getCommenttext(comments) {
      let st = [];
      for (let com of comments) {
        st.push(com.Comment__c)
      }
      return st.join(', ');
    }
    if (rec.s3Rec) {
      //console.log('s3Rec',JSON.stringify(rec.s3Rec));
      let s3Rec = JSON.parse(JSON.stringify(rec.s3Rec));
      //console.log('s3Rec',JSON.stringify(s3Rec));
      //console.log('s3Rec?.File_Name__c',s3Rec?.File_Name__c);
      let jsonMarkup = s3Rec?.Markup_Json__c;
      //let markupBreakDown = getMarkupBreakDownFromInstantJSON(jsonMarkup);
      let documentDetails = {
        docId: s3Rec?.Id,
        docName: s3Rec?.File_Name__c ? s3Rec?.File_Name__c : "-",
        docTitle: s3Rec?.Document_Title__c ? s3Rec?.Document_Title__c : "-",
        folderPath: s3Rec?.Folder__r?.Folder_Path__c ? s3Rec?.Folder__r.Folder_Path__c : "-",
        version: s3Rec?.Version_Number__c ? `V${s3Rec?.Version_Number__c}` : "-",
        /*markup: markupBreakDown.annoList.length*/
      }
      //console.log('documentDetails',JSON.stringify(documentDetails));
      tablePayload = { ...tablePayload, ...documentDetails };
      //console.log('tablePayload',JSON.stringify(tablePayload));
    }
    return tablePayload;
  };
  const updateTableStateDataWithRow = async (rowEle) => {
    
    if (rowEle.id) {
      let _tempClonedList = JSON.parse(JSON.stringify(documentReviewList));
      if (_tempClonedList?.length > 0) {
        const fInd = _tempClonedList?.findIndex((data) => (data.id == rowEle.id));
        if (fInd > -1) {
          _tempClonedList[fInd] = rowEle;
          setDocumentReviewList(_tempClonedList); // init folder files
        }
      }
    }
  };
  function setVariables(_sfObj) {
    // need to set it to variables
    setName(_sfObj.Name);
    setDesc(_sfObj.Notes__c);
    __sfObj.current = _sfObj;
    //requiredMissing.current = !checkReadyToSave(__sfObj.current);
    setsfObj(_sfObj);
    // setsfObj((prv) => { return {...prv , ..._sfObj} } ); // dosent work
  }
  const onSaveDelegateApprovers = async (approvers)=>{
    setUpdateDelegateUser(true);
    const idList = _.map(currentStepApprovers, 'Id');
    const reqBody = {approvers : approvers , currentStepId : docOnCurrentStepId , existingTeamIds : idList};
    await axios.post(`${fetchUrl}/add_delegate_user/${reviewId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,reqBody)
    .then((res) => {
      //console.log("count:::: " + JSON.stringify(res));
      if(res.status === 200){
        getReviewDetails();
      }
    }).catch((err) => {
      console.log(err);
      setUpdateDelegateUser(false);
    });
  }
  const HeadIssueStyle = styled.div`
  .issueHead_section {
    display: flex;
    margin: 8px -4px;
    width: 100%;
    justify-content: space-between;
    .firstSection,
    .secondSection {
      display: flex;
      max-width: 50%;
    }
    .secondSection {
      .MuiFormControl-root,
      button {
        margin: 0 !important;
      }
    }
  }
`;
  return (
    <HeadIssueStyle>
      <div style={{ position: "relative" }}>
        {showSpinner && <Spinner />}
        {sfObj && 
	        <Typography variant="h5" style={{fontWeight: 600, background: "#f1f1f1", padding: "5px", fontSize: "18px", fontFamily: "circular", display: "flex", gap: "10px"}}>
            <div className="issueHead_section">
              <div className="firstSection">
                <Button className="btn btn-ornage" style={{background: "#ffa600", color: "#fff", padding: "3px 5px", borderRadius: "30px", fontSize: "12px", fontWeight: 600, height: "30px",
                                                            marginRight:'5px'}}>
                  {sfObj.Stage__c}
                </Button>
                <div style={{marginRight:'5px'}}>
                  #{sfObj.Numbering_Sequence__c} - {sfObj.Name}
                </div>
               {/*<div style={{marginRight:'5px'}}>
                  <Autocomplete
                    id='curuser'
                    className="inlineAutocomplete"
                    value={userId}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, newValue) => {
                      if(sfObj.Stage__c !== "Void"){
                      setUserId(newValue);
                      setCurrentUserStep(documentReviewList, newValue)
                      }
                    }}
                    options={props.userList}
                    renderInput={(params) => (
                      <div>
                        <TextField id="Current User" {...params} />
                      </div>
                    )}
                  />
                </div>*/}
                {updatingState && (
                  <Box className="custom-alert custom-alert-info" style={{margin:'0px'}}>Status update in progress.</Box>
                )}
                {updatingDelegateUser && (
                  <Box className="custom-alert custom-alert-info" style={{margin:'0px'}}>Updating State.</Box>
                )}
              </div>
              <div className="secondSection">
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                  {docOnCurrentStepId && avalableUserList?.length > 0 &&
                    <Button variant="contained" onClick={async (e) => {setDelegateConfirm(true);}}>Delegate Review</Button>
                  }
                  <Button disabled = {sfObj.Stage__c != "Open"} variant="contained"
                    onClick={async (e) => {setVoidConfrim(true);}}>Void</Button>
                    {toStepChangeDocReviews?.length > 0 && 
                      <Button disabled = {sfObj.Stage__c === "Void" || sfObj.Stage__c == "Closed"} variant="contained"
                        onClick={async (e) => {
                          setUpdateState(true);
                          const formData2 = new FormData();
                          formData2.append("recLists", JSON.stringify(toStepChangeDocReviews));
                          await axios.post(`${fetchUrl}/recSave/list/Document_Review__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,formData2)
                          .then((res) => {
                            //console.log("count:::: " + JSON.stringify(res));
                            if(res.status === 200){
                              getReviewDetails();
                            }
                          }).catch((err) => {
                            console.log(err);
                          });
                          setUpdateState(false);
                      }}>Complete Step</Button>
                    }
                  <IconButton className="btn" onClick={handleClose}><Close /></IconButton>
                </ButtonGroup>
              </div>
            </div>
          </Typography>
		    }
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: currentCommentRow ? '80%' : '100%' }}>
            <ReviewDetailTable
              columns={columns}
              data={documentReviewList.length > 0 ? documentReviewList : []}
              handleOpen={(row) => { setSelectedRow(row); /*console.log('doc row', JSON.stringify(row));*/ }}
              onClose={handleClose}  />
          </div>
          {delegateConfirm &&
            <DelegateReview 
              userList={avalableUserList}
              handleClosed={(refresh)=>{
                setDelegateConfirm(false);
              }}
              onSave={async (approvers)=>{
                onSaveDelegateApprovers(approvers);
                setDelegateConfirm(false);
              }}
            />
          } 
          {currentCommentRow &&
            <div style={{ width: '20%' }}>
              <DocumentComments
                //containerStyle={{ width: '400px', }}
                credential={credential}
                documentReviewId={currentCommentRow.id}
                comments={currentCommentRow.comments}
                canEditComments={currentCommentRow?.currAppStep && sfObj.Stage__c !== "Void" &&sfObj.Stage__c !== "Closed" && (currentCommentRow?.currentStep?.participantType == 'Approver' || currentCommentRow?.currentStep?.participantType == 'Reviewer') && currentCommentRow?.docAppInfo?.msg != 'Completed'}
                handleClose={(refresh) => {
                  setSelectedCommentRow(null);
                  if (refresh == true) {
                    getReviewDetails();
                  }
                }}
              />
            </div>
          }
        </div>
      </div>
      {toSubmitDetails && (
        <Dialog fullWidth open={true} scroll="paper">
          <DialogTitle>
              Submit Review
            <IconButton aria-label="close" className="dialog-header-close" onClick={() => { setSubmitDetails(null); }} >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography>
              {`#${sfObj.Numbering_Sequence__c} - ${sfObj.Name}`}
            </Typography>
            {`Your task for #${sfObj.Numbering_Sequence__c} - ${sfObj.Name} will be completed. 
              Your comments and markups will be visible to other participants. 
              Once you complete the task, you can no longer add, edit, or delete your comments.`}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => { setSubmitDetails(null); }} >
              Cancel
            </Button>
            <Button disabled={sfObj.Stage__c == "Closed"} variant="contained" onClick={async () => {
                let toSubDet = toSubmitDetails;
                setSubmitDetails(null);
                //console.log('toSubDet',JSON.stringify(toSubDet));
                await updateCurrentStep(toSubDet.cStep , toSubDet.toSave , toSubDet.nextAppStepDoc, toSubDet.toSubmitAnyApproverTeam , toSubDet?.setvalue);
              }} >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {voidConfirm &&
        <Dialog fullWidth open={true} scroll="paper">
          <DialogTitle>
            Void Entire Review
            <IconButton aria-label="close" className="dialog-header-close" onClick={async () => {
              setVoidConfrim(false);
            }}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography>
              #{sfObj.Numbering_Sequence__c} - {sfObj.Name}
            </Typography>
            <Typography>
              {`Are you sure you want to void the entire review for all the participants?`}
            </Typography>
            {`Once a review record has been voided, review participants 
              will no longer be able to add comments, markups, or continue this review`}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={async () => { setVoidConfrim(false); }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={async () => {
                setSpinner(true);
                setVoidConfrim(false);
                const formData = new FormData();
                formData.append("recObj", JSON.stringify({Id : sfObj.Id , Stage__c : 'Void'}));
                await axios.post(`${fetchUrl}/recSave/Review__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`,formData)
                  .then((res) => {
                    handleClose(true);
                    //  updateTableDataWithRow(jCl);
                    setSpinner(false);
                  })
                .catch((err) => {
                  setSpinner(false);
                  console.log("error", err);
                });
              }} >
              Void Review
            </Button>
          </DialogActions>
        </Dialog>
      }
    </HeadIssueStyle>
  );
}
export default ViewReviewDetails;