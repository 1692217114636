import React, { useState } from "react";
import BasicTabs from "components/Shared/Tabs";
import DrawerComponent from "components/DrawerComponent";
//import UploadLogs from 'components/upload-logs/UploadLogs';
import IssueTab from "components/IssuesTabs";
import ProjectFormTab from "components/ProjectFormTabComponent";
import useFormTabHook from "hooks/FormTab.hook";
import FormTemplateTab from "components/FormTemplateTabComponent";
import ReviewTab from "components/ReviewTab/ReviewTab";
import WorkflowTemplateTab from "components/WorkflowTemplateTabComponent";

function MainComponent({ value, setValue, projectId, credentials, tabNumber, ...props }) {
  const { tableFormList, projectTemplateFormList, ...forTabHook } =
    useFormTabHook({ props, projectId, credentials });
  const [tabs, setTabs] = useState([]);

  //console.log('***************forTabHook  ', forTabHook);
  //const {tableFormList, templateTypeOptions , defaultTemplateType , sectionItemResponseOptions , defaultItemResponse , _fetchFormLists , ...forTabHook} = useFormTabHook(props);
  /*const DATA = [
        { label: "Folders", component: <DrawerComponent projectId={projectId} tokenInfo={credentials} /> },
        { label: "Issues", component: <IssueTab projectId={projectId} /> },
        { label: "Project Forms", component: <ProjectFormTab projectId={projectId} credentials={credentials} forTabHook={forTabHook} tableFormList={projectTemplateFormList} /> },
        { label: "Templates", component: <FormTemplateTab projectId={projectId} credentials={credentials} forTabHook={forTabHook} tableFormList={tableFormList} /> }
        // { label: "UploadLogs", component: <UploadLogs /> },
        / *{ label: "Project Forms", component: <ProjectFormTab projectId={projectId} credentials={credentials} forTabHook={ {tableFormList , templateTypeOptions } }/>},
        { label: "Templates", component: <FormTemplateTab projectId={projectId} credentials={credentials} forTabHook={ {tableFormList  , templateTypeOptions , defaultTemplateType , sectionItemResponseOptions , defaultItemResponse , _fetchFormLists } }/>}* /
    ];*/
    let DATAj = [];
    if (true || credentials.ProfileName == 'System Administrator') {
        DATAj.push({ label: "Folders", component: <DrawerComponent projectId={projectId} tokenInfo={credentials} /> });
        DATAj.push({ label: "Issues", component: <IssueTab projectId={projectId} tokenInfo={credentials}/> });
    }
    DATAj.push({ label: "Project Forms", component: <ProjectFormTab projectId={projectId} credentials={credentials} forTabHook={forTabHook} tableFormList={projectTemplateFormList} /> });
    DATAj.push({ label: "Templates", component: <FormTemplateTab projectId={projectId} credentials={credentials} forTabHook={forTabHook} tableFormList={tableFormList} /> });
     DATAj.push({
    label: "Review",
    component: <ReviewTab projectId={projectId} tokenInfo={credentials} />,
  });
  DATAj.push({ label: "Workflow Template", component: <WorkflowTemplateTab projectId={projectId} tokenInfo={credentials}/> });
    //setTabs(DATAj);
    return (<>
        {
            DATAj?.length > 0 && (
                <BasicTabs value={props?.formTabvalue} setValue={props?.setFormTabValue} options={DATAj} tabNumber={tabNumber}/>
            )

        }
    </>)
}

export default MainComponent;