import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import { fetchUrl,inAppLoginUrl } from "Urls";
import { GetToken } from "api";
import Redirect from "Pages/Redirect";
import ViewReviewDetails from "components/ReviewTab/ViewReviewDetails";
import ReviewStyle from "./Review.style";
import {
  Dialog,
  DialogContent,
} from "@mui/material";
const ReviewRecordView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [reviewId, setReviewId] = useState(params?.reviewId);
  const [credential, setCredentials] = useState(null);
  const [formDetails, setFormDetails] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(true);
  useEffect(() => {
    getCreditials();
  }, []);
  async function getCreditials() {
    const data = await GetToken();
    setCredentials(data?.data);
    const token = data?.data?.token;
    const instanceUrl = data?.data?.instanceUrl;
    if (token && instanceUrl) {
      await axios.get(`${fetchUrl}/reviewRecord/${reviewId}?token=${token}&instanceUrl=${instanceUrl}`).then(async (res) => {
        console.log("reData", res?.data);
        if (res?.data?.status === 200) {
          const reData = res.data;
          console.log("reData", reData);
          setFormDetails(reData);
          return;
        } else {
          setFormDetails({ msg: 'Form record not found' });
        }
      }).catch((err) => {
        setFormDetails({ msg: 'Form record not found' });
        console.error(err);
      });
    } else {
      // login into salesforce
      window.location = `${inAppLoginUrl}reviewId=${reviewId}&viewReview=yes`;
    }
  }
  return (
    <div>
      {formDetails?.projectId ?
        <>
          <Redirect
            projectId={formDetails?.projectId}
            tabNumber={4}
          ></Redirect>
          <Dialog open={dialogOpen}
            style={{ height: "1000px", marginTop: "115px" }}
            fullScreen
          >
            
            <ViewReviewDetails
              credential={credential}
              reviewId={reviewId}
              userList={formDetails?.userList}
              handleClose={(refresh) => {
                setDialogOpen(false);
              }}
            ></ViewReviewDetails>
           
          </Dialog>
        </>
        :
        <>
          {formDetails?.msg ? <>{formDetails?.msg}</> :
            <>
              Fetching Form Data
            </>
          }
        </>
      }
    </div>
  );
};
export default ReviewRecordView;
