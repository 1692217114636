import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  List,
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SplitButtonActionStyle from "./SplitButtonActionStyle";
import downWhite from "assets/images/downWhite.svg";
export const SplitButtonAction = (props) => {
  const { items, onAction } = props;
  const [anchorEl, setAnchorEl] = useState(false);
  const [selectedItem, setSelectedItem] = useState(items[0]); // Initialize with the first item
  const [menuWidth, setMenuWidth] = useState();
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      //console.log("buttonWidth 1::", buttonRef);
      const buttonWidth = buttonRef.current.offsetWidth;
      const menu = document.getElementById("dropdown-menu");
      //console.log("buttonWidth 2::", buttonWidth);
      setMenuWidth(buttonWidth);
    }
  }, [selectedItem]);
  const handleClick = (event) => {
    setAnchorEl(!anchorEl);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleMenuItemClick = (item) => {
    setSelectedItem(item);
    setAnchorEl(false);
    onAction(item.label);
  };

  return (
    <SplitButtonActionStyle>
      <div className="button-dropdown">
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "8px" }}
        >
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            className="Button-1"
            onClick={() => onAction(selectedItem.label)}
            ref={buttonRef}
          >
            {selectedItem.icon && (
              <img
                style={{ width: "18px" }}
                src={selectedItem.icon}
                alt="icon"
              />
            )}
            {selectedItem.label}
          </Button>
          <img
            className="Button-dropDown"
            onClick={handleClick}
            style={{ width: "25px", height: "32px" }}
            src="down.svg"
            alt="dropDownIcon"
          />
        </div>
        {anchorEl && (
          <>
            <div
              id="dropdown-menu"
              className="dropdown-menu"
              style={{ width: `${menuWidth + 25}px` }}
            >
              {items
                .filter((item) => item !== selectedItem)
                .map((item, index) => (
                  <div
                    className="listView"
                    key={index}
                    style={{
                      display: "flex",
                      paddingTop: "5px",
                      paddingLeft: "10px",
                    }}
                    onClick={() => handleMenuItemClick(item)}
                  >
                    {item.icon && (
                      <div style={{ width: "20px" }}>
                        <ListItemIcon>
                          <img
                            style={{ width: "18px" }}
                            src={item.icon}
                            alt="icon"
                          />
                        </ListItemIcon>
                      </div>
                    )}
                    <div
                      style={{
                        marginLeft: "6px",
                        color: "#505050",
                        fontSize:
                          selectedItem.label == "Void Issue" ? "11px" : "14px",
                        fontWeight: 400,
                        fontStyle: "normal",
                        lineHeight: "normal",
                        marginTop: selectedItem.label == "Void Issue" && "2px",
                      }}
                    >
                      {item.label}
                    </div>
                  </div>
                ))}
            </div>
            <div className="outer-div" onClick={() => setAnchorEl(false)}></div>
          </>
        )}
      </div>
    </SplitButtonActionStyle>
  );
};
export const SplitButtonContainedAction = (props) => {
  const { items, onAction, label } = props;
  const [anchorEl, setAnchorEl] = useState(false);
  const [selectedItem, setSelectedItem] = useState(items[0]); // Initialize with the first item
  const [menuWidth, setMenuWidth] = useState();
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      //console.log("buttonWidth 1::", buttonRef);
      const buttonWidth = buttonRef.current.offsetWidth;
      const menu = document.getElementById("dropdown-menu");
      //console.log("buttonWidth 2::", buttonWidth);
      setMenuWidth(buttonWidth);
    }
  }, [selectedItem]);
  const handleClick = (event) => {
    setAnchorEl(buttonRef.current);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleMenuItemClick = (item) => {
    setSelectedItem(item);
    setAnchorEl(false);
    onAction(item.label);
  };

  return (
    <SplitButtonActionStyle>
      <div className="button-container-dropdown">
        <div
          style={{ display: "flex", alignItems: "center"}}
        >
          <Button
            aria-controls={anchorEl ? 'dropdown-menu' : undefined}
            aria-haspopup="true"
            className="custom_button apply_button"
            onClick={handleClick}
            ref={buttonRef}
          >
            {label}
          </Button>
          <Button className="custom_button apply_button" onClick={handleClick} style={{padding: "0px", minWidth: "40px", marginLeft:'1px'}} >
            <ArrowDropDownIcon sx={{color: 'white'}} />
          </Button>
        </div>
        <Menu
          id="dropdown-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
         }}
         transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
         }}
        >
          {items
            .map((item, index) => (
              <div style={{width: "auto", height: "35px", padding: "5px", display: "flex", cursor: "pointer", paddingTop: "10px"}} key={index} onClick={() => handleMenuItemClick(item)}>
            <div className="list-style">
              {item.icon &&
            <img style={{paddingRight: "8px", paddingLeft: "8px"}} src={item.icon} alt="icon" />
              }
                  {item.label}
                  </div>
              </div>
            ))}
        </Menu>
      </div>
    </SplitButtonActionStyle>
  );
};
