/* MultiSelectDropdown.css */
import React, { useEffect, useRef, useState } from "react";
import Styled from "styled-components";
/* Styles for the dropdown container */
const DropdownSelectStyle = Styled.div`
.select-dropdown {
    display: inline-block;
    position: relative;
    font-family: Arial, sans-serif;
  }
  /* Styles for the dropdown box */
  .dropdown-box {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    width: 359px;
  }
  
  /* Styles for the selected count (when dropdown is open) */
  .selected-count {
    margin-right: 10px;
    color: #929292;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  /* Styles for the selected values container (when dropdown is closed) */
  .selected-values-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
  
  /* Styles for individual selected values (when dropdown is closed) */
  .selected-value {
    display: flex;
    align-items: center;
    background-color: #E8EFFF;
    color: #505050;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 14px;
  }
  
  /* Styles for the cancel icon (when dropdown is closed) */
  .cancel-icon {
    margin-left: 4px;
    cursor: pointer;
  }
  
  /* Styles for the dropdown icon */
  .dropdown-icon {
    margin-left: auto;
    font-size: 16px;
  }
  .open .dropdown-icon{
    z-index:101;
  }
  .open .selected-value{
    z-index:101;
  }
  
  /* Styles for the dropdown menu (when open) */
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    width : 100%;
    z-index: 101;
    min-height: 100px !important;
    max-height: 250px !important;
  }
  
  /* Styles for the list items in the dropdown menu */
  
  .dropdown-menu li {
    cursor: pointer;
    font-family: var(--common-font);
  }
  
  /* Style for the placeholder text (when no option is selected) */
  .placeholder {
    color: #888;
  }
  .option-item {
    &:hover {
      background: #F8F8F8;
    }
  }
  .option-item.selected {
    background: #E8EFFF;
  }
  .option-item .check-hide {
    display:none;
  }
  .selected-checkbox .MuiSvgIcon-root {
    fill: green; /* Change to your desired color */
  }
  `;

  export default DropdownSelectStyle;