import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import StepperComponent from "components/Shared/Stepper";
import { CheckCircleOutline, Folder } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import _ from "lodash";
import Spinner from "components/spinner";

const ViewDialog = (props) => {
  const {
    selectedViewData,
    containerRef1,
    templateName,
    folderPath,
    _changeSplitValue,
    open,
    viewLogsTableData,
    handleClose,
    activeStep,
    setActiveStep,
    loader, selectedSplitData,
    loaderrev, setSelectedSplitData, saveSplit,preSelectedReviewTableRows,setSelectedReviewTableRows,duplicateAvailable,
    setDuplicateValidation,
    errorNumberState,
    setErrorNumberState,
  } = props?.viewData;

  const columnsB = [
    { field: "id", headerName: "ID" },
    {
      field: "image",
      flex: 1,
      headerName: "Image",
      width: 300,
      height: 500,
      sortable: false,
      renderCell: (param) => {
        return <img src={param?.value} />;
      },
    },
    {
      field: "src",
      headerName: "Title / Number",
      width: 300,
      height: 500,
      sortable: false,
      renderCell: (param) => {
        return (
          <>
            <div>
              <div>
                <img src={param?.row.titleSrc} />
              </div>
              <div>
                <img src={param?.row.numberSrc} />
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: "number",
      headerName: "Number",
      flex: 1,
      renderCell: (param, index) => {
         const Data = _.find(errorNumberState, { id: param?.id });
        return (
          <div style={{ display: "block !important" }}>
            <TextField
              type="text"
              value={param?.value}
              onChange={(e) => {
                _changeSplitValue(param?.id, "number", e?.target?.value);
              }}
              onKeyDown={(event) => {
                if (event.key.toLowerCase() != "tab") {
                  event.stopPropagation();
                }
              }}
            />
            { Data && duplicateAvailable && (
              <p className="error" style={{ color: "red" }}>
                {Data && Data?.error}
              </p>
            )} 
          </div>
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      renderCell: (param) => {
        // const Data = _.find(errorState, { id: param?.id });
        return (
          <div style={{ display: "block !important" }}>
            <TextareaAutosize
              type="textarea"
              value={param?.value}
              onChange={(e) => {
                _changeSplitValue(param?.id, "title", e?.target?.value);
              }}
              onKeyDown={(event) => {
                if (event.key.toLowerCase() != "tab") {
                  event.stopPropagation();
                }
              }}
            />
            {/* {Data && duplicateAvailable && (
              <p className="error" style={{ color: "red" }}>
                {Data && Data?.error}
              </p>
            )} */}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="4000px"
        onClose={handleClose}
        /*  classes={{
            paper: "view_detail_section",
          }}*/
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <StepperComponent handleNext={setActiveStep} activeStep={activeStep}>
            {activeStep === 1 && (
              <Box component={"div"} className="publichButton_Container">
                <Button style={{ marginTop: "15px", color: "black", backgroundColor: "white" }}
                  onClick={() => handleClose()}>Cancel</Button>
                <Button style={{ marginTop: "15px" }}
                  disabled={!(selectedSplitData?.length > 0 && duplicateAvailable == false) || loaderrev} variant="contained" onClick={() =>{ saveSplit();}}>Publish All {duplicateAvailable}</Button>
              </Box>
            )}
          </StepperComponent>
        </DialogTitle>
        <DialogContent>
          {/*<span>{'activeStep :: '+activeStep +' loaderrev :: '+loaderrev}</span>*/}
          {activeStep === 1 && <>
            <div className="first_Section" style={{ marginLeft: "1600px" }}>
              <h3><b>Review your documents</b></h3>
            </div>
            <Box component={"div"}>
              <div className="second_Section">
                <section className="left_section">
                  <div className="folderNameSection">
                    <div className="icon"><Folder />    {folderPath}</div>
                    <div className="folderName"></div>
                  </div>

                </section>
                <section>
                  <div className="templateName"><b>Layout Template :</b>  {templateName}</div>
                </section>
              </div>
            </Box>
            <div style={{ height: 500, width: "100%" }}>
              <DataGrid
                //className="TextareaAndNumerTable"
                rows={viewLogsTableData}
                loading={loaderrev}
                columns={columnsB}
                pageSize={100}
                rowsPerPageOptions={[]}
                checkboxSelection
                columnVisibilityModel={{ id: false }}
                onRowSelectionModelChange={(ids) => {
                  const selectedSplitData = viewLogsTableData?.filter(
                    (row) => ids.includes(+row?.id)
                  );
                  setSelectedSplitData(selectedSplitData);
                  setSelectedReviewTableRows(ids);
                }}
                hideFooter={true}
                autoPageSize={true}
                rowHeight={200}
                rowSelectionModel={preSelectedReviewTableRows}
                disableSelectionOnClick
                disableVirtualization
              />
            </div>
          </>}
          {activeStep === 2 && (
            <div className="Finished">
              <div>
                <CheckCircleOutline />
              </div>
              <h3>finish</h3>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
          style={{ color: "black", backgroundColor: "white" }}
            onClick={() => {
              handleClose();
              // PSPDFKit.unload(instance);
              // PSPDFKit.unload(containerRef1);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ViewDialog;
