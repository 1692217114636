import { useEffect, useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  TextField,
  InputLabel,
  Box,
  DialogActions,
  Button,
  ButtonGroup,
} from "@mui/material";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Close, CalendarToday, Error } from "@mui/icons-material";
import Radio from "@mui/joy/Radio";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import moment from "moment";
import { fetchUrl } from "Urls";
import SetpopUpStyle from "./SetpopUpStyle";
import { GetToken } from "api";

const SetsPopUp = ({
  openSets,
  setOpensets,
  selectedradio,
  setSelectedRadio,
  setUploadWithoutSet,
  credential,
}) => {
  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }
  //setting null for first time
  //
  const [selectedValue, setSelectedValue] = useState("Don't upload to a set");
  const [value, setValue] = useState(new Date());
  const [rows, setRow] = useState([]);
  const [nameDuplicate, setNameDuplicate] = useState(false);

  const handleChangeDate = (newValue) => {
    setValue(newValue);
    selectedradio["date"] = moment(newValue).format("MMM DD YYYY");
    selectedradio["date_sf"] = moment(newValue).format("YYYY-MM-DD");

    setSelectedRadio(selectedradio);
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value == "Don't upload to a set") {
      setUploadWithoutSet(true);
    } else {
      setUploadWithoutSet(false);
    }
    setSelectedRadio({
      id: "",
      date: event.target.value == "Upload to a new set" ? moment(value).format("MMM DD YYYY") : null,
      date_sf: event.target.value == "Upload to a new set" ? moment(value).format("YYYY-MM-DD") : null,
      name: "",
    });
  };
  console.log("f selectedradio", selectedradio);
  useEffect(() => {
    _getSetsApi();
  }, []);

  const columns = [
    {
      field: "radio",
      renderCell: (cellValues) => {
        return (
          <Radio
            disabled={selectedValue !== "Upload to an existing set"}
            checked={cellValues.id === selectedradio?.id}
            onChange={() => {
              setSelectedRadio({
                id: cellValues.id,
                date: cellValues.row.date,
                name: cellValues.row.name,
                date_sf: cellValues.row.date
              });
            }}
            value={cellValues.id}
            name="radio-buttons"
            inputProps={{ "aria-label": { cellValues } }}
          />
        );
      },
    },
    { field: "name" },
    {
      field: "date",
      renderCell: (parms) => {
        return (
          <>
            <CalendarToday />
            {parms.row.date}
          </>
        );
      },
    },
  ];

  async function _getSetsApi() {
    const data = await GetToken();
    const instanceUrl = data?.data?.instanceUrl;
    const token = data?.data?.token;
    const projectId = data?.data?.projectId;
    await axios
      .get(
        `${fetchUrl}/sets/${projectId}?token=${token}&instanceUrl=${instanceUrl}`
      )
      .then((res) => {
        //console.log("herererererer", res);
        if (res?.status === 200) {
          setRow(res?.data?.records);
          let rows = [];
          res?.data?.records?.map((val, index) => {
            //console.log("val>>>>>>>>", val);
            if (val?.Issuance_Date__c != null) {
              rows.push({ id: val?.Id, name: val?.Name, date: moment(val?.Issuance_Date__c).format("MMM DD YYYY"), date_sf: moment(val?.Issuance_Date__c).format("YYYY-MM-DD") });
            }
          });
          setRow(rows);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  return (
    <>
      <Dialog className="dialog_set_cls" open={openSets} maxWidth="lg">
        <SetpopUpStyle>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={() => { setOpensets(false); }} >
            Set Assignment
          </BootstrapDialogTitle>
          <div className="dialog_body">
            <h5 className="dialog_heading">
              Upload your project documents into an existing set or create a new set
            </h5>
            <div className="dialog_body_radiomain">
              <div className="dialog_body_radio_btns">
                <Radio
                  checked={selectedValue === "Don't upload to a set"}
                  onChange={handleChange}
                  value="Don't upload to a set"
                  name="radio-buttons"
                  componentsProps={{ input: { "aria-label": "A" } }}
                />
                <h5> Don't upload to a set </h5>
              </div>
              <div className="dialog_body_radio_btns_upload">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Radio
                    checked={selectedValue === "Upload to a new set"}
                    onChange={handleChange}
                    value="Upload to a new set"
                    name="radio-buttons"
                    componentsProps={{ input: { "aria-label": "B" } }}
                  />
                  <h5> Upload to a new set </h5>
                </div>
              </div>
              {selectedValue === "Upload to a new set" && (
                <div className="input-main">
                  <div className="left-cls">
                    <InputLabel>Name</InputLabel>
                    <TextField
                      disabled={selectedValue !== "Upload to a new set"}
                      id="outlined-password-input"
                      placeholder="Type set name"
                      value={selectedradio?.name}
                      name="name"
                      onChange={(e) => {
                        let ffound = false;
                        if (rows && rows.length > 0 && e.target.value && e.target.value != null && e.target.value.length > 2) {
                          let lCases = e.target.value.toLowerCase();
                          for (let el of rows) {
                            let elNam = el?.name?.toLowerCase();
                            //console.log(str.substring(1, 3));
                            if (elNam && !(lCases.length > elNam.length) && elNam.substring(0, lCases.length) === lCases) {
                              ffound = true;
                              break;
                            }
                          }
                        }
                        setNameDuplicate(ffound);
                        setSelectedRadio({
                          ...selectedradio,
                          name: e.target.value,
                        });
                      }}
                      error={nameDuplicate}
                      helperText={nameDuplicate ? 'Set name already exists' : ''}
                      autoComplete="off"
                    />
                  </div>
                  <div className="right-cls">
                    <InputLabel>Issuance date</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        disabled={selectedValue !== "Upload to a new set"}
                        disablePast={false}
                        placeholder="Choose date "
                        inputFormat="MM/dd/yyyy"
                        value={value}
                        onChange={handleChangeDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              )}
            </div>
            <div className="dialog_body_radio_btns_existing">
              <div className="radio-ex" style={{ display: "flex", alignItems: "center" }} >
                <Radio
                  checked={selectedValue === "Upload to an existing set"}
                  onChange={handleChange}
                  value="Upload to an existing set"
                  name="radio-buttons"
                  componentsProps={{ input: { "aria-label": "C" } }}
                />
                <h5> Upload to an existing set</h5>
                <Box>
                  <Error color="warning" />
                </Box>
              </div>
              {selectedValue === "Upload to an existing set" && (
                <div className="table-sx" style={{ display: 'flex', height: 400, width: "100%" }} >
                  {rows && (
                    <DataGrid
                      className={selectedValue !== "Upload to an existing set" ? "disable_class_datagrid" : ""}
                      rows={rows}
                      columns={columns}
                      headerHeight={0}
                      hideFooter={true}
                      autoPageSize={false}
                      components={{ Toolbar: QuickSearchToolbar }}
                      componentsProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: { debounceMs: 500 },
                        },
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <DialogActions>
            <ButtonGroup variant="contained" aria-label="outlined button group">
              <Button variant="outlined" sx={{ "marginRight": "2px" }} onClick={() => { setSelectedRadio({ id: "", date: "", name: "", date_sf: null }); setOpensets(false); }} >
                Cancel
              </Button>
              <Button disabled={nameDuplicate || (selectedValue !== "Don't upload to a set" && (selectedradio?.name === "" || selectedradio?.date === ""))}
                onClick={() => { setOpensets(false); }} >
                Continue
              </Button>
            </ButtonGroup>
          </DialogActions>
        </SetpopUpStyle>
      </Dialog>
    </>
  );
};
export default SetsPopUp;
