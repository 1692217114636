import * as React from "react";
import {useState} from 'react';
import { Tabs, Tab, Box } from "@mui/material";
import { TabPanel, a11yProps } from "./RelativeFunction";
import _ from "lodash";

export default function BasicTabs(props) {
  const { options, tabNumber } = props;

  const [value, setValue] = useState(() => {
if(tabNumber){
  return tabNumber
}
return 0;
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="tabs_class" sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          className="tabs_new"
          sx={{
            position: "relative",
            zIndex: "70",
            background: "#f7f9fa",
            borderBottom: "1px solid #ddd",
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {_.map(options, ({ label }, index) => (
            <Tab
              key={label}
              label={label}
              {...a11yProps(index)}
              sx={{ textTransform: "none" }}
            />
          ))}
        </Tabs>
      </Box>
      {_.map(options, ({ component }, index) => (
        <TabPanel key={index + value} value={value} index={index}>
          {component}
        </TabPanel>
      ))}
    </Box>
  );
}
