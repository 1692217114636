import React, { useState, useEffect, useRef } from "react";
import { DisciplineDialogStyles } from "components/DrawerComponent/DisciplineSettingDialog";
import axios from "axios";
import { fetchUrl } from "Urls";
import CloseIcon from "../../assets/images/close.svg";
import RestrictedIcon from './Icons/restricted_link_icon.svg'
import PublicShareIcon from './Icons/public_share_icon.svg'
import ShowPasswordIcon from './Icons/show_password_icon.png'
import HidePasswordIcon from './Icons/hide_password_icon.png'
import CopyLinkIcon from './Icons/copy_link_icon.svg'
import formIcon from './Icons/form_icon.svg'
import PasswordErrorIcon from './Icons/error.svg'
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import pdfRed from "assets/images/file.svg";
import folderIcon from "assets/images/folder.svg";
import { Dialog, DialogContent, DialogContentText, Typography, DialogActions, DialogTitle, IconButton, Button, Select, MenuItem, TextField, InputAdornment ,Switch } from "@mui/material";
import { cloneDeep } from "lodash";
import moment from "moment";
import { addDays ,addYears } from "date-fns";
import Spinner from "components/spinner";
import { copyTextToClipboard } from "api";

const anyOneSharing = 'Anyone with the link';
const restrictedSharing = 'Restricted';
const sharingOptionArray = [
    { label: anyOneSharing, value: anyOneSharing },
    { label: restrictedSharing, value: restrictedSharing }
];
const to_date = new Date();
const defaultExpiry = addDays(new Date() , 14);
const maxExpiry = addYears(new Date() , 1);
const newLink = {
    Password__c:'',
    Expiration_Date__c: moment(defaultExpiry).format('YYYY-MM-DD'),
    Attachment__c: null,
    Folder__c: null,
    Form__c: null
};
function ShareableLinkComponent({ ...props }) {
    const { onCancel, shareListItems , setToastMessage } = props;

    const [items, setItemsState] = useState([]);
    const itemsRef = useRef(items);
    const setItems = (value) =>{
        itemsRef.current = value;
        setItemsState(value);
    };
    const [showGeneral , setShowGeneral] = useState(false);
    const [generalSharing, setGeneralSharing] = useState(anyOneSharing);
    const [generalExpiryDate, setGeneralExpiryDate] = useState(defaultExpiry);
    const [generalPassword, setGeneralPassword] = useState('');
    const [showGeneralPassword, setShowGeneralPassword] = useState(false);

    const [isGeneratingLink, setIsGeneratingLink] = useState(false);
    const [linkGenerate, setLinkGenerate] = useState(false);
    const [deleteEnable, setEnableDelete] = useState(false);
    const [showSpinner, setSpinner] = useState(false);

    const [hasChange, setChange] = useState(false);

    useEffect(() => {
        if (items.length > 0) {
            setShowGeneral(items.length > 1);
            checkCommonSharingOption();
        }
    }, [items]);

    useEffect(() => {
        init();
    }, []);

    const init=()=>{
        if (shareListItems?.length > 0) {
            const filteredFiles = shareListItems.map(({ id, name, type }) => ({ id, name, type }));
            fetchLinkInformation(filteredFiles);
        }
    };

    const fetchLinkInformation = async (items) => {
        try{
            if(items?.length > 0){
                setSpinner(true);
                const cloneItems = JSON.parse(JSON.stringify(items));
                const folders = cloneItems.filter(t=> t.type ==='folder');
                const attachments = cloneItems.filter(t=> t.type ==='file');
                const forms = cloneItems.filter(t=> t.type ==='form');
                let e_links = []; // existing links
                if(folders?.length > 0 ){
                    let parentIds = folders.map(t=>t.id);
                    const links = await queryGeneratedLinks( parentIds , 'Folder__c');
                    if(links?.length > 0 ){
                        e_links = [...e_links , ...links];
                    }
                }
                if(attachments?.length > 0 ){
                    let parentIds = attachments.map(t=>t.id);
                    const links = await queryGeneratedLinks( parentIds , 'Attachment__c');
                    if(links?.length > 0 ){
                        e_links = [...e_links , ...links];
                    }
                }
                if(forms?.length > 0 ){
                    let parentIds = forms.map(t=>t.id);
                    const links = await queryGeneratedLinks( parentIds , 'Form__c');
                    if(links?.length > 0 ){
                        e_links = [...e_links , ...links];
                    }
                }
                let urlCount = 0 ;
                for(let itm of cloneItems){
                    let link = e_links.find(k=>{
                        return k.Folder__c == itm.id || k.Attachment__c == itm.id || k.Form__c == itm.id ;
                    });
                    if(!link){
                        link = cloneDeep(newLink);
                        if(itm.type == 'folder'){
                            link.Folder__c = itm.id;
                        }else if(itm.type == 'file'){
                            link.Attachment__c = itm.id;
                        }else if(itm.type == 'form'){
                            link.Form__c = itm.id;
                        }
                    }
                    itm.link = link;
                    //console.log('link :: ',link);
                    if(itm.link.Password__c){
                        itm.sharing = restrictedSharing ;
                    }else{
                        itm.sharing = anyOneSharing ;
                    }
                    if(itm.link.URL__c){
                        urlCount++;
                    }
                }
                setItems(cloneItems);
                setLinkGenerate(urlCount > 0 && urlCount === cloneItems.length);
            }
            setSpinner(false);
        }catch(e){
            console.log('fetch Link info failed',e) ;
            setSpinner(false);
        }
        setSpinner(false);
    };
    const queryGeneratedLinks = async (parentIds , parentField) => {
        const reqBody = { parentIds, parentField };
        const {data} = await axios.post(`${fetchUrl}/get-generated-link`, reqBody);
        return data;
    };
    const onCopyAllLink = async () => {
        let urls = itemsRef.current.map(itm=>itm.link.URL__c);
        onCopyLinks(urls);
    };
    const onCopyLinks = async (urls) => {
        if(urls?.length > 0){
            const textToCopy = urls.join('\n');
            let copied = copyTextToClipboard(textToCopy , document);
            if(setToastMessage){
                if(copied){
                    let msg = `Link${urls?.length > 1 ? 's' : ''} ${urls?.length > 1 ? 'have' : 'has'} been copied to clipboard`
                    setToastMessage(msg);
                }
            }
        }
    };


    // Check if all items have the same sharing option or if it's mixed
    const checkCommonSharingOption = () => {
        const firstOption = itemsRef.current[0]?.sharing;
        const isSameOption = itemsRef.current.every(item => item.sharing === firstOption);
        if (isSameOption) {
            setGeneralSharing(firstOption); // Set to the common sharing option
        } else {
            setGeneralSharing('Mixed Audience'); // Set to 'Mixed Audience'
        }
        const haveId = itemsRef.current.some(item => {
            if(item?.link?.Id){
                return true;
            }
            return false;
        });
        setEnableDelete(haveId);
    };

    // Handle when the common sharing option changes
    const handleCommonSharingOptionChange = (newOption) => {
        if (newOption === 'Mixed Audience') return; // Don't allow changing to 'Mixed Audience' directly
        // Update all items to the new common sharing option
        itemsRef.current.forEach((item, index) => {
            updateIndividualSharingOption(index, newOption);
        });
        setGeneralSharing(newOption); // Update common sharing option
    };

    // Function to update all list items' expiry dates when common is changed
    const handleGeneralExpiryDateChange = (newDate) => {
        setGeneralExpiryDate(newDate);
        let newD = moment(newDate);
        let putDate = newD.isValid() ? newD.format("YYYY-MM-DD") : null;
        const updatedItems = itemsRef.current.map(item =>{
            item.link.Expiration_Date__c = putDate;
            return item;
        });
        setItems(updatedItems);
        setChange(true);
    };

    const handleCommonPasswordRequired = (value) => {
        ///console.log("value ::", value);
        setGeneralSharing(value);
        const updatedItems = itemsRef.current.map(item =>{
            item.sharing = value;
            return item;
        });
        setItems(updatedItems);
        setChange(true);
    };

    const handleCommonPasswordChange = (value) => {
        setGeneralPassword(value);
        const updatedItems = itemsRef.current.map(item =>{
            item.link.Password__c = value;
            return item;
        });
        setItems(updatedItems);
        setChange(true);
    };


    // Function to update the individual sharing option for each item
    const updateIndividualSharingOption = (index, value) => {
        const updatedItems = [...itemsRef.current];
        updatedItems[index].sharing = value;
        setItems(updatedItems);
        setChange(true);
    };

    // Function to update the individual expiry date for each item
    const updateIndividualExpiryDate = (index, newDate) => {
        const updatedItems = [...itemsRef.current];
        updatedItems[index].link.Expiration_Date__c = moment(newDate).format('YYYY-MM-DD');
        setItems(updatedItems);
        setChange(true);
    };

    const updatePassword = (index, password) => {
        const updatedItems = [...itemsRef.current];
        updatedItems[index].link.Password__c = password;
        setItems(updatedItems);
        setChange(true);
    };

    const handleLinkGenerate = async () => {
        // use for password error check
        // const errorCheck = await checkPasswordMatch();
        let validateResult = validateItems();
        //console.log('validateResult :: ',JSON.stringify(validateResult));
        if(validateResult.error){
            setToastMessage({message : validateResult.error , variant : 'Error'})
        }else{
            if( validateResult.toSave?.length > 0 ){
                setSpinner(true);
                setIsGeneratingLink(true);
                const reqBody = { links : validateResult.toSave };
                //console.log('validateResult.toSave :: ',validateResult.toSave);
                await axios.post(`${fetchUrl}/generate-share-link`, reqBody).then(res=>{
                    if(res.data && res.status === 200){
                        setChange(false);
                    }
                }).catch(e=>{
                    console.log('generate link errors :: ',e);
                    if(setToastMessage){
                        setToastMessage({message : 'Error Generating Links' , variant : 'Error'})
                    }
                });
                init();
            }
        }
    };

    const handleDeleteLinks = async () => {
        setSpinner(true);
        const reqBody = itemsRef.current
        .filter(item => item.link?.Id) // Ensure item.link.Id is available
        .map(item => ({
          Id: item.link.Id,
          IsDeleted__c: true
        }));
        const formData = new FormData();
        formData.append("recLists", JSON.stringify(reqBody));
        await axios.post(`${fetchUrl}/recSave/list/Shared_Link__c`, formData)
        .then(async (res) => {
            if (res.data.status == 200) {
                if(setToastMessage){
                    let toastMess = 'Link has been successfully deleted'
                    setToastMessage(toastMess);
                }
                setChange(true);
            }
        }).catch((err) => {
            setSpinner(false);
            if(setToastMessage){
                setToastMessage({message : 'Failed to delete the link' , variant : 'Error'})
            }
            console.log('recSave/Set__c', err);
        });
        init();
    };

    const handleShowPassword = async (index, value) => {
        const updatedItems = [...itemsRef.current];
        updatedItems[index].showPassword = value;
        setItems(updatedItems);
    };
    const validateItems = () =>{
        let validateResult = {toSave : []};
        if(itemsRef.current?.length > 0 ){
            let chnge = false;
            const tempItem = cloneDeep(itemsRef.current);
            const to_date = moment().startOf('day');
            const next_todate = moment().add(1, 'year').startOf('day');
            //console.log('to_date :: ',to_date.format('YYYY-MM-DD'));
            //console.log('next_todate :: ',next_todate.format('YYYY-MM-DD'));
            for(let itm of tempItem){
                let err ;
                if(itm.sharing == restrictedSharing){
                    let pwd = itm.link.Password__c;
                    if(pwd && pwd.trim() !=''){
                        if(pwd.length < 6){
                            err = 'Password is too short! It must be at least 6 characters.';
                        }
                    }else{
                        err = 'Please input the password.';
                    }
                    itm.pwd_err = err;
                }else{
                    itm.link.Password__c = null;
                }
                if(!err){
                    itm.pwd_err = undefined;
                }
                if(!itm.link.Expiration_Date__c || itm.link.Expiration_Date__c == 'Invalid date'){
                    err = 'Please input valid expiration date.';
                    itm.date_err = err;
                }else{
                    let toCheckDate = moment(itm.link.Expiration_Date__c);
                    //console.log(itm.link.Expiration_Date__c,'itm.link.Expiration_Date__c :: ',toCheckDate.isValid());
                    let isBetween = toCheckDate.isBetween(to_date , next_todate);
                    //console.log(itm.link.Expiration_Date__c,'isBetween :: ',isBetween);
                    if(!isBetween){
                        err = `Date should be between ${to_date.format('MM/DD/YYYY')} and ${next_todate.format('MM/DD/YYYY')}`;
                        itm.date_err = err;
                    }else{
                        itm.date_err = undefined;
                    }
                }
                if(err){
                    chnge = true;
                    validateResult.error = 'Please check errors';
                    continue;
                }
                validateResult.toSave.push(itm.link);
            }
            if(chnge){
                setItems(tempItem);
            }
        }
        return validateResult;
    };
    const handleClose = () =>{
        let links = [];
        if(itemsRef.current?.length > 0 ){
            for(let itm of itemsRef.current){
                let obj = {id : itm.id};
                if( itm.link?.Id !=null ){
                    obj.link = itm.link;
                }
                links.push(obj);
            }
        }
        onCancel(links);
    };


    return (
        <Dialog open={true}>
            <DisciplineDialogStyles style={{ width: "450px" }}>
                <DialogTitle className="dialog_title">
                    Generate Shareable Link
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 12,
                            borderRadius: 0
                        }}
                    >
                        <img src={CloseIcon} alt="icons" />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="dialog_content" style={{ minHeight : '200px' , position: 'relative' , overflowY: items?.length == 1 ? 'unset' : 'auto'}}>
                    {showSpinner && <Spinner />}
                    {/* Common Sharing Option */}
                    {showGeneral && 
                        <div style={{ borderBottom: '1px solid #D8D8D8', padding: "16px 20px 16px 20px" }}>
                            <div style={{ color: 'black', fontWeight: 600, fontSize: '1.3rem', marginBottom: '10px' }}>General access</div>
                            <div className='link_options'>
                                <div className="link_option_icon" style={{ backgroundColor: generalSharing !== restrictedSharing && 'rgba(10, 161, 16, 0.08)' }}>
                                    <img src={generalSharing === restrictedSharing ? RestrictedIcon : PublicShareIcon} alt="restricted_link_icon" />
                                </div>
                                <div>
                                    <Select
                                        variant="standard"
                                        style={{ fontWeight: 600, height: '21px' }}
                                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: "none" } }}
                                        MenuProps={{
                                            autoFocus: false, PaperProps: {
                                                style: { width: '230px' }, className: "project-name"
                                            },
                                        }}
                                        disableUnderline={true}
                                        value={generalSharing}
                                        onChange={(e) => handleCommonSharingOptionChange(e.target.value)}
                                    >
                                        <MenuItem disabled value="Mixed Audience">
                                            Mixed Audience
                                        </MenuItem>
                                        {sharingOptionArray.map((option, i) => (
                                            <MenuItem key={i} value={option.value}>
                                                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                    {option?.label}
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <div className="mediaDetailsFeildValue" style={{ color: '#505050' }}>
                                        {generalSharing === restrictedSharing
                                            ? 'People can access the file within a specified time.'
                                            : generalSharing === 'Mixed Audience'
                                                ? 'Sharing options are mixed.'
                                                : 'No restrictions. Anyone can view the link.'}
                                    </div>
                                </div>
                            </div>
                            <div className="mediaDetailsFeildValue" style={{ color: '#505050', fontSize: '14px', marginBottom: '4px' }}>Access Expiry Date</div>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        value={generalExpiryDate}
                                        minDate={to_date}
                                        maxDate={maxExpiry}
                                        onChange={(newDate) => handleGeneralExpiryDateChange(newDate)}
                                        placeholder="Select Date"
                                        renderInput={(params) => <TextField {...params}
                                        className="Searchbar-field"
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                color: "#505050",
                                                height: "37px",
                                            },
                                        }} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div style={{ padding: "16px 0px 0px 0px" }}>
                                {/* Password Protection for single shareable item */}
                                <div style={{ display: 'flex', gap: '8px' ,alignItems : 'center'}}>
                                    <div>
                                        <Switch checked={generalSharing == restrictedSharing} onChange={(evt)=>
                                            handleCommonPasswordRequired(evt.target.checked ? restrictedSharing : anyOneSharing)
                                        }/>
                                    </div>
                                    <div className="mediaDetailsFeildValue" style={{ color: '#505050', fontSize: '14px', marginTop: '-5px' }}>
                                        Password Protection
                                    </div>
                                </div>
                                {generalSharing == restrictedSharing && (
                                    <div style={{ display: 'flex', gap: '12px', paddingTop: '16px' }}>
                                        <div>
                                            <div className="mediaDetailsFeildValue" style={{ color: '#505050', fontSize: '14px', paddingBottom: '4px' }}>
                                                Password
                                            </div>
                                            <TextField
                                                sx={{
                                                    "& .MuiInputBase-root": {
                                                        color: "#505050",
                                                        height: "37px",
                                                    },
                                                }}
                                                className='Searchbar-field'
                                                variant="outlined"
                                                type={showGeneralPassword ? "text" : "password"}
                                                fullWidth
                                                value={generalPassword}
                                                autoComplete="off"
                                                onChange={(e) => handleCommonPasswordChange(e.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            <img
                                                                style={{ width: "25px", cursor: "pointer" }}
                                                                src={showGeneralPassword ? HidePasswordIcon : ShowPasswordIcon}
                                                                alt="close icon"
                                                                onClick={() => {
                                                                    setShowGeneralPassword(!showGeneralPassword);
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    {/* Individual file options container */}
                    {items.length > 0 &&
                        <div style={{ maxHeight: '350px' , paddingBottom : 10, overflowY: items.length == 1 ? 'unset' : 'auto'}}>
                            {items.map((item, index) => (
                                <div key={item.id} style={{ padding: "16px 20px 0px 20px" }}>
                                    {/* File Name */}
                                    <div className="mediaDetailsFeildValue" style={{ color: 'Black', fontSize: '1rem', paddingBottom: '10px', fontWeight: 600 }}>
                                        <img src={item.type == 'file' ? pdfRed : item.type == 'folder' ? folderIcon : formIcon} alt="folderFileIcon" />
                                        <div className='truncate' style={{maxWidth : '80%'}}>
                                            {item.name}
                                        </div>
                                        {item.link?.URL__c && 
                                            <img src={CopyLinkIcon} alt='copy_link_icon' onClick={()=>onCopyLinks([item.link?.URL__c])}/>
                                        }
                                    </div>

                                    {/* Individual Sharing Option */}
                                    <div className="link_options" style={{ paddingBottom: '0px' }}>
                                        <div className="link_option_icon" style={{ backgroundColor: item.sharing !== restrictedSharing && 'rgba(10, 161, 16, 0.08)' }}>
                                            <img src={item.sharing === restrictedSharing ? RestrictedIcon : PublicShareIcon} alt="restricted_link_icon" />
                                        </div>
                                        <div>
                                            <Select
                                                variant="standard"
                                                style={{ fontWeight: 600, height: '21px', fontSize: '0.8rem' }}
                                                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: "none" } }}
                                                MenuProps={{
                                                    autoFocus: false, PaperProps: {
                                                        style: { width: '230px' }, className: "project-name"
                                                    },
                                                }}
                                                disableUnderline={true}
                                                value={item.sharing}
                                                onChange={(e) => updateIndividualSharingOption(index, e.target.value)}
                                            >
                                                {sharingOptionArray.map((option, i) => (
                                                    <MenuItem key={i} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <div className="mediaDetailsFeildValue" style={{ color: '#505050' }}>
                                                {generalSharing === restrictedSharing ? 'People can access the file with a password.' : 'No restrictions. Anyone can view the link.'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mediaDetailsFeildValue" style={{ color: '#505050', fontSize: '14px', marginTop: '16px' }}>Access Expiry Date</div>
                                    <div style={{paddingTop: '4px'}}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                value={item.link.Expiration_Date__c ? moment(item.link.Expiration_Date__c).utc().toDate() : null }
                                                minDate={to_date}
                                                maxDate={maxExpiry}
                                                onChange={(newDate) => updateIndividualExpiryDate(index, newDate)}
                                                placeholder="Select Date"
                                                renderInput={(params) => <TextField {...params}
                                                    className={item.date_err ? "Searchbar-field-red" : "Searchbar-field"}
                                                    sx={{
                                                        "& .MuiInputBase-root": {
                                                            color: "#505050",
                                                            height: "37px",
                                                        },
                                                    }} />
                                                }/>
                                        </LocalizationProvider>
                                        {item.date_err &&
                                            <div style={{ display: 'flex', gap: '4px', alignItems: 'center', marginBottom: '10px' }}>
                                                <div>
                                                    <img src={PasswordErrorIcon} alt="error_icon" />
                                                </div>
                                                <div className="mediaDetailsFeildValue" style={{ color: 'red' }}>
                                                    {item.date_err}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {/* Password Protection */}
                                    <div style={{ paddingTop: '16px', display: 'flex', gap: '8px',alignItems : 'center'}}>
                                        <div>
                                            <Switch key={item.id} id={item.id} checked={item.sharing == restrictedSharing} onChange={(evt)=>
                                                updateIndividualSharingOption(index, evt.target.checked ? restrictedSharing : anyOneSharing)
                                            }/>
                                        </div>
                                        <div className="mediaDetailsFeildValue" style={{ color: '#505050', fontSize: '14px', marginTop: '-5px' }}>
                                            Password Protection
                                        </div>
                                    </div>
                                    {/* Password Fields (if password protection is enabled) */}
                                    {item.sharing == restrictedSharing && (
                                        <>
                                            <div style={{paddingTop: '16px'}}>
                                                <div className="mediaDetailsFeildValue" style={{ color: '#505050', fontSize: '14px', paddingBottom: '4px' }}>
                                                    Password
                                                </div>
                                                <TextField
                                                    sx={{
                                                        "& .MuiInputBase-root": {
                                                            color: "#505050",
                                                            height: "37px",
                                                        },
                                                    }}
                                                    className={item.pwd_err ? "Searchbar-field-red" : "Searchbar-field"}
                                                    variant="outlined"
                                                    type={item.showPassword ? "text" : "password"}
                                                    value={item.link.Password__c}
                                                    fullWidth
                                                    autoComplete="off"
                                                    onChange={(e) => updatePassword(index, e.target.value)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                {item.showPassword ? (
                                                                    <img
                                                                        style={{ width: "25px", cursor: "pointer" }}
                                                                        src={ShowPasswordIcon}
                                                                        alt="close icon"
                                                                        onClick={() => {
                                                                            handleShowPassword(index, false);
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        style={{ width: "25px", cursor: "pointer" }}
                                                                        src={HidePasswordIcon}
                                                                        alt="close icon"
                                                                        onClick={() => {
                                                                            handleShowPassword(index, true);
                                                                        }}
                                                                    />
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                            {item.pwd_err &&
                                                <div style={{ display: 'flex', gap: '4px', alignItems: 'center', marginBottom: '10px' }}>
                                                    <div>
                                                        <img src={PasswordErrorIcon} alt="error_icon" />
                                                    </div>
                                                    <div className="mediaDetailsFeildValue" style={{ color: 'red' }}>
                                                        {item.pwd_err}
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    }
                </DialogContent>
                <DialogActions className="action_button">
                    {linkGenerate && !showSpinner &&
                        <div className="text-style-for-div" style={{ display: "flex", cursor: 'pointer' }} onClick={onCopyAllLink}>
                            <img src={CopyLinkIcon} alt='copy_link_icon' />
                            <div style={{ paddingLeft: "8px" }}>Copy Link</div>
                        </div>
                    }
                    <div style={{ marginLeft: "auto" }}>
                        {deleteEnable && !showSpinner &&
                            <Button className="custom_button red_button" style={{ marginRight: "0.8rem", color: 'red', background: 'white', border: '1px solid red' }} onClick={handleDeleteLinks}>
                                Delete Link
                            </Button>
                        }
                        {linkGenerate && !hasChange &&
                            <Button className={"custom_button apply_button"} onClick={handleClose}>
                                Done
                            </Button>
                        }
                        {(!linkGenerate || hasChange) &&
                            <Button className={`custom_button apply_button`} onClick={handleLinkGenerate} disabled={showSpinner}>
                                {isGeneratingLink ? 'Generating Link' : 'Generate Link'}
                            </Button>
                        }
                    </div>
                </DialogActions>
            </DisciplineDialogStyles>
        </Dialog>
    );
}
export default ShareableLinkComponent;
