import { useState, useEffect, useRef } from "react";
import CloseIcon from "assets/images/close.svg";
import _, { cloneDeep, find, } from 'lodash';
import moment from "moment";
import axios from "axios";
import { fetchUrl } from "Urls";
import { Search } from "@mui/icons-material";
import { Button, Dialog, TextField, MenuItem, Select, Box, InputAdornment } from "@mui/material";
import Paper from '@mui/material/Paper';
import errorRed from "assets/images/error-red.svg";
import { DataGrid } from '@mui/x-data-grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import BuildChecklistTemplate from "components/FormTemplateComponent/BuildChecklistTemplate";
import BuildPdfTemplate from "components/FormTemplateComponent/BuildPdfTemplate";
import Spinner from "components/spinner";
const CreateProjectForm = ({
    credential,
    onCancel,
    currentStep,
    setCurrentStep,
    templateOptions,
    sfObj,
    callFrom,
    sfFormDetail,
    onAccept,
    projectFormTemplates,
    fetchTemplateList,
	showTemplateSpinner,
    dialogData
}) => {
    const [selectionModel, setSelectionModel] = useState([]);
    const [templateList, setTemplateList] = useState([]);
    const [filterTemplateList, setFilterTemplateList] = useState();
    const [filterTemplateType, setFilterTemplateType] = useState("All");
    const [filterTemplateCategory, setFilterTemplateCategory] = useState("All");
    const [formTemplate, setFormTemplate] = useState(sfObj ? JSON.parse(JSON.stringify(sfObj)) : null);
    const [sfRecord, setSfRecord] = useState(sfFormDetail ? JSON.parse(JSON.stringify(sfFormDetail)) : defaultObj);
    const [formDate, setFormDate] = useState(new Date());
    const [lineCount, setAddLineCount] = useState(1);
    const currentTemplatList = useRef([]);
    const allTemplatList = useRef([]);
    const [searchBoxFilter, setSearchBoxFilter] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [showSpinner, setSpinner] = useState(false);
    const [createChecklistTemplate, setCreateChecklistTemplate] = useState(false);
    const [createPdfTemplate, setCreatePdfTemplate] = useState(false);
    function defaultObj() {
        let i = {
            Project__c: credential?.projectId,
            Ball_in_Court__c: credential?.userId,
            Ball_in_Court__r: { Id: credential?.userId, Name: credential?.userFullName }
        };
        return i
    }

    useEffect(() => {
        if(projectFormTemplates && projectFormTemplates.length > 0){
        const filteredData = projectFormTemplates.filter(item => item.status === 'Active' && item.stage === 'Published');
        currentTemplatList.current = filteredData;
        allTemplatList.current = projectFormTemplates;
        setTemplateList(filteredData);
        }
    }, [projectFormTemplates]);

    useEffect(() => {
        setSpinner(showTemplateSpinner)
    }, [showTemplateSpinner]);
    
    useEffect(() => {
        onHandleFilterData();
    }, [filterTemplateCategory, filterTemplateType]);
    const types = [
        {
            value: "All", label: "All"
        },
        {
            value: "Checklist", label: "Checklist"
        },
        {
            value: "PDF", label: "PDF"
        }
    ]
    const columns = [
        {
            field: 'radio',
            headerName: '',
            sortable: false,
            width: 40,
            renderCell: (params) => (
                <RadioGroup
                    value={selectionModel.includes(params.id)}
                    onChange={() => handleRadioChange(params.id, params?.row.title, params?.row?.Form_Type__c)}
                >
                    <FormControlLabel value={true} control={<Radio />} />
                </RadioGroup>
            ),
        },
        { field: 'title', headerName: 'Template Name', width: 200, },
        { field: 'type', headerName: 'Type', width: 96, },
        { field: 'category', headerName: 'Category', width: 120, },
    ];
    const handleSelectionModelChange = (newSelectionModel) => {
        // Find the full row data based on the selected row ID
        const selectedRowsData = newSelectionModel.map((id) =>
            templateList.find((row) => row.id === id)
        );
        if (!selectionModel.includes(selectedRowsData[0].id)) {
            setSelectionModel([selectedRowsData[0].id]);
            setFormTemplate({ label: selectedRowsData[0].name, value: selectedRowsData[0].id, type: selectedRowsData[0].type });
        }
    };

    const handleRadioChange = (id, name, type) => {
        if (!selectionModel.includes(id)) {
            setSelectionModel([id]);
            setFormTemplate({ label: name, value: id, type: type });
        }
    };
    function setVariables(_sfObj) {
        if (_sfObj.Date__c) {
            setFormDate(new Date(_sfObj.Date__c));
        } else {
            setFormDate(null);
        }
        setSfRecord(JSON.parse(JSON.stringify(_sfObj)));
    }
    const handleNextStep = (event) => {
        setCurrentStep((prev) => (prev + 1));
    };
    const handleBackStep = (event) => {
        setCurrentStep((prev) => (prev - 1));
    };
    const handleLineCountChange = (e) => {
        // Ensure the value does not exceed the limit of 10
        const newValue = e.target.value;
        if ((newValue >= 0 && newValue <= 10)) {
            setAddLineCount(Number(newValue));
        }
    };
    
    async function onSaveClick() {
        let tmpSec_ans = [];
        for (let i = 0; i < lineCount; i++) {
            let obj = {};
            obj.Name = sfRecord.Name + `${lineCount > 1 && tmpSec_ans?.length > 0 ? ' ' + tmpSec_ans?.length : ""}`;
            obj.Project__c = credential.projectId;
            obj.Form_Template__c = formTemplate.value;
            obj.Ball_in_Court__c = sfRecord.Ball_in_Court__c;
            obj.Ball_in_Court__r = sfRecord.Ball_in_Court__r;
            obj.Date__c = sfRecord.Date__c;
            tmpSec_ans.push(obj);
            //console.log('tmpSec_ans?.length ::',tmpSec_ans?.length,'linecount ::',lineCount , formTemplate.type);
            if (tmpSec_ans?.length === lineCount) {
                setSpinner(true);
                const formData = new FormData();
                formData.append("recLists", JSON.stringify(tmpSec_ans));
                if (formTemplate.type !== 'PDF') {
                    await axios.post(`${fetchUrl}/recSave/list/Form__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
                        .then((res) => {
                            if (res.status === 200) {
                                setSpinner(false);
                                if (tmpSec_ans.length === 1) {
                                    onCancel(true, res.data.retList[0].Id, formTemplate.type);
                                } else {
                                    onCancel(true);
                                }
                            }
                        }).catch((err) => {
                            console.log(err);
                            setSpinner(false);
                        });
                } else {
                    await axios.post(`${fetchUrl}/insertPdfForms/${formTemplate.value}`, formData)
                        .then((res) => {
                            console.log("insertPdfForms:::: " + JSON.stringify(res));
                            if (res.status === 200) {
                                setSpinner(false);
                                if (tmpSec_ans.length === 1) {
                                    onCancel(true, res.data.records[0].Id, formTemplate.type);
                                } else {
                                    onCancel(true);
                                }
                            }
                        }).catch((err) => {
                            console.log(err);
                            setSpinner(false);
                        });
                }
            }
        }
    }
    async function onHandleFilterData() {
        let filteredFoundList = [];
        let orgData = cloneDeep(currentTemplatList.current);
        const result = orgData.filter((item) => {
            const typeCondition = !filterTemplateType || filterTemplateType === 'All' || item.type === filterTemplateType;
            const categoryCondition = !filterTemplateCategory || filterTemplateCategory === 'All' || item.category === filterTemplateCategory;

            return typeCondition && categoryCondition;
        });
        setTemplateList(result);
        setFilterTemplateList(result)
    }
    async function searchListViewFunction(searchTerm) {
        setSearchBoxFilter(false);
        setSearchText(searchTerm);
        let orgData = filterTemplateList.length > 0 ? cloneDeep(filterTemplateList) : cloneDeep(currentTemplatList.current);
        let searchFoundList = [];
        if (orgData?.length > 0) {
            if (searchTerm && searchTerm !== null && searchTerm !== "") {
                searchFoundList = [];
                for (let el of orgData) {
                    if ((el.title && el.title.toLowerCase().includes(searchTerm))) {
                        searchFoundList.push(el);
                    }
                }
                setSearchBoxFilter(true);
            } else {
                searchFoundList = filterTemplateList.length > 0 ? cloneDeep(filterTemplateList) : cloneDeep(currentTemplatList.current);
                setTemplateList(searchFoundList);
            }
        }
        setTemplateList(searchFoundList);
    }
    function CustomNoRowsOverlay() {
        return (
            !searchBoxFilter ? (
                <div style={{ paddingTop: "20px", justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                    <div >
                        <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                            <img src="/template_empty.svg" alt="now template icon" />
                        </div>
                        <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', fontSize: '14px', color: '#505050', fontWeight: 600, lineHeight: 'normal', marginTop: '12px' }}>
                            No templates created
                        </div>
                        <div className="template_steps_body_text" style={{ paddingTop: '2px' }}>
                            To create a form, you must select a template.
                        </div>
                        <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', gap: "12px" }}>
                            <Button className="custom_button cancel_button" onClick={() => {
                                setCreateChecklistTemplate(true);
                            }}>
                                Build New Form
                            </Button>
                            <Button className="custom_button cancel_button" onClick={() => {
                                setCreatePdfTemplate(true);
                            }}>
                                Upload PDF
                            </Button>
                        </div>
                    </div>
                </div>
            ) : (
                <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>None of your templates matched this search</div>
            )

        );
    }
    return (
        <Dialog open={true} maxWidth="auto">
            <Paper sx={{ width: currentStep === 1 ? "888px" : "560px" }}>
                <div className="template_steps">
                    <div className="template_steps_head">
                        <div className="template_steps_right">
                            {dialogData.showSteps &&
                                <div className="template_steps_count">
                                    step {currentStep} of 2
                                </div>
                            }
                             <div className="template_steps_right_title">
                             {dialogData.headerTitle}
                            </div>
                        </div>
                        <div className="template_steps_left" onClick={onCancel}>
                            <img src={CloseIcon} alt="icons" />
                        </div>
                    </div>
                    <div className="template_steps_body">
                        {showSpinner && <Spinner />}
                        {currentStep === 1 &&
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "512px", padding: "16px 20px", borderRight: "1px solid #eaeaea" }}>
                                    <div className="template_steps_body_text" style={{ padding: "0px" }}>
                                        Select a template to use to proceed
                                    </div>
                                    <div style={{ display: "flex", gap: "12px", paddingTop: "10px" }}>
                                        <div>
                                            <TextField sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", width: "216px", }, }}
                                                inputProps={{ maxLength: 2000 }}
                                                id="outlined-basic"
                                                variant="outlined"
                                                className="Searchbar-field"
                                                autoComplete="off"
                                                required
                                                placeholder="Search Templates"
                                                value={searchText}
                                                onChange={(e) => {
                                                    //convert input text to lower case
                                                    var lowerCase = e.target.value.toLowerCase();
                                                    if (!lowerCase || lowerCase === null || lowerCase === "") {
                                                        searchListViewFunction(lowerCase);
                                                    }
                                                    setSearchText(lowerCase);
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        // Do code here
                                                        e.preventDefault();
                                                        var lowerCase = e.target.value.toLowerCase();
                                                        //console.log('searchText ',searchText , 'lowerCase',lowerCase);
                                                        searchListViewFunction(searchText);
                                                    }
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            {searchBoxFilter ? (
                                                                <img
                                                                    style={{ width: "25px", cursor: "pointer" }}
                                                                    src="/cancel.svg"
                                                                    alt="close icon"
                                                                    onClick={() => {
                                                                        searchListViewFunction("");
                                                                        setSearchText("");
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Search />
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <Select id="type" style={{ height: "37px", width: "120px", color: "#505050" }} placeholder="Type"
                                                value={filterTemplateType}
                                                renderValue={() => { return filterTemplateType }}
                                                onChange={(event) => {
                                                    let fff = sfRecord;
                                                    // Extract both value and name from the selected option
                                                    const selectedValue = event?.target?.value;
                                                    setFilterTemplateType(selectedValue);
                                                }}
                                            >
                                                {types &&
                                                    types.map(
                                                        (opt, index) => (
                                                            <MenuItem key={opt.value} value={opt.value} >
                                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                                    <div style={{ color: "#505050", fontWeight: filterTemplateType == opt.value && 600 }}>
                                                                        {opt.label}
                                                                    </div>
                                                                    {filterTemplateType == opt.value &&
                                                                        <div>
                                                                            <img src="check_blue.svg" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </div>
                                        <div>
                                            <Select id="type" style={{ height: "37px", width: "120px", color: "#505050" }}
                                                value={filterTemplateCategory}
                                                renderValue={() => { return filterTemplateCategory }}
                                                onChange={(event) => {
                                                    let fff = sfRecord;
                                                    // Extract both value and name from the selected option
                                                    const selectedValue = event?.target?.value;
                                                    setFilterTemplateCategory(selectedValue);
                                                }}>
                                                <MenuItem key={"All"} value={"All"} >
                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                        <div>
                                                            All
                                                        </div>
                                                        {filterTemplateCategory == "All" &&
                                                            <div>
                                                                <img src="check_blue.svg" />
                                                            </div>
                                                        }
                                                    </div>
                                                </MenuItem>
                                                {templateOptions?.checkListOptions?.Template_Category__c?.options &&
                                                    templateOptions?.checkListOptions?.Template_Category__c?.options.map(
                                                        (opt, index) => (
                                                            <MenuItem key={opt.value} value={opt.value} >
                                                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                                    <div>
                                                                        {opt.label}
                                                                    </div>
                                                                    {filterTemplateCategory == opt.value &&
                                                                        <div>
                                                                            <img src="check_blue.svg" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </div>
                                    </div>
                                    {templateList &&
                                        <Box className="template_data_grid" style={{ height: "416px", width: "490px" }}>
                                            <DataGrid
                                                slots={{
                                                    noRowsOverlay: CustomNoRowsOverlay,
                                                }}
                                                rows={templateList}
                                                columns={columns}
                                                rowSelectionModel={selectionModel}
                                                hideFooter
                                                disableColumnMenu
                                                sortingMode
                                                onRowSelectionModelChange={handleSelectionModelChange}
                                            />
                                        </Box>
                                    }
                                </div>
                                <div style={{ background: "#F8F8F8", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {templateList && templateList.length > 0 &&
                                        <div>
                                            <img src="/template.png" alt="checklisttemplate" />
                                        </div>
                                    }
                                    {templateList.length < 1 &&
                                        <div>
                                            No Template to Preview
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {currentStep === 2 &&
                            <>
                                {dialogData.showTemplateDetail &&
                                    <div style={{ display: "flex", margin: "16px 20px", border: "1px solid #EAEAEA", background: "#F8F8F8", color: "#505050", height: "42px", padding: "10px", gap: "5px" }}>
                                        <div><img src="/template.svg" alt="icon" /></div>
                                        <div><b>Template Selected:</b> {formTemplate?.label} ({formTemplate?.type})</div>
                                    </div>
                                }
                                {!sfRecord.Id &&
                                    <div className="template_steps_body_text">
                                        Kindly provide the essential details to continue
                                    </div>
                                }
                                <div className="template_steps_body_radio_1">
                                    <div style={{ marginBottom: "15px" }}>
                                        <div className="issue_label_name" style={{ paddingTop: "0px" }}>Form Name</div>
                                        <div>
                                            <TextField sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", width: "520px", }, }}
                                                inputProps={{ maxLength: 80 }}
                                                id="outlined-basic"
                                                variant="outlined"
                                                className={!sfRecord.Name ? "Searchbar-field-red" : "Searchbar-field"}
                                                autoComplete="off"
                                                required
                                                value={sfRecord?.Name ? sfRecord?.Name : ""}
                                                onChange={(e) => {
                                                    let fff = sfRecord;
                                                    fff.Name = e?.target?.value;
                                                    setVariables(fff);
                                                }}
                                                onKeyDown={(event) => {
                                                    if (event.key.toLowerCase() != "tab") {
                                                        event.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </div>
                                        {!sfRecord.Name &&
                                            <div style={{ fontSize: "12px", color: "red", paddingTop: "5px", fontWeight: 400 }}>
                                                <img style={{ marginRight: "5px" }} src={errorRed} alt="error" /> This is required. Please input an Form Name.
                                            </div>
                                        }
                                    </div>
                                    {/*<div style={{ marginBottom: "15px" }}>
                                        <div className="issue_label_name" style={{ paddingTop: "0px" }}>Location</div>
                                        <div>
                                            <Select id="type" style={{ height: "37px", width: "520px", color: "#505050" }}
                                            >
                                            </Select>
                                        </div>
                                    </div>*/}
                                    <div style={{ marginBottom: "15px" }}>
                                        <div className="issue_label_name" style={{ paddingTop: "0px" }}>Ball In Court</div>
                                        <div>
                                            <Select id="type" style={{ height: "37px", width: "520px", color: "#505050" }}
                                                sx={{ "& .MuiOutlinedInput-notchedOutline": { borderColor: !sfRecord.Ball_in_Court__c && "red" } }}
                                                value={sfRecord?.Ball_in_Court__c}
                                                onChange={(event) => {
                                                    let fff = sfRecord;
                                                    fff.Ball_in_Court__c = event?.target?.value;
                                                    if (fff.Ball_in_Court__c) {
                                                        let dsfIssue = find(credential?.userList, { value: fff.Ball_in_Court__c, });
                                                        fff.Ball_in_Court__r = { Id: event?.target?.value, Name: dsfIssue?.label };
                                                    } else {
                                                        fff.Ball_in_Court__r = null;
                                                    }
                                                    setVariables(fff);
                                                }}
                                            >
                                                {credential?.userList &&
                                                    credential?.userList.map(
                                                        (opt, index) => (
                                                            <MenuItem key={opt.value} value={opt.value}>
                                                                {opt.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </div>
                                        {!sfRecord.Ball_in_Court__c &&
                                            <div style={{ fontSize: "12px", color: "red", paddingTop: "5px", fontWeight: 400 }}>
                                                <img style={{ marginRight: "5px" }} src={errorRed} alt="error" /> This is required. Please select an Ball In Court.
                                            </div>
                                        }
                                    </div>
                                    <div style={{ marginBottom: "15px" }}>
                                        <div className="issue_label_name" style={{ paddingTop: "0px" }}>Due Date</div>
                                        <div style={{ width: "520px" }}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ height: "37px", width: "520px", color: "#505050" }}>
                                                <DesktopDatePicker
                                                    sx={{ height: "37px", width: "520px", color: "#505050" }}
                                                    className="common_class"
                                                    disablePast={false}
                                                    placeholder="Form Date"
                                                    minDate={new Date()} // Restricting to today onwards
                                                    inputFormat="MM/dd/yyyy"
                                                    value={sfRecord?.Date__c}
                                                    onChange={(newValue) => {
                                                        let fff = sfRecord;
                                                        if (newValue) {
                                                            fff.Date__c = moment(newValue).format("YYYY-MM-DD");
                                                        } else {
                                                            fff.Date__c = null;
                                                        }
                                                        setVariables(fff);
                                                    }}
                                                    renderInput={(params) =>
                                                        <div>
                                                            <TextField sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", width: "520px", }, }} id={"formDate"} variant="outlined" label="Form Date" required {...params} />
                                                        </div>
                                                    }
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                    {!sfRecord.Id &&
                                        <div style={{ marginBottom: "15px" }}>
                                            <div className="issue_label_name" style={{ paddingTop: "0px" }}>Form Instance <img src="/info_grey.svg" /></div>
                                            <div>
                                                <TextField sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", width: "520px", }, }}
                                                    inputProps={{ maxLength: 80 }}
                                                    name="lineCount"
                                                    id="lineCount"
                                                    type="number"
                                                    variant="outlined"
                                                    className="Searchbar-field"
                                                    autoComplete="off"
                                                    required
                                                    value={lineCount}
                                                    onChange={handleLineCountChange}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                    </div>
                    <div className="template_steps_footer">
                        {currentStep === 1 &&
                            <div className="template_steps_footer_button">
                                <Button className="custom_button cancel_button" onClick={onCancel}>Cancel</Button>
                                <Button disabled={!formTemplate} className="custom_button apply_button" onClick={handleNextStep}>Next</Button>
                            </div>
                        }
                        {currentStep === 2 &&
                            <div className="template_steps_footer_button">
                                    <Button className="custom_button cancel_button" onClick={dialogData.showSteps ? handleBackStep : onCancel}>{dialogData.button1}</Button>
                                    <Button disabled={!sfRecord.Name || !sfRecord.Ball_in_Court__c} className="custom_button apply_button" onClick={() => {dialogData.button2 == 'Save Changes' ?  onAccept(sfRecord) :  onSaveClick()}}>{dialogData.button2}</Button>
                            </div>
                        }
                    </div>
                </div>
                {createChecklistTemplate &&
                    <BuildChecklistTemplate
                        _templateOptions={templateOptions}
                        templateId={null}
                        templateList={allTemplatList.current}
                        projectTemplateId={null}
                        onCancel={(refresh) => {
                            //console.log('onCancel :: '+refresh);
                            setCreateChecklistTemplate(false);
                            if (refresh == true) {
                                fetchTemplateList();
                            }
                        }}
                        onAccept={(sfObjR) => {
                            const sObj = { value: sfObjR.Id, type: sfObjR.Form_Type__c, label: sfObjR?.Name };
                            sObj.Description__c = sfObjR?.Description__c;
                            // setCreateForm(sObj);
                            fetchTemplateList();
                        }}
                        credential={credential}
                    />
                }
                {createPdfTemplate &&
                    <BuildPdfTemplate
                        _templateOptions={templateOptions}
                        templateId={null}
                        projectTemplateId={null}
                        onCancel={(refresh) => {
                            //console.log('onCancel :: '+refresh);
                            setCreatePdfTemplate(false);
                            if (refresh == true) {
                                fetchTemplateList();
                            }
                        }}
                        onAccept={(sfObjR) => {
                            const sObj = { value: sfObjR.Id, type: sfObjR.Form_Type__c, label: sfObjR?.Name };
                            sObj.Description__c = sfObjR?.Description__c;
                            fetchTemplateList();
                        }}
                        credential={credential}

                    />
                }
            </Paper>
        </Dialog>
    );
}
export default CreateProjectForm;